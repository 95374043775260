import React, { useEffect, useRef, useState } from "react";
import { useSelector } from "react-redux";
import * as Yup from "yup";
import { Field, Form, Formik } from "formik";
import toast, { Toaster } from "react-hot-toast";
import Modal from "../../../../Components/Modal/Modal";
import Spinner from "../../../../Components/Loadig";
import ErrorModal from "../../../../Components/Modal/ErrorModal";
import SuccessModal from "../../../../Components/Modal/SuccessModal";
import { SelectField } from "../../../../Components/SelectField";
import { TextAreaField } from "../../../../Components/TextAreaField";
import { TextField } from "../../../../Components/TextField";
import CostoGruaModal from "./CostoGruaModal";
import Llamada from "../../../../Services/Cartera/Llamadas";
import { FormatFecha, message } from "../../../../Utils/functions";
import CobranzaService from "../../../../Services/Cobranza/Cobranza";
import dataDocsServices from "../../../../Services/DataDocs/dataDocsServices";
import AuthLogin from "../../../../Utils/AuthLogin";
import axios from "axios";
import { useNavigate } from "react-router-dom";
import VisorFile from "../../../../Components/VisorFile";
import SubirDocumentoModal from "./SubirDocumentoModal";
import { v4 as uuidv4 } from 'uuid';

const auth = new AuthLogin();
const RecoleccionDeUnidadCard = React.forwardRef(({ ...props }, ref) => {
  const {itemGeneral, contratoActual} = useSelector((state) => state.SeguimientoVisitaDetalleSlice);
  const [collpsableGrua, setCollpsableGrua] = useState(false);
  const [gruaSeleccionada, setGruaSeleccionada] = useState(0);
  const [costoGrua, setCostoGrua] = useState(0);
  const [iva, setIva]= useState(0);
  const [openModal, setIsOpenModal] = useState(false);
  const [dataMunicipio, setDataMunicipio] = useState([]);
  const [dataColonia, setDataColonia] = useState([]);
  const [dataEstado, setDataEstado] = useState([])
  const userName = sessionStorage.getItem('usuario')
  const userId = sessionStorage.getItem('userId').trim()
  const [fileName, setFileName] = useState("");
  const [archivoSeleccionado, setArchivoSeleccionado] = useState({})
  let url = auth.UrlDataDocs();
  const navigate = useNavigate();
  const [SelectedDocs, setSelectedDocs] = useState([]);
  const [isOpenDocs, setIsOpenDocs] = useState(false)
  const [archivoId, setArchivoId] = useState("")
  const [openModalSubirDocumento, setOpenModalSubirDocumento] = useState(false)
  const [confirmarOperacion, setConfirmarOperacion] = useState("")
  const [nombreDocumento, setNombreDocumento] = useState("")
  const [archivosCargados, setArchivosCargados] = useState([])
  const [show, setShow] = useState(false);
  useEffect(() => {
    setShow(props.show);
  }, []);

  let initial = {
    folio: "01193881",
    fechaInicioProceso: "23/10/2023 09:00",
    fechaFinProceso: "23/10/2023 09:00",
    usuarioProgramo: userName,
    tipoGrua: "",
    empresa: "",
    empresa_id: "",
    gruaAsignada: "",
    costoGrua: "",
    estado: "",
    municipio: "",
    colonia: "",
    calle: "",
    numero: "",
    referenciasUbicacion: "",
    ubicacionDestino: "",
    fechaRecoleccion: "",
    horaRecoleccion: "",
    reg_concluido: false
  };

  const [initialValue, setInitialValue] = useState(initial);
  const [loading, setLoading] = useState(false);
  const [modalMessage, setModalMessage] = useState({
    isOpen: false,
    type: 1,
    title: "",
    message: "",
    data: [],
    authorizationType: 2,
    Origin: "Listado",
  });

  const dataGrua = [
    { value: 1, label: "Externa" },
    { value: 2, label: "Datamovil" },
  ];

  const dataEmpresa = [
    { value: 1, label: "Externa" },
    { value: 2, label: "Datamovil" },
  ];

  const dataUbicacion = [
    { value: 1, label: "Ubicación 1" },
    { value: 2, label: "Ubicación 2" },
  ];

  const dataHoras = [
    { value: 1, label: "00:00" },
    { value: 2, label: "01:00" },
    { value: 3, label: "02:00" },
    { value: 4, label: "03:00" },
    { value: 5, label: "04:00" },
    { value: 6, label: "05:00" },
    { value: 7, label: "06:00" },
    { value: 8, label: "07:00" },
    { value: 9, label: "08:00" },
    { value: 10, label: "09:00" },
    { value: 11, label: "10:00" },
    { value: 12, label: "11:00" },
    { value: 13, label: "12:00" },
    { value: 14, label: "13:00" },
    { value: 15, label: "14:00" },
    { value: 16, label: "15:00" },
    { value: 17, label: "16:00" },
    { value: 18, label: "17:00" },
    { value: 19, label: "18:00" },
    { value: 20, label: "19:00" },
    { value: 21, label: "20:00" },
    { value: 22, label: "21:00" },
    { value: 23, label: "22:00" },
    { value: 24, label: "23:00" },
  ];

  const validate = Yup.object({
    tipoGrua: Yup.string().required("El campo es requerido."),
    gruaAsignada: Yup.string().required("El campo es requerido."),
    empresa: Yup.string().required("El campo es requerido."),
    estado: Yup.string().required("El campo es requerido."),
    municipio: Yup.string().required("El campo es requerido."),
    colonia: Yup.string().required("El campo es requerido."),
    calle: Yup.string().required("El campo es requerido."),
    numero: Yup.string().required("El campo es requerido."),
    ubicacionDestino: Yup.string().required("El campo es requerido."),
    horaRecoleccion: Yup.string().required("El campo es requerido."),
  });

  const HandleGetEstadoMx = async () => {
    await Llamada.GetEstadoMx().then(res => {
        let Estados = []
        res.data.data?.map(item => {
            Estados.push({
                value: item.edoMexicoId,
                label: item.estado
            });
        });
        setDataEstado(Estados);
    })
  }

  const HandleGetMunicpio = async (id) => {
      setDataMunicipio([]);
      await Llamada.GetMunicipio(id).then(res => {
          let Municipios = []
          res.data?.data?.map(item => {
              Municipios.push({
                  value: item.municipioId,
                  label: item.municipio
              });
          });
          setDataMunicipio(Municipios);
      })
  }
  const HandleGetColonia = async (id) => {
      setDataColonia([])
      await Llamada.GetColonia(id).then(res => {
          let Colonias = []
          res.data?.data?.map(item => {
              Colonias.push({
                  value: item.coloniaId,
                  label: item.colonia
              });
          });
          setDataColonia(Colonias);
      })
  }

  useEffect(()=>{
    HandleGetEstadoMx();
  },[])

  useEffect(()=>{
    if(confirmarOperacion !== ""){
      CreateRelacionRecoleccionDocumento()
      setConfirmarOperacion("")
    }
  },[confirmarOperacion])

  const handleGuardar = async (values,actions) =>{
    // setLoading(true);

    let data = {
      "verb": "string",
      "id": "string",
      "field": "string",
      "value": "string",
      "level": 0,
      "flag": true,
      "anyObject": {
        "id":  uuidv4(),
        "ruta_head_id": itemGeneral.ruta_head_id,
        "ruta_item_id":itemGeneral.id,
        "evento_head_id": itemGeneral.evento_head_id,
        "evento_item_id": itemGeneral.evento_item_id,
        "user_id":userId,
        "grua": values.tipoGrua,
        "empresa_id": values.empresa_id,
        "empresa_nombre": values.empresa,
        "costo_client":0,
        "grua_asignada":values.gruaAsignada,
        "ubi_origen_estado": values.estado,
        "ubi_origen_municipio": values.municipio,
        "ubi_origen_colonia": values.colonia,
        "ubi_origen_calle": values.calle,
        "ubi_origen_numero": values.numero,
        "ubi_origen_comentarios": values.referenciasUbicacion,
        "costo_grua": costoGrua,
        "iva": Number(iva.toFixed(2)),
        "total": costoGrua + iva,
        "ubi_destino": values.ubicacionDestino,
        "fecha_hora_recoleccion": `${FormatFecha(values.fechaRecoleccion)} ${values.horaRecoleccion}`
      }
    }

    console.log(data)

    // await CobranzaService.CreateRecoleccionUnidad(data)
    //   .then(res => {
    //     setLoading(false)
    //     toast.success(message("¡Operación exitosa!", res.data.mensaje));
    //     actions.resetForm();
    // }).catch(e => {
    //   console.log(e)
    //     setLoading(false)
    //     if (e.response !== undefined)
    //       toast.error(message("¡Error!", e.response.data.message));
    //     else
    //       toast.error(message("¡Error!", e.message));
    // })
  }

  const CreateRelacionRecoleccionDocumento = async()=>{
    // const data = {
    //   "verb": "string",
    //   "id": "string",
    //   "field": "string",
    //   "value": "string",
    //   "level": 0,
    //   "flag": true,
    //   "anyObject": {
    //     "id":archivoId,
    //     "visita_dom_id":infoVisitaDom.id,
    //     "tipo_doc":"documento",
    //     "extension_doc":".pdf",
    //     "nombre_doc":nombreDocumento,
    //     "descripcion":"",
    //     "url":"",
    //     "subido":1,
    //     }
    // }

    // await CobranzaService.(data)
    //   .then(res=>{
    //     toast.success(message("Documento relacionado correctamente", res.data.mensaje));
    //   })
    //   .catch(e => {
    //     setLoading(false)
    //     if (e.response !== undefined)
    //       toast.error(message("¡Error!", e.response.data.message));
    //     else
    //       toast.error(message("¡Error!", e.message));
    //   })
  }

  const handleGetTokenDatadocs = async () => {
    await dataDocsServices.UpdateTokenDataDc()
      .then(res => {
        auth.handleAuthSesionDataDocs(res.data.data);
      }).catch(e => { })
  }
  const handleViewdocs = async () => {
    setLoading(true);
    setFileName(archivoSeleccionado.label);
    await handleGetTokenDatadocs();
    await axios.post(`${url}Documento/GetFileById/${archivoSeleccionado.idFolder}/${archivoSeleccionado.value}/false`, {}, {
        headers: {
            'Authorization': 'Bearer ' + auth.tokenDataDocs(),
        },
        responseType: "blob"
    })
    .then(res => {
        var file = new File([res.data], res.data.name, { type: res.data.type });
        setSelectedDocs([file])
        setLoading(false);
        setIsOpenDocs(true)
    }).catch(e => {
        setLoading(false);
        if (e.response !== undefined)
            toast.error(message("Error.!", e.response.data.errors?.error));
        else
            toast.error(message("Error.!", e.message));
    })
  }

  const handleVisualizarDocumentos = async()=>{
    await handleViewdocs()
  }

  const handleUploadFile = async () => {
    const archivoId = uuidv4();
    setArchivoId(archivoId)
    navigate(`/cobranza/seguimiento-visita/datadocs/fRecupera/eRecupera/${contratoActual?.contrato_id}/${archivoId}`, {
      state: { ...contratoActual, registroId: archivoId, vin: contratoActual.vin, nombreMarca: "", nombreModelo: "", nombreVersion: "", nombreYear: "" }
    });
  }

  const handleSubirDocumento = () =>{
    const archivoId = uuidv4();
    setArchivoId(archivoId)
    setOpenModalSubirDocumento(true)
  }


  return (
    <>
      <Modal isOpen={loading} color="transparent">
        <Spinner message={""} />
      </Modal>
      <Modal isOpen={modalMessage.isOpen} color="#fff" width={660}>
        {modalMessage.type === 1 && (
          <ErrorModal
            modalMessage={modalMessage}
            setModalMessage={setModalMessage}
          />
        )}
        {modalMessage.type === 2 && (
          <SuccessModal
            modalMessage={modalMessage}
            setModalMessage={setModalMessage}
          />
        )}
      </Modal>
      <Toaster
        position="top-right"
        toastOptions={{
          success: {
            style: {
              background: "#47a066",
              color: "#FFFF",
              borderLeft: "10px solid #2f7246",
            },
          },
          error: {
            style: {
              background: "#d53f3f",
              color: "#FFFF",
              borderLeft: "10px solid #ac241a",
            },
          },
        }}
      />
      {show && (
      <div className="row wrapper-vehiculo">
        <div className="col col-12">
          <div className="row mx-md-4 my-4">
            <div className="col-12 px-md-3">
              <div className="p-4 white-wrapper mb-2">
                <div
                  className="row mt-2 px-4"
                  data-bs-toggle="collapse"
                  href="#collapseRecoleccionDeUnidadCard"
                  role="button"
                  aria-expanded="false"
                  aria-controls="collapseRegistroTramite"
                  onClick={() => setCollpsableGrua(!collpsableGrua)}
                >
                  <div className="col-11">
                    <h6>
                      <span
                        className={`semaforo semaforo-${initialValue.color}`}
                      ></span>{" "}
                      <strong>Recolección de unidad</strong>
                    </h6>
                  </div>
                  <div
                    className="col-1 col-md-1 collapsable-icon"
                    style={{ textAlign: "right" }}
                  >
                    <i
                      className={
                        !collpsableGrua
                          ? "icon-blue bx bx-chevron-down x2"
                          : "icon-blue bx bx-chevron-up x2"
                      }
                    ></i>
                  </div>
                </div>
                <div className="collapse" id="collapseRecoleccionDeUnidadCard">
                  <Formik
                    enableReinitialize={true}
                    validationSchema={validate}
                    initialValues={initialValue}
                    onSubmit={(values, actions) => {
                      handleGuardar(values, actions);
                    }}
                  >
                    {({ isValid, values, setFieldValue, errors, actions}) => (
                      <>
                        <Form>
                          <div className="separator"></div>
                          <div className="row mt-2 px-4">
                            <div className="col-2 mt-4">
                              <p><span style={{ fontSize: "14px", fontWeight: "bold" }} ><strong>Asignación de grua</strong></span></p>
                            </div>
                            <div className="col-10 row justify-content-end">
                            <div className="col-12 col-lg-3">
                                <div className="col-auto">
                                  <button
                                    id="btnVisualizarDocumentos"
                                    className="btn btn-outline col-12 mw-100 d-flex align-items-center justify-content-center"
                                    type="button"
                                    onClick={handleVisualizarDocumentos}
                                    style={{
                                      padding: "10px",
                                      lineHeight: "20px",
                                      border: "none",
                                      backgroundColor: "#f0f0f0",
                                  }}>
                                    <i className="ri-file-3-fill fs-4" style={{ color: "#004C97", marginRight: "8px", }} ></i>
                                    Visualizar Documentos
                                  </button>
                                </div>
                              </div>
                              <div className="col-12 col-lg-2">
                                <SelectField
                                  id="archivoscargados"
                                  label="Archivos cargados"
                                  name="archivoscargados"
                                  items={archivosCargados}
                                  onChange={(event) => {
                                    setArchivoSeleccionado(event)
                                }}/>
                              </div>
                              <div className="col-12 col-lg-2">
                                <div className="col-auto">
                                  <button
                                    id="btnSubirDocumento"
                                    className="btn btn-outline col-12 mw-100 d-flex align-items-center justify-content-center"
                                    type="button"
                                    onClick={ handleUploadFile }
                                    style={{
                                      padding: "10px",
                                      lineHeight: "20px",
                                      border: "none",
                                      backgroundColor: "#f0f0f0",
                                  }}>
                                    <i className="ri-file-3-fill fs-4" style={{ color: "#004C97", marginRight: "8px", }} ></i>
                                    Subir documento
                                  </button>
                                </div>
                              </div>
                              <div className="col-12 col-lg-2">
                                <div className="col-auto">
                                  <button
                                    id="btnSubirDocumento"
                                    className="btn btn-outline col-12 mw-100 d-flex align-items-center justify-content-center"
                                    type="button"
                                    onClick={ handleSubirDocumento }
                                    style={{
                                      padding: "10px",
                                      lineHeight: "20px",
                                      border: "none",
                                      backgroundColor: "#f0f0f0",
                                  }}>
                                    <i className="ri-file-3-fill fs-4" style={{ color: "#004C97", marginRight: "8px", }} ></i>
                                    Tomar foto
                                  </button>
                                </div>
                              </div>
                              <div className="col-12 col-lg-2">
                                <div className="col-auto">
                                  <button
                                    className="btn col-10"
                                    type="submit"
                                    disabled={!(isValid) || (setCostoGrua <= 0) || !(values.reg_concluido)}
                                    style={{
                                      padding: "10px",
                                      lineHeight: "25px",
                                    }}
                                  >
                                    Guardar
                                  </button>
                                </div>
                              </div>
                            </div>
                          </div>
                          <div className="mb-3 row px-4">
                            <div className="col-12 col-lg-2">
                              <TextField
                                label="Folio"
                                name="folio"
                                type="text"
                                value={values.folio}
                                disabled={true}
                              />
                            </div>
                            <div className="col-12 col-lg-2">
                              <TextField
                                label="Usuario Programo"
                                name="usuarioProgramo"
                                type="text"
                                value={values.usuarioProgramo}
                                disabled={true}
                              />
                            </div>
                            <div className="col-12 col-lg-2">
                              <SelectField
                                id="grua"
                                label="Grua"
                                name="tipoGrua"
                                items={dataGrua}
                                onChange={(event) => {
                                  if (event.value === 1 || event.value === 2) {
                                    setFieldValue("tipoGrua", event.label);
                                    setGruaSeleccionada(event.value);
                                  } else {
                                    setFieldValue("tipoGrua", "");
                                    setGruaSeleccionada(0);
                                    setFieldValue("empresa", "");
                                    setFieldValue("gruaAsignada", "");
                                    setFieldValue("costoGrua", "");
                                    setCostoGrua(0);
                                    setFieldValue("estado", "");
                                    setFieldValue("municipio", "");
                                    setFieldValue("colonia", "");
                                    setFieldValue("calle", "");
                                    setFieldValue("numero", "");
                                    setFieldValue("referenciasUbicacion", "");
                                    setFieldValue("ubicacionDestino", "");
                                    setFieldValue("fechaRecoleccion", "");
                                    setFieldValue("horaRecoleccion", "");
                                  }
                                }}
                              />
                            </div>
                          </div>
                          {(gruaSeleccionada === 1 || gruaSeleccionada === 2) && (
                            <>
                              <div className="mb-3 row px-4">
                                <div className="col-12 col-lg-2">
                                  <SelectField
                                    id="empresa"
                                    label="Empresa"
                                    name="empresa"
                                    items={dataEmpresa}
                                    onChange={(event) => {
                                      setFieldValue("empresa", event.value === '' ? '' : event.label);
                                      setFieldValue("empresa_id", event.value === '' ? '' : event.value);
                                    }}
                                  />
                                </div>
                                <div className="col-2">
                                  <TextField label="Grua asignada" name="gruaAsignada" value={values.gruaAsignada} type="text" holder="Escribe"
                                      onChange={(event) => {setFieldValue("gruaAsignada", event.target.value);}} />
                                </div>
                                <div className="col-2">
                                  <TextField
                                    label="Costo de grua"
                                    name="costoGrua"
                                    type="text"
                                    value={costoGrua + iva}
                                    disabled={true}
                                  />
                                </div>
                                <div className="col-auto mt-4">
                                  <button
                                    className="btn col-12"
                                    type="button"
                                    onClick={() => setIsOpenModal(true)}
                                    style={{
                                      padding: "10px",
                                      lineHeight: "17px",
                                    }}
                                  >
                                    Registrar costo
                                  </button>
                                </div>
                              </div>
                              <div className="row mb-3 px-4">
                                <div className="col-2 mt-3">
                                  <p>
                                    <span
                                      style={{
                                        fontSize: "14px",
                                        fontWeight: "bold",
                                      }}
                                    >
                                      Indicar ubicación de origen
                                    </span>
                                  </p>
                                </div>
                              </div>
                              <div className="mb-3 row px-4">
                                <div className="col-12 col-lg-2">
                                <SelectField  label="Estado" name="estado" items={dataEstado} onChange={(e) => {
                                      setFieldValue("estado", e.value === "" ? "" : e.label );
                                      HandleGetMunicpio(e.value);
                                  }} />
                                </div>
                                <div className="col-12 col-lg-2">
                                <SelectField  label="Municipio" name="municipio" items={dataMunicipio} onChange={(e) => {
                                      setFieldValue("municipio", e.value === "" ? "" : e.label);
                                      HandleGetColonia(e.value);
                                  }} />
                                </div>
                                <div className="col-12 col-lg-2">
                                <SelectField  label="Colonia" name="colonia" items={dataColonia} onChange={(e) => {
                                      setFieldValue("colonia", e.value === "" ? "" : e.label);
                                  }} />
                                </div>
                                <div className="col-2">
                                <TextField label="Calle" name="calle" value={values.calle} type="text" holder="Escribe"
                                      onChange={(event) => {setFieldValue("calle", event.target.value);}} />
                                </div>
                                <div className="col-2">
                                <TextField label="Número" name="numero" value={values.numero} type="text" holder="Escribe"
                                      onChange={(event) => {setFieldValue("numero", event.target.value);}} />
                                </div>
                              </div>
                              <div className="row mb-3 px-4">
                                <div className="col-10">
                                  <TextAreaField label="Referencia de ubicación:" className="col-8 mt-2" rows="4" name="referenciasUbicacion" type="text" holder="Escribe" />
                                </div>
                              </div>
                              <div className="row mb-3 px-4">
                                <div className="col-2 mt-3">
                                  <p>
                                    <span
                                      style={{
                                        fontSize: "14px",
                                        fontWeight: "bold",
                                      }}
                                    >
                                      Indicar ubicación destino
                                    </span>
                                  </p>
                                </div>
                              </div>
                              <div className="mb-3 row px-4">
                                <div className="col-12 col-lg-2">
                                  <SelectField
                                    id="ubicacionDestino"
                                    label="Ubicación"
                                    name="ubicacionDestino"
                                    items={dataUbicacion}
                                    onChange={(event) => {
                                      if (event.value !== "") {
                                        setFieldValue("ubicacionDestino", event.label);
                                      } else {
                                        setFieldValue("ubicacionDestino", "");
                                      }
                                    }}
                                  />
                                </div>
                                <div className="col-2">
                                  Fecha de recolección
                                  <input
                                    type="date"
                                    className="form-control mb-4"
                                    placeholder="Fecha inicio"
                                    onChange={(e) => {
                                      setFieldValue("fechaRecoleccion", e.target.value);
                                    }}
                                  />
                                </div>
                                <div className="col-12 col-lg-2">
                                  <SelectField
                                    id="horaRecoleccion"
                                    label="Hora de recolección"
                                    name="horaRecoleccion"
                                    items={dataHoras}
                                    onChange={(event) => {
                                      if (event.value !== "") {
                                        setFieldValue(
                                          "horaRecoleccion",
                                          event.label
                                        );
                                      } else {
                                        setFieldValue("horaRecoleccion", "");
                                      }
                                    }}
                                  />
                                </div>
                              </div>
                            </>
                          )}
                          <div className="mb-3 row px-4">
                          <div className="col-sm-12 col-md-6 col-lg-3">
                                  <Field className="form-check-input" type="checkbox" name="reg_concluido" onClick={(event) => { 
                                    setFieldValue("reg_concluido", event.target.value); 
                                  }} />
                                  <label className="form-check-label" style={{ 'marginLeft': '10px' }}>Grua programada</label>
                              </div>
                            <div className="col-sm-12 col-md-6 col-lg-9 text-end">
                              <label>
                                Inicio de proceso: {values.fechaInicioProceso}
                              </label>{" "}
                              |{" "}
                              <label>
                                Fin de proceso: {values.fechaFinProceso}
                              </label>
                            </div>
                          </div>
                        </Form>
                      </>
                    )}
                  </Formik>
                </div>
              </div>
            </div>
          </div>
        </div>
      </div>
      )}
      {
        <CostoGruaModal
          isOpen={openModal}
          setIsOpen={setIsOpenModal}
          asignarCosto={setCostoGrua}
          asignarIva={setIva}
        />
      }
      {
        isOpenDocs &&
        <VisorFile
          isOpen={isOpenDocs}
          setIsOIpen={setIsOpenDocs}
          fileName={fileName}
          doc={SelectedDocs}
        />
      }
      {
        openModalSubirDocumento &&(
          <SubirDocumentoModal
            contrato_id={contratoActual.contrato_id}
            archivoId={archivoId}
            isOpen={openModalSubirDocumento}
            setIsOpen={setOpenModalSubirDocumento}
            cardId={2}
            setConfirmarOperacion={setConfirmarOperacion}
            setNombreDocumento={setNombreDocumento}
          />
        )
      }
    </>
  );
});
export default RecoleccionDeUnidadCard;