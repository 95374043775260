import { useEffect } from 'react';
import { useState } from 'react';
import { useNavigate } from 'react-router-dom';
import Cobranza from '../../Services/Cobranza/Cobranza';
import { useDispatch, useSelector } from 'react-redux';
import { setUsuario } from '../../Redux/Slice/Cobranza/ReasignarCargaNivelUnoSlice';
import { Exception } from 'sass';
import CobranzaService from '../../Services/Cobranza/Cobranza';

const useSupervisionCargaNivel1 = () => {
  const navigate = useNavigate();
  const dispatch = useDispatch();
  //Columnas de vista principal
  const columns = [
    { field: 'nombrecobrador', headerName: 'Cobrador', width: 200 },
    { field: 'cantidadcontratos', headerName: 'Cantidad contratos', width: 200 },
    { field: 'fechabalanceo', headerName: 'Fecha balanceo', width: 170 },
  ]

  const columnsDetail = [
    { field: 'numero', headerName: '#', width: 50 },
    { field: 'contrato', headerName: 'Contrato', width: 150 },
    { field: 'diasvencidos', headerName: 'Días vencidos', width: 170 },
    { field: 'diassinconsultarclave', headerName: 'Días sin consultar clave', width: 220 },
    { field: 'montovencido', headerName: 'Monto vencido', width: 200 },
    { field: 'saldoinsoluto', headerName: 'Saldo insoluto', width: 200 },
  ];

  const [data, setData] = useState([]);
  const [dataCobradores, setDataCobradores] = useState([]);
  const [cobradorSeleccionado, setCobradorSeleccionado] = useState("");
  const [str, setStr] = useState("");
  const [loading, setLoading] = useState(true);
  const [pageCount, setPageCount] = useState(1);
  const [pageRows, setpageRows] = useState(10)
  const [currentData, setCurrentData] = useState(data)
  const [currentPage, setCurrentPage] = useState(1);
  const [openModalAvanzado, setOpenModalAvanzado] = useState(false);

  const [dataFiltro, setDataFiltro] = useState({
    estatus: "",
    strAvanzado: "",
    fechaInicio: "",
    fechaFin: "",
    concluido: false,
    busquedaAvanzada: false
});

  useEffect(() =>{
    handleGetAllData();
  }, []);

  useEffect(() => {
    const copydata = structuredClone(data)
    let filteredData = copydata.filter(item => {
      let counter = 0;
      let count = 1;
      let totalDias = 0;
      let totalSuma = 0;
      const nameMatches = str === '' || (item.nombrecobrador && item.nombrecobrador.toLowerCase().includes(str.toLowerCase()));

      let filteredContrats = item?.detail?.length === 0 ? item.detail : item?.detail?.filter(contrato => {
        const contratoMatches = str === '' || (contrato.contrato && contrato.contrato.toLowerCase().includes(str.toLowerCase())) || nameMatches;
        if(contratoMatches){

          totalDias = totalDias + contrato?.diassinconsultarclave
          contrato.numero = count
          totalDias = totalDias + contrato?.cantidad_promediar;
          totalSuma = totalSuma + contrato?.cantidad_a_sumar;
          count++;
          counter++
        }
        return ( contratoMatches )
      })

      if(item.detail.length === 0 && nameMatches){
        counter++
      }

      item.detail = filteredContrats
      item.cantidadcontratos = filteredContrats.length
      item.promedio_dias = item.detail.length === 0 ? 0 : totalDias/item.detail.length;
      item.total_mostrar = totalSuma

      return (
        (nameMatches || counter > 0) 
        && (cobradorSeleccionado === "" || item.nombrecobrador === cobradorSeleccionado || cobradorSeleccionado === "Seleccionar")
      )
    })

    const indexOfLastItem = currentPage * pageRows
    const indexOfFirstItem = indexOfLastItem - pageRows
    const newData = filteredData.slice(indexOfFirstItem, indexOfLastItem)
    setCurrentData(newData)

    const newTotalPages = Math.ceil(filteredData.length / pageRows)
    setPageCount(newTotalPages)
 }, [str, pageRows, data, cobradorSeleccionado])

//  //Filtro avanzado
//     useEffect(() => {
//         if(dataFiltro.busquedaAvanzada){
//             setCurrentData(data)
//             // Filtra los datos según los filtros seleccionados
//             const filteredData = data.filter(item => {
//                 let fechaFiltrada = true
//                 if (dataFiltro.busquedaAvanzada && dataFiltro.fechaFin !== "" && dataFiltro.fechaInicio !== "") {
//                     const FechaVencimiento = new Date(dataFiltro.fechaFin)
//                     const FechaInicio = new Date(dataFiltro.fechaInicio)
//                     const fechaItem = new Date(item.fechaAlta)
//                     fechaFiltrada = fechaItem >= FechaInicio && fechaItem <= FechaVencimiento
//                 }
//                 const nameMatches = dataFiltro.strAvanzado === '' || (item.vin && item.vin.toLowerCase().includes(dataFiltro.strAvanzado.toLowerCase()))
//                     || (item?.contrato && item?.contrato.toLowerCase().includes(dataFiltro.strAvanzado.toLowerCase()))
//                     || (item?.proceso && item?.proceso.toLowerCase().includes(dataFiltro.strAvanzado.toLowerCase()))
//                     || (item?.subproceso && item?.subproceso.toLowerCase().includes(dataFiltro.strAvanzado.toLowerCase()))
//                     || (item?.marca && item?.marca.toLowerCase().includes(dataFiltro.strAvanzado.toLowerCase()))
//                     || (item?.modelo && item?.modelo.toLowerCase().includes(dataFiltro.strAvanzado.toLowerCase()))
//                     || (item?.year && item?.year.toLowerCase().includes(dataFiltro.strAvanzado.toLowerCase()));

//                 if(dataFiltro.strAvanzado === '' && dataFiltro.fechaFin !== "" && dataFiltro.fechaInicio !== ""){
//                     return (dataFiltro.busquedaAvanzada === false || fechaFiltrada)
//                 }else if(dataFiltro.strAvanzado !== '' && dataFiltro.fechaFin === "" && dataFiltro.fechaInicio === ""){
//                     return (nameMatches)
//                 }else{
//                     return (nameMatches || (dataFiltro.busquedaAvanzada === false || fechaFiltrada))
//                 }
//             })

//             const indexOfLastItem = currentPage * pageRows
//             const indexOfFirstItem = indexOfLastItem - pageRows
//             const newData = filteredData.slice(indexOfFirstItem, indexOfLastItem)
//             setCurrentData(newData)

//             const newTotalPages = Math.ceil(filteredData.length / pageRows)
//             setPageCount(newTotalPages)
//             setDataFiltro({
//                 estatus: "",
//                 strAvanzado: "",
//                 fechaInicio: "",
//                 fechaFin: "",
//                 concluido: false,
//                 busquedaAvanzada: false
//             })
//         }
//     }, [dataFiltro])

  const handleGetAllData = async () => {
    let dataUsuarios = [];
    let NewData = [{value:"", label:"Seleccionar"}];
    await Cobranza.getEjecutivosFullDetail()
      .then(res => {
        if(res.data.callSucceded){
          res.data.responseData?.map(item => {
            if (item.nivel_cobrador === "1"){
              NewData.push({value: item?.userId, label: item?.user_detail?.names});
              let count = 1;
              let dataContratos = [];
              let totalDias = 0;
              let totalSuma = 0;
              item.contratos?.map(cont => {
                totalDias = totalDias + cont?.dias_sin_consultar_clave
                if (cont.cartera_vencida === 1 && cont.nivel_cobranza === '1') {
                  dataContratos.push({
                    id: cont?.id,
                    numero: count,
                    contrato: cont?.contrato_id,
                    diasvencidos: cont?.dias_vencido,
                    diassinconsultarclave: cont?.dias_sin_consultar_clave,
                    montovencido: 0,
                    saldoinsoluto: cont?.monto_adeudo,
                    cantidad_promediar: isNaN(cont?.dias_vencido) ? 0 :cont?.dias_vencido,
                    cantidad_a_sumar: isNaN(cont?.monto_adeudo) ? 0 :cont?.monto_adeudo,
                  });
                  totalDias = totalDias + (isNaN(cont?.dias_vencido) ? 0 :cont?.dias_vencido);
                  totalSuma = totalSuma + (isNaN(cont?.monto_adeudo) ? 0 :cont?.monto_adeudo);
                  count++;
                }
              });
              dataUsuarios.push({
                userid:item.userid,
                nombrecobrador: item.user_detail.names,
                //cantidadcontratos: item.numContratos,
                cantidadcontratos: count - 1,
                fechabalanceo: "0",
                detail: dataContratos,
                disponible: item?.disponible,
                nivel_cobrador: item?.nivel_cobrador,
                numTelefono: item?.numTelefono,
                status: item?.status,
                rangoFechas: item?.rangoFechas,
                email: item?.user_detail?.email,
                numeroEmpleado: item?.user_detail?.numeroEmpleado,
                sucursalId: item?.user_detail?.sucursalId,
                zona: item?.zona,
                promedio_dias:dataContratos.length === 0 ? 0 : totalDias/dataContratos.length,
                total_mostrar: totalSuma
              });
            }
          })
        }
      })
      .catch(e => {
        setLoading(false);
        console.log(`ERROR -- ${e}`)
      })
      setData(dataUsuarios);
      setLoading(false);
      setDataCobradores(NewData);
  }

  const getAllCobradores = async () => {
    let NewData = [{value:"", label:"Seleccionar"}];
    await Cobranza.getAllUsersNotInCobranza()
        .then(res => {
            if(res.data.callSucceded){
                res.data.responseData?.map(item => {
                    NewData.push({value: item.userId, label: item.names})
                })
            }
        })
        .catch(e => {
            console.log(`ERROR -- ${e}`)
        })
        setDataCobradores(NewData);
  }

  const distribucionDeCarga = async () => {
    setLoading(true);
    await Cobranza.distribucionDeCarga({})
        .then(res => {
            if(res.data.callSucceded){
                setLoading(false);
            } else {
              throw Exception(res.data.message);
            }
        })
        .catch(e => {
            console.log(`ERROR -- ${e}`)
            setLoading(false);
        });
  }

  const handleDetails = (item) =>{
    dispatch(setUsuario({
      userid: item.userid,
      nombrecobrador: item.nombrecobrador,
      numerocontratos: item.cantidadcontratos,
      contratos: item.detail
    }));

    navigate(`/cobranza/reasignarcargatrabajoniveluno`, {
      state: {
        page: 'Detalle',
        title: "Editar usuario"
      },
    });
  }

  const handleDownloadFile = async () => {
    let datosAExportar = []
    let nombreArchivo = `SupervisionCargaNivelUno`

    data.map(item=>{
      datosAExportar.push({
        "id_usuario":item?.userid === null ? "-": item?.userid,
        "nombrecobrador": item.nombrecobrador === null ? "-": item.nombrecobrador,
        "disponibilidad": item?.disponible === null ? "-": item?.disponible,
        "nivel_cobrador": item?.nivel_cobrador === null ? "-": item?.nivel_cobrador,
        "numero_contratos": item?.cantidadcontratos === null ? "-": item?.cantidadcontratos,
        "telefono": item?.numTelefono === null ? "-": item?.numTelefono,
        "status": item?.status === null ? "-": item?.status,
        "rangoFechas": item?.rangoFechas === null ? "-": item?.rangoFechas,
        "email": item?.email === null ? "-": item?.email,
        "numeroEmpleado": item?.numeroEmpleado === null ? "-": item?.numeroEmpleado,
        "sucursalId": item?.sucursalId === null ? "-": item?.sucursalId,
        "zona": item?.zona === null ? "-": item?.zona
      })
    })

    let reporte = {
      "verb": "string",
      "id": nombreArchivo,
      "field": "string",
      "value": "string",
      "level": 0,
      "flag": true,
      "anyObject": datosAExportar,
      "kvp": {}
    }

    console.log(reporte)

    setLoading(true);
    await CobranzaService.ExportReport(reporte)
      .then(res => {
        var FileSaver = require('file-saver');
        FileSaver.saveAs(res.data, `${nombreArchivo}_${new Date(Date.now()).toLocaleString().split(',')[0]}.xlsx`);

        setLoading(false);
      }).catch(e => {
        setLoading(false);
      })
  }

  return {
    columns,
    columnsDetail,
    currentData,
    dataCobradores,
    setCobradorSeleccionado,
    setStr,
    loading,
    handleDetails,
    distribucionDeCarga,
    dataFiltro,
    setDataFiltro,
    openModalAvanzado, 
    setOpenModalAvanzado,
    handleDownloadFile
  }
}

export default useSupervisionCargaNivel1