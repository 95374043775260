import React, { useEffect } from 'react'
import { useState, useRef } from 'react';
import { Form, Formik, Field, ErrorMessage } from 'formik';
import Modal from './Modal';
import { SelectField } from '../SelectField';
import * as Yup from 'yup';
import { message } from '../../Utils/functions';
import Spinner from '../Loadig';
import Llamada from '../../Services/Cartera/Llamadas';
import { TextAreaField } from '../TextAreaField';
import CobranzaService from '../../Services/Cobranza/Cobranza';
import { differenceInYears } from 'date-fns'

const RegistrarReferenciaModal = ({
    isOpen,
    setIsOpen,
    relacionSolicitante,
    dataEstado,
    tipoContacto,
    subtipoContacto,
    state,
    toast,
    GetDataContactos
}) => {

    const refFormik = useRef();
    const [loading, setLoading] = useState(false);
    // const [showModal, setShowModal] = useState(false);
    // const [formData, setFormData] = useState(null);

    const inicial = {
        sol_id: state.sol_id,
        contrato: state.contrato_id,
        vin: state.vin,
        relacion_solicitante: "",
        nombre_ref: "",
        apellido_paterno_ref: "",
        apellido_materno_ref: "",
        curp: "",
        // fecha_nacimiento: new Date(),

        calle_ref: "",
        no_ext_ref: "",
        no_int_ref: "",
        cp_ref: "",
        colonia_ref: "",
        municipio_ref: "",
        estado_ref: "",
        pais_ref: "México",

        tipo: "",
        subtipo: "",
        valor: "",
        message: "",

        referencia_ref: "",
    }

    const [page, setPage] = useState(1)
    const [initialValue, setInitialValue] = useState(inicial)
    const [dataMunicipio, setDataMunicipio] = useState([]);
    const [dataColonia, setDataColonia] = useState([]);

    const HandleGetMunicpio = async (id) => {
        setDataMunicipio([]);
        await Llamada.GetMunicipio(id).then(res => {
            let Municipios = []
            res.data?.data?.map(item => {
                Municipios.push({
                    value: item.municipioId,
                    label: item.municipio
                });
            });
            setDataMunicipio(Municipios);
            //setDataEmpresaId
        })
    }
    const HandleGetColonia = async (id) => {
        setDataColonia([])
        await Llamada.GetColonia(id).then(res => {
            let Colonias = []
            res.data?.data?.map(item => {
                Colonias.push({
                    value: item.coloniaId,
                    label: item.colonia
                });
            });
            setDataColonia(Colonias);
            //setDataEmpresaId
        })
    }

    const validationSchema = Yup.object({
        relacion_solicitante: Yup.string().required('Relacion es requerido'),
        nombre_ref: Yup.string().required('Nombre es requerido'),
        apellido_paterno_ref: Yup.string().required('Apellido es requerido'),
        apellido_materno_ref: Yup.string().required('Apellido es requerido'),
        curp: Yup.string().required('CURP es requerido'),
        // fecha_nacimiento: Yup.string().required('Fecha es requerido'),
        fecha_nacimiento: Yup.date()
            .test(
                'edad-valida',
                'La edad permitida es de 22 a 75 años.',
                function (value) {
                    if (!value) return false; // Si la fecha de nacimiento está vacía
                    const edad = differenceInYears(new Date(), new Date(value));
                    return edad >= 22 && edad <= 75;
                }
            )
            .required('La fecha de nacimiento es obligatoria.'),
        calle_ref: Yup.string().required('Calle es requerido'),
        cp_ref: Yup.string().required('Codigo es requerido'),
        colonia_ref: Yup.string().required('Colonia es requerido'),
        municipio_ref: Yup.string().required('Municipio es requerido'),
        estado_ref: Yup.string().required('Estado es requerido'),

        tipo: Yup.string().required('Tipo es requerido'),
        subtipo: Yup.string().required('Subtipo es requerido'),
        valor: Yup.string().required('Valor es requerido'),

        message: Yup.string().test(
            'min-length-if-not-empty',
            'El comentario debe tener al menos 20 caracteres',
            function (value) {
                // Si el valor no está vacío, debe tener al menos 20 caracteres
                return !value || (value && value.length >= 20);
            }),
    })

    // const handlerGuardar = async (values) => {

    //     setFormData(values);
    //     setShowModal(true);


    // }

    const handlerGuardar = async (values) => {
        setLoading(true);
        await CobranzaService.nuevaReferenciaContacto(values)
            .then(res => {
                GetDataContactos()
                setIsOpen(false);
                setLoading(false)
                toast.success(message("¡Operación exitosa!", res.data.mensaje));
            }).catch(e => {
                setIsOpen(false);
                setLoading(false)
                if (e.response !== undefined)
                    toast.error(message("¡Error!", e.response.data.message));
                else
                    toast.error(message("¡Error!", e.message));
            })
    };

    return (
        <>
            <Formik
                innerRef={refFormik}
                initialValues={initialValue}
                validationSchema={validationSchema}
                onSubmit={(values) => {
                    handlerGuardar(values)
                }}

            >
                {({ isValid, values, setFieldValue, errors, resetForm }) => (
                    <>
                        <Modal isOpen={isOpen} color='#fff' width={1000}>
                            <Form style={{ maxWidth: '100%' }}>
                                <div className="row">
                                    <div className="col col-12 d-flex dias-festivos-modal-underline">
                                        <div className='px-4'>
                                            <h4><strong>Registrar referencia</strong></h4>
                                            <small>A continuación completa todos los campos para el registro de la referencia</small>
                                        </div>
                                    </div>
                                </div>
                                <div className="row mt-2">
                                    {/* DATOS PERSONALES */}
                                    {page === 1 ?
                                        <>
                                            <div className="col-lg-12 col-12 py-2"> <span><strong>1. Datos iniciales</strong></span></div>

                                            <div className="col-lg-4 col-4">
                                                <SelectField
                                                    id="relacion"
                                                    label="Relación:"
                                                    name="value"
                                                    disabled={false}
                                                    items={relacionSolicitante}
                                                    onChange={(event) => {
                                                        setFieldValue("relacion_solicitante", event.label)
                                                    }} />
                                            </div>

                                            <div className="col-lg-4 col-4" style={{ display: 'flex', flexFlow: 'column' }}>
                                                <label className='text'>Nombre:</label>
                                                <Field type="text" name="nombre_ref" placeholder="Escribir"
                                                    className='programacionJobsCarteraModalSelect' />
                                                <ErrorMessage name="nombre_ref" component="div" className="error" />
                                            </div>

                                            <div className="col-lg-4 col-4" style={{ display: 'flex', flexFlow: 'column' }}>
                                                <label className='text'>Apellido paterno:</label>
                                                <Field type="text" name="apellido_paterno_ref" placeholder="Escribir"
                                                    className='programacionJobsCarteraModalSelect' />
                                                <ErrorMessage name="apellido_paterno_ref" component="div" className="error" />
                                            </div>

                                            <div className="col-lg-4 col-4" style={{ display: 'flex', flexFlow: 'column' }}>
                                                <label className='text'>Apellido Materno:</label>
                                                <Field type="text" name="apellido_materno_ref" placeholder="Escribir"
                                                    className='programacionJobsCarteraModalSelect' />
                                                <ErrorMessage name="apellido_materno_ref" component="div" className="error" />
                                            </div>

                                            <div className="col-lg-4 col-4" style={{ display: 'flex', flexFlow: 'column' }}>
                                                <label className='text'>Curp:</label>
                                                <Field type="text" name="curp" placeholder="Escribir"
                                                    className='programacionJobsCarteraModalSelect' />
                                                <ErrorMessage name="curp" component="div" className="error" />
                                            </div>

                                            <div className="col-lg-4 col-4" style={{ display: 'flex', flexFlow: 'column' }}>
                                                <label className='text'>Fecha de nacimiento:</label>
                                                <Field type="date" name="fecha_nacimiento" placeholder="Escribir"
                                                    className='programacionJobsCarteraModalSelect' />
                                                <ErrorMessage name="fecha_nacimiento" component="div" className="error" />
                                            </div>

                                            {/* DIRECCION */}
                                            <div className="col-lg-12 col-12 py-2"> <span><strong>2. Captura su dirección</strong></span></div>

                                            <div className="col-lg-4 col-4" style={{ display: 'flex', flexFlow: 'column' }}>
                                                <label className='text'>Calle:</label>
                                                <Field type="text" name="calle_ref" placeholder="Escribir"
                                                    className='programacionJobsCarteraModalSelect' />
                                                <ErrorMessage name="calle_ref" component="div" className="error" />
                                            </div>
                                            <div className="col-lg-4 col-4" style={{ display: 'flex', flexFlow: 'column' }}>
                                                <label className='text'>Numero exterior:</label>
                                                <Field type="text" name="no_ext_ref" placeholder="Escribir"
                                                    className='programacionJobsCarteraModalSelect' />
                                                <ErrorMessage name="no_ext_ref" component="div" className="error" />
                                            </div>
                                            <div className="col-lg-4 col-4" style={{ display: 'flex', flexFlow: 'column' }}>
                                                <label className='text'>Numero interior:</label>
                                                <Field type="text" name="no_int_ref" placeholder="Escribir"
                                                    className='programacionJobsCarteraModalSelect' />
                                                <ErrorMessage name="no_int_ref" component="div" className="error" />
                                            </div>
                                            <div className="col-lg-4 col-4" style={{ display: 'flex', flexFlow: 'column' }}>
                                                <label className='text'>Código postal:</label>
                                                <Field type="text" name="cp_ref" placeholder="Escribir"
                                                    className='programacionJobsCarteraModalSelect' />
                                                <ErrorMessage name="cp_ref" component="div" className="error" />
                                            </div>
                                            <div className="col-lg-4 col-4" style={{ display: 'flex', flexFlow: 'column' }}>
                                                <SelectField id="estado_ref" label="Estado" name="estado_ref" items={dataEstado} onChange={(event) => {
                                                    setFieldValue("estado_ref", event.label);
                                                    HandleGetMunicpio(event.value);
                                                }} />
                                            </div>
                                            <div className="col-lg-4 col-4" style={{ display: 'flex', flexFlow: 'column' }}>
                                                <SelectField id="MunicipioId" label="Municipio" name="municipio_ref" items={dataMunicipio} onChange={(event) => {
                                                    setFieldValue("municipio_ref", event.label);
                                                    HandleGetColonia(event.value);
                                                }} />
                                            </div>
                                            <div className="col-lg-4 col-4" style={{ display: 'flex', flexFlow: 'column' }}>
                                                <SelectField id="colonia_ref" label="Colonia" name="colonia_ref" items={dataColonia} onChange={(event) => {
                                                    setFieldValue("colonia_ref", event.label);
                                                }} />
                                            </div>
                                        </>
                                        :
                                        <>
                                            <div className="col-lg-12 col-12 py-2"> <span><strong>3. Detalle de contacto</strong></span></div>
                                            <div className="col-lg-4 col-4">
                                                <SelectField
                                                    id="tipo"
                                                    label="Tipo de Contacto:"
                                                    name="tipo"
                                                    disabled={false}
                                                    items={tipoContacto}
                                                    onChange={(event) => {
                                                        setFieldValue("tipo", event.value)
                                                    }} />
                                            </div>
                                            <div className="col-lg-4 col-4">
                                                <SelectField
                                                    id="subtipo"
                                                    label="Subtipo:"
                                                    name="subtipo"
                                                    disabled={false}
                                                    items={subtipoContacto}
                                                    onChange={(event) => {
                                                        setFieldValue("subtipo", event.value)
                                                    }} />
                                            </div>
                                            <div className="col-lg-4 col-4" style={{ display: 'flex', flexFlow: 'column' }}>
                                                <label className='text'>Valor:</label>
                                                <Field type="text" name="valor" placeholder="Escribir"
                                                    className='programacionJobsCarteraModalSelect' />
                                                <ErrorMessage name="valor" component="div" className="error" />
                                            </div>
                                            <div className="col-lg-12 col-12" style={{ display: 'flex', flexFlow: 'column' }}>
                                                <TextAreaField label="Comentarios" className="form-control col-12" rows="3" name="message" type="text" holder="Escribe" />
                                            </div>
                                        </>
                                    }

                                    {/* BOTONES */}
                                    <section className='d-flex justify-content-end'>
                                        {page !== 1 && (
                                            <p onClick={(e) => setPage(1)} style={{
                                                display: 'flex',
                                                alignItems: 'flex-end',
                                                marginInlineEnd: 'auto',
                                                textDecoration: 'underline',
                                                cursor: 'pointer'
                                            }}>Regresar</p>
                                        )}
                                        <div className="row">
                                            <div className="col-auto">
                                                <button
                                                    type='reset'
                                                    id="AT_BtnCloseModal"
                                                    className='btn btn-outline d-flex justify-content-center align-items-center'
                                                    style={{ paddingLeft: '25px', paddingRight: '25px' }}
                                                    onClick={() => {
                                                        setIsOpen(false)
                                                    }}
                                                >
                                                    Cancelar
                                                </button>
                                            </div>
                                            {page === 1 ?
                                                (
                                                    <div className="col-auto">
                                                        <button
                                                            id="AT_BtnCloseModal"
                                                            className='btn btn-blue d-flex justify-content-center align-items-center'
                                                            style={{ paddingLeft: '25px', paddingRight: '25px' }}
                                                            onClick={(e) => setPage(2)}
                                                        >
                                                            Continuar
                                                        </button>
                                                    </div>
                                                ) :
                                                (
                                                    <div className="col-auto">
                                                        <button
                                                            id="AT_BtnCloseModal"
                                                            className='btn btn-blue d-flex justify-content-center align-items-center'
                                                            disabled={!(isValid)}
                                                            style={{ paddingLeft: '25px', paddingRight: '25px' }}
                                                            type="submit"
                                                        >
                                                            Guardar
                                                        </button>
                                                    </div>)

                                            }

                                            <div className="col-auto">
                                            </div>
                                        </div>
                                    </section>
                                </div>
                            </Form>
                        </Modal>
                        {
                            loading &&
                            <Modal isOpen={loading} color='transparent'><Spinner message={""} /></Modal>
                        }

                        {/* <Modal isOpen={showModal} color='#fff'>
                            <section className='my-2 mx-4' style={{ width: '28rem' }}>
                                <Form>
                                    <span className='fs-5'><strong>¿Estas seguro de los cambios?</strong></span>
                                    <div className="mb-3">
                                        <label style={{ fontSize: '13px' }} className="col-form-label">Los cambios que se realicen impactaran en el sistema.</label>
                                    </div>
                                    <section className='my-3 d-flex justify-content-end'>
                                        <button id="AT_btnCancelar" className='btn btn-outline' type='reset' onClick={() => { setIsOpen(false) }}>Cancelar</button>
                                        <button id="AT_btnGuardar" className='btn mx-2' type="submit" onClick={() => confirmSubmit()} >Confirmar</button>
                                    </section>
                                </Form>
                            </section>
                        </Modal> */}
                    </>
                )}
            </Formik >
        </>
    )
}
export default RegistrarReferenciaModal