import React, { useState } from "react"
import VehiculoInfoCard from "../../../../Components/VehiculoInfoCard.jsx"
import CarteraInfoCard from "../../../../Components/CarteraInfoCard.jsx"
import RegistroPromesaCards from "../Cards/RegistroPromesaCards.jsx"
import ResguardoUnidadCards from "../Cards/ResguardoUnidadCards.jsx"
import SeguimientoVisitaCards from "../Cards/SeguimientoVisitaCards.jsx"
import ContratoInfoCard from "../../../../Components/ContratoInfoCard.jsx"
import useSeguimientoVisitaDetalle from '../../../../Hooks/SeguimientoVisita/useSeguimientoVisitaDetalle.js'
import VisitaDomiciliariaCard from "../Cards/VisitaDomiciliariaCard.jsx"
import RecoleccionUnidadCard from "../Cards/RecoleccionUnidadCard.jsx"
import Modal from "../../../../Components/Modal/Modal.jsx"
import Spinner from "../../../../Components/Loadig.jsx"
import { useSelector } from "react-redux"

const SeguimientoVisitaDetalle = () => {
   const {contratoActual} = useSelector((state) => state.SeguimientoVisitaDetalleSlice);
   const [showPromesaPago, setShowPromesaPago] = useState(false);
   const [showRecoleccionResguardo, setShowRecoleccionResguardo] = useState(false);
   const {
      currentDataSeguimiento,
      userDetail,
      promesasRotas,
      loading,
      handleReturnPage
   } = useSeguimientoVisitaDetalle();

   return (
      <>
         <Modal isOpen={loading} color='transparent'><Spinner message={""} /></Modal>
         <div style={{ background: "#F5F5F5", paddingBottom: "1rem" }}>
               <header className='heder-subtitle d-flex justify-content-start align-items-center border-bottom px-2' style={{ background: "#FFF" }}>
                  <div className='cursor-pointer' onClick={() => { handleReturnPage() }}>
                     <i className="bx bxs-chevron-left mx-2"></i>
                     Regresar a listado de seguimiento de visita 
                  </div>
               </header>
               <div className="col col-11 d-flex">
                  <div className="col col-6 px-4 py-2">
                     <VehiculoInfoCard 
                        VIN={currentDataSeguimiento.vin}
                        verClaves = {true}
                        solicitarClave = {true}
                        datosVehiculo={contratoActual.vehiculo}/>
                  </div>
                  <div className="col col-6 px-4 py-2">
                     <ContratoInfoCard 
                        contrato={currentDataSeguimiento}/>
                  </div>
               </div>
               <div className="col col-11 px-4 py-2">
                  <CarteraInfoCard 
                     contrato={currentDataSeguimiento}
                     user={userDetail}
                     promesasRotas={promesasRotas}/>
               </div>
               <div className="col col-11 px-4 py-2">
                  <SeguimientoVisitaCards />
               </div>
               <div className="col col-11 px-4 py-2">
                  <VisitaDomiciliariaCard
                     handleNextSteps={showNext => {
                        setShowPromesaPago(showNext);
                        if (showNext === false) {
                           setShowRecoleccionResguardo(false);
                        }
                     }}/>
               </div>
               <div className="col col-11 px-4 py-2">
                  <RegistroPromesaCards
                     show={showPromesaPago}
                     handleNextSteps={(formularioActual) => { 
                        setShowRecoleccionResguardo(formularioActual > 1);
                     }}/>
               </div>
               <div className="col col-11 px-4 py-2">
                  <RecoleccionUnidadCard
                     show={showRecoleccionResguardo}/>
               </div>
               <div className="col col-11 px-4 py-2">
                  <ResguardoUnidadCards
                     show={showRecoleccionResguardo}/>
               </div>
         </div>
      </>
   )
}

export default SeguimientoVisitaDetalle