import React from 'react'
import { useState, useEffect, useRef } from 'react';
import Modal from './Modal';
import Spinner from '../Loadig';
import { Field, Form, Formik } from 'formik';
import * as Yup from 'yup';
import Catalogo from '../../Services/Catalogos/Catalogo';
import { SelectField } from '../SelectField';
import { TextField } from '../TextField';
import { toast, Toaster } from 'react-hot-toast';
import { useDispatch, useSelector } from 'react-redux'
import Datamovil from '../../Services/Resguardos/Datamovil';
import Valuaciones from '../../Services/Valuaciones/Valuaciones';
import { useLocation, useParams } from 'react-router-dom';
import Gestoria from '../../Services/Gestoria/Gestoria';
import { TextAreaField } from '../TextAreaField';
import AuthLogin from '../../Utils/AuthLogin';
import Siniestros from '../../Services/Siniestro/Siniestro';
import { message } from '../../Utils/functions';

const auth = new AuthLogin();
const BoletinarClienteModal = ({ modalMessage, setModalMessage, handleOk }) => {
    const refFormik = useRef();
    const [loading, setLoading] = useState(false);
    const { id } = useParams();
    const { state } = useLocation();

    const initial = {
        siniestroClienteBoletinarId: "",
        siniestroId: id,
        siniestroClienteBoletinarMotivoId: "",
        siniestroClienteBoletinarMotivo: "",
        usuarioBoletina: auth.getSesionValue("nombreUsuario") ?? "",
        comentariosEjecutivo: "",
        contrato: state?.contrato ?? "",
        clienteBoletinado: false,
        fechaAlta: ""
    }
    const [initialValue, setInitialValue] = useState(initial)

    const [dataClienteBoletinarMotivo, setDataClienteBoletinarMotivo] = useState([]);

    useEffect(() => {
        if (modalMessage.isOpen) {
            getSiniestroClienteBoletinar();
            getSiniestroClienteBoletinarMotivo();
        }
    }, [modalMessage])


    const handleRegresar = () => {
        refFormik.current.resetForm();
        setModalMessage({ ...modalMessage, isOpen: false })
    }

    const validate = Yup.object({
        siniestroClienteBoletinarMotivoId: Yup.string()
            .required("El campo es requerido."),
        comentariosEjecutivo: Yup.string()
            .required("El campo es requerido.")
    });

    const getSiniestroClienteBoletinar = async () => {
        setLoading(true);
        await Siniestros.siniestroClienteBoletinarGet(id)
            .then(res => {
                let item = res.data;
                let newData = {
                    siniestroClienteBoletinarId: item?.siniestroClienteBoletinarId,
                    siniestroId: id,
                    siniestroClienteBoletinarMotivoId: item?.siniestroClienteBoletinarMotivoId ?? "",
                    usuarioBoletina: item?.usuarioBoletinaNombre ?? "",
                    comentariosEjecutivo: item?.comentariosEjecutivo ?? "",
                    contrato: state?.contrato ?? "",
                    clienteBoletinado: item?.clienteBoletinado ?? false,
                    fechaAlta: item?.fechaAlta.split("T")[0] ?? "",
                }
                setInitialValue(newData);
            })
            .catch(err => {
                console.error(err);
            })
            .finally(() => setLoading(false));
    };

    const getSiniestroClienteBoletinarMotivo = async () => {
        await Catalogo.getDropdownsByTable('Catalogos.SiniestroClienteBoletinarMotivo')
            .then(resp => {
                let items = [];
                resp.data.forEach(item => {
                    items.push({ value: item.SiniestroClienteBoletinarMotivoId, label: item.SiniestroClienteBoletinarMotivo })
                });
                setDataClienteBoletinarMotivo(items);
            })
            .catch(err => {
                console.error(err);
            });
    };
    const submitForm = async (values) => {
        if (values.siniestroClienteBoletinarId !== "") {
            setLoading(true);
            await Siniestros.siniestroClienteBoletinarActualizar(values)
                .then(resp => {
                    if (resp.data.data.ban === 1) {
                        toast.success(message("¡Correcto.!", resp.data.data.mensaje));
                        setLoading(false);
                        handleRegresar();
                    } else {
                        toast.error(message("¡Error.!", resp.data.data.mensaje));
                        setLoading(false);
                    }
                })
                .catch(err => {
                    console.error(err);
                    toast.error(message("¡Error!", err.message));
                    setLoading(false);
                })
        } else {
            setLoading(true);
            await Siniestros.siniestroClienteBoletinarCrear(values)
                .then(resp => {
                    if (resp.data.data.ban === 1) {
                        toast.success(message("¡Correcto.!", resp.data.data.mensaje));
                        setLoading(false);
                        handleRegresar();
                    } else {
                        setLoading(false);
                        toast.error(message("¡Error.!", resp.data.data.mensaje));
                    }

                })
                .catch(err => {
                    console.error(err);
                    toast.error(message("¡Error!", err.message));
                    setLoading(false);
                })
        }
    }

    return (
        <>
            <Toaster
                position="top-right"
                toastOptions={{
                    success: {
                        style: {
                            background: '#47a066',
                            color: '#FFFF',
                            borderLeft: '10px solid #2f7246'
                        },
                    },
                    error: {
                        style: {
                            background: '#d53f3f',
                            color: '#FFFF',
                            borderLeft: '10px solid #ac241a'
                        },
                    },
                }}
            />

            <Formik
                enableReinitialize={true}
                innerRef={refFormik}
                isInitialValid={false}
                initialValues={initialValue}
                validationSchema={validate}
                onSubmit={(values) => {
                    submitForm(values);
                }}
            >
                {({ isValid, values, setFieldValue, errors, resetForm }) => (
                    <div>
                        <Modal isOpen={loading} color='transparent'><Spinner message={""} /></Modal>
                        <div className='justify-content-start p-2 align-items-center'>
                            <Form>
                                <div className="row">
                                    <div className="col col-12">
                                        <h5><strong>{modalMessage.title}</strong></h5>
                                    </div>
                                </div>
                                <div className="row mt-2 mb-2">
                                    <div className="col col-12">
                                        <div className="row">
                                            <small id="AT_TxtBasicModalMenssage">
                                                <strong>Detalle del boletinado</strong>
                                            </small>
                                        </div>
                                        <div className="row mt-2">
                                            <div className="col-4">
                                                <SelectField className="col-12" disabled={initialValue?.clienteBoletinado} label="Motivo boletinado:" items={dataClienteBoletinarMotivo} name={`siniestroClienteBoletinarMotivoId`} onChange={(event) => {
                                                    setFieldValue(`siniestroClienteBoletinarMotivoId`, event.value);
                                                    setFieldValue(`siniestroClienteBoletinarMotivo`, event.label);
                                                }} />
                                            </div>
                                            <div className="col-4">
                                                <TextField
                                                    id="usuarioBoletinaId"
                                                    disabled={true}
                                                    label="Usuario"
                                                    name="usuarioBoletina"
                                                    type="text"
                                                    holder="Escribe" />
                                            </div>
                                            <div className="col-4">
                                                <TextField
                                                    id="fechaId"
                                                    disabled={true}
                                                    label="Fecha de solicitud"
                                                    name="fechaAlta"
                                                    type="date"
                                                    holder="Escribe"
                                                    onChange={(event) => {
                                                        setFieldValue("subtotal", event.target.value);
                                                    }} />
                                            </div>
                                        </div>
                                        <div className="row mt-2">
                                            <div className="col-12">
                                                <TextAreaField
                                                    label="Comentarios"
                                                    disabled={initialValue?.clienteBoletinado}
                                                    className="form-control col-12"
                                                    rows="3"
                                                    name="comentariosEjecutivo"
                                                    id="comentariosEjecutivoId"
                                                    type="text"
                                                    holder="Escribe"
                                                />
                                            </div>
                                        </div>
                                    </div>
                                </div>
                                <div className="row mt-2">
                                    <div className="col-12 col-lg-6">
                                        <Field className="form-check-input" disabled={initialValue?.clienteBoletinado} type="checkbox" name="clienteBoletinado" onClick={(event) => {
                                            setFieldValue("clienteBoletinado", event.target.value);
                                        }} />
                                        <label className="form-check-label" style={{ 'marginLeft': '10px' }}>Cliente boletinado</label>
                                    </div>
                                </div>
                                <section className='d-flex justify-content-end'>
                                    <div className="row">
                                        <div className="col-auto d-flex">
                                            <button
                                                type='reset'
                                                id="AT_BtnCloseModal"
                                                className='btn btn-outline me-2'
                                                style={{ paddingLeft: '25px', paddingRight: '25px' }}
                                                onClick={() => handleRegresar()}
                                            >
                                                Cerrar
                                            </button>
                                            <button
                                                disabled={initialValue?.clienteBoletinado ? true : !(isValid)}
                                                type='submit'
                                                id="AT_BtnCloseModal"
                                                className='btn'
                                                style={{ paddingLeft: '25px', paddingRight: '25px' }}
                                                onClick={() => handleOk(values)}
                                            >
                                                Guardar
                                            </button>
                                        </div>

                                    </div>
                                </section>
                            </Form>
                        </div>
                    </div>
                )}
            </Formik>
        </>
    );
}

export default BoletinarClienteModal;
