import { Formik } from "formik";
import { SelectField } from "../SelectField";
import { useEffect, useRef, useState } from "react";
import useCalendar from "../../Hooks/Calendar/useCalendar";
import Modal from "../Modal/Modal";
import ScheduleModal from "./ScheduleModal";
import DetailScheduleModal from "./DetailScheduleModal";
import Spinner from "../Loadig";
import { useLocation } from "react-router-dom";
import ScheduleDinamicalModal from "./ScheduleDynamicalModal";
import DetailScheduleDynamicalModal from "./DetailScheduleDynamicalModal";

const CalendarDynamical = ({ sundayOn = false, type = 1, halfDay = 12 }) => {
    const { state } = useLocation();
    const refFormik = useRef();

    const {
        sucursales,
        initialValue,
        Week,
        tamanoCasilla,
        parentDiv,
        vista,
        handleGetCurrentMonthString,
        handleSucursalId,
        handleVista,
        handlePrevButton,
        handleNextButton,
        handleToday,
        generateMonthyCalendar,
        dataScheduleDay,
        modalMessage,
        setModalMessage,
        loading,
        setLoading,
        generateDaysOfWeek,
        generateScheduleRows
    } = useCalendar({ sundayOn, type, halfDay });


    return (
        <>
            <Formik
                innerRef={refFormik}
                enableReinitialize={true}
                initialValues={initialValue}
            >
                {({ isValid, values, setFieldValue, errors }) => (
                    <>
                        <Modal isOpen={loading} color='transparent'><Spinner message={""} /></Modal>
                        <Modal isOpen={modalMessage.isOpen} color='#fff' width={660}>
                            {
                                modalMessage.type === 4 &&
                                <ScheduleDinamicalModal
                                    modalMessage={modalMessage}
                                    setModalMessage={setModalMessage}
                                    schedules={dataScheduleDay}
                                />
                            }
                            {
                                modalMessage.type === 5 &&
                                <DetailScheduleDynamicalModal
                                    modalMessage={modalMessage}
                                    setModalMessage={setModalMessage}
                                    schedules={dataScheduleDay}
                                    tipoCalendario={state.tipoCalendario}
                                />
                            }
                        </Modal>
                        <div className="row justify-content-center mt-4">
                            <div className="col-11 px-1">
                                <div className="row">
                                    <div className="col-12">
                                        <h4 className="fw-bold">Calendario para agendar citas { state?.title ? `- ${state?.title}` : '' }</h4>
                                    </div>
                                </div>
                                <div className="row mt-4">
                                    <div className="col-3">
                                        <button className="btn" onClick={() => handleToday()}>Hoy</button>
                                    </div>
                                    <div className="col-6">
                                        <div className="row align-items-center">
                                            <div className="col-2">
                                                <button className="btn btn-indicator" onClick={() => handlePrevButton()}>
                                                    <i className="glyphicon fas fa-angle-left"></i>
                                                </button>
                                            </div>
                                            <div className="col-8 text-center px-md-2">
                                                <h4 className="fw-bold text-capitalize">{handleGetCurrentMonthString()}</h4>
                                            </div>
                                            <div className="col-2">
                                                <button className="btn btn-indicator" onClick={() => handleNextButton()}>
                                                    <i className="glyphicon fas fa-angle-right"></i>
                                                </button>
                                            </div>
                                        </div>
                                    </div>
                                    <div className="col-3">
                                        <div className="row">
                                            <div className="col-12 col-md-6">
                                                <SelectField id="sucursalId" name="sucursalId" items={sucursales} onChange={(event) => {
                                                    setFieldValue("sucursalId", event.value);
                                                    handleSucursalId(event.value, values);
                                                }} />
                                            </div>
                                            <div className="col-12 col-md-6">
                                                <SelectField id="vista" name="vista" items={vista} onChange={(event) => {
                                                    setFieldValue("vista", event.value);
                                                    handleVista(event.value);
                                                }} />
                                            </div>
                                        </div>
                                    </div>
                                </div>
                                {/* VISTA MENSUAL */}
                                {
                                    values.vista === 1 &&
                                    <div className="row my-4 justify-content-center d-flex" ref={parentDiv}>
                                        <div className="col-12">
                                            <div className="row justify-content-start d-flex">
                                                {
                                                    Week.map((i, idx) => {
                                                        return <div key={'dia_' + idx} className='text-center cell-calendar' style={{ maxWidth: tamanoCasilla, minHeight: '40px', lineHeight: '40px' }}><small><strong>{i}</strong></small></div>
                                                    })
                                                }
                                                {
                                                    generateMonthyCalendar()
                                                }
                                            </div>
                                        </div>
                                    </div>
                                }
                                {/* VISTA SEMANAL */}
                                {
                                    values.vista === 2 &&
                                    <div className='row mx-md-4 my-4 px-4 mb-4' ref={parentDiv}>
                                        {generateDaysOfWeek()}
                                        {generateScheduleRows()}
                                    </div>
                                }
                            </div>
                        </div>
                    </>
                )
                }
            </Formik>
        </>
    );
}

export default CalendarDynamical;