import React, { useEffect } from 'react'
import { useState, useRef } from 'react';
import Modal from './Modal';
import Spinner from '../Loadig';
import { Form, Formik } from 'formik';
import * as Yup from 'yup';
import { useDispatch, useSelector } from 'react-redux';
import { TextField } from '../TextField';
import { toast, Toaster } from 'react-hot-toast';
import { message, pageRows } from '../../Utils/functions';
import { useParams } from 'react-router-dom';
import Siniestros from '../../Services/Siniestro/Siniestro';
import { SelectField } from '../SelectField';
import { TextAreaField } from '../TextAreaField';
import Catalogo from '../../Services/Catalogos/Catalogo';



const ModalAddSolicitarExpedienteSiniestro = ({ item, setIsOpenModalPago, isOpenModalPago, handleGetAll }) => {
    const refFormik = useRef();
    const { id } = useParams();
    const inicial = {
        seguroSiniestroSeguimientoProcesoDatamovilId: item?.seguroSiniestroSeguimientoProcesoDatamovilId ?? "",
        seguroSiniestroProcesoDataMovilId: item.seguroSiniestroProcesoDataMovilId ?? "",
        siniestroProcesoDatamovilId: item?.siniestroProcesoDatamovilId ?? "",
        // fechaVisita: item?.fechaVisita ?? "",
        MotivoId: item?.MotivoId ?? "",
        comentarios: item?.comentarios ?? "",
    };
    const [loading, setLoading] = useState(false);
    const [initialValue, setInitialValue] = useState(inicial);
    const [motivo, setMotivo] = useState([]);
    useEffect(() => {
        handleGetAllMotivoRecuperacion();
    }, [isOpenModalPago])

    const validate = Yup.object({
        // fechaVisita: Yup.string()
        // .required("El campo es requerido."),
        MotivoId: Yup.string()
            .required("El campo es requerido."),
        comentarios: Yup.string()
            .required("El campo es requerido."),
    });

    const handleGetAllMotivoRecuperacion = async () => {
        await Catalogo.getDropdownsByTable('Catalogos.SiniestroSolicitarExpedienteMotivo')
            .then(resp => {
                let items = [];
                resp.data.forEach(item => {
                    items.push({
                        value: item.SiniestroSolicitarExpedienteMotivoId,
                        label: item.Motivo
                    })
                });
                setMotivo(items);
            })
            .catch(err => {
                console.error(err);
            });
    }

    const handleAddEdit = async (values) => {
        setLoading(true)
        await Siniestros.AddProcesoDatamovil({...values,siniestroId:id})
            .then(res => {
                if (res.data.data.ban === 1) {
                    setIsOpenModalPago(false);
                    toast.success(message("¡correcto!", res.data.data.mensaje));
                    handleGetAll(1);
                } else {
                    toast.error(message("Error!", res.data.data.mensaje));
                }
                setLoading(false)
            }).catch(e => {
                setLoading(false)
                setIsOpenModalPago(false);
                if (e.response !== undefined)
                    toast.error(message("Error!", e.response.data.message));
                else
                    toast.error(message("Error!", e.message));
            })
    }

    return (
        <>

            <Toaster
                position="top-right"
                toastOptions={{
                    success: {
                        style: {
                            background: '#47a066',
                            color: '#FFFF',
                            borderLeft: '10px solid #2f7246'
                        },
                    },
                    error: {
                        style: {
                            background: '#d53f3f',
                            color: '#FFFF',
                            borderLeft: '10px solid #ac241a'
                        },
                    },
                }}
            />

            <Formik
                enableReinitialize={true}
                innerRef={refFormik}
                initialValues={initialValue}
                validationSchema={validate}
                isInitialValid={false}
                onSubmit={(values) => {
                    handleAddEdit(values);
                }}
            >
                {({ isValid, values, setFieldValue, errors, resetForm }) => (
                    <>
                        <Modal isOpen={isOpenModalPago} color='#fff' width={500}>
                            <div>
                                <section className='d-flex mb-4'>
                                    {/* <button className='btn btn-outline d-flex justify-content-center align-items-center' style={{ width: '23px', height: '23px' }}
                                        onClick={() => setIsOpenModalPago(false)}><i className="ri-close-fill"></i></button> */}
                                    <span className='fs-6'><strong>Solicitud de expediente</strong></span>
                                </section>
                                <Form>
                                    <div className="mb-3 row">
                                        <div className="col-lg-10 col-md-10 col-sm-12">
                                            <SelectField
                                                label="Motivo"
                                                disabled={initialValue?.concluido}
                                                name="MotivoId"
                                                items={motivo}
                                                onChange={(event) => {
                                                    setFieldValue("MotivoId", event.value);
                                                }} />
                                        </div>
                                    </div>
                                    <div className="mb-3 row">
                                        <div className="col-lg-12 col-md-12 col-sm-12">
                                            <TextAreaField
                                                id="comentarios"
                                                label="Comentarios"
                                                className="form-control"
                                                rows="3"
                                                name="comentarios"
                                                type="text"
                                                holder="Escribe"
                                            />
                                        </div>
                                    </div>
                                    <section className='my-3 d-flex justify-content-end'>
                                        <button id="AT_btnCancelar" className='btn btn-outline' type='reset' onClick={() => { setIsOpenModalPago(false) }}>Cancelar</button>
                                        <button id="AT_btnGuardar" className='btn mx-2' type="submit" disabled={!(isValid)}>{initialValue.siniestroResguardoUnidad_CostoAdicionalId == "" ? "Guardar" : "Editar"}</button>
                                    </section>
                                </Form>
                            </div>
                        </Modal>
                        {
                            loading &&
                            <Modal isOpen={loading} color='transparent'><Spinner message={""} /></Modal>
                        }
                    </>

                )}
            </Formik>
        </>
    );
}

export default ModalAddSolicitarExpedienteSiniestro;
