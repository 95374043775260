import React, { useEffect, useState } from "react"
import { useLocation, useNavigate } from "react-router-dom"
import toast, { Toaster } from 'react-hot-toast'
import UnidadesRecuperadasDetalleHook from "../../../Hooks/UnidadesRecuperadas/UnidadesRecuperadasDetalleHook.js"
import VehiculoInfoCard from "../../../Components/VehiculoInfoCard.jsx"
import CarteraInfoCard from "../../../Components/CarteraInfoCard.jsx"
import ContratoInfoCard from "../../../Components/ContratoInfoCard.jsx"
import Modal from "../../../Components/Modal/Modal.jsx"
import Spinner from "../../../Components/Loadig.jsx"
import EliminarPromesaPagoModal from "../../../Components/Modal/EliminarPromesaPagoModal.jsx"
import DatosPromesaPagoModal from "../../../Components/Modal/DatosPromesaPagoModal.jsx"
import RegistrarReferenciaModal from "../../../Components/Modal/RegistrarReferencia.jsx"
import AgregarDatosContactoModal from "../../../Components/Modal/AgregarDatosContactoModal.jsx"
import IncumplimientoPromesaPagoModal from "./Cards/IncumplimientoPromesadePagoModal.jsx"
import AmpliacionPromesasdePagoModal from "./Cards/AmpliacionPromesasdePagoModal.jsx"
import DireccionesContactoModal from "../../../Components/Modal/DireccionesContactoModal.jsx"
import RegistroLlamadaModal from "../../../Components/Modal/RegistroLlamadaModal.jsx"
import VisorFile from "../../../Components/VisorFile.jsx"
import DataTable from "../../../Components/datatable/DataTable.jsx"
import Accordion from "../../../Components/TitleAcordeon.jsx"
import TablaAnidada from "../../../Components/TablaAnidada.jsx"
import CitaEntregaUnidad from "./Cards/CitaEntregaUnidad.jsx"
import EntregaUnidad from "./Cards/EntregaUnidad.jsx"

const UnidadesRecuperadasDetalle = () => {
    const { state } = useLocation();
    const {
        currentDataSeguimiento,
        userDetail,
        promesasRotas,
        loading,
        setLoading,
        handleReturnPage,

        //Catalogos
        motivoEliminacion,
        relacionSolicitante,
        dataEstado,
        tipoContacto,
        subtipoContacto,
        infoContactosFiltered,

        ///Promesas de Pago
        columnsDetallePromesas,
        columnsGarantiaPromesas,
        columnsHistorialPromesas,
        currentDataPromesas,
        dataGarantia,
        dataGarantiaHistorial,
        handlePromesasPago,
        handlePromesasPagoDelete,
        handleGetPromesasdePago,
        handleModalIncumplimiento,
        handleModalAmpliacion,
        isOpenModalPromesaPagoData,
        setisOpenModalPromesaPagoData,
        promesaPagoData,
        isOpenModalPromesaPagoDelete,
        setisOpenModalPromesaPagoDelete,
        isOpenModalPromesaPagoIncumplimiento,
        setisOpenModalPromesaPagoIncumplimiento,
        isOpenModalPromesaPagoAmpliacion,
        setisOpenModalPromesaPagoAmpliacion,
        motivosVisita,
        tipoResultado,

        //Directorio de contactos
        columnsInformacionContacto,
        columnsSubInformacionContacto,
        currentDataSeguimientoLog,
        directorioValue,
        infoContactoData,
        handleSetStateTab,
        handleModalRef,
        handleGetInformacionContactos,
        handleModalAdd,
        handleDireccionesModal,
        handleRegistroLlamada,
        handleContactosSeguimientoLog,
        isOpenModalAgregarContacto,
        setisOpenModalAgregarContacto,
        isOpenModalReferencias,
        setisOpenModalReferencias,
        isOpenModalDireccionContactos,
        setIsOpenModalDireccionContactos,
        isOpenModalRegistroLlamada,
        setIsOpenModalRegistroLlamada,
        promesasRotasTotal,

        //Subir Documentos
        handleUploadFile,
        handleViewdocs,
        isOpenDocs,
        setIsOpenDocs,
        fileName,
        SelectedDocs,

        //Logs
        columnsSeguimientoLog,
    } = UnidadesRecuperadasDetalleHook(state);

    return (
        <>
            <Toaster
                position="top-right"
                toastOptions={{
                    success: {
                        style: {
                            background: '#47a066',
                            color: '#FFFF',
                            borderLeft: '10px solid #2f7246'
                        },
                    },
                    error: {
                        style: {
                            background: '#d53f3f',
                            color: '#FFFF',
                            borderLeft: '10px solid #ac241a'
                        },
                    },
                }}
            />
            {
                loading &&
                <Modal isOpen={loading} color='transparent'><Spinner message={""} /></Modal>
            }
            {
                isOpenModalPromesaPagoData &&
                <DatosPromesaPagoModal
                    data={promesaPagoData}
                    isOpen={isOpenModalPromesaPagoData}
                    setIsOpen={setisOpenModalPromesaPagoData}

                />
            }
            {
                isOpenModalPromesaPagoDelete &&
                <EliminarPromesaPagoModal
                    item={promesaPagoData}
                    isOpen={isOpenModalPromesaPagoDelete}
                    setIsOpen={setisOpenModalPromesaPagoDelete}
                    state={state.ruta_item?.contrato}
                    toast={toast}
                    motivoEliminacion={motivoEliminacion}
                    GetData={handleGetPromesasdePago}
                />
            }
            {
                isOpenModalPromesaPagoAmpliacion &&
                <AmpliacionPromesasdePagoModal
                    item={promesaPagoData}
                    isOpen={isOpenModalPromesaPagoAmpliacion}
                    setIsOpen={setisOpenModalPromesaPagoAmpliacion}
                    state={state.ruta_item?.contrato}
                    promesa={state.ruta_item?.promesa}
                    toast={toast}
                    GetData={handleGetPromesasdePago}
                    infoContactoData={infoContactoData}
                />
            }
            {
                isOpenModalPromesaPagoIncumplimiento &&
                <IncumplimientoPromesaPagoModal
                    item={promesaPagoData}
                    isOpen={isOpenModalPromesaPagoIncumplimiento}
                    setIsOpen={setisOpenModalPromesaPagoIncumplimiento}
                    state={state.ruta_item?.contrato}
                    promesa={state.ruta_item?.promesa}
                    toast={toast}
                    GetData={handleGetPromesasdePago}
                />
            }
            {
                isOpenModalReferencias &&
                <RegistrarReferenciaModal
                    setIsOpen={setisOpenModalReferencias}
                    isOpen={isOpenModalReferencias}
                    relacionSolicitante={relacionSolicitante}
                    dataEstado={dataEstado}
                    tipoContacto={tipoContacto}
                    subtipoContacto={subtipoContacto}
                    state={state.ruta_item?.contrato}
                    toast={toast}
                    GetDataContactos={handleGetInformacionContactos}
                />
            }
            {
                isOpenModalAgregarContacto &&
                <AgregarDatosContactoModal
                    setIsOpen={setisOpenModalAgregarContacto}
                    isOpen={isOpenModalAgregarContacto}
                    relacionSolicitante={relacionSolicitante}
                    dataEstado={dataEstado}
                    tipoContacto={tipoContacto}
                    subtipoContacto={subtipoContacto}
                    infoContactoData={infoContactoData}
                    state={state.ruta_item?.contrato}
                    toast={toast}
                    GetDataContactos={handleGetInformacionContactos}
                    GetDataSeguimiento={handleContactosSeguimientoLog}
                />
            }
            {
                isOpenModalDireccionContactos &&
                <DireccionesContactoModal
                    data={infoContactoData}
                    isOpen={isOpenModalDireccionContactos}
                    setIsOpen={setIsOpenModalDireccionContactos}

                />
            }
            {
                isOpenModalRegistroLlamada &&
                <RegistroLlamadaModal
                    setIsOpen={setIsOpenModalRegistroLlamada}
                    isOpen={isOpenModalRegistroLlamada}
                    // motivoPromesa={motivoPromesa}
                    // motivoIngresoCartera={motivoIngresoCartera}
                    motivosVisita={motivosVisita}
                    // tipoOperacion={tipoOperacion}
                    tipoOperacionResultado={tipoResultado}
                    infoContactoData={infoContactoData}
                    // promesasStatus={promesasStatus}
                    user={userDetail}
                    state={state}
                    toast={toast}
                    GetDataContactos={handleGetInformacionContactos}
                    GetDataPromesa={handleGetPromesasdePago}
                    GetDataSeguimiento={handleContactosSeguimientoLog}
                    directorioValue={directorioValue}
                    generadoEn={"Unidades Recuperadas"}
                />
            }
            {
                isOpenDocs &&
                <VisorFile
                    isOpen={isOpenDocs}
                    setIsOIpen={setIsOpenDocs}
                    fileName={fileName}
                    doc={SelectedDocs}
                />
            }
            <div style={{ background: "#F5F5F5", paddingBottom: "1rem" }}>
                <header className='heder-subtitle d-flex justify-content-start align-items-center border-bottom px-2' style={{ background: "#FFF" }}>
                    <div className='cursor-pointer' onClick={() => { handleReturnPage() }}>
                        <i className="bx bxs-chevron-left mx-2"></i>
                        Regresar a listado de unidades recuperadas
                    </div>
                </header>
                <div className="col col-11 d-flex">
                    <div className="col col-6 px-4 py-2">
                        <VehiculoInfoCard
                            VIN={currentDataSeguimiento.vin}
                            verClaves={true}
                            solicitarClave={true}
                            datosVehiculo={state?.ruta_item?.contrato.vehiculo}
                        />
                    </div>
                    <div className="col col-6 px-4 py-2">
                        <ContratoInfoCard
                            contrato={currentDataSeguimiento} />
                    </div>
                </div>
                <div className="col col-11 px-4 py-2">
                    <CarteraInfoCard
                        contrato={currentDataSeguimiento}
                        user={userDetail}
                        promesasRotas={promesasRotas} />
                </div>
                <div>
                    <div className="col col-11 mx-3 my-2  wrapper-vehiculo " style={{ background: "#FFF" }}>
                        <Accordion title="Historial de promesas de pago">
                            <p className="text-base"><strong>Registro de garantía actual</strong></p>
                            <DataTable
                                column={columnsGarantiaPromesas}
                                data={dataGarantia}
                                actionColumns
                                shieldNegativeColumn
                                handleShieldNegative={handleModalIncumplimiento}
                                calendarPlusColumn
                                handleCalendarPlus={handleModalAmpliacion}
                            />
                            <p className="text-base"><strong>Historial de garantías</strong></p>
                            <DataTable
                                column={columnsHistorialPromesas}
                                data={dataGarantiaHistorial}
                            />
                            <p className="text-base"><strong>Detalle de promesas</strong></p>
                            <DataTable
                                column={columnsDetallePromesas}
                                data={currentDataPromesas}
                                detailable={true}
                                handleDetail={handlePromesasPago}
                                actionColumns
                                deleteableColumn
                                handleDelete={handlePromesasPagoDelete}
                            />
                        </Accordion>
                    </div>
                </div>
                <div>
                    <div className="col col-11 mx-3 my-2 wrapper-vehiculo " style={{ background: "#FFF" }}>
                        <Accordion title="Directorio de Contactos">
                            <ul className="nav nav-tabs text-sm" id="myTab" role="tablist">
                                <li className="nav-item" role="presentation">
                                    <button className={`seguimiento-telefonico-detalle-nav-link ${directorioValue == "Nuevo Titular" && "tab-active"}`} value={'Nuevo Titular'} type="button" role="tab" onClick={(e) => handleSetStateTab(e.target.value)} disabled={promesasRotas >= promesasRotasTotal}>Nuevo Titular</button>
                                </li>
                                <li className="nav-item" role="presentation">
                                    <button className={`seguimiento-telefonico-detalle-nav-link ${directorioValue == "Cliente" && "tab-active"}`} value={'Cliente'} type="button" role="tab" onClick={(e) => handleSetStateTab(e.target.value)} disabled={promesasRotas >= promesasRotasTotal}>Cliente</button>
                                </li>
                                <li className="nav-item" role="presentation">
                                    <button className={`seguimiento-telefonico-detalle-nav-link ${directorioValue == "Aval" && "tab-active"}`} value={'Aval'} type="button" role="tab" onClick={(e) => handleSetStateTab(e.target.value)} >Aval</button>
                                </li>
                                <li className="nav-item" role="presentation">
                                    <button className={`seguimiento-telefonico-detalle-nav-link  ${directorioValue == "Coacredito" && "tab-active"}`} value={'Coacredito'} type="button" role="tab" onClick={(e) => handleSetStateTab(e.target.value)} disabled={promesasRotas >= promesasRotasTotal} >Coacredito</button>
                                </li>
                                <li className="nav-item" role="presentation">
                                    <button className={`seguimiento-telefonico-detalle-nav-link  ${directorioValue == "Representante Legal" && "tab-active"}`} value={'Representante Legal'} type="button" role="tab" onClick={(e) => handleSetStateTab(e.target.value)} disabled={promesasRotas >= promesasRotasTotal} >Representante Legal</button>
                                </li>
                                <li className="nav-item" role="presentation">
                                    <button className={`seguimiento-telefonico-detalle-nav-link  ${directorioValue == "Referencia" && "tab-active"}`} value={'Referencia'} type="button" role="tab" onClick={(e) => handleSetStateTab(e.target.value)} disabled={promesasRotas >= promesasRotasTotal} >Referencias</button>
                                </li>
                                <div className="container mt-2">
                                </div>
                            </ul>
                            <div>
                                <div className="d-flex align-items-center my-2">
                                    <span className="mx-2"><strong>Datos de contacto</strong></span>
                                    {directorioValue === 'Referencia' && (
                                        <button
                                            className='btn btn-blue d-flex justify-content-center align-items-center'
                                            style={{ paddingLeft: '25px', paddingRight: '25px' }}
                                            onClick={handleModalRef}
                                        >
                                            Agregar Referencias
                                        </button>
                                    )}
                                </div>
                                <TablaAnidada
                                    state={state?.ruta_item?.contrato}
                                    data={infoContactosFiltered}
                                    column={columnsInformacionContacto}
                                    addColumns={2}
                                    buttonText={"Agregar"}
                                    handleClick={handleModalAdd}
                                    moreDirections={true}
                                    handleMoreDirections={handleDireccionesModal}
                                    handleRegistro={handleRegistroLlamada}
                                    childColumn={columnsSubInformacionContacto}
                                    // childData={currentDataPromesas}
                                    childCommentColumn={true}
                                    childActionColumns={true}
                                    childRegistroColumn={true}
                                    setLoading={setLoading}
                                >
                                    <DataTable />
                                </TablaAnidada>
                            </div>
                        </Accordion>
                    </div>
                </div>
                <div>
                    <div className="col col-11 mx-3 my-2  wrapper-vehiculo " style={{ background: "#FFF" }}>
                        <Accordion title="Seguimiento de contactos">
                            <p className="text-base"><strong>Seguimiento telefónico</strong></p>
                            <DataTable
                                column={columnsSeguimientoLog}
                                data={currentDataSeguimientoLog}
                                commentColumn={true}
                                handleDocuments={handleViewdocs}
                                handleAction={(value, idx) => { handleUploadFile(value, idx) }}
                            />
                        </Accordion>
                    </div>
                </div>
                <div>
                    <div className="col col-11 mx-3 my-2  wrapper-vehiculo " style={{ background: "#FFF" }}>
                        <Accordion title="Cita entrega de unidad">
                            <div className="p-4">
                                <CitaEntregaUnidad
                                    dataEstado={dataEstado}
                                    toast={toast}
                                    state={state}
                                    vehiculo={state?.ruta_item?.contrato.vehiculo}
                                />
                            </div>
                        </Accordion>
                    </div>
                </div>
                <div>
                    <div className="col col-11 mx-3 my-2  wrapper-vehiculo " style={{ background: "#FFF" }}>
                        <Accordion title="Entrega de la unidad">
                            <div className="p-4">

                                <EntregaUnidad
                                    state={state}
                                    toast={toast}
                                />
                            </div>
                        </Accordion>
                    </div>
                </div>
            </div>
        </>
    )
}

export default UnidadesRecuperadasDetalle