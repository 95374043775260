import { Form, Formik } from 'formik';
import React, { useEffect, useState } from 'react'
import { useLocation, useNavigate } from 'react-router-dom'
import { useSelector } from 'react-redux';
import DataTable from '../../../Components/datatable/DataTable';
import EditarCobradorModal from './EditarCobradorModal';
import ReasignacionCobradorModal from './ReasignacionCobradorModal';
import Modal from '../../../Components/Modal/Modal';
import { SelectField } from '../../../Components/SelectField';
import Llamada from '../../../Services/Cartera/Llamadas';
import Spinner from '../../../Components/Loadig';
import HistorialDeEstatusDeSistema from './HistorialDeEstatusDeSistema';
import CobranzaService from '../../../Services/Cobranza/Cobranza';

const CobradoresDetalle = () => {
   const {usuario, ZonasCobranza} = useSelector((state) => state.UsuarioSeleccionadoSlice);
   const [data, setData] = useState(usuario.contratos)
   const [dataZonasCobranza, setDataZonasCobranza] = useState(ZonasCobranza);
   const [btnEditConfirm, setBtnEditConfirm] = useState(1);
   const [isOpen, setIsOpen] = useState(false);
   const [dataEstado, setDataEstado] = useState([]);
   const [dataMunicipio, setDataMunicipio] = useState([]);
   const [loading, setLoading] = useState(false);
   const [ openModalHistorial, setOpenModalHistorial] = useState(false);
   const userName = sessionStorage.getItem('usuario')
   const userId = sessionStorage.getItem('userId').trim()
   const [currentData, setCurrentData] = useState([])
   const [zonasTotalesActuales, setZonasTotalesActuales]=useState([])
   
   const [zonasSeleccionadas, setZonasSeleccionadas]=useState([])
   const [dataZonas, setDataZonas] = useState([])
   const [dataZonasTotales, setDataZonasTotales] = useState([])
   const [filtroEstado, setFiltroEstado] = useState('')
   const [filtroMunicipio, setFiltroMunicipio] = useState('')
   const [currentPage, setCurrentPage] = useState(1);
   const [pageRows, setpageRows] = useState(10)
   const [pageCount, setPageCount] = useState();
   const [update, setUpdate] = useState(0)

   let initial = {
      userid: usuario.userid,
      nombrecobrador: usuario.nombrecobrador,
      usuariosistema: userName,
      nivel: usuario.nivel_cobrador.value,
      telefono: usuario.telefono,
      status: usuario.status,
      estatusdesistema: usuario.estatusdesistema,
      statussistem: usuario?.estatusdesistema === null ? false : usuario?.estatusdesistema?.toLowerCase() === "disponible" ? true : false,
      estado: "",
      municipio: ""
   }
   
   const [initialValue, setInitialValue] = useState(initial);
   const { state } = useLocation();
   const navigate = useNavigate();
   const [str, setStr] = useState("");
   const [OpenModalEditar, setIsOpenModalEditar] = useState(false);
   const [OpenModalReasignacion, setIsOpenModalReasignacion] = useState(false);

   const HandleGetEstadoMx = async () => {
      setLoading(true);
      await Llamada.GetEstadoMx().then(res => {
         let Estados = []
         res.data.data?.map(item => {
            Estados.push({
                  value: item.edoMexicoId,
                  label: item.estado
            });
         });
         setDataEstado(Estados);
         setLoading(false);
      })
   }

   const HandleGetMunicpio = async (id) => {
      setDataMunicipio([]);
      await Llamada.GetMunicipio(id).then(res => {
         let Municipios = []
         res.data?.data?.map(item => {
            Municipios.push({
               value: item.municipioId,
               label: item.municipio
            });
         });
         setDataMunicipio(Municipios);
      })
   }

   useEffect(()=>{
      HandleGetEstadoMx()
   },[])
   
   useEffect(()=>{
      handleCargarZonasCobranza()
   },[filtroEstado, filtroMunicipio])

   useEffect(() => {
      // Filtra los datos según los filtros seleccionados
      let filteredData = dataZonas.filter(item => {
         const nameMatches = str === '' || (item.zonacobranza && item.zonacobranza.toLowerCase().includes(str.toLowerCase()));

         return ( nameMatches )
      })

      let filterZonas = dataZonasTotales.filter(item => {
         const nameMatches = str === '' || (item.zonacobranza && item.zonacobranza.toLowerCase().includes(str.toLowerCase()));

         return ( nameMatches ) 
      })

      const indexOfLastItem = currentPage * pageRows
      const indexOfFirstItem = indexOfLastItem - pageRows
      const newData = filteredData.slice(indexOfFirstItem, indexOfLastItem)
      setCurrentData(newData)

      const newTotalPages = Math.ceil(filteredData.length / pageRows)
      setPageCount(newTotalPages)

      const indexOfLastItemZ = currentPage * pageRows
      const indexOfFirstItemZ = indexOfLastItemZ - pageRows
      const newDataZ = filterZonas.slice(indexOfFirstItemZ, indexOfLastItemZ)
      setZonasTotalesActuales(newDataZ)

      const newTotalPagesZ = Math.ceil(filterZonas.length / pageRows)
      setPageCount(newTotalPagesZ)

   }, [str, dataZonas])

   const handleCargarZonasCobranza = () =>{
      let zonasCobranzaUsuario = []
      let listaZonas = new Set()
      data?.map(contrato=>{
         listaZonas.add(contrato.zona)
      })

      let zonasTotalesCobranza = []
      let listaZonasTotales = new Set()
      dataZonasCobranza?.map(contrato=>{
         listaZonasTotales.add(contrato.zona)
      })

      for(const zona of listaZonas){
         let counter = 0
         let totalZonas = []
         let newData = [{}]
         data?.map(contrato=>{
            if(contrato.zona === zona && (filtroEstado === '' || contrato.estado === filtroEstado) && (filtroMunicipio === '' || contrato.municipio === filtroMunicipio)){
               counter++
               totalZonas.push({
                  numero: counter,
                  estado: contrato.estado,
                  municipio: contrato.municipio,
                  colonia: contrato.colonia
               })
            }
            else{
               newData.push(contrato)
            }
         })
         if(counter > 0){
            zonasCobranzaUsuario.push({
               zonacobranza: zona,
               constratosasignados: counter,
               detail: totalZonas
            })
         }
         setData(newData)
      }

      for(const zona of listaZonasTotales){
         let counter = 0
         let totalZonas = []
         let newData = [{}]
         dataZonasCobranza?.map(contrato=>{
            if(contrato.zona === zona && (filtroEstado === '' || contrato.estado === filtroEstado) && (filtroMunicipio === '' || contrato.municipio === filtroMunicipio)){
               counter++
               totalZonas.push({
                  numero: counter,
                  estado: contrato.estado,
                  municipio: contrato.municipio,
                  colonia: contrato.colonia
               })
            }
            else{
               newData.push(contrato)
            }
         })
         if(counter > 0){
            zonasTotalesCobranza.push({
               zonacobranza: zona,
               constratosasignados: counter,
               detail: totalZonas
            })
         }
         setDataZonasCobranza(newData)
      }

      setDataZonasCobranza(ZonasCobranza)
      setData(usuario.contratos)
      setDataZonasTotales(zonasTotalesCobranza)
      setDataZonas(zonasCobranzaUsuario)
      
   }

   const columns = [
      { field: 'zonacobranza', headerName: 'Zona de cobranza', width: 350 },
      { field: 'constratosasignados', headerName: 'Contratos asignados', width: 200 },
      { field: 'zonaasignadatooltip', headerName: 'Zona asignada', width: 150 },
   ]

   const columnsDetail = [
      { field: 'numero', headerName: '#', width: 50 },
      { field: 'estado', headerName: 'Estado', width: 180 },
      { field: 'municipio', headerName: 'Municipio', width: 200 },
      { field: 'colonia', headerName: 'Colonia', width: 200 }
   ];

   const handleCheck = (checked, value) => {
      let actualizarZonas = []
      if(checked){
         zonasSeleccionadas.map(item=>{
            actualizarZonas.push(item)
         })
         actualizarZonas.push(value)
      }
      else{
         zonasSeleccionadas.map(item=>{
            if(item.indice !== value.indice){
               actualizarZonas.push(item)
            }
         })
      }
      setZonasSeleccionadas(actualizarZonas)
   }

   const handleConfirmar = async() =>{
      setLoading(true);
      let dataContratos = []

      zonasSeleccionadas.map(item=>{
         item.detail.map(element=>{
            dataContratos.push({ "id":element.id,  "cartera_vencida":1} )
         })
      })

      let data = {
         "verb": "string",
         "id": userId,
         "field": "string",
         "value": usuario.userid,
         "anyObject": dataContratos
      }
      
      await CobranzaService.reAsignarCarga(data)
         .then(res => {
            setLoading(false);
            navigate(-1)
         })
         .catch(e => {
            setLoading(false);
            console.log(`ERROR ${e}`);
         })

      setIsOpen(false);
      setBtnEditConfirm(1);
   }

   const handleHistorial = () => {
      setOpenModalHistorial(true);
   }

   return (
      <>
         <Modal isOpen={loading} color='transparent'><Spinner message={""} /></Modal>

      <Formik
         enableReinitialize={true}
         initialValues={initialValue}
         //validationSchema={validate}
         onSubmit={(values) => {
         }}
      >{
            ({ isValid, values, handleChange, setFieldValue }) => (
               <>
                  <Modal isOpen={loading} color='transparent'><Spinner message={""} /></Modal>
                  <header className='heder-subtitle d-flex justify-content-start align-items-center border-bottom px-3'>
                     <i className="bx bxs-chevron-left mx-2" onClick={() => { navigate(-1) }}></i>
                     Regresar a Cobradores {state.page}
                  </header>
                  <Form className='mx-5' style={{width: "90rem"}}>
                  <section style={{ height: "14rem"}}>
                     <section className=' py-4'>
                        <div className='d-flex'>
                           <span className='fs-5 d-flex' >
                              <fieldset data-toggle="tooltip" data-placement="top" data-bs-placement="top" title={"Cobrador Activo"}>
                                 <i className="icon-dark ri-account-circle-line" style={{fontSize: "30px", marginRight: "5px"}}></i>
                              </fieldset>
                              <span style={{fontSize: "30px", color: "#000", fontWeight: "bold"}}>
                                 <strong>{values.nombrecobrador}</strong>
                              </span>
                           </span>
                           <div className='px-4'>
                              <fieldset className='btn btn-light secondary d-flex align-items-center' style={{ width: '40px' }} onClick={() => { setIsOpenModalEditar(true); }} >
                                 <i className='ri-pencil-fill' style={{fontSize:"22px", color: "#032fe7"}}></i>
                              </fieldset>
                           </div>
                        </div>
                        <div className='mt-3' >
                           <span style={{fontSize: "17px", fontWeight: "bold"}}>Datos de cobrador:</span>
                        </div>
                     </section>
                     <div className="mb-3 row" style={{fontSize: "17px"}}>
                        <div className='col-2'>
                           <span style={{ fontWeight: "bold"}}><strong>Usuario</strong></span> <br />
                           <span >{values.usuariosistema}</span>
                        </div>
                        <div className='col-2'>
                           <span style={{ fontWeight: "bold"}}><strong>Nivel de cobranza</strong></span> <br />
                           <span >{values.nivel}</span>
                        </div>
                        <div className='col-2'>
                           <span style={{fontWeight: "bold"}}><strong>Número de teléfono</strong></span> <br />
                           <span >{values.telefono}</span>
                        </div>
                        <div className='col-2'>
                           <span style={{ fontWeight: "bold"}}><strong>Estatus de sistema</strong></span> <br />
                           <span style={{display: 'flex'}}><i className="ri-checkbox-circle-fill" style={{color: values.statussistem===true ? "#3ed800" : "#b9b9b9"}}></i> {values.estatusdesistema}</span>
                        </div>
                        <div className='col-3'>
                           <span style={{ fontWeight: "bold"}}><strong>Historial Estatus de sistema</strong></span> <br />
                           <span style={{display: 'flex'}} onClick={()=>{handleHistorial()}} ><i className="icon-dark ri-file-search-line" style={{fontSize:"20px"}}></i> Ver historial</span>
                        </div>
                     </div>
                  </section>
                  <section>
                     <section className=' py-4'>
                        <div className='d-flex row'>
                           <span className='fs-5 col-6'><span style={{fontSize: "25px", color: "#000", fontWeight: "bold"}}><strong>Zona de cobranza</strong></span></span>
                           <section className='d-flex justify-content-end row col-6'>
                              {
                                 btnEditConfirm === 1 &&(
                                    <button id="AT_btnReasignar" className='btn btn-outline col-4' type='button' onClick={() => { setBtnEditConfirm(2) }}>Editar</button>
                                 )
                              }
                              {  
                                 btnEditConfirm === 2 &&(
                                    <button id="AT_btnAgregar" className='btn mx-2 col-4' type="button" onClick={() => { setIsOpen(true);}}>Confirmar</button>
                                 )
                              }
                           </section>
                        </div>
                     </section>
                     <div className="mb-3 row">
                        <div className="col-2">
                           <SelectField  label="Estado" name="estado" items={dataEstado} onChange={(e) => {
                              setFiltroMunicipio('');
                              setFiltroEstado(e.value !== '' ? e.label : "");
                              setFieldValue("estado", e.value !== 0 ? e.value : "");
                              HandleGetMunicpio(e.value);
                           }} />
                        </div>
                        <div className="col-2">
                           <SelectField  label="Municipio" name="municipio" items={dataMunicipio} onChange={(e) => {
                              setFiltroMunicipio(e.value !== '' ? e.label : "");
                              setFieldValue("municipio", e.value !== 0 ? e.value : "");
                           }} />
                        </div>
                        <div className="col-sm-4 inner-addon right-addon mt-4">
                           <i className="glyphicon fas fa-search"></i>
                           <input type="text" className="form-control" placeholder="Buscar" onKeyUp={(e) => { setStr(e.target.value) }} />
                        </div>
                     </div>

                     {/* <ValidatePermission
                        isActive={ValidatePermissionById(4, Section., SubModule.Usuarios).Ver}
                        > */}
                        {
                           btnEditConfirm === 2 &&(
                              <div className='mx-4 table-responsive' >
                                 <DataTable
                                    column={columns}
                                    data={zonasTotalesActuales}
                                    checkbox={true}
                                    handleCheck={handleCheck}
                                    masterDetail={true}
                                    columnDetail={columnsDetail}
                                 />
                              </div>
                           )
                        }
                        {
                           btnEditConfirm === 1 &&(
                              <div className='mx-4 table-responsive' >
                                 <DataTable
                                    column={columns}
                                    data={currentData}
                                    masterDetail={true}
                                    columnDetail={columnsDetail}
                                 />
                              </div>
                           )
                        }
                        {/* </ValidatePermission> */}

                  </section>
                  </Form>
                  {
                     OpenModalEditar &&(
                     <>
                        <EditarCobradorModal
                           isOpen={OpenModalEditar}
                           setIsOpen={setIsOpenModalEditar}
                           item={{userid:usuario.userid, 
                              nombrecobrador: values?.nombrecobrador, 
                              usuariosistema: values?.usuariosistema, 
                              telefono: values?.telefono, 
                              zona:usuario.zona, 
                              nivel_cobrador: usuario?.nivel_cobrador,
                              contratos: usuario?.contratos}}
                           nivel={2}
                        />
                     </>)
                  }
                  {
                     OpenModalReasignacion &&(
                     <>
                        <ReasignacionCobradorModal
                           isOpen={OpenModalReasignacion}
                           setIsOpen={setIsOpenModalReasignacion}
                           item={{nombrecobrador: values?.nombrecobrador, usuariosistema: values?.usuariosistema,telefono: values?.telefono }}
                        />
                     </>)
                  }
                  {
                     openModalHistorial &&(
                     <>
                        <HistorialDeEstatusDeSistema
                           isOpen={openModalHistorial}
                           setIsOpen={setOpenModalHistorial}
                           item={usuario}
                        />
                     </>)
                  }
                  <Modal isOpen={isOpen} color='#fff'>
                     <section className='my-2 mx-4' style={{ width: '35rem' }}>
                        <span className='fs-4' style={{fontWeight:'bold', color:"#000"}}><strong>Confirma los cambios</strong></span>
                        <p>¿Estas seguro de los cambios realizados al cobrador:<span style={{fontWeight:"bold"}}>{usuario.nombrecobrador}</span>?</p>
                        <section className='d-flex justify-content-end mt-5'>
                           <button id="AT_btnCancelar" className='btn btn-outline col-4 mw-100' type='button' onClick={() => { setBtnEditConfirm(1);setIsOpen(false) }}>Regresar</button>
                           <button id="AT_btnGuardar" className='btn mx-2 col-4 mw-100' type="button" onClick={() => { handleConfirmar()}}>Confirmar</button>
                        </section>
                     </section>
                  </Modal>
               </>
            )
         }
      </Formik>
      </>
   )
}

export default CobradoresDetalle