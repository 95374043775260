import React, { useEffect, useRef, useState } from 'react'
import { useNavigate } from 'react-router';
import SeguimientoVisitaService from '../../Services/Cartera/SeguimientoVisitaService';
import SeguroPolizaService from '../../Services/Seguros/SeguroPolizaService';
import CobranzaService from '../../Services/Cobranza/Cobranza';
import { message } from '../../Utils/functions';
import { FormatFecha } from '../../Utils/functions'
import InstalacionDatamovilService from '../../Services/Inventario/InventarioDatamovilService';
import { useDispatch } from 'react-redux';

import { setContrato, setItemGeneral } from '../../Redux/Slice/Cobranza/SeguimientoVisita/SeguimientoVisitaDetalleSlice';

const useHistorialVisitas = () => {
    const navigate = useNavigate();
    const dispatch = useDispatch();
    const columns = [
        { field: 'folio', headerName: 'Ruta', width: 150 },
        { field: 'contrato_id', headerName: 'Contrato', width: 150 },
        { field: 'tipo_persona', headerName: 'Tipo de persona', width: 150 },
        { field: 'nombre', headerName: 'Nombre', width: 150 },
        { field: 'dias_vencido', headerName: 'Días vencidos', width: 150 },
        { field: 'tipo_visita', headerName: 'Tipo de visita', width: 150 },
        { field: 'monto_adeudo', headerName: 'Monto vencido', width: 150 },
        { field: 'resultado', headerName: 'Resultado', width: 150 },
        { field: 'fecha_formateada', headerName: 'Fecha visita', width: 150 }
    ];

    //HARDCODE: START CODIGO DE TEST
    const tipovisitatest = [
        { id: '8210a662-7de9-4c68-bc8d-0ac33d671db4', descripcion: 'Primer visita' },
        { id: 'd283e90c-83a1-41af-b78d-63a643e2d9cb', descripcion: 'Segunda visita' },
        { id: 'a7b9c77e-6d87-4a3a-b0d8-d2c8a8437a1f', descripcion: 'Tercer visita' },
        { id: 'b3fa1a8c-9a7b-4977-824e-7e3644de8dfb', descripcion: 'Cuarta visita' }
    ];

    //HARDCODE: END CODIGO DE TEST

    const [textoBusqueda, setTextoBusqueda] = useState("")
    const [currentPage, setCurrentPage] = useState(1);
    const [pageCount, setPageCount] = useState(1);
    const [loading, setLoading] = useState(false);
    const [opcionesTipoVisita, setopcionesTipoVisita] = useState([]);
    const [TipoVisita, setTipoVisita] = useState("")
    //Filtro
    const [data, setData] = useState([{}]);
    const [pageRows, setpageRows] = useState(10);
    const [currentData, setCurrentData] = useState(data);
    const [fechaInicio, setFechaInicio] = useState("");
    const [fechaFin, setFechaFin] = useState("");
    const userId = sessionStorage.getItem('userId').trim();
    const [rangoFechas, setRangoFechas] = useState();
    const [percentage, setPercentage] = useState(0)

    const [active7, setActive7] = useState(false)
    const [active15, setActive15] = useState(false)
    const [firstProgressVigente, setFirstProgressVigente] = useState(0)
    const [firstProgressCumplidas, setFirstProgressCumplidas] = useState(0)
    const [secondProgressVigentes, setSecondProgressVigentes] = useState(0)
    const [secondProgressCumplidas, setSecondProgressCumplidas] = useState(0)

    const [totalContratos, setTotalContratos] = useState('0')
    const [totalMontoVencido, setTotalMontoVencido] = useState('0')
    const [totalMontoCobrado, setTotalMontoCobrado] = useState('0')

    const [allPromesas, setAllPromesas] = useState()
    const [modalMessage, setModalMessage] = useState({ isOpen: false, type: 1, title: '', message: '', data: [], authorizationType: 2, Origin: "Listado" });

    const [isOpen, setIsOpen] = useState(false)
    const [isVisible, SetIsVisible] = useState(true)
    const dropdownRef = useRef(null)

    const toggleDropdown = () => {
        setIsOpen(!isOpen)
    }

    const toggleVisible = (visible) => {
        SetIsVisible(visible)
        setIsOpen(false)
    }

    // Volver estas funciones una sola, que active y que guarde los datos secundarios en variable seundaria a enviar.
    const toggleActive7 = () => {
        setActive15(false)
        const vigente = analizarDatos(allPromesas, 7, "Vigente")
        const cumplidas = analizarDatos(allPromesas, 7, "Cumplidas")

        setSecondProgressVigentes(vigente)
        setSecondProgressCumplidas(cumplidas)
        setActive7(!active7)
    }

    const toggleActive15 = () => {
        setActive7(false)
        const vigente = analizarDatos(allPromesas, 15, "Vigente")
        const cumplidas = analizarDatos(allPromesas, 15, "Cumplidas")
        setSecondProgressVigentes(vigente)
        setSecondProgressCumplidas(cumplidas)
        setActive15(!active15)
    }

    function analizarDatos(datos, dias, estado) {

        // Función para obtener la fecha límite
        const obtenerFechaLimite = () => {
            const hoy = new Date();
            const fechaLimite = new Date(hoy);
            fechaLimite.setDate(hoy.getDate() - dias); // Resta los días especificados
            return fechaLimite;
        };

        const fechaLimite = obtenerFechaLimite();

        // Filtrar datos según la fecha
        const datosFiltrados = datos.filter(obj => new Date(obj.fecha_compromiso) <= fechaLimite);
        const total = datosFiltrados.length;

        // Suma total de dinero
        const activos = datosFiltrados.filter(obj => obj.promesa_status_nombre === estado);
        const sumaTotalDinero = activos.length > 0 ?
            activos.reduce((total, obj) => total + obj.monto_recuperado, 0) : 0

        // Número de IDs diferentes
        const idsDiferentes = activos.length > 0 ? new Set(activos.map(obj => obj.contrato_id)).size : 0

        // Número de estado activo y cálculo del porcentaje
        const porcentajeActivos = total > 0 ? (activos.length / total) * 100 : 0;

        return {
            sumaTotalDinero,
            idsDiferentes,
            porcentajeActivos: porcentajeActivos.toFixed(2)
        };
    }

    useEffect(() => {
        habdleGetAll();
        // handleGetTipoVisita();
        handleGetPromesasdePago();
    }, [])

    useEffect(() => {
        // Filtra los datos según los filtros seleccionados
        const filteredData = data.filter(x => {
            return (textoBusqueda === '' || (x.ruta_head_id && x.ruta_head_id.toLowerCase().includes(textoBusqueda.toLowerCase()))) &&
                (TipoVisita === 'Todos' || (x.tipo_visita && x.tipo_visita.toLowerCase().includes(TipoVisita.toLowerCase())));
        });

        const filteredDataSegundoFiltro = filteredData.filter(x => {
            const fechaVisita = new Date(x.fecha.split('/').reverse().join('-'));
            const cumpleFechaInicio = fechaInicio ? fechaVisita >= new Date(fechaInicio) : true;
            const cumpleFechaFin = fechaFin ? fechaVisita <= new Date(fechaFin) : true;
            return cumpleFechaInicio && cumpleFechaFin;
        }, []);

        const indexOfLastItem = currentPage * pageRows
        const indexOfFirstItem = indexOfLastItem - pageRows
        const newData = filteredDataSegundoFiltro.slice(indexOfFirstItem, indexOfLastItem)
        // const newData = filteredData.slice(indexOfFirstItem, indexOfLastItem)
        setCurrentData(newData)
        const newTotalPages = Math.ceil(filteredDataSegundoFiltro.length / pageRows)
        // const newTotalPages = Math.ceil(filteredData.length / pageRows)
        setPageCount(newTotalPages)
        // if (currentPage > newTotalPages) {
        //     setCurrentPage(newTotalPages)
        // } else if (currentPage <= 0) {
        //     setCurrentPage(1)
        // }

        // Solo actualiza currentPage si es necesario para evitar bucles infinitos
        if (currentPage > newTotalPages && currentPage !== newTotalPages) {
            setCurrentPage(newTotalPages);
        } else if (currentPage <= 0 && currentPage !== 1) {
            setCurrentPage(1);
        }

        // }, [currentPage, pageRows, textoBusqueda, TipoVisita, fechaInicio, fechaFin])
    }, [data, pageRows, textoBusqueda, TipoVisita])

    const closeDropdown = (e) => {
        if (dropdownRef.current && !dropdownRef.current.contains(e.target)) {
            setIsOpen(false)
        }
    }

    useEffect(() => {
        document.addEventListener('mousedown', closeDropdown)
        return () => document.removeEventListener('mousedown', closeDropdown)
    }, [])

    const habdleGetAll = async () => {
        setLoading(true);
        await SeguimientoVisitaService.getAllCobranzaRutaHead()
            .then(res => {
                const userContracts = res.data.responseData.find(user => user.userid.toLowerCase() === userId.toLowerCase());

                // Validar y filtrar si el listado de contratos no es null o vacío
                if (Array.isArray(userContracts?.contratos) && userContracts?.contratos?.length > 0) {
                    userContracts.contratos = userContracts.contratos.filter(item => item.cartera_vencida === 1 && item.nivel_cobranza === "2");
                }

                // feb42264-1529-49ad-8473-29e5e61d9402
                let datosFormateados = userContracts?.items?.map((item) => {
                    // const fechaPago = FormatFecha(item.fecha_ultimo_pago)
                    let filtroContrato = null
                    if (item?.contrato !== null && item?.contrato?.cartera_vencida === 1 && item?.contrato?.nivel_cobranza === "2") {
                        filtroContrato = item.contrato
                    }
                    const fechaFormateada = FormatFecha(item.fecha)
                    return {
                        ...item,
                        contrato: filtroContrato,
                        folio: userContracts.folio.toString().padStart(9, '0'),
                        contrato_id: item.contrato.contrato_id,
                        numero_ejecutivo: item.contrato.numero_ejecutivo,
                        nombre: item.contrato.nombre + ' ' + item.contrato.apellido_paterno + ' ' + item.contrato.apellido_materno,
                        dias_vencido: item.contrato.dias_vencido,
                        monto_adeudo: item.contrato.monto_adeudo,
                        fecha_formateada: fechaFormateada,
                        tipo_persona: filtroContrato === null ? "" : filtroContrato?.tipo_persona
                    }
                })

                let contratosFiltrador = userContracts?.items.filter(item => item.contrato.estatus === 'Activo')
                let length = userContracts?.items.filter(item => item.contrato).length;
                let percentage = contratosFiltrador?.length > 0 ? length / contratosFiltrador?.length : 0

                setTotalContratos(length)
                setPercentage(percentage)

                // setRangoFechas(userContracts.rangoFechas);

                const opcionesTipoVisita = [...new Map(datosFormateados.map(x => [`${x.id}-${x.tipo_visita}`, { value: x.id, label: x.tipo_visita }])).values()];
                setopcionesTipoVisita(opcionesTipoVisita);

                setData(datosFormateados);
            }).catch(e => {
                setLoading(false);
                if (e.response !== undefined)
                    setModalMessage({ ...modalMessage, isOpen: true, type: 1, message: e.response.data.message })
                else
                    setModalMessage({ ...modalMessage, isOpen: true, type: 1, message: e.message })
            });
        setLoading(false);
    }

    const handleDownloadFile = async () => {
        setLoading(true);
        await InstalacionDatamovilService.downloadVehiculosInventario(TipoVisita)
            .then(res => {

                var FileSaver = require('file-saver');
                FileSaver.saveAs(res.data, `VehiculoDeInventario-${new Date(Date.now()).toLocaleString().split(',')[0]}.xlsx`);

                habdleGetAll(1);

                setLoading(false);
            }).catch(e => {
                setLoading(false);
            })
    }

    const handleGetTipoVisita = async () => {
        await SeguroPolizaService.getAllTipocompra()
            .then(res => {
                let NewData = [];
                NewData.push({
                    value: "",
                    label: "Todos"
                })
                // res.data.data?.map(item => {
                //     NewData.push({
                //         value: item.tipoCompraId,
                //         label: item.tipoCompra
                //     })
                // });

                tipovisitatest?.map(item => {
                    NewData.push({
                        value: item.id,
                        label: item.descripcion
                    })
                });

                setopcionesTipoVisita(NewData);
            }).catch(e => {
            })
    }

    const handlePageClick = (event) => {
        setCurrentPage(event.selected + 1);
        habdleGetAll(event.selected + 1);
    };

    // const handleDetails = (value) => {
    //     //Movimiento a Detalles del Job
    //     navigate(`/cobranza/seguimiento-visita/historial-visitas-detalle`, {
    //         state: value
    //     })
    // }

    const handleDetails = async (item) => {
        // setLoading(true);

        dispatch(setContrato(item.contrato));
        dispatch(setItemGeneral(item));
        navigate(`/cobranza/seguimiento-visita/historial-visitas-detalle`);

        // await CobranzaService.GetAllRutasCobranza()
        //     .then(res=>{
        //         res.data.responseData?.map(user=>{
        //         if(user.userid.toLowerCase() === userId.toLowerCase()){
        //             user.items?.map(item=>{
        //             if(item.contrato.contrato_id.toLowerCase() === contrato.contrato_id.toLowerCase()){
        //                 dispatch(setContrato(item.contrato));
        //                 dispatch(setItemGeneral(item));
        //                 navigate(`/cobranza/seguimiento-visita/historial-visitas-detalle`);
        //             }
        //             })
        //         }
        //         })
        //       setLoading(false);
        //     })
        //     .catch(e => {
        //         setLoading(false);
        //     })
    }

    const handleGetPromesasdePago = async () => {
        setLoading(true);
        await CobranzaService.getAllPromesasDePago()
            .then(res => {
                const vigente = analizarDatos(res.data, 0, "Vigente")
                const cumplidas = analizarDatos(res.data, 0, "Cumplidas")

                const suma = res.data.reduce((total, obj) => {
                    return {
                        montoVencido: total.montoVencido + obj.monto_vencido,
                        montoCobrado: total.montoCobrado + obj.monto_recuperado
                    };
                }, { montoVencido: 0, montoCobrado: 0 }); // Inicializamos el acumulador

                setAllPromesas(res.data)
                setTotalMontoCobrado(suma.montoCobrado)
                setTotalMontoVencido(suma.montoVencido)
                setFirstProgressVigente(vigente)
                setFirstProgressCumplidas(cumplidas)

                setLoading(false);
            }).catch(e => {
                setLoading(false);
            })
    }

    return {
        setTextoBusqueda,
        columns,
        currentData,
        handleDetails,
        handlePageClick,
        currentPage,
        pageCount,
        loading,
        opcionesTipoVisita,
        setpageRows,
        setTipoVisita,
        handleDownloadFile,
        setFechaInicio,
        setFechaFin,
        rangoFechas,
        dropdownRef,
        toggleDropdown,
        toggleVisible,
        firstProgressVigente,
        firstProgressCumplidas,
        secondProgressVigentes,
        secondProgressCumplidas,
        totalMontoCobrado,
        totalMontoVencido,
        active7,
        active15,
        totalContratos,
        percentage,
        isVisible,
        toggleActive7,
        toggleActive15,
        isOpen
    }
}

export default useHistorialVisitas