import { createSlice } from '@reduxjs/toolkit'

const initialState = {
   usuario: {
      // userid: "",
      // nombrecobrador: "",
      // usuariosistema: "",
      // nivel: {},
      // telefono: "",
      // status: false,
      // estatusdesistema: "",
      // data: []
   },
   ZonasCobranza:[]
}
export const UsuarioSeleccionadoSlice = createSlice({
   name: 'UsuarioSeleccionadoSlice',
   initialState,
   reducers: {
      setUsuario: (state, action) => {
         return { ...state, usuario: action.payload }
      },
      setLimpiarUsuario: (state, action) => {
         return { ...state,
            usuario: {
               // userid: "",
               // nombrecobrador: "",
               // usuariosistema: "",
               // nivel: "",
               // telefono: "",
               // status: false,
               // estatusdesistema: "",
               // data: []
            }
         }
      },
      setZonasCobranza: (state, action) => {
         return { ...state, ZonasCobranza: action.payload }
      },
      setLimpiarZonasCobranza: (state, action) => {
         return { ...state,
            usuario: []
         }
      }
   },
})

export const { setUsuario, setLimpiarUsuario, setZonasCobranza, setLimpiarZonasCobranza } = UsuarioSeleccionadoSlice.actions

export default UsuarioSeleccionadoSlice.reducer