import React from 'react'
import { useState, useEffect } from 'react';
import { useSelector, useDispatch } from 'react-redux'
import { setFields } from '../../Redux/Slice/Calendario/calendarDynamicalSlice';
import { useLocation } from 'react-router-dom';

const ScheduleDynamicalModal = ({ modalMessage, setModalMessage, schedules }) => {
    const { state } = useLocation();
    const dispatch = useDispatch();

    useEffect(() => {
        getDate();
    }, [schedules]);

    const [day, setDay] = useState("");
    const days = ['Domingo', 'Lunes', 'Martes', 'Miércoles', 'Jueves', 'Viernes', 'Sabado'];
    const months = ['Enero', 'Febrero', 'Marzo', 'Abril', 'Mayo', 'Junio', 'Julio', 'Agosto', 'Septiembre', 'Octubre', 'Noviembre', 'Diciembre'];

    const schedulesPerColumn = schedules.length <= 9 ? Math.ceil(schedules.length / 3) : Math.ceil(schedules.length / 4);
    const columns = schedules.length <= 9 ? 3 : 4;


    const getDate = () => {
        const selectedDay = schedules[0].fecha.split("T")[0];
        const date = new Date(selectedDay.replace('-', '/'));
        const strDate = days[date.getDay()] + ", " + ("0" + date.getDate()).slice(-2) + " de " + months[date.getMonth()];
        setDay(strDate);
    }

    const handleNextModal = (hour) => {
        const schedule = schedules.filter(item => item.horario.split(" ")[0] === hour)[0];
        let data = {
            tipoCalendario: state?.tipoCalendario,
            fields: {
                day: schedule.fecha,
                hour: schedule.horario,
                calendarioId: schedule.id,
            }
        }
        dispatch(setFields(data));
        setModalMessage({ ...modalMessage, type: 5 })
    }

    const getSchedules = () => {
        const rows = [];
        const grid = 12 / columns;
        const currentDate = new Date();
        const currentDay = currentDate.toISOString().split('T')[0];
        const currentHour = currentDate.toTimeString().slice(0, 5);
        const selectedDay = schedules[0].fecha.split('T')[0];
    
        let idx = 0;
        
        const renderCell = (hour, disponible) => (
            <div className='row px-3 py-1' key={'cell_' + hour}>
                {disponible === 1 ? (
                    <button className='btn btn-gray' onClick={() => handleNextModal(hour)}>
                        {hour}
                    </button>
                ) : (
                    <span style={{ textDecoration: 'line-through', padding: '5px' }} className='text-center'>
                        {hour}
                    </span>
                )}
            </div>
        );
    
        for (let i = 1; i <= columns; i++) {
            const cells = [];
            
            for (let j = 0; j < schedulesPerColumn && idx < schedules.length; j++) {
                const { horario, disponible } = schedules[idx];
                const hour = horario.split(' ')[0];
                const tmpHour = new Date();
                const [hourPart, minutePart] = hour.split(':');
                tmpHour.setHours(hourPart, minutePart, 0, 0);
                const tmpHourMinutes = hour;
    
                if (currentDay !== selectedDay) {
                    cells.push(renderCell(hour, disponible));
                } else {
                    if (disponible === 1 && currentHour < tmpHourMinutes) {
                        cells.push(renderCell(hour, disponible));
                    } else if (disponible === 2 || currentHour >= tmpHourMinutes) {
                        cells.push(renderCell(hour, 2));
                    }
                }
    
                idx++;
            }
    
            rows.push(
                <div className={'col-' + grid} key={'row_' + grid + '_' + i}>
                    {cells}
                </div>
            );
        }
    
        return rows;
    }    

    return (
        <div className='p-2'>
            <div className='justify-content-start align-items-center'>
                <div className="row">
                    <div className="col col-12">
                        <h6><strong>Horarios de cita</strong></h6>
                    </div>
                </div>
                <div className="row mt-3 mb-2">
                    <div className="col col-12">
                        <small>A continuación se muestran los horarios disponibles para agendar cita:</small>
                    </div>
                </div>
                <div className="row mt-2 mb-2">
                    <div className="col col-12">
                        <strong>{day}</strong>
                    </div>
                </div>
                <div className='row mt-2 mb-2'>
                    <div className='col col-12'>
                        <div className="row">
                            {getSchedules()}
                        </div>
                    </div>
                </div>
                <section className='d-flex justify-content-end'>
                    <button id="AT_BtnCloseModal" className='btn btn-blue d-flex justify-content-center align-items-center' style={{ paddingLeft: '25px', paddingRight: '25px' }} onClick={() => { setModalMessage({ ...modalMessage, isOpen: false }) }}>Cerrar</button>
                </section>
            </div>
        </div>
    )
}

export default ScheduleDynamicalModal