import {Formik } from 'formik';
import React, { useEffect, useState } from 'react'
import { useLocation, useNavigate } from 'react-router-dom'
import Select from 'react-select';
import { TextField } from '../../../Components/TextField';
import CobranzaService from '../../../Services/Cobranza/Cobranza';
import DataTable from '../../../Components/datatable/DataTable';
import RowPages from '../../../Components/RowPages';
import { useSelector } from 'react-redux';
import Modal from '../../../Components/Modal/Modal';
import Spinner from '../../../Components/Loadig';

const ReasignarCargaNivel2 = () => {
   const {usuario} = useSelector((state) => state.ReasignarCargaNivelDosSlice);
   let initial = {
      userid: usuario.userid,
      nombrecobrador: usuario.nombrecobrador,
      numerocontratos: usuario.numerocontratos,
      numerozonas: usuario.numerocontratos, //Se debe ajustar al numero de zonas, aun por determinar
      contratos: usuario.contratos,
      tipodeintercambio: "",
      cobradordestino: ""
   }
   const [initialValue, setInitialValue] = useState(initial);
   const [dataPersonal, setDataPersonal] = useState([]);
   const [pageRows, setpageRows] = useState(10);
   const [counterContratosZonas, setCounterContratosZonas ] = useState(0);
   const [opcionIntercambio, setOpcionIntercambio] = useState({value:1, label:"Contratos"});
   const [loading, setLoading] = useState(false);
   const [listaContratos, setListaContratos] = useState([]);
   const [str, setStr] = useState("");
   const [pageCount, setPageCount] = useState();
   const [page, setPage] = useState(1);
   const [currentPage, setCurrentPage] = useState(1);
   const [currentData, setCurrentData] = useState(usuario.contratos)
   const [cobradorDestino, setCobradorDestino] = useState(0);

   const { state } = useLocation();
   const navigate = useNavigate();

   useEffect(() => {
      let filteredData = usuario.contratos?.filter(item => {
         const nameMatches = str === '' || (item.contrato && item.contrato.toLowerCase().includes(str.toLowerCase()));
         return (nameMatches)
      })

      const indexOfLastItem = currentPage * pageRows
      const indexOfFirstItem = indexOfLastItem - pageRows
      const newData = filteredData.slice(indexOfFirstItem, indexOfLastItem)
      setCurrentData(newData)
  
      const newTotalPages = Math.ceil(filteredData.length / pageRows)
      setPageCount(newTotalPages)
   }, [currentPage, str, pageRows])

   useEffect(()=>{
      handleGetAllUsersNotInCobranza();
  },[]);

   const handleGetAllUsersNotInCobranza = async () => {
      let NewData = [{value:null, label:"Seleccionar"}];
      //await CobranzaService.getAllUsersNotInCobranza()
      await CobranzaService.getEjecutivosFullDetail()
         .then(res => {
            if(res.data.callSucceded){
               res.data.responseData?.map(item => {
                  //NewData.push({value: item.userId, label: item.names})
                  if (item.nivel_cobrador === "2" && item?.userid !== usuario.userid){
                     NewData.push({value: item?.userid, label: item?.user_detail?.names})
                  }
               })
            }
         })
         .catch(e => {
            console.log(`ERROR -- ${e}`)
         })
      setDataPersonal(NewData);
   }

   const tipoIntercambio = [
      {value:1, label:"Contratos"},
      {value:2, label:"Zonas"}
   ]

   const columnsContratos = [
      { field: 'numero', headerName: '#', width: 50 },
      { field: 'zona', headerName: 'Zona', width: 300 },
      { field: 'contrato', headerName: 'Contrato', width: 150 },
      { field: 'tipopersona', headerName: 'Tipo persona', width: 130 },
      { field: 'nombre', headerName: 'Nombre', width: 300 },
      { field: 'direccion', headerName: 'Dirección', width: 400 },
      { field: 'diasvencidos', headerName: 'Días vencidos', width: 130 },
      { field: 'diassinconsultarclave', headerName: 'Días sin consultar clave', width: 200 },
      { field: 'montovencido', headerName: 'Monto vencido', width: 150 },
      { field: 'visitas', headerName: 'Visitas', width: 120 }
   ];

   const columnsZonas = [
      { field: 'zona', headerName: 'Zona', width: 300 },
      { field: 'visitas', headerName: 'Visitas', width: 120 }
   ]

   const handleGuardar = async () => {
      console.log(listaContratos);
      if(listaContratos.length > 0){
         setLoading(true);
         let data = {
            "verb": "string",
            "id": usuario.userid,
            "field": "string",
            "value": cobradorDestino,
            "anyObject":listaContratos
            };

         await CobranzaService.reAsignarCarga(data)
            .then(res => {
               setLoading(false);
               navigate(-1)
            })
            .catch(e => {
               setLoading(false);
               console.log(`ERROR ${e}`);
            })
      }
   }

   const handleCheck = (checked, value) => {
      let newList = [];
      if(checked){
         newList.push({id: value.id})
         if(listaContratos.length > 0){
            listaContratos.map(item => {
               newList.push({id: item.id})
            })
         }
         setCounterContratosZonas(counterContratosZonas + 1);
      }
      else{
         listaContratos.map(item => {
            if(item.id != value.id){
               newList.push({id: item.id});
            }
         })
         setCounterContratosZonas(counterContratosZonas -1 );
      }
      setListaContratos(newList);
   }

   const handlePageClick = (event) => {
      setPage(event.selected + 1);
   };

   return (
      <Formik
         enableReinitialize={true}
         initialValues={initialValue}
         onSubmit={(values) => {
            handleGuardar(values);
         }}
      >{
         ({ isValid, values, setFieldValue }) => (
      <>
         <Modal isOpen={loading} color='transparent'><Spinner message={""} /></Modal>
         <header className='heder-subtitle d-flex justify-content-start align-items-center border-bottom px-3'>
               <i className="bx bxs-chevron-left mx-2" onClick={() => { navigate(-1) }}></i>
               Regresar a listado de supervisión de carga {state.page}
         </header>
         <section className='mx-5 py-4'>
            <div className='d-flex justify-content-between'>
               <span className='fs-5'><strong>Reasignar carga de trabajo</strong></span>
            </div>
         </section>
         <section className='mt-3 mx-5' style={{width: "60rem"}}>
            <p><span style={{color:"#000", fontWeight: "bold"}}>1. Indica el cobrador destino.</span></p>
            <div className="mb-3 row" style={{marginTop:30}}>
               <div className="col-sm-4">
                     <label className='col-12 mx-1'>
                        Tipo de intercambio
                        <Select options={tipoIntercambio} label="Tipo de intercambio" name='tipodeintercambio' defaultValue={opcionIntercambio} placeholder="Seleccionar"
                           onChange={(e) => { 
                              setFieldValue("tipodeintercambio", e.value !== 0 ? e.value : "");
                              setOpcionIntercambio(e);
                              setCounterContratosZonas(0);
                           }}
                        />
                     </label>
                  </div>
               <div className="col-sm-4">
                  <label className='col-12 mx-1'>
                     Cobrador destino
                     <Select options={dataPersonal} label="Cobrador destino" name='cobradordestino' placeholder="Seleccionar" onChange={(e) => {
                        setFieldValue("cobradordestino", e.value !== 0 ? e.value : ""); setCobradorDestino(e.value != 0 ? e.value : null);}}
                     />
                  </label>
               </div>
               <div className="col-sm-4">
                     <TextField label={opcionIntercambio.value === 1 ? "Contratos actuales": "Zonas actuales"} name="numerocontratos" type="text" value={values.numerocontratos} disabled={true} />
               </div>
            </div>
            <p><span style={{color:"#000", fontWeight: "bold"}}>2. Selecciona los contratos que deseas reasignar.</span></p>
            <div className="mb-3 row" style={{marginTop:30}}>
               <div className="mx-1  inner-addon right-addon col-8">
                  <i className="glyphicon fas fa-search"></i>
                  <input type="text" className="form-control mb-4" placeholder="Buscar" onKeyUp={(e) => { setStr(e.target.value) }} />
               </div>
            </div>
         </section>
         <section className='mt-3 mx-5 mb-3 row'>
            <div className='col-sm-6'>
            <p><span style={{color:"#000", fontWeight: "bold"}}>Cobrador fuente: {values.nombrecobrador}</span></p>
            </div>
            <div className='col-sm-6 d-flex justify-content-end'>
               {
                  opcionIntercambio.value === 1 && (
                     <><p>Total de contratos: {values.numerocontratos} <span style={{color:"#000", fontWeight: "bold"}}> Contratos seleccionados {counterContratosZonas} </span></p></>
                  )
               }
               {
                  opcionIntercambio.value === 2 && (
                     <><p>Total de zonas: {values.numerozonas} <span style={{color:"#000", fontWeight: "bold"}}> Zonas seleccionadas {counterContratosZonas} </span></p></>
                  )
               }
               <button id="AT_btnGuardar" className='btn mx-2 col-3' type="submit" disabled={listaContratos.length <= 0} onClick={() => {handleGuardar()}}>Guardar</button>
            </div>
         </section>
         <section className='my-2'>
            {/* <ValidatePermission
               isActive={ValidatePermissionById(4, Section., SubModule.Usuarios).Ver}
               > */}
               <RowPages  setpageRows={setpageRows} />
            {
               opcionIntercambio.value == 1 && (
                  <div className='mx-4 table-responsive'>
                  <DataTable
                     column={columnsContratos}
                     data={currentData}
                     checkbox={true}
                     handleCheck={handleCheck}
                     paginate={true}
                     handlePageClick={handlePageClick}
                     currentPage={page - 1}
                  />
                  </div>
               )
            }
            {
               opcionIntercambio.value == 2 && (
               <div className='mx-4 table-responsive'>
                  <DataTable
                     column={ columnsZonas}
                     data={currentData}
                     checkbox={true}
                     handleCheck={handleCheck}
                     paginate={true}
                     handlePageClick={handlePageClick}
                     currentPage={page - 1}
                  />
               </div>
               )
            }
         {/* </ValidatePermission> */}
         </section>
         </>
         )

      }
   </Formik>
   )
}

export default ReasignarCargaNivel2