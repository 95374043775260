import React, { useEffect, useRef, useState } from 'react';
import * as Yup from 'yup';
import { Field, Form, Formik } from 'formik'
import { toast, Toaster } from 'react-hot-toast';
import { TextField } from '../../../../Components/TextField';
import { TextAreaField } from '../../../../Components/TextAreaField';
import { FormatNumberMoney2, getDateFormat, message } from '../../../../Utils/functions'
import Modal from '../../../../Components/Modal/Modal';
import Spinner from '../../../../Components/Loadig';
import { SelectField } from '../../../../Components/SelectField';
import { useLocation, useNavigate, useParams } from 'react-router-dom';
import Siniestros from '../../../../Services/Siniestro/Siniestro';
import DataTable from '../../../../Components/datatable/DataTable';

const CierreSiniestroReparacionCard = ({ handleConcluido }) => {
    const { id } = useParams();
    const { state } = useLocation();
    let initial = {
        siniestroReparacionId: "",
        siniestroId: id,

        fechaInicioReparacion: "",
        fechaEntregaVehiculo: "",
        comentariosReparcion: "",
        clientePagoDeducible: "",
        aplicaFinanciamiento: "",
        montoFinanciar: "",
        comentariosCierre: "",

        concluido: false,
        color: "default",
        fechaInicio: "",
        fechaFin: "",
        //Stepper Update        
        paso: 4,
        porcentaje: 100,
        status: 2,
        procesoId: state.procesoId,
    }
    const [collpsableGrua, setCollpsableGrua] = useState(false);
    const { refFormik } = useRef();

    const [initialValue, setInitialValue] = useState(initial);
    const [loading, setLoading] = useState(false);
    const opcionesBool = [{ value: true, label: 'Si' }, { value: false, label: 'No' }];

    //tblAdeudos
    const [adeudosData, setAdeudosData] = useState([]);
    const [totalAdeudos, setTotalAdeudos] = useState(0);
    const [totalPagos, setTotalPagos] = useState(0);

    const columnsAdeudos = [
        { field: 'orden', headerName: 'No. Orden', width: '120' },
        { field: 'usuarioEjecutivoNombre', headerName: 'Ejecutivo', width: '120' },
        { field: 'tipoAdeudo', headerName: 'Adeudo', width: '100' },
        { field: 'fechaAdeudo', headerName: 'Fecha de adeudo', width: '300' },
        { field: 'subtotal', headerName: 'Subtotal', width: '140' },
        { field: 'iva', headerName: 'IVA', width: '100' },
        { field: 'total', headerName: 'Total', width: '100' },
        { field: 'fechaAlta', headerName: 'Fecha de registro', width: '300' },
    ];

    useEffect(() => {
        handleGetAllValuacionById();
        getPagoTramites();
    }, []);

    const getPagoTramites = async () => {
        setLoading(true);
        await Siniestros.siniestroPagoTramitesGet(id)
            .then((response) => {
                let adeudos = [];
                let totalAdeudos = 0;
                let totalPagos = 0;
                //Agregar una columna autonumèrica en data.adeudos con el nombre de 'orden'
                response.data.adeudos.map((item, index) => {
                    item.orden = index + 1;
                    item.total = item.subtotal + item.iva;
                    totalAdeudos += item.total;
                    adeudos.push(item);
                });
                //Actualizar los valores a formato moneda en data.adeudos
                response.data.adeudos.map((item) => {
                    item.subtotal = FormatNumberMoney2(item.subtotal);
                    item.iva = FormatNumberMoney2(item.iva);
                    item.total = FormatNumberMoney2(item.total);
                    item.fechaAlta = getDateFormat(item.fechaAlta, "");
                    item.fechaAdeudo = getDateFormat(item.fechaAdeudo, "");
                });

                response.data.pagos.map((item, index) => {
                    totalPagos += item.monto;
                });
                setTotalAdeudos(totalAdeudos);
                setTotalPagos(totalPagos);
                setAdeudosData(adeudos);
            })
            .catch((error) => {
                console.log(error);
            })
            .finally(() => setLoading(false));

    }
    /* CONSULTAS */
    const handleGetAllValuacionById = async () => {
        setLoading(true);
        await Siniestros.siniestroReparacionGet(id)
            .then(resp => {
                let item = resp.data;
                let newData = {
                    siniestroReparacionId: item.siniestroReparacionId ?? "",
                    siniestroId: id,

                    fechaInicioReparacion: item?.fechaInicioReparacion.split("T")[0] ?? "",
                    fechaEntregaVehiculo: item?.fechaEntregaVehiculo.split("T")[0] ?? "",
                    comentariosReparcion: item?.comentariosReparcion ?? "",
                    clientePagoDeducible: item?.clientePagoDeducible ?? "",
                    aplicaFinanciamiento: item?.aplicaFinanciamiento ?? "",
                    montoFinanciar: item?.montoFinanciar ?? "",
                    comentariosCierre: item?.comentariosCierre ?? "",

                    concluido: item?.concluido ?? false,
                    fechaInicio: item?.fechaInicio ?? "",
                    fechaFin: item?.fechaFin ?? "",
                    //Stepper Update        
                    paso: 4,
                    porcentaje: 100,
                    status: 2,
                    procesoId: state.procesoId,
                }
                setInitialValue(newData);
                handleConcluido({ concluido: item?.concluido });
            })
            .catch(err => {
                console.error(err);
            })
            .finally(() => setLoading(false));
    }
    /*  TERMINA CONSULTAS */

    const submitForm = async (values) => {
        setLoading(true);
        await Siniestros.siniestroReparacionUpdate(values)
            .then(res => {
                if (res.data.data.ban === 1) {
                    setLoading(false);
                    toast.success(message("Correcto!", res.data.data.mensaje));
                    handleGetAllValuacionById();
                } else {
                    setLoading(false);
                    toast.error(message("¡Error!", res.data.data.mensaje));
                }
            })
            .catch(err => {
                console.error(err);
                toast.error(message("¡Error, al intentar actualizar el registro de cliente desiste!"));
            })
            .finally(() => {
                setLoading(false);
            });
    }

    const validate = Yup.object().shape({
        // fechaInicioReparacion: Yup.string()
        //     .required("El campo es requerido."),
        // fechaEntregaVehiculo: Yup.string()
        //     .required("El campo es requerido."),
        // comentariosReparcion: Yup.string()
        //     .required("El campo es requerido."),

        // clientePagoDeducible: Yup.bool()
        //     .required("El campo es requerido."),
        // aplicaFinanciamiento: Yup.bool()
        //     .required("El campo es requerido."),
        // montoFinanciar: Yup.number()
        //     .when("aplicaFinanciamiento", {
        //         is: true,
        //         then: Yup.number()
        //             .required("El campo es requerido.")
        //     }),
        // comentariosCierre: Yup.string()
        //     .required("El campo es requerido."),
        ////
        fechaInicioReparacion: Yup.string()
            .when("concluido", {
                is: true,
                then: Yup.string()
                    .required("El campo es requerido.")
            }),
        fechaEntregaVehiculo: Yup.string()
            .when("concluido", {
                is: true,
                then: Yup.string()
                    .required("El campo es requerido.")
            }),
        comentariosReparcion: Yup.string()
            .when("concluido", {
                is: true,
                then: Yup.string()
                    .required("El campo es requerido.")
            }),
        clientePagoDeducible: Yup.bool()
            .when("concluido", {
                is: true,
                then: Yup.bool()
                    .required("El campo es requerido.")
            }),
        aplicaFinanciamiento: Yup.bool()
            .when("concluido", {
                is: true,
                then: Yup.bool()
                    .required("El campo es requerido.")
            }),

        montoFinanciar: Yup.string()
            .when(["concluido", "aplicaFinanciamiento"], {
                is: (concluido, aplicaFinanciamiento) => {
                    return (concluido && aplicaFinanciamiento);
                },
                then: Yup.string()
                    .required("El campo es requerido.")
            }),
        comentariosCierre: Yup.string()
            .when("concluido", {
                is: true,
                then: Yup.string()
                    .required("El campo es requerido.")
            }),

    });
    return (
        <>
            <Modal isOpen={loading} color='transparent'><Spinner message={""} /></Modal>
            <Toaster
                position="top-right"
                toastOptions={{
                    success: {
                        style: {
                            background: '#47a066',
                            color: '#FFFF',
                            borderLeft: '10px solid #2f7246'
                        },
                    },
                    error: {
                        style: {
                            background: '#d53f3f',
                            color: '#FFFF',
                            borderLeft: '10px solid #ac241a'
                        },
                    },
                }}
            />
            <div className='d-flex flex-column background-grey' style={{ height: 'auto' }}>
                <div className='row mx-md-4 my-4' >
                    <div className="col-12 px-md-3">
                        <div className="p-4 white-wrapper mb-2">
                            <div className="row mt-2 px-4" data-bs-toggle="collapse" href="#collapseValuacionAseguradora" role="button" aria-expanded="false" aria-controls="collapseValuacionAseguradora" onClick={() => setCollpsableGrua(!collpsableGrua)}>
                                <div className="col-11">
                                    <h6><span className={`semaforo semaforo-${initialValue.concluido ? "green" : "default"}`}></span> <strong>Reparación</strong></h6>
                                </div>
                                <div className="col-1 col-md-1 collapsable-icon" style={{ 'textAlign': 'right' }}>
                                    <i className={!collpsableGrua ? "icon-blue bx bx-chevron-down x2" : "icon-blue bx bx-chevron-up x2"}></i>
                                </div>
                                <div className='col-12'>
                                    <small>{initialValue.concluido ? "Concluido" : "En Proceso"}</small>
                                </div>
                            </div>
                            <div className="collapse" id="collapseValuacionAseguradora">
                                <Formik
                                    innerRef={refFormik}
                                    enableReinitialize={true}
                                    validationSchema={validate}
                                    initialValues={initialValue}
                                    onSubmit={(values) => {
                                        values.concluido ? values.porcentaje = 100 : values.porcentaje = 0 ;
                                        submitForm(values);
                                    }}
                                >
                                    {({ isValid, values, setFieldValue, errors }) => (
                                        <>
                                            <Form>
                                                <div className="separator"></div>
                                                <div className="row mt-2 px-4 mb-2">
                                                    <div className="col-3">
                                                        <small><strong>Detalle de reparación</strong></small>
                                                    </div>
                                                    <div className="col-9">
                                                        <div className="row justify-content-end">
                                                            <div className="col-auto">
                                                                {
                                                                    initialValue.concluido ?
                                                                        <fieldset className='btn btn-light secondary d-flex align-items-center mx-2'><i className="icon-dark bx bx-hide mx-1" ></i>Modo lectura</fieldset>
                                                                        : <button
                                                                            className='btn'
                                                                            disabled={initialValue?.concluido ? true : !(isValid)}
                                                                            type='submit'>
                                                                            Guardar
                                                                        </button>
                                                                }
                                                            </div>
                                                        </div>
                                                    </div>
                                                </div>
                                                <div className="row mt-2 px-4">
                                                    <div className="col-12 col-lg-4">
                                                        <TextField id="FechadictamenId" disabled={initialValue?.concluido} label="Fecha inicio de reparación" name="fechaInicioReparacion" type="date" />
                                                    </div>
                                                    <div className="col-12 col-lg-4">
                                                        <TextField id="FechadictamenId" disabled={initialValue?.concluido} label="Fecha tentativa entrega de vehículo" name="fechaEntregaVehiculo" type="date" />
                                                    </div>
                                                </div>
                                                <div className="row mt-2 px-4">
                                                    <div className="col-lg-8 col-md-12 col-sm-12">
                                                        <TextAreaField
                                                            id="comentarios"
                                                            label="Comentarios de reparación"
                                                            disabled={false}
                                                            className="form-control col-12"
                                                            rows="3"
                                                            name="comentariosReparcion"
                                                            type="text"
                                                            holder="Escribe"
                                                        />
                                                    </div>
                                                </div>
                                                <div className="row mt-2 px-4 mt-4">
                                                    <div className="col-lg-12 col-md-12 col-sm-12">
                                                        <div className="col-3">
                                                            <small><strong>Costos adicionales</strong></small>
                                                        </div>
                                                        <div className="col-lg-12 col-md-12 col-sm-12">
                                                            <DataTable
                                                                data={adeudosData}
                                                                column={columnsAdeudos}
                                                            />
                                                            <div className='d-flex align-items-center justify-content-end'>
                                                                <div>
                                                                    <strong>Total: ${FormatNumberMoney2(totalAdeudos)}</strong>
                                                                </div>
                                                            </div>
                                                        </div>
                                                        <div className="row mt-2">
                                                            <div className="col-4">
                                                                <div className="row">
                                                                    <strong>Balance</strong>
                                                                </div>
                                                                <div className="row mt-3">
                                                                    <div className="col-12">
                                                                        <table id='tbl_balance' className='col-12'>
                                                                            <thead>
                                                                                <tr>
                                                                                    <th>Concepto</th>
                                                                                    <th>Monto</th>
                                                                                </tr>
                                                                            </thead>
                                                                            <tbody>
                                                                                <tr>
                                                                                    <td>Adeudos</td>
                                                                                    <td>${FormatNumberMoney2(totalAdeudos)}</td>
                                                                                </tr>
                                                                                <tr>
                                                                                    <td>Pagos cliente</td>
                                                                                    <td>${FormatNumberMoney2(totalPagos)}</td>
                                                                                </tr>
                                                                            </tbody>
                                                                            <tfoot>
                                                                                <tr>
                                                                                    <td>Pendiente</td>
                                                                                    <td>${FormatNumberMoney2(totalAdeudos - totalPagos)}</td>
                                                                                </tr>
                                                                            </tfoot>
                                                                        </table>
                                                                    </div>
                                                                </div>
                                                            </div>
                                                        </div>
                                                    </div>
                                                </div>
                                                <div className="row mt-2 px-4 mb-2 mt-4">
                                                    <div className="col-3">
                                                        <small><strong>Cierre de proceso</strong></small>
                                                    </div>
                                                </div>
                                                <div className="row mt-2 px-4">
                                                    <div className="col-12 col-lg-3">
                                                        <SelectField id="vehiculoApto"
                                                            label="¿Cliente pago dedicible?"
                                                            name="clientePagoDeducible"
                                                            disabled={initialValue.concluido}
                                                            items={opcionesBool}
                                                            onChange={(event) => {
                                                                setFieldValue("clientePagoDeducible", event.value);
                                                            }}
                                                        />
                                                    </div>
                                                    <div className="col-12 col-lg-3">
                                                        <SelectField id="vehiculoApto"
                                                            label="¿Aplica financiamiento?"
                                                            name="aplicaFinanciamiento"
                                                            disabled={initialValue.concluido}
                                                            items={opcionesBool}
                                                            onChange={(event) => {
                                                                setFieldValue("aplicaFinanciamiento", event.value);
                                                                if (event.value) {
                                                                    setFieldValue("montoFinanciar", "");
                                                                }
                                                            }}
                                                        />
                                                    </div>
                                                    {
                                                        values.aplicaFinanciamiento &&
                                                        <div className="col-12 col-lg-3">
                                                            <TextField id="utilidad" disabled={initialValue.concluido} label="Monto a financiar" holder="Nombre de aseguradora" name="montoFinanciar" type="number" />
                                                        </div>
                                                    }

                                                </div>
                                                <div className="row mt-2 px-4">
                                                    <div className="col-12 col-lg-6">
                                                        <TextAreaField id="comentarios"
                                                            label="Comentarios de cierre"
                                                            disabled={initialValue?.concluido}
                                                            className="form-control col-12"
                                                            rows="3"
                                                            name="comentariosCierre"
                                                            type="text"
                                                            holder="Escribe"
                                                        />
                                                    </div>
                                                    <div className="row mt-2">
                                                        <div className="col-12 col-lg-6">
                                                            <Field className="form-check-input" disabled={initialValue.concluido} type="checkbox" name="concluido" />
                                                            <label className="form-check-label" style={{ 'marginLeft': '10px' }}>Cierre de siniestro</label>
                                                        </div>
                                                    </div>
                                                </div>
                                                <div className="row mt-2 d-flex justify-content-end">
                                                    <div className="col-12 col-lg-6">
                                                        <div className="row flex-column align-items-end">
                                                            <div className="col-auto">
                                                                {
                                                                    initialValue.fechaInicio !== "" &&
                                                                    <small className='text-body-tertiary'>Inicio de proceso: {initialValue.fechaInicio}</small>
                                                                }
                                                            </div>
                                                            <div className="col-auto">
                                                                {
                                                                    initialValue.concluido &&
                                                                    <small className='text-body-tertiary'>Fin de proceso: {initialValue.fechaFin}</small>
                                                                }
                                                            </div>
                                                        </div>
                                                    </div>
                                                </div>
                                            </Form>
                                        </>
                                    )
                                    }
                                </Formik>

                            </div>
                        </div>
                    </div>
                </div>
            </div>
        </>
    )
}
export default CierreSiniestroReparacionCard