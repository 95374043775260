import React from "react";
import DataTable from "../../../../Components/datatable/DataTable";
import { useNavigate } from "react-router-dom";
import { useSelector } from "react-redux";

const BoletinarDetalle = () => {
   const navigate = useNavigate();
   // const { state } = useLocation();
   const {datosConsulta} = useSelector((state) => state.SeguimientoVisitaDetalleSlice);

   const columns = [
      { field: 'nombreCobrador', headerName: 'Nombre', width: 210 },
      { field: 'fechaHora', headerName: 'Fecha y hora', width: 210 },
      { field: 'motivoBoletinado', headerName: 'Motivo del boletinado', width: 230 },
      { field: 'comentariosTooltip', headerName: 'Comentarios', width: 150 },
   ]

   const data = [
      {
         nombreCobrador: "Roberto Flores",
         fechaHora: "09-10-2023 10:30",
         motivoBoletinado: "Incumplimiento de promesa",
         comentariosTooltip: "No responde"
      },
      {
         nombreCobrador: "Fernanda Montemayor",
         fechaHora: "09-10-2023 10:30",
         motivoBoletinado: "Incumplimiento de promesa",
         comentariosTooltip: "No responde"
      },
      {
         nombreCobrador: "Roberto Flores",
         fechaHora: "09-10-2023 10:30",
         motivoBoletinado: "Incumplimiento de promesa",
         comentariosTooltip: "No responde"
      },
      {
         nombreCobrador: "Fernanda Montemayor",
         fechaHora: "09-10-2023 10:30",
         motivoBoletinado: "Incumplimiento de promesa",
         comentariosTooltip: "No responde"
      },
      {
         nombreCobrador: "Roberto Flores",
         fechaHora: "09-10-2023 10:30",
         motivoBoletinado: "Incumplimiento de promesa",
         comentariosTooltip: "No responde"
      },
      {
         nombreCobrador: "Fernanda Montemayor",
         fechaHora: "09-10-2023 10:30",
         motivoBoletinado: "Incumplimiento de promesa",
         comentariosTooltip: "No responde"
      },
   ]

   return (
      <>
         <div style={{ background: "#F5F5F5", paddingBottom: "1rem" }}>
            <header className='heder-subtitle d-flex justify-content-start align-items-center border-bottom px-2' style={{ background: "#FFF" }}>
               <div className='cursor-pointer' onClick={() => { navigate(-1) }}>
                  <i className="bx bxs-chevron-left mx-2" ></i>
                  Regresar al detalle de seguimiento de visita
               </div>
            </header>
            <div className="col col-12 d-flex">
               <div className="col col-11 px-4 py-2">
                  <div className="row p-4 wrapper-vehiculo">
                     <div className="col col-12">
                        <div className="row align-items-center justify-content-between">
                           <div className="col col-auto ">
                              <h2 className="fw-bold ">Boletinar</h2>
                           </div>
                        </div>
                        <div className="row gap-2">
                           <div className="col col-12">
                              <div className="row mt-4">
                                 <div className="col col-3">
                                    <div className="row justify-content-start">
                                       <div className="col col-12">
                                          <span><strong className="mr-2">Tipo de persona:</strong> {datosConsulta.persona.tipo}</span>
                                       </div>
                                    </div>
                                 </div>
                                 <div className="col col-3">
                                    <div className="row justify-content-start">
                                       <div className="col col-12">
                                          <span><strong className="mr-2"> Nombre:</strong> {datosConsulta.persona.label}</span>
                                       </div>
                                    </div>
                                 </div>
                              </div>
                           </div>
                        </div>
                     </div>
                  </div>
               </div>
            </div>
            <div className="col col-11 mx-3 my-2  wrapper-vehiculo " style={{ background: "#FFF" }}>
               <div className="px-4">
                  <p className="pt-2" >Detalle de Boletinado</p>
                  <DataTable
                     column={columns}
                     data={data}
                  />
               </div>
            </div>
         </div>
      </>
   );
}

export default BoletinarDetalle;