import React from 'react'
import DataTable from '../../../../Components/datatable/DataTable';
import RowPages from '../../../../Components/RowPages';
import Modal from '../../../../Components/Modal/Modal';
import Spinner from '../../../../Components/Loadig';
import useRutasCobranza from '../../../../Hooks/SeguimientoVisita/useRutasCobranza';
import SubMenuSeguimientoVisita from '../SubMenuSeguimientoVisita';
import CrearRutaModal from './CrearRutaModal';

const RutasCobranza = () => {
   let {
      columns,
      columnsDetail,
      currentData,
      pageCount,
      setStr,
      loading,
      handlePageClick,
      handleDetails,
      handleCrearRuta,
      page,
      setpageRows,
      openModalRuta,
      setOpenModalRuta,
      setFilterFechaInicio,
      setFilterFechaFin
   } = useRutasCobranza();

   return (
      <>
         <Modal isOpen={loading} color='transparent'><Spinner message={""} /></Modal>
         <SubMenuSeguimientoVisita />
         <section className='mx-5 py-4'>
         <div className='d-flex justify-content-between'>
            <div>
               <span className='fs-5'><strong>Rutas de cobranza</strong></span>
               <p>A continuación encontráras todas las rutas creadas para la cobranza de cartera</p>
            </div>
            <div>
               {/* <ValidatePermission isActive={ValidatePermissionById(4, Section.UsuariosRegistrados, SubModule.Usuarios).Agregar} > */}
               <button className='btn px-4' onClick={handleCrearRuta}>Crear Ruta</button>
               {/* </ValidatePermission> */}
            </div>
         </div>
         </section>
         <section className='mx-4 row'>
            <div className='col-3 mt-4'>
               <div className="col-12 mx-1  inner-addon right-addon">
                  <i className="glyphicon fas fa-search"></i>
                  <input type="text" className="form-control mb-4" placeholder="Buscar" onKeyUp={(e) => { setStr(e.target.value) }} />
               </div>
            </div>
            <div className='col-2'>
               Fecha de inicio
               <input type="date" className="form-control mb-4" placeholder="Fecha inicio" onChange={(e) => { setFilterFechaInicio(e.target.value) }} />
            </div>
            <div className='col-2'>
               Fecha de fin
               <input type="date" className="form-control mb-4" placeholder="Fecha fin" onChange={(e) => { setFilterFechaFin(e.target.value) }} />
            </div>
         </section>
         {/* <ValidatePermission
         isActive={ValidatePermissionById(4, Section.UsuariosRegistrados, SubModule.Usuarios).Ver}
         > */}
         <RowPages
            setpageRows={setpageRows}
         />
         <div className='mx-4 table-responsive'>
            <DataTable
               column={columns}
               data={currentData}
               pageCount={pageCount}
               detailable={true}
               masterDetail={true}
               columnDetail={columnsDetail}
               //detailable={ValidatePermissionById(4, Section.UsuariosRegistrados, SubModule.Usuarios).Actualizar}
               handleDetail={handleDetails}
               paginate={true}
               handlePageClick={handlePageClick}
               currentPage={page - 1}
            />
         </div>
         {
            <CrearRutaModal
               isOpen={openModalRuta}
               setIsOpen={setOpenModalRuta}
               tipoUsuario={1}
            />
         }
      </>
   )
}

export default RutasCobranza