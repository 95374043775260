import React, { useEffect, useState } from "react";
import { useNavigate } from "react-router-dom";
import { Formik, Form } from "formik";
import * as Yup from 'yup';
import { SelectField } from "../../../../Components/SelectField";
import { TextAreaField } from "../../../../Components/TextAreaField";
import { TextField } from "../../../../Components/TextField";
import CobranzaService from "../../../../Services/Cobranza/Cobranza";
import Modal from "../../../../Components/Modal/Modal";
import Spinner from "../../../../Components/Loadig";
import { message } from "../../../../Utils/functions";
import toast from "react-hot-toast";
import { useSelector } from "react-redux";

const ContactoAgregar = () => {
   const navigate = useNavigate();
   // const { state } = useLocation();
   const {datosConsulta} = useSelector((state) => state.SeguimientoVisitaDetalleSlice);
   const [fechaInicio, setFechaInicio] = useState("21-09-2023 09:00")
   const [fechaFin, setFechaFin] = useState("21-09-2023 09:00")
   const [dataTipoContacto, setDataTipoContacto] = useState([]);
   const [dataSubTipoContacto, setDataSubTipoContacto] = useState([]);
   const [loading, setLoading] = useState(false);

   let initial = {
      tipoContacto: "",
      subtipoContacto: ""
   }

   const HandleGetTipoContacto = async () => {
      await CobranzaService.getAllTipoContacto()
         .then(res => {
            let tipos = []
            res.data.responseData?.map(item => {
               if (item.activo) {
                     tipos.push({
                        value: item.id,
                        label: item.nombre
                     });
               }
            });
            setDataTipoContacto(tipos);
         })
   }

   const HandleGetSubtiposContacto = async () => {
      await CobranzaService.getAllSubTipoContacto()
         .then(res => {
            let subtipo = []
            res.data?.map(item => {
               if (item.activo) {
                     subtipo.push({
                        value: item.id,
                        label: item.nombre
                     });
               }
            });
            setDataSubTipoContacto(subtipo);
         })
   }

   const handleAgregarContacto = async(values) => {
      setLoading(true);

      let today = new Date();
      let day = `${today.getDate()}`.length === 1 ? `0${today.getDate()}` :  today.getDate()
      let month = `${today.getMonth() + 1}`.length === 1 ? `0${today.getMonth() + 1}` :  `${today.getMonth() + 1}`

   await CobranzaService.createNuevoDatoContacto({
         sol_id: parseInt(datosConsulta.sol_id),
         contrato: datosConsulta.contrato,
         vin: datosConsulta.vin,
         idpersona_acendes: parseInt(datosConsulta.persona.value),
         tipo_persona: datosConsulta.persona.tipo, 
         curp: datosConsulta.persona.curp, 
         tipo_contacto: values.tipoContacto, 
         subtipo_contacto: values.subtipoContacto, 
         dato: values.valor, 
         comentario: values.comentarios,
         fecha_operacion: `${today.getFullYear()}-${month}-${day} ${today.getHours()}:${today.getMinutes()}`
      })
         .then(res => {
            toast.success(message("¡Operación exitosa!", res.data.mensaje));
            setLoading(false)
            navigate(-1)
         }).catch(e => {
            setLoading(false)
            if (e.response !== undefined)
               toast.error(message("¡Error!", e.response.data.message));
            else
               toast.error(message("¡Error!", e.message));
         })
   }

   const [initialValue, setInitialValue] = useState(initial); 
   const validate = Yup.object({
      tipoContacto: Yup.string()
         .required("El campo es requerido."),
      subtipoContacto: Yup.string()
         .required("El campo es requerido."),
   });

   useEffect(()=>{
      HandleGetTipoContacto();
      HandleGetSubtiposContacto();
   },[])

   return (
      <>
         <div style={{ background: "#F5F5F5", paddingBottom: "1rem" }}>
            <header className='heder-subtitle d-flex justify-content-start align-items-center border-bottom px-2' style={{ background: "#FFF" }}>
               <div className='cursor-pointer' onClick={() => { navigate(-1) }}>
                  <i className="bx bxs-chevron-left mx-2" ></i>
                  Regresar al detalle de seguimiento de visita
               </div>
            </header>
            <div className="col col-12 d-flex">
               <div className="col col-11 px-4 py-2">
                  <div className="row p-4 wrapper-vehiculo">
                     <div className="col col-12">
                        <div className="row align-items-center justify-content-between">
                           <div className="col col-auto ">
                              <h2 className="fw-bold ">Agregar contacto</h2>
                           </div>
                        </div>
                        <div className="row gap-2">
                           <div className="col col-12">
                              <div className="row mt-4">
                                 <div className="col col-3">
                                    <div className="row justify-content-start">
                                       <div className="col col-12">
                                          <span><strong className="mr-2">Tipo de persona:</strong> {datosConsulta.persona.tipo}</span>
                                       </div>
                                    </div>
                                 </div>
                                 <div className="col col-3">
                                    <div className="row justify-content-start">
                                       <div className="col col-12">
                                          <span><strong className="mr-2"> Nombre:</strong> {datosConsulta.persona.label}</span>
                                       </div>
                                    </div>
                                 </div>
                              </div>
                           </div>
                        </div>
                     </div>
                  </div>
               </div>
            </div>
            <div className="col-11 mx-3 wrapper-vehiculo " style={{ background: "#FFF", height: "auto" }}>
               <Formik
                  enableReinitialize={true}
                  validationSchema={validate}
                  initialValues={initialValue}
                  onSubmit={(values) => {
                     handleAgregarContacto(values);
               }}>
               {({ isValid, values, setFieldValue, errors }) => (
                  <>
                     <Form>
                        <div className="px-4">
                           <div className="row mt-2 px-4">
                              <div className="col-2 mt-3">
                                 <p><span style={{fontSize: '20px', fontWeight: 'bold'}}>Detalle de contacto</span></p>
                              </div>
                              <div className="col-7 mt-3">
                                 <div className="row justify-content-end">
                                    <div className="col-auto">
                                       <button className='btn col-12' type='submit' disabled={!(isValid)}>Continuar</button>
                                    </div>
                                 </div>
                              </div>
                           </div>
                           <div className="mb-3 row px-4" style={{marginTop:30}}>
                              <div className="col-2">
                                 <SelectField  label="Tipo contacto" name="tipoContacto" items={dataTipoContacto} onChange={(e) => {
                                    setFieldValue("tipoContacto", e.label !== 0 ? e.label : "");
                                 }} />
                              </div>
                              <div className="col-2">
                                 <SelectField  label="Subtipo" name="subtipoContacto" items={dataSubTipoContacto} onChange={(e) => {
                                    setFieldValue("subtipoContacto", e.label !== 0 ? e.label : "");
                                 }} />
                              </div>
                              <div className="col-2">
                                 <TextField label="Valor" min={0} name="valor" value={values.telefono} type="text" holder="Escribe"
                                    onChange={(event) => {setFieldValue("valor", event.target.value);}} />
                              </div>
                           </div>
                           <div className="mb-3 row px-4" style={{marginTop:30}}>
                              <div className="col-6">
                                 <TextAreaField label="Comentarios:" className="col-8 mt-2" rows="4" name="comentarios" type="text" holder="Escribe" />
                              </div>
                           </div>
                           <div className="row mb-3 px-4">
                              <div className="col-3 mb-3">
                                 <span className="text-sm">Inicio de proceso: {fechaInicio} </span>
                              </div>
                              <div className="col-3">
                                 <span className="text-sm">Fin de proceso: {fechaFin} </span>
                              </div>
                           </div>
                        </div>
                     </Form>
                  </>)}
               </Formik>
            </div>
         </div>
         <Modal isOpen={loading} color='transparent'><Spinner message={""} /></Modal>
      </>
   );
}

export default ContactoAgregar;