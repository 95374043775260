import React, { useRef, useState,useEffect } from 'react';
import { Formik, Field } from 'formik';
import toast, { Toaster } from 'react-hot-toast'
import SolicitudesHook from '../../../Hooks/AutorizaciondeSolicitudes/solicitudesHooks';
import RowPages from '../../../Components/RowPages';
import DataTable from "../../../Components/datatable/DataTable";
import { Section, SubModule } from '../../../Auth/Authorization';
import { ValidatePermissionById } from '../../../Auth/ValidatePermission';
import { SelectField2 } from '../../../Components/SelectField2';
import SolicitudVisitaModal from '../../../Components/Modal/SolicitudVisitaModal';
import CobranzaService from '../../../Services/Cobranza/Cobranza'; 


const AutorizacionSolicitudes = () => {
  const refFormik = useRef();
  const [selectedData, setSelectedData] = useState(null);
  const [isOpenModalSolicitudVisita, setisOpenModalSolicitudVisita] = useState(false);
  const [cobradores, setCobradores] = useState([]);
  const [cobradorSeleccionado, setCobradorSeleccionado] = useState(''); // Estado para el cobrador seleccionado
  const [filteredData, setFilteredData] = useState([]); // Datos filtrados

  // Custom hook para manejar la lógica
  const {
    setName,
    setpageRows,
    columns,
    currentData = [], // Inicializa currentData como un array vacío para evitar el error
    handleDetails,
    pageCount,
    currentPage,
  } = SolicitudesHook();

  // Traer cobradores desde el servicio
  useEffect(() => {
    const fetchCobradores = async () => {
      try {
        const res = await CobranzaService.GetAllContactoSeguimientoLog();
        const cobradoresData = res.data.responseData;

        // Eliminar duplicados por nombre
        const cobradoresUnicos = cobradoresData.filter(
          (cobrador, index, self) => 
            index === self.findIndex((t) => t.cobrador === cobrador.cobrador)
        );

        setCobradores(cobradoresUnicos);
      } catch (error) {
        toast.error('Error al obtener los cobradores');
      }
    };

    fetchCobradores();
  }, []);

  // Filtrar los datos cuando se selecciona un cobrador
  useEffect(() => {
    if (cobradorSeleccionado) {
      // Filtrar por cobrador seleccionado
      const dataFiltrada = currentData.filter(item => item.cobrador === cobradorSeleccionado);
      setFilteredData(dataFiltrada);
    } else {
      // Mostrar todos los datos si no hay cobrador seleccionado
      setFilteredData(currentData);
    }
  }, [cobradorSeleccionado, currentData]);

  const handleDetailClick = (item) => {
    setSelectedData(item);
    setisOpenModalSolicitudVisita(true); // Mostrar el modal
  };

  return (
    <>
      <Toaster
        position="top-right"
        toastOptions={{
          success: {
            style: {
              background: '#47a066',
              color: '#FFFF',
              borderLeft: '10px solid #2f7246'
            },
          },
          error: {
            style: {
              background: '#d53f3f',
              color: '#FFFF',
              borderLeft: '10px solid #ac241a'
            },
          },
        }}
      />

      {isOpenModalSolicitudVisita && (
        <SolicitudVisitaModal
          data={selectedData}  // Aquí debes pasar el objeto `selectedData`
          setIsOpen={setisOpenModalSolicitudVisita}
          isOpen={isOpenModalSolicitudVisita}
          toast={toast}
        />
      )}

      <Formik
        innerRef={refFormik}
        initialValues={{
          buscar: "",
          cobradorSeleccionado: "",
          page: 1,
          rows: 10
        }}
      >
        {({ setFieldValue }) => (
          <section>
            <div className="pb-4">
              <section className="mx-4 my-4 d-flex flex-column">
                <div className="col-12 mt-4">
                  <div className="row">
                    <div className="col-auto">
                      <strong className="mt-2">Solicitudes de visita</strong>
                    </div>
                  </div>
                </div>

                <div className="col-12 mb-4">
                  <div className="row">
                    <div className="col-lg-3 col-md-5 col-sm-5 mt-4 mx-4 inner-addon right-addon">
                      <i className="glyphicon fas fa-search"></i>
                      <Field
                        type="text"
                        name="buscar"
                        className="form-control mb-4"
                        placeholder="Buscar"
                        onKeyUp={(e) => setName(e.target.value)}
                      />
                    </div>
                    <div className="col-12 col-md-2 mt-4 me-1">
                      <SelectField2
                        id="cobradorSeleccionado"
                        name="cobradorSeleccionado"
                        items={cobradores.map(c => ({ label: c.cobrador, value: c.cobrador }))}
                        onChange={(option) => {
                          setFieldValue("cobradorSeleccionado", option.value);
                          setCobradorSeleccionado(option.value); // Actualiza el estado del cobrador seleccionado
                        }}
                      />
                    </div>
                  </div>
                </div>

                <div className="mx-4 table-responsive">
                  {/* Evitar renderizar la tabla si no hay datos */}
                  {filteredData.length > 0 ? (
                    <DataTable
                      column={columns}
                      data={filteredData} // Usar los datos filtrados
                      detailable={ValidatePermissionById(4, Section.Cartera, SubModule.ProgramacionDeJobs).Historial}
                      handleDetail={handleDetailClick}
                      paginate
                      pageCount={pageCount}
                      handlePageClick={handleDetails}
                      currentPage={currentPage - 1}
                      commentColumn={true}
                    />
                  ) : (
                    <p>No hay datos disponibles.</p>
                  )}
                  <RowPages setpageRows={setpageRows} />
                </div>
              </section>
            </div>
          </section>
        )}
      </Formik>
    </>
  );
};

export default AutorizacionSolicitudes;