import { useEffect, useRef, useState } from 'react'
import { useNavigate } from 'react-router';
import CobranzaService from '../../Services/Cobranza/Cobranza'
import { useDispatch } from 'react-redux';
import { setContrato, setItemGeneral } from '../../Redux/Slice/Cobranza/SeguimientoVisita/SeguimientoVisitaDetalleSlice';

const useSeguimientoVisita = () => {
  const navigate = useNavigate();
  const [name, setName] = useState("")
  const [pageRows, setpageRows] = useState(10);
  const [data, setData] = useState([{}]);
  const dispatch = useDispatch();

  const userName = sessionStorage.getItem('usuario')
  const userId = sessionStorage.getItem('userId').trim()

  const [percentage, setPercentage] = useState(0)

  const columns = [
    { field: 'contrato_id', headerName: 'Contrato' },
    { field: 'tipo_persona', headerName: 'Tipo de persona' },
    { field: 'nombre', headerName: 'Nombre' },
    { field: 'monto_adeudo', headerName: 'Monto Vencido' },
    { field: 'fecha', headerName: 'Fecha' },
    { field: 'colonia', headerName: 'Dirección' },
    { field: 'tipo_visita', headerName: 'Tipo de visita' },
    { field: 'contrato_status', headerName: 'Estatus' },
  ];

  const [pageCount, setPageCount] = useState(1);
  const [currentPage, setCurrentPage] = useState(1);
  const [currentData, setCurrentData] = useState(data);

  const [active7, setActive7] = useState(false)
  const [active15, setActive15] = useState(false)
  const [firstProgressVigente, setFirstProgressVigente] = useState(0)
  const [firstProgressCumplidas, setFirstProgressCumplidas] = useState(0)
  const [secondProgressVigentes, setSecondProgressVigentes] = useState(0)
  const [secondProgressCumplidas, setSecondProgressCumplidas] = useState(0)

  const [totalContratos, setTotalContratos] = useState('0')
  const [totalMontoVencido, setTotalMontoVencido] = useState('0')
  const [totalMontoCobrado, setTotalMontoCobrado] = useState('0')

  const [allPromesas, setAllPromesas] = useState()
  const [loading, setLoading] = useState(false);
  const [modalMessage, setModalMessage] = useState({ isOpen: false, type: 1, title: '', message: '', data: [], authorizationType: 2, Origin: "Listado" });

  const [isOpen, setIsOpen] = useState(false)
  const [isVisible, SetIsVisible] = useState(true)
  const dropdownRef = useRef(null)

  const toggleDropdown = () => {
    setIsOpen(!isOpen)
  }

  const toggleVisible = (visible) => {
    SetIsVisible(visible)
    setIsOpen(false)
  }

  // Volver estas funciones una sola, que active y que guarde los datos secundarios en variable seundaria a enviar.
  const toggleActive7 = () => {

    setActive15(false)
    const vigente = analizarDatos(allPromesas, 7, "Vigente")
    const cumplidas = analizarDatos(allPromesas, 7, "Cumplidas")

    setSecondProgressVigentes(vigente)
    setSecondProgressCumplidas(cumplidas)
    setActive7(!active7)
  }

  const toggleActive15 = () => {
    setActive7(false)
    const vigente = analizarDatos(allPromesas, 15, "Vigente")
    const cumplidas = analizarDatos(allPromesas, 15, "Cumplidas")

    setSecondProgressVigentes(vigente)
    setSecondProgressCumplidas(cumplidas)
    setActive15(!active15)
  }

  function analizarDatos(datos, dias, estado) {

    // Función para obtener la fecha límite
    const obtenerFechaLimite = () => {
      const hoy = new Date();
      const fechaLimite = new Date(hoy);
      fechaLimite.setDate(hoy.getDate() - dias); // Resta los días especificados
      return fechaLimite;
    };

    const fechaLimite = obtenerFechaLimite();

    // Filtrar datos según la fecha
    const datosFiltrados = datos.filter(obj => new Date(obj.fecha_compromiso) <= fechaLimite);
    const total = datosFiltrados.length;

    // Suma total de dinero
    const activos = datosFiltrados.filter(obj => obj.promesa_status_nombre === estado);
    const sumaTotalDinero = activos.length > 0 ?
      activos.reduce((total, obj) => total + obj.monto_recuperado, 0) : 0

    // Número de IDs diferentes
    const idsDiferentes = activos.length > 0 ? new Set(activos.map(obj => obj.contrato_id)).size : 0

    // Número de estado activo y cálculo del porcentaje
    const porcentajeActivos = total > 0 ? (activos.length / total) * 100 : 0;

    return {
      sumaTotalDinero,
      idsDiferentes,
      porcentajeActivos: porcentajeActivos.toFixed(2)
    };
  }

  const closeDropdown = (e) => {
    if (dropdownRef.current && !dropdownRef.current.contains(e.target)) {
      setIsOpen(false)
    }
  }

  useEffect(() => {
    document.addEventListener('mousedown', closeDropdown)
    return () => document.removeEventListener('mousedown', closeDropdown)
  }, [])

  useEffect(() => {
    const filteredData = data.filter(item => {
      const nameMatches = name === '' || (item.contrato && item.contrato.toLowerCase().includes(name.toLowerCase()));

      return (nameMatches)
    })

    const indexOfLastItem = currentPage * pageRows
    const indexOfFirstItem = indexOfLastItem - pageRows
    const newData = filteredData.slice(indexOfFirstItem, indexOfLastItem)
    setCurrentData(newData)

    const newTotalPages = Math.ceil(filteredData.length / pageRows)
    setPageCount(newTotalPages)
    // if (currentPage > newTotalPages) {
    //   setCurrentPage(newTotalPages)
    // } else if (currentPage <= 0) {
    //   setCurrentPage(1)
    // }

  }, [currentPage, name, pageRows, data])

  const handleDetails = async (contrato) => {
    setLoading(true);
    await CobranzaService.GetAllRutasCobranza()
      .then(res => {
        res.data.responseData?.map(user => {
          if (user.userid.toLowerCase() === userId.toLowerCase()) {
            user.items?.map(item => {
              if (item?.contrato !== null && item.contrato.contrato_id.toLowerCase() === contrato.contrato_id.toLowerCase()
                && item?.contrato?.cartera_vencida === 1 && item?.contrato?.nivel_cobranza === "2") {
                dispatch(setContrato(contrato));
                dispatch(setItemGeneral(item));
                navigate(`/cobranza/seguimiento-visita/detalle`);
              }
            })
          }
        })
        setLoading(false);
      })
      .catch(e => {
        setLoading(false);
      })
  }

  const handlePageClick = (event) => {
    setCurrentPage(event.selected + 1)
  };

  const dataTipoVisita = [
    { value: 0, label: "Seguimiento" },
    { value: 1, label: "Recuperación de Unidad" },
    { value: 2, label: "Entrega citatorio" }
  ]

  const dataEstatus = [
    { value: 0, label: "Pendiente" },
    { value: 1, label: "En proceso" }
  ]

  useEffect(() => {
    handleGetSeguimientoContrato()
    handleGetPromesasdePago()
  }, [])

  const handleGetSeguimientoContrato = async () => {
    setLoading(true);
    const data = {
      "verb": null,
      "id": null,
      "field": null,
      "value": null
    }
    await CobranzaService.getAllEjecutivosWithFullDetail(data)
      .then(res => {
        const userContracts = res.data.responseData.find(user => user.userid.toLowerCase() === userId.toLowerCase() && user.nivel_cobrador === "2");

        // Validar y filtrar si el listado de contratos no es null o vacío
        if (Array.isArray(userContracts?.contratos) && userContracts?.contratos?.length > 0) {
          userContracts.contratos = userContracts.contratos.filter(item => item.cartera_vencida === 1 && item.nivel_cobranza === "2");
        }

        let datosFormateados = userContracts?.contratos?.map((item) => {
          const nombreCompleto = `${item.nombre} ${item.apellido_paterno} ${item.apellido_materno}`;
          const direccionCompleta = `${item.calle} #${item.no_ext} Col. ${item.colonia}`;

          let colorFondo
          let colorTexto
          //contrato_status

          switch (item.estatus) {
            case "Cumplida":
              colorFondo = "#D8F2CD"
              colorTexto = "#47A066"
              break;
            case "Rota":
              colorFondo = "#F5CECE"
              colorTexto = "#CE2222"
              break;
            case "Vigente":
              colorFondo = "#FAE7BC"
              colorTexto = "#B46B16"
              break;
            default:
              break;
          }

          //(gris, rojo, amarillo y verde de acuerdo al valor)

          return {
            ...item,
            nombre: nombreCompleto,
            colonia: direccionCompleta,
            contrato_status: item.estatus,
            colorFondo: colorFondo,
            colorTexto: colorTexto,
          }
        })

        let contratosFiltrador = userContracts?.contratos?.filter(item => item.estatus === 'active')
        let length = userContracts?.contratos?.length
        let percentage = contratosFiltrador?.length > 0 ? userContracts?.contratos?.length / contratosFiltrador?.length : 0

        setTotalContratos(length)
        setPercentage(percentage)
        setData(datosFormateados)
        setLoading(false);

      }).catch(e => {
        setLoading(false);
        if (e.response !== undefined)
          setModalMessage({ ...modalMessage, isOpen: true, type: 1, message: e.response.data.message })
        else
          setModalMessage({ ...modalMessage, isOpen: true, type: 1, message: e.message })
      })
  }

  const handleGetPromesasdePago = async () => {
    setLoading(true);

    await CobranzaService.getAllPromesasDePago()
      .then(res => {
        const vigente = analizarDatos(res.data, 0, "Vigente")
        const cumplidas = analizarDatos(res.data, 0, "Cumplidas")

        const suma = res.data.reduce((total, obj) => {
          return {
            montoVencido: total.montoVencido + obj.monto_vencido,
            montoCobrado: total.montoCobrado + obj.monto_recuperado
          };
        }, { montoVencido: 0, montoCobrado: 0 }); // Inicializamos el acumulador

        setAllPromesas(res.data)
        setTotalMontoCobrado(suma.montoCobrado)
        setTotalMontoVencido(suma.montoVencido)
        setFirstProgressVigente(vigente)
        setFirstProgressCumplidas(cumplidas)

        setLoading(false);

      }).catch(e => {
        setLoading(false);
      })
  }

  return {
    setName,
    setpageRows,
    columns,
    currentData,
    handleDetails,
    pageCount,
    currentPage,
    dataTipoVisita,
    dataEstatus,
    totalContratos,
    userName,
    dropdownRef,
    toggleDropdown,
    toggleVisible,
    percentage,
    active7,
    toggleActive7,
    active15,
    toggleActive15,
    isOpen,
    isVisible,
    loading,
    firstProgressVigente,
    firstProgressCumplidas,
    secondProgressVigentes,
    secondProgressCumplidas,
    totalMontoCobrado,
    totalMontoVencido,
    handlePageClick
  }
}
export default useSeguimientoVisita