import React, { useEffect, useState } from 'react'
import { useDispatch, useSelector } from 'react-redux'
import { NavLink, useNavigate } from 'react-router-dom'
import { tabActiveInstalaciones } from '../../../Redux/Slice/navigateSliceInstalacion';
import { ValidatePermission, ValidatePermissionById } from '../../../Auth/ValidatePermission';
import { Section } from '../../../Auth/Authorization';

const SubMenuSeguimientoVisita = () => {
  const { value } = useSelector((state) => state.tabNavigateInstalaciones);
  const dispatch = useDispatch();

  const navigate = useNavigate();

  useEffect(() => {
    handleVigate(value);
  }, [value])


  const handleSetStateTab = (value) => {
    dispatch(tabActiveInstalaciones(value));
  }

  //HARDCODE: actualizar seccion al validar permisos
  const handleVigate = (value) => {
    if (ValidatePermissionById(3, Section.CarteraGeneral) && value === 1) {
      navigate(`/cobranza/seguimiento-visita/cartera-general`);
    }
    if (ValidatePermissionById(3, Section.RutasCobranza) && value === 2) {
      navigate(`/cobranza/seguimiento-visita/rutas-cobranza`);
    }
    if (ValidatePermissionById(3, Section.SeguimientoVisita) && value === 3) {
      navigate(`/cobranza/seguimiento-visita/seguimiento-visita`);
    }
    if (ValidatePermissionById(3, Section.HistorialVisitas) && value === 4) {
      navigate(`/cobranza/seguimiento-visita/historial-visitas`);
    }

  }

  //HARDCODE: actualizar seccion al validar permisos
  return (
    <>
      <ul className="nav nav-tabs" id="myTab" role="tablist">
        <ValidatePermission isActive={ValidatePermissionById(3, Section.CarteraGeneral)}>
          <li className="nav-item" role="presentation">
            <button className={`nav-link ${value == 1 && "tab-active"}`} value={1} type="button" role="tab" onClick={(e) => { handleSetStateTab(e.target.value) }}>Cartera general</button>
          </li>
        </ValidatePermission>
        <ValidatePermission isActive={ValidatePermissionById(3, Section.RutasCobranza)}>
          <li className="nav-item" role="presentation">
            <button className={`nav-link ${value === 2 && "tab-active"}`} value={2} type="button" role="tab" onClick={(e) => { handleSetStateTab(e.target.value) }}>Rutas de cobranza</button>
          </li>
        </ValidatePermission>
        <ValidatePermission isActive={ValidatePermissionById(3, Section.SeguimientoVisita)}>
          <li className="nav-item" role="presentation">
            <button className={`nav-link ${value === 3 && "tab-active"}`} value={3} type="button" role="tab" onClick={(e) => { handleSetStateTab(e.target.value) }}>Seguimiento de visita</button>
          </li>
        </ValidatePermission>
        <ValidatePermission isActive={ValidatePermissionById(3, Section.HistorialVisitas)}>
          <li className="nav-item" role="presentation">
            <button className={`nav-link ${value === 4 && "tab-active"}`} value={4} type="button" role="tab" onClick={(e) => { handleSetStateTab(e.target.value) }}>Historial de visitas</button>
          </li>
        </ValidatePermission>
      </ul>
    </>
  )
}

export default SubMenuSeguimientoVisita