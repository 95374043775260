import React from 'react';
import { useDispatch, useSelector } from 'react-redux';
import { NavLink, useNavigate } from 'react-router-dom'
import { chekItem } from '../Redux/Slice/quickActionsSlice';
import { handleMenu } from '../Redux/Slice/menuSlice';
import { AuthExpireSesion, ChangevalueMenu } from '../Utils/functions';
import AuthLogin from '../Utils/AuthLogin';
import { SubModule } from '../Auth/Authorization';
import { ValidatePermission, ValidatePermissionById } from '../Auth/ValidatePermission';

const auth = new AuthLogin();
const CobranzaSidebar = () => {
    const { visible } = useSelector((state) => state.changeMenu);
    const navigate = useNavigate();
    const dispatch = useDispatch();

    if (AuthExpireSesion()) {
        auth.logout();
        return navigate("/")
    }

    const handleChangeValue = () => {
        ChangevalueMenu(visible);
        dispatch(handleMenu(!visible));
    }

    return (
        <>
            <aside id="sidebar" className={`sidebar ${visible ? "toggle-sidebar-aside" : ""}`}>
                <div id="lbl_Inventario_Sidebar" className={`title_sidebar fs-2 mb-2 d-flex ${visible ? "justify-content-center" : "justify-content-between"}`}>
                    <div>
                        {visible ? "" : "Cobranza"}
                    </div>
                    <i className={`icon_burger px-4 ${visible ? "ri-menu-unfold-fill" : "ri-menu-fold-fill"}`} onClick={(e) => { handleChangeValue(); }}></i>
                </div>
                <ul className="sidebar-nav" id="sidebar-nav">
                    <li className="nav-heading"><strong>{visible ? "GDC" : "Gestión de cobranza"}</strong></li>
                    <li className="nav-item" onClick={() => { dispatch(chekItem({ check: false, item: null })) }}>
                        <NavLink to="/cobranza/seguimientogestoria" className="nav-link collapsed text-dark">
                            <i className="icon-dark bx bx-credit-card-front"></i>
                            <span className={`${visible ? "d-none" : "d-block"}`}>Seguimiento a gestoría</span>
                        </NavLink >
                    </li>
                    <li className="nav-item" onClick={() => { dispatch(chekItem({ check: false, item: null })) }}>
                        <NavLink to="/cobranza/accesorios" className="nav-link collapsed text-dark">
                            <i className="icon-dark bx bx-credit-card-front"></i>
                            <span className={`${visible ? "d-none" : "d-block"}`}>Financiamiento de accesorios</span>
                        </NavLink >
                    </li>
                    <li className="nav-item" onClick={() => { dispatch(chekItem({ check: false, item: null })) }}>
                        <NavLink to="/cobranza/seguimiento-telefonico" className="nav-link collapsed text-dark">
                            <i className=' icon-dark  bx bx-phone' ></i>
                            <span className={`${visible ? "d-none" : "d-block"}`}>Seguimiento telefónico</span>
                        </NavLink >
                    </li>
                    <li className="nav-item" onClick={() => { dispatch(chekItem({ check: false, item: null })) }}>
                        <NavLink to="/cobranza/autorizacion-solicitudes" className="nav-link collapsed text-dark">
                            <i className=' icon-dark ri-survey-line' ></i>
                            <span className={`${visible ? "d-none" : "d-block"}`}>Autorización de solicitudes</span>
                        </NavLink >
                    </li>
                    <li className="nav-item" onClick={() => { dispatch(chekItem({ check: false, item: null })) }}>
                        <NavLink to="/cobranza/seguimiento-boletinado" className="nav-link collapsed text-dark">
                            <i className=' icon-dark ri-account-box-line' ></i>
                            <span className={`${visible ? "d-none" : "d-block"}`}>Seguimiento Boletinado</span>
                        </NavLink >
                    </li>
                    <li className="nav-item" onClick={() => { dispatch(chekItem({ check: false, item: null })) }}>
                        <NavLink to="/cobranza/seguimiento-visita" className="nav-link collapsed text-dark">
                            <i className=' icon-dark ri-map-pin-line' ></i>
                            <span className={`${visible ? "d-none" : "d-block"}`}>Seguimiento de visita</span>
                        </NavLink >
                    </li>
                    <ValidatePermission
                        isActive={ValidatePermissionById(2, SubModule.UnidadesRecuperadas)}
                    >
                        <li className="nav-item" onClick={() => { dispatch(chekItem({ check: false, item: null })) }}>
                            <NavLink to="/cobranza/unidades-recuperadas" className="nav-link collapsed text-dark">
                                <i className=' icon-dark ri-map-pin-line' ></i>
                                <span className={`${visible ? "d-none" : "d-block"}`}>Unidades Recuperadas</span>
                            </NavLink >
                        </li>
                    </ValidatePermission>
                    <li className="nav-item" onClick={() => { dispatch(chekItem({ check: false, item: null })) }}>
                        <NavLink to="/cobranza/generacion-expedientes" className="nav-link collapsed text-dark">
                            <i className=' icon-dark  bx bx-phone' ></i>
                            <span className={`${visible ? "d-none" : "d-block"}`}>Generacion de expedientes</span>
                        </NavLink >
                    </li>
                    <li className="nav-heading"><strong>Autorización administrativa</strong></li>
                    <li className="nav-item" onClick={() => { dispatch(chekItem({ check: false, item: null })) }}>
                        <NavLink to="/cobranza/cita-entrega-unidad" className="nav-link collapsed text-dark">
                            <i className="icon-dark ri-file-list-2-line"></i>
                            <span className={`${visible ? "d-none" : "d-block"}`}>Cita de entrega de unidad</span>
                        </NavLink >
                    </li>
                    <li className="nav-heading"><strong>Configuración de Cobranza</strong></li>
                    <li className="nav-item" onClick={() => { dispatch(chekItem({ check: false, item: null })) }}>
                        <NavLink to="/cobranza/areascobradores" className="nav-link collapsed text-dark">
                            <i className="icon-dark ri-map-pin-2-fill"></i>
                            <span>Zonas de cobranza</span>
                        </NavLink >
                    </li>
                    <li className="nav-item" onClick={() => { dispatch(chekItem({ check: false, item: null })) }}>
                        <NavLink to="/cobranza/cobradoresniveluno" className="nav-link collapsed text-dark">
                            <i className="icon-dark ri-account-box-line"></i>
                            <span>Cobradores</span>
                        </NavLink >
                    </li>
                    <li className="nav-item" onClick={() => { dispatch(chekItem({ check: false, item: null })) }}>
                        <NavLink to="/cobranza/supervisioncarganiveluno" className="nav-link collapsed text-dark">
                            <i className="icon-dark ri-survey-line"></i>
                            <span>Supervisión de carga</span>
                        </NavLink >
                    </li>
                </ul>
            </aside>
        </>
    )
}

export default CobranzaSidebar