import axios from '../../Utils/ApiService';

const AutorizarDictamenService = {
    getAllAutorizarDictamen: async (page, rows, string) => {
        return await axios.get(`/cartera/GetAllAutorizarDictamen?page=${page}&Rows=${rows}&Buscar=${string}`);
    },
    AprobarRechazarDictamen: async (data) => {
        return axios.put('/cartera/AprobarRechazarDictamen', data);
    },
    //Autorizacion de solicitudes
    GetAllAutorizarCitas: async (page, rows, string, procesoId, ubicacionId = "", ejecutivoId = "", tipoCitaid = "") => {       
        return await axios.get(`/cartera/GetAllAutorizarCitas?page=${page}&Rows=${rows}&Buscar=${string}&ProcesoId=${procesoId}&UbicacionId=${ubicacionId}&EjecutivoId=${ejecutivoId}&TipoCitaId=${tipoCitaid}`);
        // return await axios.get(`/cartera/GetAllAutorizarCitas?page=${page}&Rows=${rows}&Buscar=${string}&ProcesoId=${procesoId}`);
    },
    AprobarRechazarSolicitudCita: async (data) => {
        return axios.put('/cartera/AprobarRechazarSolicitudCita', data);
    },
}

export default AutorizarDictamenService