import React, { useEffect, useState } from "react";
import { useLocation } from "react-router-dom";
import Siniestros from "../Services/Siniestro/Siniestro";
import { useDispatch } from "react-redux";
import { SaveClienteSiniestro } from "../Redux/Slice/reloadSlice";

const ContratoCard = ({ vin }) => {
    const dispatch = useDispatch();
    const [contrato, setContrato] = useState({});
    const [vehiculo, setVehiculo] = useState({});
    const [acendes, setAcendes] = useState({});

    useEffect(() => {
        getContratoAcendes();
    }, []);

    const getContratoAcendes = async () => {
        await Siniestros.siniestroByVinGet(vin)
            .then(resp => {
                setVehiculo(resp.data.vehiculo);
                setAcendes(resp.data.infoAscendes);
                dispatch(SaveClienteSiniestro({clienteSiniestro:resp?.data?.infoAscendes?.cliente ?? ""}));
            })
            .catch(err => {
                console.error(err);
            });
    }

    const styleTemplate = {
        'Activo': { borderRadius: '5px', backgroundColor: "#D8F2CD", color: "#47A066", padding: ".35em .65em" },
        'Inactivo': { borderRadius: '5px', backgroundColor: "#F5CECE", color: "#CE2222", padding: ".35em .65em" },
        'Cancelado': { borderRadius: '5px', backgroundColor: "#F5CECE", color: "#CE2222", padding: ".35em .65em" },
        'Cerrado': { borderRadius: '5px', backgroundColor: "#F5CECE", color: "#CE2222", padding: ".35em .65em" },
        '': { borderRadius: '5px', backgroundColor: "#F5CECE", color: "#CE2222", padding: ".35em .65em" },
    }

    const getOnlyDateFormat = (date) => {
        if (date === undefined || date === null) return date;
        return date.split("T")[0].split("-").reverse().join("-");
    }

    const formatoMoneda = (monto = 0) => {
        if (monto === 0 || monto === undefined || monto === null) monto = 0;
        monto = monto.toString().replaceAll("$", "");
        const formatter = new Intl.NumberFormat('es-MX', {
            style: 'currency',
            currency: 'MXN',
        });
        let value = formatter.format(monto);
        return value;
    }

    return (
        <>
            <div className="row p-4 wrapper-vehiculo mb-4" style={{ minHeight: '240px', maxHeight: '240px' }}>
                <div className="col col-12">
                    <div className="row align-items-center justify-content-start" style={{ minHeight: '40px' }}>
                        <div className="col col-auto">
                            <h1 className="fw-bold text-sm">Contrato</h1>
                        </div>
                        <div className="col col-6">
                            <span className="badge text-xs">
                                <span style={styleTemplate[acendes?.status_contrato]} className='d-flex align-items-center justify-content-center'>{acendes?.status_contrato}</span>
                            </span>
                        </div>
                    </div>
                    <div className="divider"></div>
                    <div className="row gap-2">
                        <div className="col col-12">
                            <div className="row mt-1">
                                <div className="col col-6">
                                    <div className="row justify-content-start">
                                        <div className="col col-12 text-xs">
                                            <span><strong className="mr-2">No. de contrato:</strong> {acendes?.contrato ?? 'Sin dato'}</span>
                                        </div>
                                    </div>
                                </div>
                                <div className="col col-6">
                                    <div className="row justify-content-start">
                                        <div className="col col-12 text-xs">
                                            <span><strong className="mr-2">Término de crédito:</strong> {getOnlyDateFormat(acendes?.termino_contrato) ?? 'Sin dato'} </span>
                                        </div>
                                    </div>
                                </div>
                            </div>
                            <div className="row mt-1">
                                <div className="col col-6">
                                    <div className="row justify-content-start">
                                        <div className="col col-12 text-xs">
                                            <span><strong className="mr-2">Cliente:</strong> {acendes?.cliente ?? 'Sin dato'}</span>
                                        </div>
                                    </div>
                                </div>
                                <div className="col col-6">
                                    <div className="row justify-content-start">
                                        <div className="col col-12 text-xs">
                                            <span><strong className="mr-2">Pago de periodo:</strong> {formatoMoneda(acendes?.pago_periodo) ?? 'Sin dato'}</span>
                                        </div>
                                    </div>
                                </div>
                            </div>
                            <div className="row mt-1">
                                <div className="col col-6">
                                    <div className="row justify-content-start">
                                        <div className="col col-12 text-xs">
                                            <span><strong className="mr-2">Uso de unidad:</strong> {vehiculo?.usoUnidad ?? 'Sin dato'}</span>
                                        </div>
                                    </div>
                                </div>
                                <div className="col col-6">
                                    <div className="row justify-content-start">
                                        <div className="col col-12 text-xs">
                                            <span><strong className="mr-2">Frecuencia de pago:</strong> {acendes?.frecuencia_pago ?? 'Sin dato'}</span>
                                        </div>
                                    </div>
                                </div>
                            </div>
                            <div className="row mt-1">
                                <div className="col col-6">
                                    <div className="row justify-content-start">
                                        <div className="col col-12 text-xs">
                                            <span><strong className="mr-2">Saldo Insoluto:</strong> {formatoMoneda(acendes?.saldo_credito) ?? 'Sin dato'}</span>
                                        </div>
                                    </div>
                                </div>
                                <div className="col col-6">
                                    <div className="row justify-content-start">
                                        <div className="col col-12 text-xs">
                                            <span><strong className="mr-2">Fideicomiso:</strong> {acendes?.fideicomisario ?? 'Sin dato'}</span>
                                        </div>
                                    </div>
                                </div>
                            </div>
                            <div className="row mt-1 mb-4">
                                <div className="col col-6">
                                    <div className="row justify-content-start">
                                        <div className="col col-12 text-xs">
                                            <span><strong className="mr-2">Fecha de último pago:</strong> {getOnlyDateFormat(acendes?.ultimo_pago) ?? 'Sin dato'}</span>
                                        </div>
                                    </div>
                                </div>
                            </div>
                        </div>
                    </div>
                </div>
            </div>
        </>
    );
}

export default ContratoCard;