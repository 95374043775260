import { Form, Formik } from 'formik';
import React, { useEffect, useRef, useState } from 'react'
import { useLocation, useNavigate, useParams } from 'react-router-dom'
import Select from 'react-select';
import { TextField } from '../../../Components/TextField';
import CobranzaService from '../../../Services/Cobranza/Cobranza';
import DataTable from '../../../Components/datatable/DataTable';
import RowPages from '../../../Components/RowPages';
import { useSelector } from 'react-redux';
import Modal from '../../../Components/Modal/Modal';
import Spinner from '../../../Components/Loadig';

const ReasignarCargaNivel1 = () => {
   const refbtn = useRef();
   const {usuario} = useSelector((state) => state.ReasignarCargaNivelUnoSlice);
   const [currentData, setCurrentData] = useState(usuario.contratos)
   let initial = {
      userid: usuario.userid,
      nombrecobrador: usuario.nombrecobrador,
      numerocontratos: usuario.numerocontratos,
      contratos: usuario.contratos,
      cobradordestino: ""
   }
   const [initialValue, setInitialValue] = useState(initial);
   const [dataPersonal, setDataPersonal] = useState([]);
   const [pageRows, setpageRows] = useState(10);
   const [counterContratos, setCounterContratos ] = useState(0);
   const [loading, setLoading] = useState(false);
   const [listaContratos, setListaContratos] = useState([]);
   const [currentPage, setCurrentPage] = useState(1);
   const [str, setStr] = useState("");
   const [pageCount, setPageCount] = useState();
   const [page, setPage] = useState(1);
   const [cobradorDestino, setCobradorDestino] = useState(0);

   const { state } = useLocation();
   const navigate = useNavigate();

   useEffect(() => {
      let filteredData = usuario.contratos?.filter(item => {
         const nameMatches = str === '' || (item.contrato && item.contrato.toLowerCase().includes(str.toLowerCase()));
         return (nameMatches)
      })

      const indexOfLastItem = currentPage * pageRows
      const indexOfFirstItem = indexOfLastItem - pageRows
      const newData = filteredData.slice(indexOfFirstItem, indexOfLastItem)
      setCurrentData(newData)
  
      const newTotalPages = Math.ceil(filteredData.length / pageRows)
      setPageCount(newTotalPages)
   }, [currentPage, str, pageRows])


   useEffect(()=>{
      handleGetAllUsersNotInCobranza();
  },[]);

   const handleGetAllUsersNotInCobranza = async () => {
      let NewData = [{value:null, label:"Seleccionar"}];
      //await CobranzaService.getAllUsersNotInCobranza()
      await CobranzaService.getEjecutivosFullDetail()
         .then(res => {
            console.log(res);
            if(res.data.callSucceded){
                  res.data.responseData?.map(item => {
                     //NewData.push({value: item.userId, label: item.names})
                     if (item.nivel_cobrador === "1" && item?.userid !== usuario.userid){
                        NewData.push({value: item?.userid, label: item?.user_detail?.names})
                     }
                  })
            }
         })
         .catch(e => {
            console.log(`ERROR -- ${e}`)
         })
      setDataPersonal(NewData);
   }


   const columns = [
      { field: 'numero', headerName: '#', width: 50 },
      { field: 'contrato', headerName: 'Contrato', width: 150 },
      { field: 'diasvencidos', headerName: 'Días vencidos', width: 170 },
      { field: 'diassinconsultarclave', headerName: 'Días sin consultar clave', width: 220 },
      { field: 'montovencido', headerName: 'Monto vencido', width: 200 },
      { field: 'saldoinsoluto', headerName: 'Saldo insoluto', width: 200 },
   ];

   const handleGuardar = async () => {
      console.log(listaContratos);
      if(listaContratos.length > 0){
         setLoading(true);
         let data = {
            "verb": "string",
            "id": usuario.userid,
            "field": "string",
            "value": cobradorDestino,
            "anyObject":listaContratos
            };

         await CobranzaService.reAsignarCarga(data)
            .then(res => {
               console.log(res);
               setLoading(false);
               navigate(-1)
            })
            .catch(e => {
               setLoading(false);
               console.log(`ERROR ${e}`);
            })
      }
   }

   const handleCheck = (checked, value) => {
      console.log(value);
      let newList = [];
      if(checked){
         newList.push({id: value.id})
         if(listaContratos.length > 0){
            listaContratos.map(item => {
               newList.push({id: item.id})
            })
         }
         setCounterContratos(counterContratos + 1);
      }
      else{
         listaContratos.map(item => {
            if(item.id != value.id){
               newList.push({id: item.id});
            }
         })
         setCounterContratos(counterContratos -1 );
      }
      setListaContratos(newList);
   }

   const handlePageClick = (event) => {
      setPage(event.selected + 1);
   };

   return (
      <Formik
         enableReinitialize={true}
         initialValues={initialValue}
      >{
         ({ isValid, values, setFieldValue }) => (
      <>
         <Modal isOpen={loading} color='transparent'><Spinner message={""} /></Modal>
         <header className='heder-subtitle d-flex justify-content-start align-items-center border-bottom px-3'>
               <i className="bx bxs-chevron-left mx-2" onClick={() => { navigate(-1) }}></i>
               Regresar a listado de supervisión de carga {state.page}
         </header>
         <section className='mx-5 py-4'>
            <div className='d-flex justify-content-between'>
               <span className='fs-5'><strong>Reasignar carga de trabajo</strong></span>
            </div>
         </section>
         <section className='mt-3 mx-5' style={{width: "40rem"}}>
            <p><span style={{color:"#000", fontWeight: "bold"}}>1. Indica el cobrador destino.</span></p>
            <div className="mb-3 row" style={{marginTop:30}}>
               <div className="col-sm-6">
                  <label className='col-12 mx-1'>
                     Cobrador destino
                     <Select options={dataPersonal} label="Nombre" name='cobradordestino' placeholder="Seleccionar" onChange={(e) => { 
                        setFieldValue("cobradordestino", e.value != 0 ? e.value : null); setCobradorDestino(e.value != 0 ? e.value : null);}}/>
                  </label>
               </div>
               <div className="col-sm-6">
                     <TextField label="Contratos actuales" name="numerocontratos" type="text" placeholder={values.numerocontratos} disabled={true} />
               </div>
            </div>
            <p><span style={{color:"#000", fontWeight: "bold"}}>2. Selecciona los contratos que deseas reasignar.</span></p>
            <div className="mb-3 row" style={{marginTop:30}}>
               <div className="mx-1  inner-addon right-addon">
                  <i className="glyphicon fas fa-search"></i>
                  <input type="text" className="form-control mb-4" placeholder="Buscar" onKeyUp={(e) => { setStr(e.target.value) }} />
               </div>
            </div>
         </section>
         <section className='mt-3 mx-5 mb-3 row'>
            <div className='col-sm-6'>
            <p><span style={{color:"#000", fontWeight: "bold"}}>Cobrador fuente: {values.nombrecobrador}</span></p>
            </div>
            <div className='col-sm-6 d-flex justify-content-end'>
               <p>Total de contratos: {values.numerocontratos} <span style={{color:"#000", fontWeight: "bold"}}>Contratos seleccionados {counterContratos} </span></p>
               <button id="AT_btnGuardar" className='btn mx-2 col-3' type="button" disabled={listaContratos.length <= 0} onClick={() => {handleGuardar()}}>Guardar</button>
            </div>
         </section>
         <section className='my-2'>
            {/* <ValidatePermission
            isActive={ValidatePermissionById(4, Section., SubModule.Usuarios).Ver}
            > */}
            <RowPages setpageRows={setpageRows} />
            <div className='mx-4 table-responsive'>
               <DataTable
                  column={columns}
                  data={currentData}
                  checkbox={true}
                  handleCheck={handleCheck}
                  paginate={true}
                  handlePageClick={handlePageClick}
                  currentPage={page - 1}
               />
            </div>
            {/* </ValidatePermission> */}
         </section>
         </>
         )
      }
   </Formik>
   )
}

export default ReasignarCargaNivel1