import * as Yup from 'yup';
import React, { useEffect, useRef, useState } from 'react'
import Select from 'react-select'
import { Formik } from 'formik'
import { TextField } from '../../Components/TextField';
import { SelectField } from '../../Components/SelectField';
import { useDispatch, useSelector } from 'react-redux'
import { toast, Toaster } from 'react-hot-toast';
import { useNavigate, useParams } from 'react-router-dom'
import Catalogo from '../../Services/Catalogos/Catalogo';
import Modal from '../../Components/Modal/Modal';
import Spinner from '../../Components/Loadig';
import useTablasSecundariasDinamicas from '../../Hooks/TablasSecundarias/useTablasSecundariasDinamicas';
import { ValidatePermission, ValidatePermissionById } from '../../Auth/ValidatePermission';
import { Section, SubModule } from '../../Auth/Authorization';
import BasicModal from '../../Components/Modal/BasicModal';
import DataTableDinamica from '../../Components/datatable/DataTableDinamica';
import ElementoDinamico from './ElementoDinamico';
import Submenu from './Submenu';

const TablasSecundariasDinamicas = (props) => {
    const { refFormik } = useRef();
    const dispatch = useDispatch();
    const navigate = useNavigate();
    
    const { columns,
        data,
        pageCount,
        dataList,
        handleChangeList,
        handleChange,
        handlePageClick,
        loading,
        setLoading,
        currentPage,
        configuration,
        EditRow,
        tablaName
    } = useTablasSecundariasDinamicas();

    let initial = {}
    const [initialValue, setInitialValue] = useState(initial);
    const [modalMessage, setModalMessage] = useState({ isOpen: false, type: 1, title: '', message: '', data: [], authorizationType: 1, Origin: "Listado" });


    const handleEdit = (obj) => {
        EditRow(obj);
    }
    // const validate = Yup.object({});

    return (
        <>
            <Modal isOpen={modalMessage.isOpen} color='#fff' width={560}>
                {
                    modalMessage.type === 1 &&
                    <BasicModal
                        modalMessage={modalMessage}
                        setModalMessage={setModalMessage}
                    />
                }
            </Modal>
            <Toaster
                position="top-right"
                toastOptions={{
                    success: {
                        style: {
                            background: '#47a066',
                            color: '#FFFF',
                            borderLeft: '10px solid #2f7246'
                        },
                    },
                    error: {
                        style: {
                            background: '#d53f3f',
                            color: '#FFFF',
                            borderLeft: '10px solid #ac241a'
                        },
                    },
                }}
            />
            <Modal isOpen={loading} color='transparent'><Spinner message={""} /></Modal>
            <Submenu />
            <div className='title fs-5 mx-4 pt-3'>
                <strong>Configuración tablas dinámicas</strong>
            </div>
            <section className='mx-4'>
                A continuación, se presentará un listado con las tablas que son indispensables para realizar uno o varios procesos del DataWare.
            </section>
            <div className='mx-4 my-5'>
                {/* <section className='row'> */}
                <div className='row col-12'>
                    <div className='col-lg-4 col-md-6 col-sm-12'>
                        <select className="form-select" onChange={(e) => handleChangeList(e.target.value)} placeholder="Tablas">
                            {/* <option value="" disabled selected={true}>Proceso</option> */}
                            <option value="0" selected={true} disabled={true}>Selecciona una tabla</option>
                            {
                                dataList?.map((item, index) => {
                                    return <option key={index} value={item.NombreTabla}>{item.Nombre}</option>
                                })
                            }
                        </select>
                    </div>

                </div>
                <ValidatePermission
                    isActive={ValidatePermissionById(4, Section.TablasSecundariasCartera, SubModule.TablasSecundarias).Ver}
                >
                    <div className='d-flex justify-content-between align-items-center me-4'>
                        <div className='fs-5 mt-3'>
                            Tablas Secundarias Dinámicas
                        </div>
                        <div className='mx-1 col-lg-4 col-md-6 col-sm-12'>
                            <div className='d-flex justify-content-end align-items-center'>
                                <span className="me-1" style={{ fontSize: '12px' }}>Resultado por pagina</span>
                                {/* <Select options={pageRows()} className='col-2' defaultValue={{ value: 10, label: 10 }} onChange={(e) => { setpageRows(e.value) }} /> */}
                            </div>

                        </div>
                    </div>
                    <div className='table-responsive'>
                        <DataTableDinamica
                            column={columns}
                            data={data}
                            // editable={ValidatePermissionById(4, Section.TablasSecundarias, SubModule.TablasSecundarias).Actualizar}
                            editable={true}
                            handleEdit={handleEdit}
                            configuration={configuration}
                            pageCount={pageCount}
                            handlePageClick={handlePageClick}
                            currentPage={currentPage}
                        />
                    </div>
                    <div className='justify-content-between align-items-center me-4'>
                        <div className='fs-5 mt-3'>
                            <ElementoDinamico 
                                tabla={tablaName}
                                configuration={configuration}
                            />
                        </div>
                    </div>
                </ValidatePermission>
            </div>
        </>
    )
}

export default TablasSecundariasDinamicas