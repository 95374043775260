import React from "react";
import DataTable from "../../../../Components/datatable/DataTable";
import { useNavigate } from "react-router-dom";
import { useSelector } from "react-redux";

const ReferenciasDetalle = () => {
   const navigate = useNavigate();
   const {datosConsulta} = useSelector((state) => state.SeguimientoVisitaDetalleSlice);

   const columns = [
      { field: 'tiporelacion', headerName: 'Relación con solicitante', width: 210 },
      { field: 'nombre', headerName: 'Nombre', width: 150 },
      { field: 'apellido', headerName: 'Apellido', width: 150 },
      { field: 'direccion', headerName: 'Domicilio principal', width: 550 },
      { field: 'direccionadicional', headerName: 'Direcciones adicionales', width: 100 },
      { field: 'interacciones', headerName: 'Interacciones', width: 100 },
      { field: 'ultimainteraccion', headerName: 'Última interacción', width: 150 }
   ]

   const columnsDetail = [
      { field: 'tipocontacto', headerName: 'Tipo de contacto', width: 210 },
      { field: 'subtipo', headerName: 'Subtipo', width: 150 },
      { field: 'valor', headerName: 'Valor', width: 150 },
      { field: 'ultimainteraccion', headerName: 'Última interacción', width: 150 },
      { field: 'interacciones', headerName: 'Interacciones', width: 100 },
      { field: 'exitoso', headerName: 'Exitoso', width: 100 },
      { field: 'estatus', headerName: 'Estatus', width: 100 },
      { field: 'comentariosTooltip', headerName: 'Comentarios', width: 150 }
   ]

   const data = [
      {
         tiporelacion: "Hermano",
         nombre: "Roberto",
         apellido: 'Flores',
         direccion: "Calle 10 #330 Col.Jardines de Pedregal, San Nicolas de los Garza, NL.",
         direccionadicional: '',
         interacciones: 5,
         ultimainteraccion: "12/07/2023",
         detail: [
            {
               tipocontacto: 'Teléfono fijo',
               subtipo:'Particular',
               valor: '8112837472',
               ultimainteraccion: '12/07/2023',
               interacciones: 5,
               exitoso: 'Si',
               estatus: 'Activo',
               comentariosTooltip: "La reparación se realizo exitosamente. Encontraron las piezas por reparar de la marca que pidió el cliente."
            },
            {
               tipocontacto: 'Teléfono fijo',
               subtipo:'Particular',
               valor: '8112837472',
               ultimainteraccion: '12/07/2023',
               interacciones: 5,
               exitoso: 'Si',
               estatus: 'Activo',
               comentariosTooltip: "La reparación se realizo exitosamente. Encontraron las piezas por reparar de la marca que pidió el cliente."
            }
         ]
      },
      {
         tiporelacion: "Hermano",
         nombre: "Roberto",
         apellido: 'Flores',
         direccion: "Calle 10 #330 Col.Jardines de Pedregal, San Nicolas de los Garza, NL.",
         direccionadicional: '',
         interacciones: 5,
         ultimainteraccion: "12/07/2023",
         detail: [
            {
               tipocontacto: 'Teléfono fijo',
               subtipo:'Particular',
               valor: '8112837472',
               ultimainteraccion: '12/07/2023',
               interacciones: 5,
               exitoso: 'Activo',
               comentariosTooltip: "La reparación se realizo exitosamente. Encontraron las piezas por reparar de la marca que pidió el cliente."
            },
            {
               tipocontacto: 'Teléfono fijo',
               subtipo:'Particular',
               valor: '8112837472',
               ultimainteraccion: '12/07/2023',
               interacciones: 5,
               exitoso: 'Activo',
               comentariosTooltip: "La reparación se realizo exitosamente. Encontraron las piezas por reparar de la marca que pidió el cliente."
            }
         ]
      },
   ]

   return (
      <>
         <div style={{ background: "#F5F5F5", paddingBottom: "1rem" }}>
            <header className='heder-subtitle d-flex justify-content-start align-items-center border-bottom px-2' style={{ background: "#FFF" }}>
               <div className='cursor-pointer' onClick={() => { navigate(-1) }}>
                  <i className="bx bxs-chevron-left mx-2" ></i>
                  Regresar al detalle de seguimiento de visita
               </div>
            </header>
            <div className="col col-12 d-flex">
               <div className="col col-11 px-4 py-2">
                  <div className="row p-4 wrapper-vehiculo">
                     <div className="col col-12">
                        <div className="row align-items-center justify-content-between">
                           <div className="col col-auto ">
                                 <h2 className="fw-bold ">Consulta de referencias</h2>
                           </div>
                        </div>
                        <div className="row gap-2">
                           <div className="col col-12">
                                 <div className="row mt-4">
                                    <div className="col col-3">
                                       <div className="row justify-content-start">
                                             <div className="col col-12">
                                                <span><strong className="mr-2">Tipo de persona:</strong> {datosConsulta.persona.tipo}</span>
                                             </div>
                                       </div>
                                    </div>
                                    <div className="col col-3">
                                       <div className="row justify-content-start">
                                             <div className="col col-12">
                                                <span><strong className="mr-2"> Nombre:</strong> {datosConsulta.persona.label}</span>
                                             </div>
                                       </div>
                                    </div>
                                 </div>
                           </div>
                        </div>
                     </div>
                  </div>
               </div>
            </div>
            <div className="col col-11 mx-3 my-2  wrapper-vehiculo " style={{ background: "#FFF" }}>
               <div className="px-4">
                  <p className="pt-2" >Detalle de las referencias registradas</p>
                  <DataTable
                     column={columns}
                     data={data}
                     masterDetail={true}
                     columnDetail={columnsDetail}
                  />
               </div>
            </div>
         </div>
      </>
   );
}

export default ReferenciasDetalle;