import React from 'react'
import useProgramacionJobsCartera from "../../Hooks/ProgramacionJobs/useProgramacionJobsCartera"
import { ValidatePermissionById } from '../../Auth/ValidatePermission'
import ErrorModal from '../../Components/Modal/ErrorModal';
import SuccessModal from '../../Components/Modal/SuccessModal';
import Modal from '../../Components/Modal/Modal';
import Loadig from '../../Components/Loadig';
import SubMenuJobs from './SubMenuJobs';
import Select from 'react-select'
import DataTable from '../../Components/datatable/DataTable'
import RowPages from '../../Components/RowPages'
import { Section, SubModule } from '../../Auth/Authorization'

const ProgramacionJobsCartera = () => {
    const {
        loading,
        dataProceso,
        dataFrecuencia,

        setName,
        setProceso,
        setFrecuencia,

        columns,
        currentData,

        modalMessage,
        setModalMessage,

        currentPage,
        pageCount,
        setpageRows,
        handlePageClick,

        handleDetails

    } = useProgramacionJobsCartera()

    return (
        <>
            <Modal isOpen={loading} color='transparent'><Loadig message={""} /></Modal>
            <Modal isOpen={modalMessage.isOpen} color='#fff'>
                {
                    modalMessage.type === 1 &&
                    <ErrorModal
                        modalMessage={modalMessage}
                        setModalMessage={setModalMessage}
                    />
                }
                {
                    modalMessage.type === 2 &&
                    <SuccessModal
                        modalMessage={modalMessage}
                        setModalMessage={setModalMessage}
                    />
                }

            </Modal>
            <SubMenuJobs />
            <section className='mx-5 my-4 d-flex flex-column'>
                <div className='d-flex justify-content-between'>
                    <span className='fs-5'><strong>Programación de jobs</strong></span>
                </div>
                <span className='mt-2'>Programa los jobs de cartera entrando al detalle del registro.</span>
            </section>

            <section className='mt-5 mx-4'>
                <div className='row col-12'>
                    <div className="col-lg-3 col-md-6 col-sm-12 inner-addon right-addon">
                        <i className="glyphicon fas fa-search"></i>
                        <input type="text" className="form-control mb-4" placeholder="Buscar" onChange={(e) => { setName(e.target.value) }} />
                    </div>
                    <div className='col-lg-3 col-md-6 col-sm-12'>
                        <Select options={dataProceso} className='col-12' onChange={(e) => { setProceso(e.value) }} placeholder="Procesos" />
                    </div>
                    <div className='col-lg-3 col-md-6 col-sm-12'>
                        <Select options={dataFrecuencia} className='col-12' onChange={(e) => { setFrecuencia(e.value) }} placeholder="Frecuencia" />
                    </div>
                </div>
            </section>
            <RowPages
                setpageRows={setpageRows}
            />
            <div className='mx-4 table-responsive'>
                <DataTable
                    column={columns}
                    data={currentData}
                    detailable={ValidatePermissionById(4, Section.Cartera, SubModule.ProgramacionDeJobs).Historial}
                    handleDetail={handleDetails}
                    paginate={true}
                    pageCount={pageCount}
                    handlePageClick={handlePageClick}
                    currentPage={currentPage}
                />
            </div>
        </>
    )
}


export default ProgramacionJobsCartera