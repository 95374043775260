import React, { useRef, useEffect } from 'react';
import { Formik, Field } from 'formik';
import toast, { Toaster } from 'react-hot-toast';
import RowPages from '../../../Components/RowPages';
import DataTable from "../../../Components/datatable/DataTable";

const GeneracionExpedientes = () => {
  const refFormik = useRef();

  useEffect(() => {
    // Efecto si es necesario
  }, []);
  
  const initialValues = {
    // Definir valores iniciales si es necesario
  };

  const handleSubmit = (values) => {
    // Definir lógica de envío de datos aquí
    console.log("Formulario enviado con valores:", values);
  };

  return (
    <>
      <Toaster
        position="top-right"
        toastOptions={{
          success: {
            style: {
              background: '#47a066',
              color: '#FFFF',
              borderLeft: '10px solid #2f7246'
            },
          },
          error: {
            style: {
              background: '#d53f3f',
              color: '#FFFF',
              borderLeft: '10px solid #ac241a'
            },
          },
        }}
      />

      <Formik
        innerRef={refFormik}
        initialValues={initialValues}
        onSubmit={handleSubmit}
      >
        {() => (
          <form>
            <section>
              <div className="pb-4">
                <section className="mx-4 my-4 d-flex flex-column">
                  <div className="col-12 mt-4">
                    <div className="row">
                      <div className="col-auto">
                        <strong className="mt-2">Expediente de Visitas</strong>
                      </div>
                      <p className="mt-5">Página en construcción</p>
                    </div>
                  </div>
                </section>
              </div>
            </section>
          </form>
        )}
      </Formik>
    </>
  );
};

export default GeneracionExpedientes;
