import { useEffect, useState } from 'react'
import { useNavigate, } from 'react-router';
import CobranzaService from '../../Services/Cobranza/Cobranza'
import { FormatFecha } from '../../Utils/functions'
const SeguimientoCobranzaHook = () => {
  const navigate = useNavigate();

  const [name, setName] = useState("")
  const [data, setData] = useState([])
  
  const userId = sessionStorage.getItem('userId').trim()
  const nivel_cobranza = "1"
  
  const columns = [
    { field: 'contrato_id', headerName: 'Contrato' },
    { field: 'periodo', headerName: 'Periodo' },
    { field: 'dias_vencido', headerName: 'Días Vencidos' },
    { field: 'saldo_credito', headerName: 'Monto Insoluto' },
    { field: 'monto_adeudo', headerName: 'Monto adeudo' },
    { field: 'fecha_ultimo_pago', headerName: 'Fecha último pago' },
    { field: 'fecha_consulta_clave', headerName: 'Fecha última clave' },
    { field: 'dias_sin_consultar_clave', headerName: 'Días sin consultar' },
    { field: 'llamadas', headerName: 'Llamadas' },
    { field: 'fecha_ultimo_pago', headerName: 'Ult. Actualización' },
  ];

  const [pageRows, setpageRows] = useState(10)
  const [pageCount, setPageCount] = useState(1);
  const [currentPage, setCurrentPage] = useState(0);

  const [currentData, setCurrentData] = useState(data)
  //Modal
  const [loading, setLoading] = useState(false);
  const [modalMessage, setModalMessage] = useState({ isOpen: false, type: 1, title: '', message: '', data: [], authorizationType: 2, Origin: "Listado" });

  useEffect(() => {
    // Filtra los datos según los filtros seleccionados
    const filteredData = data.filter(item => {
      const nameMatches = name === '' || (item.contrato_id && item.contrato_id.toLowerCase().includes(name.toLowerCase()));

      return (
        nameMatches
      )
    })

    const indexOfLastItem = (currentPage + 1 ) * pageRows
    const indexOfFirstItem = indexOfLastItem - pageRows
    const newData = filteredData.slice(indexOfFirstItem, indexOfLastItem)
    setCurrentData(newData)

    const newTotalPages = Math.ceil(filteredData.length / pageRows)
    setPageCount(newTotalPages)
  }, [currentPage, name, pageRows, data])

  useEffect(() => {
    setCurrentPage(0)
  }, [pageRows, name])

  const handleDetails = (value) => {
    //Movimiento a Detalles del Job
    navigate(`/cobranza/seguimiento-telefonico/detalles`, {
      state: value
    })
  }

  const handlePageClick = (event) => {
    setCurrentPage(event.selected)
  };

  useEffect(() => {
    handleGetSeguimientoContrato()
  }, [])


  const handleGetSeguimientoContrato = async () => {
    setLoading(true);
    const data = {
      "verb": null,
      "id": null,
      "field": null,
      "value": null
    }
    await CobranzaService.getAllEjecutivosWithFullDetail(data)
      .then(res => {
        const userContracts = res.data.responseData.find(user => user.userid.toLowerCase() === userId.toLowerCase());

        let datosFormateados = userContracts.contratos.map((item) => {
          const fechaPago = (item.fecha_ultimo_pago === "1990-01-01T00:00:00" || item.fecha_ultimo_pago === "1900-01-01T00:00:00") ? "-" : FormatFecha(item.fecha_ultimo_pago)
          const fechaClave = (item.fecha_consulta_clave === "1990-01-01T00:00:00" || item.fecha_consulta_clave === "1900-01-01T00:00:00") ? "-" : FormatFecha(item.fecha_consulta_clave)
          const saldoCredito = formatearMonto(item.saldo_credito);
          const montoAdeudo = formatearMonto(item.monto_adeudo);
          const diasformateado = item.dias_vencido + ' días';
          const diasformateado2= item.dias_sin_consultar_clave + ' días'
          return {
            ...item,
            fecha_ultimo_pago: fechaPago,
            fecha_consulta_clave: fechaClave,
            monto_adeudo: montoAdeudo,
            saldo_credito: saldoCredito,
            dias_vencido: diasformateado,
            dias_sin_consultar_clave:diasformateado2
          }
        })
        
        let nivelCobranzaUno = datosFormateados?.filter(item => item.nivel_cobranza === nivel_cobranza)
        setData(nivelCobranzaUno)
        setLoading(false);

      }).catch(e => {
        setLoading(false);
        if (e.response !== undefined)
          setModalMessage({ ...modalMessage, isOpen: true, type: 1, message: e.response.data.message })
        else
          setModalMessage({ ...modalMessage, isOpen: true, type: 1, message: e.message })
      })
  }

  const formatearMonto = (monto) => {
    return new Intl.NumberFormat('es-MX', {
      style: 'currency',
      currency: 'MXN',
      minimumFractionDigits: 2,
      maximumFractionDigits: 2,
    }).format(monto);
  };

  return {
    setName,
    setpageRows,
    columns,
    currentData,
    handleDetails,
    pageCount,
    currentPage,
    handlePageClick
  }
}
export default SeguimientoCobranzaHook