import React, { useRef, useEffect, useState } from 'react'
import { useDispatch, useSelector } from "react-redux";
import * as Yup from "yup";
import Spinner from "../../../../Components/Loadig"
import Modal from "../../../../Components/Modal/Modal"
import { TextField } from '../../../../Components/TextField';
import { Formik, Form, Field } from 'formik'
import { TextAreaField } from '../../../../Components/TextAreaField';
import { SelectField } from '../../../../Components/SelectField';
import { toast, Toaster } from 'react-hot-toast';
import CobranzaService from '../../../../Services/Cobranza/Cobranza';
import { message } from '../../../../Utils/functions';
import { setItemGeneral } from '../../../../Redux/Slice/Cobranza/SeguimientoVisita/SeguimientoVisitaDetalleSlice';
import { v4 as uuidv4 } from 'uuid';

const RegistroPromesaCards = React.forwardRef(({ ...props }, ref) => {
   const userId = sessionStorage.getItem('userId').trim()
   const {contratoActual, itemGeneral} = useSelector((state) => state.SeguimientoVisitaDetalleSlice);
   const [collpsableGrua, setCollpsableGrua] = useState(false);
   const refFormik = useRef();
   const dispatch = useDispatch();
   // const { state } = useLocation();
   const [fechaInicio, setFechaInicio] = useState(new Date((new Date()).setDate((new Date()).getDate() + 1)));
   const [show, setShow] = useState(props.show);

   useEffect(() => {
      setShow(props.show);
   }, [props.show]);

   useEffect(()=>{
      if(itemGeneral.promesa !== null){
         handleCargarDatosPromesa();
      }
   },[itemGeneral])

   const getDiasPromesa = (fecha) => {
      return (new Date(fecha) - new Date()) / (1000 * 60 * 60 * 24);
   }

   const handleUpdate = async(contrato) => {
      setLoading(true);
      await CobranzaService.GetAllRutasCobranza()
          .then(res=>{
            res.data.responseData?.map(user=>{
              if(user.userid.toLowerCase() === userId.toLowerCase()){
                user.items.map(item=>{
                  if(item.contrato.contrato_id.toLowerCase() === contrato.contrato_id.toLowerCase()){
                    dispatch(setItemGeneral(item));
                  //   navigate(`/cobranza/seguimiento-visita/detalle`);
                  }
                })
              }
            })
            setLoading(false);
          })
          .catch(e => {
              setLoading(false);
          })
    }

   const calcularTotal = (fecha) => {
      let frecuencia = 7;
      if (contratoActual.frecuencia_pago?.toLowerCase() == "quincenal") {
         frecuencia = 15;
      } else if (contratoActual.frecuencia_pago?.toLowerCase() == "mensual") {
         frecuencia = 30;
      }
      let dias_promesa = getDiasPromesa(fecha);
      // console.log(dias_promesa);
      const result = Math.floor(dias_promesa / frecuencia);
      // console.log(result);
      
      return result * contratoActual.pago_periodo + contratoActual.monto_adeudo;
   }

   const getFormatFecha = (fecha) => {
      const year = fecha.getFullYear();
      const month = String(fecha.getMonth() + 1).padStart(2, '0');
      const day = String(fecha.getDate()).padStart(2, '0');
      return `${year}-${month}-${day}`;
   }

   let initialGarantiaPago = {
      tipo_promesa: "Garantía de pago",
      folio: "Por definir",
      proceso: "",
      cobrador_apoyo: "",
      cobradores: "",
      grua: "",
      fecha_vencimiento: getFormatFecha(fechaInicio),
      dias_de_promesa: getDiasPromesa(fechaInicio),
      monto_vencido: contratoActual.monto_adeudo,
      pago_periodo: contratoActual.pago_periodo,
      total: calcularTotal(fechaInicio),
      registro_concluido: false,
      fechaInicioProceso: "23/10/2023 09:00",
      fechaFinProceso: "23/10/2023 09:00"
   };
   let initialPromesaPago = {
      tipo_promesa: "Promesa de pago",
      motivoPromesa: "",
      fecha_compromiso: "",
      horarioCompromiso: "",
      monto_prometido: "",
      monto_vencido: contratoActual.monto_adeudo,
      comentarios: "",
      registro_concluido: false,
      fechaInicioProceso: "23/10/2023 09:00",
      fechaFinProceso: "23/10/2023 09:00"
   };
   let initialPromesaEntregaDefinitiva = {
      tipo_promesa: "Promesa de entrega definitiva",
      folio: "Por definir",
      proceso: "",
      cobrador_apoyo: "",
      cobradores: "",
      grua: "",
      registro_concluido: false,
      fechaInicioProceso: "23/10/2023 09:00",
      fechaFinProceso: "23/10/2023 09:00"
   };
   const [datosGarantiaPago, setDatosGarantiaPago] = useState(initialGarantiaPago);
   const [datosPromesaPago, setDatosPromesaPago] = useState(initialPromesaPago);
   const [datosPromesaEntregaDefinitiva, setDatosPromesaEntregaDefinitiva] = useState(initialPromesaEntregaDefinitiva);
   // const [cobradorSeleccionado, setCobradorSeleccionado] = useState(0);
   // const [motivoPromesaSeleccionada, setMotivoPromesaSeleccionada] = useState(0);
   const [loading, setLoading] = useState(false);

   const dataCobradorApoyo = [
      { value: 1, label: "Si" },
      { value: 2, label: "No" }
   ];

   const dataCobrador = [
      { value: 1, label: "Si" },
      { value: 2, label: "No" }
   ];

   const dataGrua = [
      { value: 1, label: "Si" },
      { value: 2, label: "No" }
   ];

   const dataMotivoPromesa = [
      { value: 1, label: "Motivo 1" },
      { value: 2, label: "Motivo 2" },
      { value: 3, label: "Motivo 3" },
      { value: 4, label: "Motivo 4" }
   ];

   const handleCargarDatosPromesa = () =>{
      if(itemGeneral.promesa.tipo_promesa === "Garantía de pago"){
         let date = new Date(itemGeneral.promesa.fecha_vencimiento);
         let year = date.getFullYear();
         let month = (date.getMonth() + 1).toString().length === 1 ? `0${date.getMonth() + 1}` :(date.getMonth() +1)
         let day = date.getDate().toString().length === 1 ? `0${date.getDate()}` :date.getDate()
         setDatosGarantiaPago({
            tipo_promesa: "Garantía de pago",
            folio: `${itemGeneral.promesa.folio}`,
            proceso: itemGeneral.promesa.proceso,
            cobrador_apoyo: itemGeneral.promesa.cobrador_apoyo === 1 ? 1 : 2,
            cobradores: itemGeneral.promesa.cobradores === "Si" ? 1 : 2,
            grua: itemGeneral.promesa.grua === 1 ? 1 : 2,
            fecha_vencimiento: `${year}-${month}-${day}`,
            dias_de_promesa: itemGeneral.promesa.dias_de_promesa,
            monto_vencido: itemGeneral.promesa.monto_vencido,
            pago_periodo: itemGeneral.promesa.pago_periodo,
            total: calcularTotal(`${year}-${month}-${day}`),
            registro_concluido: false,
            fechaInicioProceso: "23/10/2023 09:00",
            fechaFinProceso: "23/10/2023 09:00"
         })
      }
      if(itemGeneral.promesa.tipo_promesa === "Promesa de pago"){
         establecerFormularioActual(1)
         let date = new Date(itemGeneral.promesa.fecha_compromiso);
         let year = date.getFullYear();
         let month = (date.getMonth() + 1).toString().length === 1 ? `0${date.getMonth() + 1}` :(date.getMonth() +1)
         let day = date.getDate().toString().length === 1 ? `0${date.getDate()}` :date.getDate()
         let hours = date.getHours().toString().length === 1 ? `0${date.getHours()}` : date.getHours()
         let minutes = date.getMinutes().toString().length === 1 ? `0${date.getMinutes()}` : date.getMinutes()
         setDatosPromesaPago({
            tipo_promesa: "Promesa de pago",
            motivo_promesa: itemGeneral.promesa.motivo_promesa,
            fecha_compromiso: `${year}-${month}-${day}`,
            horarioCompromiso: `${hours}:${minutes}`,
            monto_prometido: itemGeneral.promesa.monto_prometido,
            monto_vencido: contratoActual.monto_adeudo,
            comentarios: itemGeneral.promesa.comentarios,
            registro_concluido: false,
            fechaInicioProceso: "23/10/2023 09:00",
            fechaFinProceso: "23/10/2023 09:00"
         })
      }

      if(itemGeneral.promesa.tipo_promesa === "Promesa de entrega definitiva"){
         establecerFormularioActual(2)
         setDatosPromesaEntregaDefinitiva({
            tipo_promesa: "Promesa de entrega definitiva",
            folio: `${itemGeneral.promesa.folio}`,
            proceso: itemGeneral.promesa.proceso,
            cobrador_apoyo: itemGeneral.promesa.cobrador_apoyo === 1 ? 1 : 2,
            cobradores: itemGeneral.promesa.cobradores === "Si" ? 1 : 2,
            grua: itemGeneral.promesa.grua === 1 ? 1 : 2,
            registro_concluido: false,
            fechaInicioProceso: "23/10/2023 09:00",
            fechaFinProceso: "23/10/2023 09:00"
         })
      }
   }

   const [formularioActual, establecerFormularioActual] = useState(0);
   const handleNextForm = (e) => {
      e.preventDefault();
      establecerFormularioActual((formularioPrevio) => (formularioPrevio + 1) % 3);
   };

   const handleGuardar = async (values) => {
      setLoading(true);
      let data = {};
      let idRegistroPrincipal = uuidv4();
      if (formularioActual == 0) {
         {/* tipo promesa: garantia de pago */ }
         data = {
            "verb": "string",
            "id": "string",
            "field": "string",
            "value": "string",
            "flag": false,
            "anyObject": {
               "id": itemGeneral.promesa === null ? idRegistroPrincipal : itemGeneral.promesa.id,
               "ruta_head_id": itemGeneral.ruta_head_id,
               "ruta_item_id": itemGeneral.id,
               "evento_head_id": itemGeneral.evento_head_id,
               "evento_item_id": itemGeneral.evento_item_id,
               "tipo_promesa": values.tipo_promesa,
               "folio": null,
               "proceso": values.proceso,
               "cobrador_apoyo": values.cobrador_apoyo === 1 ? 1 : 0,
               "cobradores": values.cobradores,
               "grua": values.grua === 1 ? 1 : 0,
               "motivo_promesa": "",
               "fecha_compromiso": "",
               "fecha_vencimiento": values.fecha_vencimiento,
               "dias_de_promesa": Number(values.dias_de_promesa.toFixed(0)),
               "monto_prometido": 0,
               "monto_vencido": contratoActual.monto_adeudo,
               "comentarios": "",
               "pago_periodo": `${values.pago_periodo}`,
               "monto_por_cubrir": 0,
               "registro_concluido": 1,
               "promesa_status_id": "66498F01-9033-4BE1-9353-EE0FD4217120"
            }
         }
      }
      if (formularioActual == 1) {
         {/* tipo promesa: promesa de pago */ }
         data = {
            "verb": "string",
            "id": "string",
            "field": "string",
            "value": "string",
            "flag": false,
            "anyObject": {
               "id":  itemGeneral.promesa === null ? idRegistroPrincipal : itemGeneral.promesa.id,
               "ruta_head_id": itemGeneral.ruta_head_id,
               "ruta_item_id": itemGeneral.id,
               "evento_head_id": itemGeneral.evento_head_id,
               "evento_item_id": itemGeneral.evento_item_id,
               "tipo_promesa": values.tipo_promesa,
               "folio": null,
               "proceso": "",
               "cobrador_apoyo": 1,
               "cobradores": "",
               "grua": 1,
               "motivo_promesa": values.motivo_promesa,
               "fecha_compromiso": `${values.fecha_compromiso}T${values.horarioCompromiso}`,
               "fecha_vencimiento": "",
               "dias_de_promesa": 5,
               "monto_prometido": values.monto_prometido,
               "monto_vencido": contratoActual.monto_adeudo,
               "comentarios": values.comentarios,
               "pago_periodo": "",
               "monto_por_cubrir": 0,
               "registro_concluido": 1,
               "promesa_status_id": "66498F01-9033-4BE1-9353-EE0FD4217120"
            }
         }
      }
      if (formularioActual == 2) {
         {/* tipo promesa: promesa de entrega definitiva */ }
         data = {
            "verb": "string",
            "id": "string",
            "field": "string",
            "value": "string",
            "flag": false,
            "anyObject": {
               "id": itemGeneral.promesa === null ? idRegistroPrincipal : itemGeneral.promesa.id,
               "ruta_head_id": itemGeneral.ruta_head_id,
               "ruta_item_id": itemGeneral.id,
               "evento_head_id": itemGeneral.evento_head_id,
               "evento_item_id": itemGeneral.evento_item_id,
               "tipo_promesa": values.tipo_promesa,
               "folio": null,
               "proceso": values.proceso,
               "cobrador_apoyo": values.cobrador_apoyo === 1 ? 1 : 0,
               "cobradores": values.cobradores,
               "grua": values.grua === 1 ? 1 : 0,
               "motivo_promesa": "",
               "fecha_compromiso": "",
               "fecha_vencimiento": "",
               "dias_de_promesa": 5,
               "monto_prometido": 0,
               "monto_vencido": contratoActual.monto_adeudo,
               "comentarios": "",
               "pago_periodo": "",
               "monto_por_cubrir": 0,
               "registro_concluido": 1,
               "promesa_status_id": "66498F01-9033-4BE1-9353-EE0FD4217120"
            }
         }
      }

      await CobranzaService.createUpsertCobranzaPromesaPago(data)
         .then(res => {
            props.handleNextSteps(formularioActual);
            setLoading(false)
            toast.success(message("¡Operación exitosa!", res.data.mensaje));
            handleUpdate()
         }).catch(e => {
            console.log(e)
            setLoading(false)
            props.handleNextSteps(false);
            if (e.response !== undefined)
               toast.error(message("¡Error!", e.response.data.message));
            else
               toast.error(message("¡Error!", e.message));
         });
   }

   //VALIDACIONES DE FORMULARIOS
   {/* tipo promesa: garantia de pago */ }
   const validateFormGarantiaPago = Yup.object({
      tipo_promesa: Yup.string().required("El campo es requerido."),
      folio: Yup.string().required("El campo es requerido."),
      proceso: Yup.string().required("El campo es requerido."),
      cobrador_apoyo: Yup.string().required("El campo es requerido."),
      cobradores: Yup.string().required("El campo es requerido."),
      grua: Yup.string().required("El campo es requerido."),
      fecha_vencimiento: Yup.string().required("El campo es requerido."),
      dias_de_promesa: Yup.string().required("El campo es requerido."),
      monto_vencido: Yup.string().required("El campo es requerido."),
      pago_periodo: Yup.string().required("El campo es requerido."),
      total: Yup.string().required("El campo es requerido."),
   });
   {/* tipo promesa: promesa de pago */ }
   const validateFormPromesaPago = Yup.object({
      tipo_promesa: Yup.string().required("El campo es requerido."),
      motivo_promesa: Yup.string().required("El campo es requerido."),
      fecha_compromiso: Yup.string().required("El campo es requerido."),
      horarioCompromiso: Yup.string().required("El campo es requerido."),
      monto_prometido: Yup.string().required("El campo es requerido."),
      monto_vencido: Yup.string().required("El campo es requerido."),
      comentarios: Yup.string().required("El campo es requerido."),
   });
   {/* tipo promesa: promesa de entrega definitiva */ }
   const validateFormPromesaEntregaDefinitica = Yup.object({
      tipo_promesa: Yup.string().required("El campo es requerido."),
      folio: Yup.string().required("El campo es requerido."),
      proceso: Yup.string().required("El campo es requerido."),
      cobrador_apoyo: Yup.string().required("El campo es requerido."),
      cobradores: Yup.string().required("El campo es requerido."),
      grua: Yup.string().required("El campo es requerido."),
   });

   return (
      <>
         <Toaster
            position="top-right"
            toastOptions={{
               success: {
                  style: {
                     background: '#47a066',
                     color: '#FFFF',
                     borderLeft: '10px solid #2f7246'
                  },
               },
               error: {
                  style: {
                     background: '#d53f3f',
                     color: '#FFFF',
                     borderLeft: '10px solid #ac241a'
                  },
               },
            }}
         />
         {
            loading &&
            <Modal isOpen={loading} color='transparent'><Spinner message={""} /></Modal>
         }
         { show && (
         <div className="row wrapper-vehiculo">
            <div className="col col-12">
               <div className="row mx-md-4 my-4">
                  <div className="col-12 px-md-3">
                     <div className="p-4 white-wrapper mb-2">
                        <div
                           className="row mt-2 px-4"
                           data-bs-toggle="collapse"
                           href="#collapsePromesaPago"
                           role="button"
                           aria-expanded="false"
                           aria-controls="collapseRegistroTramite"
                           onClick={() => setCollpsableGrua(!collpsableGrua)}
                        >
                           <div className="col-11">
                              <h6>
                                 <span className={`semaforo semaforo-`} ></span>{" "}
                                 <strong>Promesa de Pago</strong>
                              </h6>
                           </div>
                           <div className="col-1 col-md-1 collapsable-icon" style={{ textAlign: "right" }} >
                              <i className={ !collpsableGrua ? "icon-blue bx bx-chevron-down x2" : "icon-blue bx bx-chevron-up x2" }></i>
                           </div>
                        </div>
                        <div className="collapse" id="collapsePromesaPago">
                           <div className="separator"></div>
                           <div className="row mt-2 px-4">
                              {/* registro de promesa */}
                              {/* tipo promesa: garantia de pago */}
                              {formularioActual === 0 && (
                                 <Formik innerRef={refFormik}
                                    enableReinitialize={true}
                                    initialValues={datosGarantiaPago}
                                    validationSchema={validateFormGarantiaPago}
                                    onSubmit={(values) => {
                                       handleGuardar(values);
                                    }}
                                 >
                                    {({ isValid, values, setFieldValue }) => (
                                       <Form>
                                          <div className="row">
                                             <div className="col-sm-12 col-md-3">
                                                <p className="text-base"><strong>Detalle de promesa de pago</strong>
                                                </p>
                                             </div>
                                             <div className="col-sm-12 col-md-3">
                                                <a href="#" onClick={(e) => { handleNextForm(e) }}>Da click aquí para ver las variaciones*</a>
                                             </div>
                                             <div className="col-sm-12 col-md-6 text-end">
                                                <button className='btn btn-gray'>
                                                   <i className="icon-light fa fa-file "></i> Subir documento
                                                </button>
                                                <button id="AT_btnGuardar" className='btn mx-2' disabled={!values.registro_concluido || !isValid} type='submit'>
                                                   Guardar
                                                </button>
                                             </div>
                                          </div>
                                          <div className='my-2 mt-4'>
                                             <div className="mb-3 row">
                                                <div className="col-sm-12 col-md-6 col-lg-3">
                                                   <TextField label="Tipo de promesa" disabled name="tipo_promesa" type="text" holder="" />
                                                </div>
                                                <div className="col-sm-12 col-md-6 col-lg-3">
                                                   <TextField label="Folio" name="folio" type="text" holder={values.folio === "Por definir" ? "" : values.folio} disabled={true}/>
                                                </div>
                                                <div className="col-sm-12 col-md-6 col-lg-3">
                                                   <TextField label="Proceso" name="proceso" type="text" holder="" />
                                                </div>
                                                <div className="col-sm-12 col-md-6 col-lg-3">
                                                   <SelectField id="cobrador_apoyo" label="Cobrador de apoyo" name="cobrador_apoyo" items={dataCobradorApoyo}
                                                      onChange={(event) => {
                                                         if (event.value == "") {
                                                            setFieldValue("cobrador_apoyo", "");
                                                            // setCobradorApoyoSeleccionado(0);
                                                         }
                                                         else {
                                                            // setCobradorApoyoSeleccionado(event.value);
                                                            setFieldValue("cobrador_apoyo", event.value);
                                                         }
                                                      }} />
                                                </div>
                                             </div>
                                             <div className="mb-3 row">
                                                <div className="col-sm-12 col-md-6 col-lg-3">
                                                   <SelectField id="cobradores" label="Cobradores (en caso de aplicar)" name="cobradores" items={dataCobrador}
                                                      onChange={(event) => {
                                                         if (event.value == "") {
                                                            setFieldValue("cobradores", "");
                                                            // setCobradorSeleccionado(0);
                                                         }
                                                         else {
                                                            // setCobradorSeleccionado(event.value);
                                                            setFieldValue("cobradores", event.label);
                                                         }
                                                      }} />
                                                </div>
                                                <div className="col-sm-12 col-md-6 col-lg-3">
                                                   <SelectField id="grua" label="Grua" name="grua" items={dataGrua}
                                                      onChange={(event) => {
                                                         if (event.value == "") {
                                                            setFieldValue("grua", "");
                                                            // setGruaSeleccionado(0);
                                                         }
                                                         else {
                                                            // setGruaSeleccionado(event.value);
                                                            setFieldValue("grua", event.value);
                                                         }
                                                      }} />
                                                </div>
                                             </div>
                                          </div>
                                          <p className="text-base"><strong>Fecha de promesa</strong></p>
                                          <div className='my-2 mt-4'>
                                             <div className="mb-3 row">
                                                <div className="col-sm-12 col-md-6 col-lg-3">
                                                   {/* <TextField label="Fecha de vencimiento" name="fechaVencimiento" type="text" holder="" /> */}
                                                   {/* <TextField id="fechaVencimiento" label="Fecha de vencimiento" name="fechaVencimiento" type="date" min={new Date(fechaInicio)} holder="Escribe" onChange={(event) => { setFieldValue("fechaVencimiento", event.target.value); setFieldValue("total", calcularTotal(event.target.value)); setFieldValue("diasPromesa", getDiasPromesa(event.target.value)); }} /> */}
                                                   <TextField id="fecha_vencimiento" label="Fecha de vencimiento" name="fecha_vencimiento" type="date" min={new Date(fechaInicio)} holder={ values.fecha_vencimiento === "" ? "Escribe" : values.fecha_vencimiento} onChange={(event) => { setFieldValue("fecha_vencimiento", event.target.value); setFieldValue("total", calcularTotal(event.target.value)); setFieldValue("dias_de_promesa", getDiasPromesa(event.target.value)); }} />
                                                   {/* <input id="fecha_vencimiento" label="Fecha de vencimiento" name="fecha_vencimiento" type="date" min={new Date(fechaInicio)} holder="Escribe" onChange={(event) => { setFieldValue("fecha_vencimiento", event.target.value); setFieldValue("total", calcularTotal(event.target.value)); setFieldValue("dias_de_promesa", getDiasPromesa(event.target.value)); }} /> */}
                                                </div>
                                                <div className="col-sm-12 col-md-6 col-lg-3">
                                                   <TextField label="Días de promesa" name="dias_de_promesa" type="text" holder="" readOnly={true} />
                                                </div>
                                             </div>
                                          </div>
                                          <p className="text-base"><strong>Monto por cubrir</strong></p>
                                          <div className='my-2 mt-4'>
                                             <div className="mb-3 row">
                                                <div className="col-sm-12 col-md-6 col-lg-3 d-flex align-items-center">
                                                   <TextField label="Monto vencido" name="monto_vencido" type="text" holder="" readOnly={true} />
                                                   <div className="mt-3 ms-3">
                                                      <i className="icon-light fa fa-plus ms-2" style={{ color: '#1351A5' }}></i>
                                                   </div>
                                                </div>
                                                <div className="col-sm-12 col-md-6 col-lg-3 d-flex align-items-center">
                                                   <TextField label="Pago de periodo" name="pago_periodo" type="text" holder="" readOnly={true} />
                                                   <div className="mt-3 ms-3">
                                                      <i className="icon-light fa fa-equals ms-2" style={{ color: '#1351A5' }}></i>
                                                   </div>
                                                </div>
                                                <div className="col-sm-12 col-md-6 col-lg-3">
                                                   <TextField label="Total" name="total" type="text" holder="" readOnly={true} />
                                                </div>
                                             </div>
                                             <div className="mb-3 row">
                                                <div className="col-sm-12 col-md-6 col-lg-3">
                                                   <Field className="form-check-input" type="checkbox" name="registro_concluido" onClick={(event) => { setFieldValue("concluido", event.target.value); }} />
                                                   <label className="form-check-label" style={{ 'marginLeft': '10px' }}>Registro concluido</label>
                                                </div>
                                                <div className="col-sm-12 col-md-6 col-lg-9 text-end">
                                                   <label>Inicio de proceso: {values.fechaInicioProceso}</label> | <label>Fin de proceso: {values.fechaFinProceso}</label>
                                                </div>
                                             </div>
                                          </div>
                                       </Form>
                                    )}
                                 </Formik >
                              )}
                              {/* tipo promesa: promesa de pago */}
                              {formularioActual === 1 && (
                                 <Formik innerRef={refFormik}
                                    enableReinitialize={true}
                                    initialValues={datosPromesaPago}
                                    validationSchema={validateFormPromesaPago}
                                    onSubmit={(values) => {
                                       handleGuardar(values);
                                    }}
                                 >
                                    {({ isValid, values, setFieldValue }) => (
                                       <Form>
                                          <div className="row">
                                             <div className="col-sm-12 col-md-3">
                                                <p className="text-base"><strong>Detalle de promesa de pago</strong>
                                                </p>
                                             </div>
                                             <div className="col-sm-12 col-md-3">
                                                <a href="#" onClick={(e) => { handleNextForm(e) }}>Da click aquí para ver las variaciones*</a>
                                             </div>
                                             <div className="col-sm-12 col-md-6 text-end">
                                                <button className='btn btn-gray'>
                                                   <i className="icon-light fa fa-file "></i> Subir documento
                                                </button>
                                                <button id="AT_btnGuardar" className='btn mx-2' disabled={!values.registro_concluido || !isValid} type='submit'>
                                                   Guardar
                                                </button>
                                             </div>
                                          </div>
                                          <div className='my-2 mt-4'>
                                             <div className="mb-3 row">
                                                <div className="col-sm-12 col-md-6 col-lg-3">
                                                   <TextField disabled label="Tipo de promesa" name="tipo_promesa" type="text" holder="" />
                                                </div>
                                                <div className="col-sm-12 col-md-6 col-lg-3">
                                                   {/* <TextField disabled label="Motivo promesa" name="motivoPromesa" type="text" holder="" /> */}
                                                   <SelectField id="motivo_promesa" label="Motivo promesa" name="motivo_promesa" items={dataMotivoPromesa}
                                                      onChange={(event) => {
                                                         if (event.value == "") {
                                                            setFieldValue("motivo_promesa", "");
                                                            // setMotivoPromesaSeleccionada(0);
                                                         }
                                                         else {
                                                            // setMotivoPromesaSeleccionada(event.value);
                                                            setFieldValue("motivo_promesa", event.label);
                                                         }
                                                      }} />
                                                </div>
                                                <div className="col-sm-12 col-md-6 col-lg-3">
                                                   {/* <TextField label="Fecha compromiso" name="fechaCompromiso" type="text" holder="" /> */}
                                                   <TextField id="fecha_compromiso" label="Fecha compromiso" name="fecha_compromiso" type="date" holder={ values.fecha_compromiso === "" ? "Escribe" : values.fecha_compromiso} onChange={(event) => { setFieldValue("fecha_compromiso", event.target.value); }} />
                                                </div>
                                                <div className="col-sm-12 col-md-6 col-lg-3">
                                                   {/* <TextField label="Horario compromiso" name="horarioCompromiso" type="text" holder="" /> */}
                                                   <TextField id="horarioCompromiso" label="Horario compromiso" name="horarioCompromiso" type="time" holder={ values.horarioCompromiso === "" ? "Escribe" : values.horarioCompromiso} onChange={(event) => { setFieldValue("horarioCompromiso", event.target.value); }} />
                                                </div>
                                             </div>
                                             <div className="mb-3 row">
                                                <div className="col-sm-12 col-md-6 col-lg-3">
                                                   <TextField label="Monto prometido" name="monto_prometido" type="text" holder="" />
                                                </div>
                                                <div className="col-sm-12 col-md-6 col-lg-3">
                                                   <TextField label="Monto vencido" name="monto_vencido" type="text" holder="" />
                                                </div>
                                             </div>
                                          </div>
                                          <div className='my-2 mt-4'>
                                             <div className="mb-3 row">
                                                <div className="col-sm-12 col-md-6 col-lg-6">
                                                   <TextAreaField label="Comentarios promesa de pago" className="form-control col-12" rows="4" name="comentarios" type="text" holder="" />
                                                </div>
                                             </div>
                                          </div>
                                          <div className='my-2 mt-4'>
                                             <div className="mb-3 row">
                                                <div className="col-sm-12 col-md-6 col-lg-3">
                                                   <Field className="form-check-input" type="checkbox" name="registro_concluido" onClick={(event) => { setFieldValue("concluido", event.target.value); }} />
                                                   <label className="form-check-label" style={{ 'marginLeft': '10px' }}>Registro concluido</label>
                                                </div>
                                                <div className="col-sm-12 col-md-6 col-lg-9 text-end">
                                                   <label>Inicio de proceso: {values.fechaInicioProceso}</label> | <label>Fin de proceso: {values.fechaFinProceso}</label>
                                                </div>
                                             </div>
                                          </div>
                                       </Form>
                                    )}
                                 </Formik >
                              )}
                              {/* tipo promesa: promesa de entrega definitiva */}
                              {formularioActual === 2 && (
                                 <Formik innerRef={refFormik}
                                    enableReinitialize={true}
                                    initialValues={datosPromesaEntregaDefinitiva}
                                    validationSchema={validateFormPromesaEntregaDefinitica}
                                    onSubmit={(values) => {
                                       handleGuardar(values);
                                    }}
                                 >
                                    {({ isValid, values, setFieldValue }) => (
                                       <Form>
                                          <div className="row">
                                             <div className="col-sm-12 col-md-3">
                                                <p className="text-base"><strong>Detalle de promesa de pago</strong>
                                                </p>
                                             </div>
                                             <div className="col-sm-12 col-md-3">
                                                <a href="#" onClick={(e) => { handleNextForm(e) }}>Da click aquí para ver las variaciones*</a>
                                             </div>
                                             <div className="col-sm-12 col-md-6 text-end">
                                                <button className='btn btn-gray'>
                                                   <i className="icon-light fa fa-file "></i> Subir documento
                                                </button>
                                                <button id="AT_btnGuardar" className='btn mx-2' disabled={!values.registro_concluido || !isValid} type='submit'>
                                                   Guardar
                                                </button>
                                             </div>
                                          </div>
                                          <div className='my-2 mt-4'>
                                             <div className="mb-3 row">
                                                <div className="col-sm-12 col-md-6 col-lg-3">
                                                   <TextField disabled label="Tipo de promesa" name="tipo_promesa" type="text" holder="" />
                                                </div>
                                                <div className="col-sm-12 col-md-6 col-lg-3">
                                                   <TextField label="Folio" name="folio" type="text"  holder={values.folio === "Por definir" ? "" : values.folio} disabled={true} />
                                                </div>
                                                <div className="col-sm-12 col-md-6 col-lg-3">
                                                   <TextField label="Proceso" name="proceso" type="text" holder="" />
                                                </div>
                                                <div className="col-sm-12 col-md-6 col-lg-3">
                                                   <SelectField id="cobrador_apoyo" label="Cobrador de apoyo" name="cobrador_apoyo" items={dataCobradorApoyo}
                                                      onChange={(event) => {
                                                         if (event.value == "") {
                                                            setFieldValue("cobrador_apoyo", "");
                                                            // setCobradorApoyoSeleccionado(0);
                                                         }
                                                         else {
                                                            // setCobradorApoyoSeleccionado(event.value);
                                                            setFieldValue("cobrador_apoyo", event.value);
                                                         }
                                                   }} />
                                                </div>
                                             </div>
                                             <div className="mb-3 row">
                                                <div className="col-sm-12 col-md-6 col-lg-3">
                                                   <SelectField id="cobradores" label="Cobradores (en caso de aplicar)" name="cobradores" items={dataCobrador}
                                                      onChange={(event) => {
                                                         if (event.value == "") {
                                                            setFieldValue("cobradores", "");
                                                            // setCobradorSeleccionado(0);
                                                         }
                                                         else {
                                                            // setCobradorSeleccionado(event.value);
                                                            setFieldValue("cobradores", event.label);
                                                         }
                                                      }} />
                                                </div>
                                                <div className="col-sm-12 col-md-6 col-lg-3">
                                                <SelectField id="grua" label="Grua" name="grua" items={dataGrua}
                                                      onChange={(event) => {
                                                         if (event.value == "") {
                                                            setFieldValue("grua", "");
                                                            // setGruaSeleccionado(0);
                                                         }
                                                         else {
                                                            // setGruaSeleccionado(event.value);
                                                            setFieldValue("grua", event.value);
                                                         }
                                                      }} />
                                                </div>
                                             </div>
                                          </div>
                                          <div className='my-2 mt-4'>
                                             <div className="mb-3 row">
                                                <div className="col-sm-12 col-md-6 col-lg-3">
                                                   <Field className="form-check-input" type="checkbox" name="registro_concluido" onClick={(event) => { setFieldValue("concluido", event.target.value); }} />
                                                   <label className="form-check-label" style={{ 'marginLeft': '10px' }}>Registro concluido</label>
                                                </div>
                                                <div className="col-sm-12 col-md-6 col-lg-9 text-end">
                                                   <label>Inicio de proceso: {values.fechaInicioProceso}</label> | <label>Fin de proceso: {values.fechaFinProceso}</label>
                                                </div>
                                             </div>
                                          </div>
                                       </Form>
                                    )}
                                 </Formik >
                              )}
                           </div>
                        </div>
                     </div>
                  </div>
               </div>
            </div>
         </div>
         )}
      </>
   )
})

export default RegistroPromesaCards