
import { useEffect, useState, useRef } from "react";
import { useNavigate } from "react-router-dom";
import CobranzaService from "../../Services/Cobranza/Cobranza";
import { useDispatch, useSelector } from 'react-redux'
import { setSeguimientoContrato } from "../../Redux/Slice/navigateSliceSeguimientoTelefonico";
import { id } from "date-fns/locale";

const SeguimientoTelefonicoHook = () => {
    const dispatch = useDispatch()

    const userName = sessionStorage.getItem('usuario')
    const userId = sessionStorage.getItem('userId').trim()

    let allContratos
    

    const [percentage, setPercentage] = useState(0)
    const [rangoFechas, setRangoFechas] = useState(0)
    const [generalSearch, setGeneralSearch] = useState("")
    const [contractData, setContractData] = useState([])
    const [uniqueIds, setUniqueIds] = useState([])

    const [active7, setActive7] = useState(false)
    const [active15, setActive15] = useState(false)
    const [firstProgressVigente, setFirstProgressVigente] = useState({
        porcentajeActivos: 0,
        sumaTotalDinero: 0,
        idsDiferentes: 0
    })
    const [firstProgressCumplidas, setFirstProgressCumplidas] = useState({
        porcentajeActivos: 0,
        sumaTotalDinero: 0,
        idsDiferentes: 0
    })
    const [secondProgressVigentes, setSecondProgressVigentes] = useState({
        porcentajeActivos: 0
    })
    const [secondProgressCumplidas, setSecondProgressCumplidas] = useState({
        porcentajeActivos: 0,
    })

    const [totalContratos, setTotalContratos] = useState('0')
    const [totalMontoVencido, setTotalMontoVencido] = useState('0')
    const [totalMontoCobrado, setTotalMontoCobrado] = useState('0')

    const [allPromesas, setAllPromesas] = useState([])

    const [loading, setLoading] = useState(false)
    const [modalMessage, setModalMessage] = useState({ isOpen: false, type: 1, title: '', message: '', data: [], authorizationType: 2, Origin: "Listado" });

    //Dropdown
    const [isOpen, setIsOpen] = useState(false)
    const [isVisible, SetIsVisible] = useState(true)

    const dropdownRef = useRef(null)


    const toggleDropdown = () => {
        setIsOpen(!isOpen)
    }

    const toggleVisible = (visible) => {
        SetIsVisible(visible)
        setIsOpen(false)
    }

    // Volver estas funciones una sola, que active y que guarde los datos secundarios en variable seundaria a enviar.
    const toggleActive7 = () => {

        setActive15(false)
        const vigente = analizarDatos(allPromesas, 7, "Vigente")
        const cumplidas = analizarDatos(allPromesas, 7, "Cumplidas")

        setSecondProgressVigentes(vigente)
        setSecondProgressCumplidas(cumplidas)
        setActive7(!active7)
    }

    const toggleActive15 = () => {
        setActive7(false)
        const vigente = analizarDatos(allPromesas, 15, "Vigente")
        const cumplidas = analizarDatos(allPromesas, 15, "Cumplida")

        setSecondProgressVigentes(vigente)
        setSecondProgressCumplidas(cumplidas)
        setActive15(!active15)
    }

    function analizarDatos(datos, dias, estado) {

        // Función para obtener la fecha límite
        const obtenerFechaLimite = () => {
            const hoy = new Date();
            const fechaLimite = new Date(hoy);
            fechaLimite.setDate(hoy.getDate() - dias); // Resta los días especificados
            return fechaLimite;
        };

        const fechaLimite = obtenerFechaLimite();

        // Filtrar datos según la fecha
        const datosFiltrados = datos.filter(obj => new Date(obj.fecha_compromiso) <= fechaLimite);
        const total = datosFiltrados.length;

        // Suma total de dinero
        const activos = datosFiltrados.filter(obj => obj.promesa_status_nombre === estado);
        const sumaTotalDinero = activos.length > 0 ?
            activos.reduce((total, obj) => total + obj.monto_recuperado, 0) : 0

        // Número de IDs diferentes
        const idsDiferentes = activos.length > 0 ? new Set(activos.map(obj => obj.contrato_id)).size : 0

        // Número de estado activo y cálculo del porcentaje
        const porcentajeActivos = total > 0 ? (activos.length / total) * 100 : 0;

        return {
            sumaTotalDinero,
            idsDiferentes,
            porcentajeActivos: porcentajeActivos.toFixed(2)
        };
    }
    //////////////////////////////

    const closeDropdown = (e) => {
        if (dropdownRef.current && !dropdownRef.current.contains(e.target)) {
            setIsOpen(false)
        }
    }

    useEffect(() => {
        document.addEventListener('mousedown', closeDropdown)
        return () => document.removeEventListener('mousedown', closeDropdown)
    }, [])


    useEffect(() => {
        GetAllData()
    }, [])

    useEffect(() => {
        const filteredData = contractData.filter(item => {
            const nameMatches = generalSearch !== '' && (item.contrato_id && item.contrato_id.toLowerCase().includes(generalSearch.toLowerCase()));

            return (
                nameMatches
            )
        })

        const uniqueIds = [...new Set(filteredData.map(item => item.nivel_cobranza))];

        setUniqueIds(uniqueIds)
    }, [generalSearch])

    const GetAllData = async () => {
        await obtenerRangodeFechas(new Date())
        await handleGetSeguimientoContrato()
        await handleGetPromesasdePago()
    }

    const handleGetSeguimientoContrato = async () => {
        setLoading(true);
        const data = {
            "verb": null,
            "id": null,
            "field": null,
            "value": null
        }
        await CobranzaService.getAllEjecutivosWithFullDetail(data)
            .then(res => {
                const userContracts = res.data.responseData.find(user => user.userid.toLowerCase() === userId.toLowerCase())
                
                if (userContracts.contratos){
                    allContratos = userContracts.contratos.map(obj => obj.contrato_id)
                    
                    let contratosFiltrador = userContracts.contratos.filter(item => item.cartera_vencida === 0)
                    let length = userContracts.contratos.length
                    let percentage = contratosFiltrador.length > 0 ? (contratosFiltrador.length / length) : 0
                    
                    setContractData(userContracts.contratos)
                    setTotalContratos(length)
                    setPercentage(percentage)
                }
                
                // const rangoFechasFormat = formatearRangoDeFechas(userContracts.rangoFechas)
                // setRangoFechas(rangoFechasFormat)
                setLoading(false);

            }).catch(e => {
                setLoading(false);
            })
    }


    // function formatearRangoDeFechas(fechaStr) {
    //     // Crear un array con las fechas de inicio y fin
    //     const [fechaInicio, fechaFin] = fechaStr.split(' - ');

    //     // Función para convertir la fecha a formato "15 agosto"
    //     function convertirFecha(fecha) {
    //         const [dia, mes, anio] = fecha.split('-');
    //         const fechaObj = new Date(anio, mes - 1, dia);
    //         const opciones = { day: 'numeric', month: 'long' };  // Obtener día y mes
    //         return fechaObj.toLocaleDateString('es-ES', opciones);  // Utilizamos la localización 'es-ES' para que el mes esté en español
    //     }

    //     // Convertir las fechas al formato deseado
    //     const fechaInicioFormateada = convertirFecha(fechaInicio);
    //     const fechaFinFormateada = convertirFecha(fechaFin);

    //     // Concatenar las fechas formateadas
    //     return `${fechaInicioFormateada} al ${fechaFinFormateada}`;
    // }
    
    function obtenerRangodeFechas(fecha) {
      const meses = [
        'enero', 'febrero', 'marzo', 'abril', 'mayo', 'junio', 'julio', 'agosto', 'septiembre', 'octubre', 'noviembre', 'diciembre'
      ];
      
      const fechaObj = new Date(fecha)
      const mes = fechaObj.getMonth()
      const anio = fechaObj.getFullYear()
      const dia = fechaObj.getDate(); 
    
      let fechaInicio, fechaFin
      
      if (dia <= 15) {
        // Si es la primera quincena
        fechaInicio = new Date(anio, mes, 1); // Primer día del mes
        fechaFin = new Date(anio, mes, 15); // 15 de ese mes
      } else {
        // Si es la segunda quincena
        fechaInicio = new Date(anio, mes, 16); // 16 de ese mes
        fechaFin = new Date(anio, mes + 1, 0); // Último día del mes (se usa mes+1 para obtener el último día del mes actual)
      }
    
      const formatearFecha = (fecha) => {
        const dia = fecha.getDate();
        const mes = meses[fecha.getMonth()];
        return `${dia} de ${mes}`;
      };

    //   const inicio = formatearFecha(fechaInicio);
      const inicio = fechaInicio.getDate()
      const fin = formatearFecha(fechaFin);
    
      let fechaFormat = `${inicio} al ${fin}`;
      setRangoFechas(fechaFormat)
    }

    const handleGetPromesasdePago = async () => {
        setLoading(true);


        await CobranzaService.getAllPromesasDePago()
            .then(res => {

                const promesasFiltradas = res.data.filter(obj => allContratos.includes(obj.contrato_id))

                const vigente = analizarDatos(promesasFiltradas, 0, "Vigente")
                const cumplidas = analizarDatos(promesasFiltradas, 0, "Cumplida")

                const suma = promesasFiltradas.reduce((total, obj) => {
                    return {
                        montoVencido: total.montoVencido + obj.monto_vencido,
                        montoCobrado: total.montoCobrado + obj.monto_recuperado
                    };
                }, { montoVencido: 0, montoCobrado: 0 }); // Inicializamos el acumulador

                setAllPromesas(promesasFiltradas)
                setTotalMontoCobrado(suma.montoCobrado)
                setTotalMontoVencido(suma.montoVencido)
                setFirstProgressVigente(vigente)
                setFirstProgressCumplidas(cumplidas)

                setLoading(false);

            }).catch(e => {
                setLoading(false);
            })
    }

    return {
        userName,
        dropdownRef,
        toggleDropdown,
        toggleVisible,
        percentage,
        active7,
        toggleActive7,
        active15,
        toggleActive15,
        isOpen,
        isVisible,
        loading,
        totalContratos,
        rangoFechas,
        firstProgressVigente,
        firstProgressCumplidas,
        secondProgressVigentes,
        secondProgressCumplidas,
        totalMontoCobrado,
        totalMontoVencido,
        totalContratos,
        setGeneralSearch,
        uniqueIds
    }
}
export default SeguimientoTelefonicoHook