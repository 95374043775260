import { createSlice } from '@reduxjs/toolkit'

const initialState = {
   usuario: {
      userid: "",
      nombrecobrador: "",
      numerocontratos: "",
      contratos: []
   }
}
export const ReasignarCargaNivelDosSlice = createSlice({
   name: 'ReasignarCargaNivelDosSlice',
   initialState,
   reducers: {
      setUsuario: (state, action) => {
         return { ...state, usuario: action.payload }
      },
      setLimpiarUsuario: (state, action) => {
         return { ...state,
            usuario: {
               userid: "",
               nombrecobrador: "",
               numerocontratos: "",
               contratos: []
            }
         }
      }
   },
})

export const { setUsuario, setLimpiarUsuario } = ReasignarCargaNivelDosSlice.actions

export default ReasignarCargaNivelDosSlice.reducer