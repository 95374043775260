import React, { useEffect, useRef, useState } from 'react';
import { useLocation, useNavigate, useParams } from 'react-router-dom';
import { toast, Toaster } from 'react-hot-toast';
import { message } from '../../../../Utils/functions'
import { Field, Form, Formik } from 'formik'
import Modal from '../../../../Components/Modal/Modal';
import Spinner from '../../../../Components/Loadig';
import AuthLogin from '../../../../Utils/AuthLogin';
import { SelectField } from '../../../../Components/SelectField';
import { TextAreaField } from '../../../../Components/TextAreaField';
import { TextField } from '../../../../Components/TextField';
import LiberacionUnidadService from '../../../../Services/Inventario/Valuacion/liberacionUnidadService';
import Llamada from '../../../../Services/Cartera/Llamadas';

const auth = new AuthLogin();
const CardLiberacionDeUnidad = ({ generalId }) => {
    const { refFormik } = useRef();
    const { id } = useParams();
    const { state } = useLocation();


    let url = auth.UrlDataDocs();
    const [loading, setLoading] = useState(false);
    const [boolSelect, setBoolSelect] = useState(false);
    const [collpsableGrua, setCollpsableGrua] = useState(false);
    const opcionesBool = [{ value: true, label: 'Si' }, { value: false, label: 'No' }];

    let initial = {
        siniestroLiberacionUnidadId: "",
        siniestroId: "",
        tipoUbicacion: null,
        nombreUbicacion: null,
        totalAdeudo: 0,
        calleNumero: null,
        codigoPostal: null,
        paisId: "",
        pais: null,
        edoMexicoId: 0,
        estado: null,
        municipioId: 0,
        municipio: null,
        coloniaId: 0,
        colonia: null,
        requiereGrua: false,
        comentarios: null,
        oficioLiberado: false,
        unidadCorralon: false,
        nombreCorralon: null,
        corralonCalleNumero: null,
        corralonCodigoPostal: null,
        corralonPaisId: "",
        corralonPais: null,
        corralonEdoMexicoId: 0,
        corralonEstado: null,
        corralonMunicipioId: 0,
        corralonMunicipio: null,
        corralonColoniaId: 0,
        corralonColonia: null,
        concluido: false,
        fechaInicio: "",
        fechaFin: null,
        subido: false,
        documentoId: null,
        idFolder: null,
        nombreDocumento: null
    }

    const [initialValue, setInitialValue] = useState(initial);
    const [estados, setEstados] = useState([]);
    const [municipios, setMunicipios] = useState([]);
    const [colonias, setColonias] = useState([]);

    const [estadosDos, setEstadosDos] = useState([]);
    const [municipiosDos, setMunicipiosDos] = useState([]);
    const [coloniasDos, setColoniasDos] = useState([]);

    useEffect(() => {
        getData()
    }, []);

    useEffect(() => {
        getEstados()
        getEstadosDos()
        // getMunicipios()
        // getColonias()
    }, [])

    const getData = async () => {
        await LiberacionUnidadService.getLiberacionunidadSiniestro(id)
            .then(resp => {
                if (resp.data.corralonPaisId === "00000000-0000-0000-0000-000000000000") {
                    resp.data.corralonPaisId = ""
                }
                if (resp.data.paisId === "00000000-0000-0000-0000-000000000000") {
                    resp.data.paisId = ""
                }

                if (resp.data.edoMexicoId !== 0) {
                    getMunicipios(resp.data.edoMexicoId);
                }
                if (resp.data.corralonEdoMexicoId !== 0) {
                    getMunicipiosDos(resp.data.corralonEdoMexicoId);
                }
                if (resp.data.municipioId !== 0) {
                    getColonias(resp.data.municipioId);
                }
                if (resp.data.corralonMunicipioId !== 0) {
                    getColoniasDos(resp.data.corralonMunicipioId);
                }

                setInitialValue(resp.data)

            })
            .catch(err => {
                toast.error(message('Error al obtener los datos'));
            });
    };


    const submitForm = async (values) => {
        setLoading(true)
        values.paso = 2
        values.porcentaje = 33
        values.pasoSubStatus = 3
        values.procesoId = state.procesoId

        await LiberacionUnidadService.updateLiberacionUnidadSiniestro(values)
            .then(res => {
                toast.success(message('Registro actualizado correctamente'));
                getData()
            })
            .catch(error => {
                console.log(error)
            })
            .finally(() => {
                setLoading(false)
            })
    }

    const getEstados = async () => {
        await Llamada.GetEstadoMx().then(res => {
            let resData = res?.data.data
            let Estados = []

            resData.map(item => {
                Estados.push({
                    value: item.edoMexicoId,
                    label: item.estado
                });
            });
            setEstados(Estados);
        })
    }

    const getMunicipios = async (value) => {
        if (value === undefined)
            return;
        await Llamada.GetMunicipio(value).then(res => {
            let resData = res?.data.data
            let Municipios = []
            resData.map(item => {
                Municipios.push({
                    value: item.municipioId,
                    label: item.municipio
                });
            });
            setMunicipios(Municipios);
        })
    }

    const getColonias = async (value) => {
        if (value === undefined)
            return;
        await Llamada.GetColonia(value).then(res => {
            let resData = res?.data.data
            let Colonias = []
            resData.map(item => {
                Colonias.push({
                    value: item.coloniaId,
                    label: item.colonia
                });
            });
            setColonias(Colonias);
        })
    }

    const getEstadosDos = async () => {
        await Llamada.GetEstadoMx().then(res => {
            let resData = res?.data.data
            let Estados = []

            resData.map(item => {
                Estados.push({
                    value: item.edoMexicoId,
                    label: item.estado
                });
            });
            setEstadosDos(Estados);
        })
    }

    const getMunicipiosDos = async (value) => {
        if (value === undefined)
            return;
        await Llamada.GetMunicipio(value).then(res => {
            let resData = res?.data.data
            let Municipios = []
            resData.map(item => {
                Municipios.push({
                    value: item.municipioId,
                    label: item.municipio
                });
            });
            setMunicipiosDos(Municipios);
        })
    }

    const getColoniasDos = async (value) => {
        if (value === undefined)
            return;
        await Llamada.GetColonia(value).then(res => {
            let resData = res?.data.data
            let Colonias = []
            resData.map(item => {
                Colonias.push({
                    value: item.coloniaId,
                    label: item.colonia
                });
            });
            setColoniasDos(Colonias);
        })
    }


    return (
        <>
            <Modal isOpen={loading} color='transparent'><Spinner message={""} /></Modal>
            <Toaster
                position="top-right"
                toastOptions={{
                    success: {
                        style: {
                            background: '#47a066',
                            color: '#FFFF',
                            borderLeft: '10px solid #2f7246'
                        },
                    },
                    error: {
                        style: {
                            background: '#d53f3f',
                            color: '#FFFF',
                            borderLeft: '10px solid #ac241a'
                        },
                    },
                }}
            />

            <div className='d-flex flex-column background-grey' style={{ height: 'auto' }}>
                <div className='row mx-md-4 my-4' >
                    <div className="col-12 px-md-3">
                        <div className="p-4 white-wrapper mb-2">

                            <div className="row mt-2 px-4" data-bs-toggle="collapse" href="#collapseLiberacion" role="button" aria-expanded="false" aria-controls="collapseLiberacion" onClick={() => setCollpsableGrua(!collpsableGrua)}>
                                <div className="col-11">
                                    <h6><span className={`semaforo semaforo-${initialValue?.concluido ? "green" : "default"}`}></span> <strong>Liberacion de unidad</strong></h6>
                                </div>
                                <div className="col-1 col-md-1 collapsable-icon" style={{ 'textAlign': 'right' }}>
                                    <i className={!collpsableGrua ? "icon-blue bx bx-chevron-down x2" : "icon-blue bx bx-chevron-up x2"}></i>
                                </div>
                            </div>
                            <div className="collapse" id="collapseLiberacion">
                                <div className="separator"></div>
                                <Formik
                                    innerRef={refFormik}
                                    enableReinitialize={true}
                                    initialValues={initialValue}
                                    onSubmit={(values) => submitForm(values)}
                                >
                                    {({ isValid, values, setFieldValue, errors }) => (
                                        <Form>
                                            <div className="row mt-2 mb-2">
                                                <div className='col-4'>
                                                    <h6>
                                                        <strong>Ubicación de Unidads</strong>
                                                    </h6>
                                                </div>
                                                <div className="col-8">
                                                    <div className="row justify-content-end">
                                                        <div className="col-auto">
                                                            <button
                                                                className='btn'
                                                                disabled={initialValue?.concluido}
                                                                type='submit'
                                                            >
                                                                Guardar
                                                            </button>
                                                        </div>
                                                    </div>
                                                </div>
                                            </div>
                                            <div className="row mt-2 px-4">
                                                <div className="col-4">
                                                    <TextField label="Tipo de ubicacion" name="tipoUbicacion" type="text" holder="Escribe" onChange={(event) => {
                                                        setFieldValue("tipoUbicacion", event.target.value);
                                                    }} />
                                                </div>
                                                <div className="col-3">
                                                    <TextField label="Nombre de ubicacion" name="nombreUbicacion" type="text" holder="Escribe" onChange={(event) => {
                                                        setFieldValue("nombreUbicacion", event.target.value);
                                                    }} />
                                                </div>
                                                <div className="col-3">
                                                    <TextField label="Total adeudo" name="totalAdeudo" type="text" holder="Escribe" onChange={(event) => {
                                                        setFieldValue("totalAdeudo", event.target.value);
                                                    }} />
                                                </div>
                                            </div>

                                            <div className="row mt-2 px-4">
                                                <div className="col-3">
                                                    <TextField label="Calle y numero" name="calleNumero" type="text" holder="Escribe" onChange={(event) => {
                                                        setFieldValue("calleNumero", event.target.value);
                                                    }} />
                                                </div>
                                                <div className="col-2">
                                                    <TextField label="Codigo postal" name="codigoPostal" type="text" holder="Escribe" onChange={(event) => {
                                                        setFieldValue("codigoPostal", event.target.value);
                                                    }} />
                                                </div>
                                                {/* <div className="col-2">
                                                    <TextField label="Pais" name="paisId" type="text" holder="Escribe" onChange={(event) => {
                                                        setFieldValue("paisId", event.target.value);
                                                    }} />
                                                </div> */}
                                                <div className="col-2">

                                                    <SelectField id="edoMexicoId"
                                                        label="Estado"
                                                        name="edoMexicoId"
                                                        items={estados}
                                                        onChange={(event) => {
                                                            setFieldValue("edoMexicoId", event.value);
                                                            getMunicipios(event.value);
                                                        }} />

                                                </div>
                                                <div className="col-3">
                                                    <SelectField id="municipioId"
                                                        label="Municipio"
                                                        name="municipioId"
                                                        items={municipios}
                                                        onChange={(event) => {
                                                            setFieldValue("municipioId", event.value);
                                                            getColonias(event.value);
                                                        }} />
                                                </div>
                                            </div>
                                            <div className="row mt-2 px-4">
                                                <div className="col-3">
                                                    <SelectField
                                                        id=""
                                                        label="Colonia"
                                                        name="coloniaId"
                                                        items={colonias}
                                                        onChange={(event) => {
                                                            setFieldValue("coloniaId", event.value);
                                                        }}
                                                    />
                                                </div>
                                            </div>
                                            <div className="row mt-2 px-4">
                                                <div className="col-3">
                                                    <SelectField
                                                        id=""
                                                        label="Se requiere Grua?"
                                                        name="requiereGrua"
                                                        items={opcionesBool}
                                                        onChange={(event) => {
                                                            setFieldValue("requiereGrua", event.value);
                                                        }}
                                                    />
                                                </div>
                                            </div>
                                            <div className="row mt-2 px-4">
                                                <div className="col-3">
                                                    Subir documento
                                                </div>
                                            </div>

                                            <div className="row mt-2 px-4">
                                                <div className="col-6">
                                                    <TextAreaField
                                                        label="comentarios"
                                                        className="form-control col-12"
                                                        rows="3"
                                                        name="comentarios"
                                                        id="comentarios"
                                                        type="text"
                                                        holder="Escribe"
                                                    />
                                                </div>
                                            </div>

                                            <div className='row mt-2 px-4'>
                                                <div className="col-12 col-lg-6">
                                                    <Field className="form-check-input" disabled={initialValue?.oficioLiberado} type="checkbox" name="oficioLiberado" onClick={(event) => {
                                                        setFieldValue("oficioLiberado", event.target.value);
                                                    }} />
                                                    <label className="form-check-label" style={{ 'marginLeft': '10px' }}>Oficio de liberacion registrado en sistema</label>
                                                </div>
                                            </div>

                                            <div className="row mt-2 px-4">
                                                <div className="col-4">
                                                    <SelectField
                                                        disabled={initialValue?.concluido}
                                                        label="¿Unidad en Corralon?"
                                                        name="unidadCorralon"
                                                        items={opcionesBool}
                                                        onChange={(event) => {
                                                            setFieldValue("unidadCorralon", event.value);
                                                        }}
                                                    />
                                                </div>
                                                <div className="col-3">
                                                    <TextField label="Nombre del corralon" name="nombreCorralon" type="text" holder="Escribe" onChange={(event) => {
                                                        setFieldValue("nombreCorralon", event.target.value);
                                                    }} />
                                                </div>
                                            </div>

                                            <div className="row mt-2 px-4">
                                                <div className="col-3">
                                                    <TextField label="Calle y numero" name="corralonCalleNumero" type="text" holder="Escribe" onChange={(event) => {
                                                        setFieldValue("corralonCalleNumero", event.target.value);
                                                    }} />
                                                </div>
                                                <div className="col-2">
                                                    <TextField label="Codigo postal" name="corralonCodigoPostal" type="text" holder="Escribe" onChange={(event) => {
                                                        setFieldValue("corralonCodigoPostal", event.target.value);
                                                    }} />
                                                </div>
                                                {/* <div className="col-2">
                                                    <TextField label="Pais" name="corralonPaisId" type="text" holder="Escribe" onChange={(event) => {
                                                        setFieldValue("corralonPaisId", event.target.value);
                                                    }} />
                                                </div> */}
                                                <div className="col-2">
                                                    <SelectField id="corralonEdoMexicoId"
                                                        label="Estado"
                                                        name="corralonEdoMexicoId"
                                                        items={estadosDos}
                                                        onChange={(event) => {
                                                            setFieldValue("corralonEdoMexicoId", event.value);
                                                            getMunicipiosDos(event.value);
                                                        }} />
                                                </div>
                                                <div className="col-3">
                                                    <SelectField id="corralonMunicipioId"
                                                        label="Municipio"
                                                        name="corralonMunicipioId"
                                                        items={municipiosDos}
                                                        onChange={(event) => {
                                                            setFieldValue("corralonMunicipioId", event.value);
                                                            getColoniasDos(event.value);
                                                        }} />
                                                </div>
                                            </div>
                                            <div className="row mt-2 px-4">
                                                <div className="col-3">
                                                    <SelectField
                                                        id=""
                                                        label="Colonia"
                                                        name="corralonColoniaId"
                                                        items={coloniasDos}
                                                        onChange={(event) => {
                                                            setFieldValue("corralonColoniaId", event.value);
                                                        }}
                                                    />
                                                </div>
                                            </div>

                                            <div className='row mt-2 px-4'>
                                                <div className="col-12 col-lg-6">
                                                    <Field className="form-check-input" disabled={initialValue?.concluido} type="checkbox" name="concluido" onClick={(event) => {
                                                        setFieldValue("concluido", event.target.value);
                                                    }} />
                                                    <label className="form-check-label" style={{ 'marginLeft': '10px' }}>Unidad liberada</label>
                                                </div>
                                            </div>

                                        </Form>
                                    )}
                                </Formik>

                            </div>
                        </div>
                    </div>
                </div>
            </div>


        </>
    )
}
export default CardLiberacionDeUnidad