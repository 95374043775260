import { useEffect, useState } from 'react'
import { useNavigate, } from 'react-router';
import CobranzaService from '../../Services/Cobranza/Cobranza'
import { FormatFecha, formatearMonto } from '../../Utils/functions'

const SeguimientoCobranzaJuridico3Hook = () => {
  const navigate = useNavigate();

  const [name, setName] = useState("")
  const [pageRows, setpageRows] = useState(10)
  const [data, setData] = useState([])

  const userId = sessionStorage.getItem('userId').trim()
  const nivel_cobranza = "3"

  const columns = [
    { field: 'contrato_id', headerName: 'Contrato' },
    { field: 'periodo', headerName: 'Periodo' },
    { field: 'dias_vencido', headerName: 'Días Vencidos' },
    { field: 'saldo_credito', headerName: 'Monto Insoluto' },
    { field: 'monto_adeudo', headerName: 'Monto adeudo' },
    { field: 'fecha_ultimo_pago', headerName: 'Fecha última pago' },
    { field: 'fecha_ultima_clave', headerName: 'Hora última clave' },
    { field: 'dias_sin_consultar_clave', headerName: 'Días sin consultar' },
    { field: 'llamadas', headerName: 'Llamadas' },
    { field: 'ultima_actualizacion', headerName: 'Ult. Actualización' },
  ];

  const inicioObjeto = {
    value: "",
    label: "Seleccione"
  };

  const [pageCount, setPageCount] = useState(1);
  const [currentPage, setCurrentPage] = useState(1);

  const [currentData, setCurrentData] = useState(data)
  //Modal
  const [loading, setLoading] = useState(false);
  const [modalMessage, setModalMessage] = useState({ isOpen: false, type: 1, title: '', message: '', data: [], authorizationType: 2, Origin: "Listado" });

  //Select
  const [ejecutivoData, setEjecutivoData] = useState([])
  const [ejecutivo, setEjecutivo] = useState("")


  useEffect(() => {
    setCurrentPage(1);
  }, [])

  useEffect(() => {
    // Filtra los datos según los filtros seleccionados
    const filteredData = data.filter(item => {
      const nameMatches = name === '' || (item.contrato_id && item.contrato_id.toLowerCase().includes(name.toLowerCase()));

      return (
        nameMatches &&
        (ejecutivo === "" || item.userid.toLowerCase() === ejecutivo.toLowerCase())
      )
    })

    const indexOfLastItem = currentPage * pageRows
    const indexOfFirstItem = indexOfLastItem - pageRows
    const newData = filteredData.slice(indexOfFirstItem, indexOfLastItem)
    setCurrentData(newData)

    const newTotalPages = Math.ceil(filteredData.length / pageRows)
    setPageCount(newTotalPages)
  }, [currentPage, name, pageRows, data, ejecutivo])

  const handleDetails = (value) => {
    //Movimiento a Detalles del Job
    navigate(`/cobranza/seguimiento-telefonico/detalles`, {
      state: value
    })
  }

  const handlePageClick = (event) => {
    setCurrentPage(event.selected + 1)
  };

  useEffect(() => {
    handleGetSeguimientoContrato()
  }, [])


  const handleGetSeguimientoContrato = async () => {
    setLoading(true);
    const data = {
      "verb": null,
      "id": null,
      "field": null,
      "value": null
    }
    await CobranzaService.getAllEjecutivosWithFullDetail(data)
      .then(res => {
        let AllContratos = res.data.responseData.map((user) => {

          let datosFormateados = user?.contratos?.map((item) => {
            const fechaPago = (item.fecha_ultimo_pago === "1990-01-01T00:00:00" || item.fecha_ultimo_pago === "1900-01-01T00:00:00") ? "-" : FormatFecha(item.fecha_ultimo_pago)
            const fechaClave = (item.fecha_ultima_clave === "1990-01-01T00:00:00" || item.fecha_ultima_clave === "1900-01-01T00:00:00") ? "-" : FormatFecha(item.fecha_ultima_clave)
            const saldoCredito = formatearMonto(item.saldo_credito);
            const montoAdeudo = formatearMonto(item.monto_adeudo);
            return {
              ...item,
              fecha_ultimo_pago: fechaPago,
              fecha_ultima_clave: fechaClave,
              monto_adeudo: montoAdeudo,
              saldo_credito: saldoCredito,
              userId: user.userid
            }
          })

          return datosFormateados
        })

        //Quitar los nulos, y mapear todo en un arreglo. 
        const allContratosFormat = AllContratos.flatMap(item => Array.isArray(item) ? item : [])
          .filter(item => item !== null);

        let cobradoresFormat = res.data.responseData.map((item) => {
          if (item.nivel_cobrador === "3") {
            return {
              value: item.user_detail.userId,
              label: item.user_detail.names,
            }
          }

          return null
        })

        cobradoresFormat = cobradoresFormat.filter(item => item !== null);
        cobradoresFormat.unshift(inicioObjeto)
        setEjecutivoData(cobradoresFormat)
        let nivelCobranzaUno = allContratosFormat?.filter(item => item.nivel_cobranza === nivel_cobranza)
        setData(nivelCobranzaUno)
        setLoading(false);

      }).catch(e => {
        setLoading(false);
        if (e.response !== undefined)
          setModalMessage({ ...modalMessage, isOpen: true, type: 1, message: e.response.data.message })
        else
          setModalMessage({ ...modalMessage, isOpen: true, type: 1, message: e.message })
      })
  }

  return {
    setName,
    setpageRows,
    columns,
    currentData,
    handleDetails,
    pageCount,
    currentPage,
    handlePageClick,
    ejecutivoData,
    setEjecutivo
  }
}
export default SeguimientoCobranzaJuridico3Hook