import { useEffect } from 'react';
import { useState } from 'react';
import { useNavigate } from 'react-router-dom';
import Cobranza from '../../Services/Cobranza/Cobranza';

const useCobradoresNivel1 = () => {
   const navigate = useNavigate();

   const columns = [
      { field: 'nombrecobrador', headerName: 'Nombre', width: 215 },
      { field: 'statuscobrador', headerName: 'Estatus', width: 150 },
      { field: 'fechainhabilitado', headerName: 'Fecha inhabilitado', width: 205 },
      { field: 'estatusdesistema', headerName: 'Disponibilidad', width: 210 },
      { field: 'numContratos', headerName: 'Contratos', width: 150 },
      { field: 'historialdesistema', headerName: 'Historial Estatus de sistema', width: 250 },
      { field: 'estatulaboral', headerName: 'Estatus laboral', width: 200 },
   ];

   const dataEstatus= [
      { value: 0, label: 'Estatus' },
      { value: 1, label: 'Disponible' },
      { value: 2, label: 'Permisos' },
      { value: 3, label: 'Vacaciones' },
      { value: 4, label: 'Enfermedad' },
      { value: 5, label: 'Baja' }
   ];

   const dataDisponibilidad = [
      { value: 0, label: "Disponibilidad"},
      { value: 1, label: "Disponible"},
      { value: 2, label: "No disponible"}
   ]

   const dataEstadoLaboral = [
      { value: 0, label: "Estado laboral"},
      { value: 1, label: "Activo"},
      { value: 3, label: "Baja"}
   ]

   const [data, setData] = useState([]);
   const [page, setPage] = useState(1);
   const [str, setStr] = useState("");
   const [loading, setLoading] = useState(true);
   const [pageCount, setPageCount] = useState();
   const [pageRows, setpageRows] = useState(10)
   const [isOpenModal, setIsOpenModal] = useState(false);
   const [OpenModalEditar, setIsOpenModalEditar] = useState(false);
   const [item, setItem] = useState(null);
   const [ openModalHistorial, setOpenModalHistorial] = useState(false);
   const [ actualizar, setActualizar ] = useState("");
   const [nivelDefault, setNivelDefault] = useState({value: 1, label: "Nivel 1"});
   const [currentPage, setCurrentPage] = useState(1);
   const [currentData, setCurrentData] = useState(data)
   const [filtroDisponibilidad, setFiltroDisponibilidad] = useState("")
   const [filtroEstatus, setFiltroEstatus] = useState("")
   const [filtroEstadoLaboral, setFiltroEstadoLaboral]=useState("")

   useEffect(() => {
      handleGetAllData();
      setActualizar("");
      setPage(1);
   }, [actualizar])


   useEffect(() => {
      // Filtra los datos según los filtros seleccionados
      if(data.length > 0){
         let filteredData = data?.filter(item => {
            const nameMatches = str === '' || (item.nombrecobrador && item.nombrecobrador.toLowerCase().includes(str.toLowerCase()));
   
            return (
               nameMatches &&
               (filtroDisponibilidad === "" || item.estatusdesistema === filtroDisponibilidad || filtroDisponibilidad === "Disponibilidad") &&
               (filtroEstatus === "" || item.statuscobrador === filtroEstatus || filtroEstatus=== "Estatus") &&
               (filtroEstadoLaboral === "" || item.estatulaboral === filtroEstadoLaboral || filtroEstadoLaboral === "Estado laboral")
            )
         })
   
         const indexOfLastItem = currentPage * pageRows
         const indexOfFirstItem = indexOfLastItem - pageRows
         const newData = filteredData?.slice(indexOfFirstItem, indexOfLastItem)
         setCurrentData(newData)
     
         const newTotalPages = Math.ceil(filteredData.length / pageRows)
         setPageCount(newTotalPages)
         // if (currentPage > newTotalPages) {
         //   setCurrentPage(newTotalPages)
         // } else if (currentPage <= 0) {
         //   setCurrentPage(1)
         // }
      }
   }, [currentPage, str, pageRows, data, filtroDisponibilidad,filtroEstatus, filtroEstadoLaboral])

   const handlePageClick = (event) => {
      setPage(event.selected + 1);
      // handleGetAllCobradores(event.selected + 1);
   };

   const handleGetAllData = async () => {
      setLoading(true)
      let NewData = [];
      await Cobranza.getEjecutivosFullDetail()
         .then(res => {
            if(res.data.callSucceded){
               res.data.responseData?.map(item => {
                  if(item?.nivel_cobrador === "1"){
                     NewData.push({
                        userid: item?.userid,
                        nombrecobrador: item?.user_detail?.names,
                        nivel_cobrador: {value:item?.nivel_cobrador, label:"Nivel 1"},
                        statuscobrador: item?.status === null ? "" : item?.status,
                        fechainhabilitado: item?.rangoFechas === null ? "" : item?.rangoFechas ,
                        estatusdesistema: item?.disponible === null ? "" : item?.disponible,
                        contratos: item?.contratos === null ? [] : item?.contratos,
                        numContratos: item?.numContratos,
                        historialdesistema: true,
                        estatulaboral: item?.activo === 1 ? "Activo" : "Baja",
                        statussistem: item?.disponible === null ? false : item?.disponible.toLowerCase() === "disponible" ? true : false,
                        statuslab: item?.activo === 1,
                        telefono: item?.numTelefono,
                        usuariosistema: "0",
                        diasinhabiles: item?.diasInhabiles,
                        zona: item?.zona,
                        historialStatus: item?.historialStatus === null ? [] : item?.historialStatus
                     })
                  }
               })
            }
         })
         .catch(e => {
            setLoading(false);
            console.log(`ERROR -- ${e}`)
         })
         setData(NewData);
         setLoading(false);
   }

   const handleNewCobrador = async () =>{
      setIsOpenModal(true);
   }

   const handleDetails = (item) => {
      setItem(item);
      setIsOpenModalEditar(true);
   }

   const handleHistorial = (item) => {
      setItem(item);
      setOpenModalHistorial(true);
   }

   return {
      columns,
      currentData,
      pageCount,
      dataDisponibilidad,
      dataEstatus,
      dataEstadoLaboral,
      setStr,
      loading,
      handlePageClick,
      handleDetails,
      handleNewCobrador,
      page,
      setpageRows,
      isOpenModal,
      setIsOpenModal,
      OpenModalEditar, 
      setIsOpenModalEditar,
      item,
      handleHistorial,
      openModalHistorial,
      setOpenModalHistorial,
      setActualizar,
      nivelDefault,
      setFiltroDisponibilidad,
      setFiltroEstatus,
      setFiltroEstadoLaboral
   }
}

export default useCobradoresNivel1