import React, { useEffect, useState } from 'react'
import useProgramacionJobsCarteraDetalle from '../../Hooks/ProgramacionJobs/useProgramacionJobCarteraDetalle';
import Modal from '../../Components/Modal/Modal';
import Loadig from '../../Components/Loadig';
import ErrorModal from '../../Components/Modal/ErrorModal';
import SuccessModal from '../../Components/Modal/SuccessModal';
import DataTable from '../../Components/datatable/DataTable'
import ActualizarJobsModal from '../../Components/Modal/ActualizarJobs';
import { useLocation } from 'react-router-dom';
import JobsCarteraService from '../../Services/Jobs/JobsCartera';
import RowPages from '../../Components/RowPages'

import './ProgramacionJobs.css'

const ProgramacionJobsCarteraDetalle = () => {
    const { state } = useLocation();
    const [data, setData] = useState([]);
    const [loading, setLoading] = useState(false);

    const [frecuencia, setFrecuencia] = useState(state.frecuenciaFormateada);
    const [hora, setHora] = useState(state.horaProgramado);
    const [stateValue, setStateValue] = useState(state);
    const userId = sessionStorage.getItem('userId').trim()

    useEffect(() => {
        getAllHistorialJobs();
    }, []);

    const getAllHistorialJobs = async () => {
        setLoading(true);
        await JobsCarteraService.getHistorialJobCartera(state.campo)
            .then(res => {
                const resFechaFormateada = res.data.map(event => {
                    const horaProgramado = new Date(event.fecha_hora_ejecucion);

                    const horaProgramadoFormateada = FormatFecha(horaProgramado)
                    // const resultadoFormat = event.resultado === 0 ? 'Error' : 'Completado con Exito'
                    const resultadoFormat = event.resultado === 0 ? event.mensaje : event.mensaje

                    return {
                        ...event,
                        fecha: event.fecha_hora_ejecucion,
                        fecha_hora_ejecucion: horaProgramadoFormateada,
                        resultado: resultadoFormat
                    };
                })

                const resOrdenado = resFechaFormateada.sort((a, b) => new Date(b.fecha) - new Date(a.fecha))
                setData(resOrdenado);
            })
            .catch(err => {
                console.error(err);
            })
            .finally(() => setLoading(false));
    };

    const columns = [
        { field: 'fecha_hora_ejecucion', headerName: 'Fecha y hora' },
        { field: 'tipo_sincronizacion', headerName: 'Tipo de sincronización' },
        { field: 'resultado', headerName: 'Resultado de ejecución' },
    ];

    const UpdateInfo = (frecuencia, horaProgramado, value,) => {
        state.frecuenciaFormateada = frecuencia
        state.horaProgramado = horaProgramado
        state.horaInicio = value.horaInicio
        state.horaFin = value.horaFin
        state.lapsoRepeticion = value.tiempo

        if (value.plazoId === 3) {
            state.frecuenciaSemanal = value.frecuenciaSemanal
            state.diaProgramado = value.diaProgramado
        }
        if (value.plazoId === 4) {
            state.frecuenciaMensual = value.frecuenciaMensual
            state.diaProgramado = value.diaProgramado
        }

        setStateValue(state)
        setFrecuencia(frecuencia)
        setHora(horaProgramado)
        getAllHistorialJobs()
    }

    const {
        modalMessage,
        setModalMessage,
        handleModal,
        isOpenModalActualizar,
        setisOpenModalActualizar,
        handleReturnPage,
        pageCount,
        setpageRows,
        handlePageClick,
        paginatedData,
        currentPage,
        FormatFecha
    } = useProgramacionJobsCarteraDetalle(data)

    const handleEjecutarManual = async (jobId, campo) => {
        setLoading(true)

        let data = {
            "jobId": jobId,
            "nombreJob": campo,
            "userId": userId
        };

        await JobsCarteraService.ejecutarJobCarteraManual(data)
            .then(res => {
                if (!res.data.callSucceded)
                    setModalMessage({ ...modalMessage, isOpen: true, type: 1, message: res.data.message })
                else
                    setModalMessage({ ...modalMessage, isOpen: true, type: 2, message: res.data.message })
            }).catch(e => {
                if (e.response !== undefined)
                    setModalMessage({ ...modalMessage, isOpen: true, type: 1, message: e.response.data.message })
                else
                    setModalMessage({ ...modalMessage, isOpen: true, type: 1, message: e.message })
            }).finally(() => setLoading(false));

        getAllHistorialJobs()
        setLoading(false)
    }

    return (
        <>
            <Modal isOpen={loading} color='transparent'><Loadig message={""} /></Modal>
            {
                isOpenModalActualizar &&
                <ActualizarJobsModal
                    setisOpenModalActualizar={setisOpenModalActualizar}
                    isOpenModalActualizar={isOpenModalActualizar}
                    state={stateValue}
                    setModalMessage={setModalMessage}
                    UpdateInfo={UpdateInfo}
                />
            }
            <Modal isOpen={modalMessage.isOpen} color='#fff'>
                {
                    modalMessage.type === 1 &&
                    <ErrorModal
                        modalMessage={modalMessage}
                        setModalMessage={setModalMessage}
                    />
                }
                {
                    modalMessage.type === 2 &&
                    <SuccessModal
                        modalMessage={modalMessage}
                        setModalMessage={setModalMessage}
                    />
                }

            </Modal>
            <header className='heder-subtitle d-flex justify-content-start align-items-center border-bottom px-2'>
                <div className='cursor-pointer' onClick={() => { handleReturnPage() }}>
                    <i className="bx bxs-chevron-left mx-2" ></i>
                    Regresar a Cartera
                </div>
            </header>
            <section className='mx-5 my-4 d-flex flex-column'>
                <div className='d-flex justify-content-between'>
                    <span className='fs-5'><strong>{`Programación de job: ${state.job}`}</strong></span>
                </div>
                {/* <span className='mt-2'>{`Programa los jobs ${state.job}`}</span> */}
            </section>

            <section className='mt-5 mx-4'>
                <div className='row col-12'>
                    <div className="col-lg-3 col-md-6 col-sm-6 inner-addon right-addon programacionJobsCarteraEditButton">
                        <input type="text"
                            className="form-control"
                            placeholder={`${frecuencia} a las ${hora}`}
                            onChange={(e) => { /*setStr(e.target.value)*/ }}
                            disabled={true}
                        />
                        <i className='bx bxs-pencil cursor-pointer' onClick={handleModal}></i>
                    </div>
                    <div className="col-lg-3 col-md-6 col-sm-6 cursor-pointer'">
                        <button
                            className="btn p-2"
                            onClick={(e) => { handleEjecutarManual(state.idJob, state.campo) }}
                        >
                            Ejecutar Manual
                        </button>
                    </div>

                </div>
            </section>
            <RowPages
                setpageRows={setpageRows}
            />
            <div className='mx-4 table-responsive'>
                <DataTable
                    column={columns}
                    data={paginatedData}
                    paginate
                    pageCount={pageCount}
                    handlePageClick={handlePageClick}
                    currentPage={currentPage}
                />
            </div>
        </>
    )
}

export default ProgramacionJobsCarteraDetalle