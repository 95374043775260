import { useEffect, useState } from 'react'
import { useNavigate, } from 'react-router';
import Parametros from '../../Services/Parametros/Parametros';
import { FechaYHoraFront } from '../../Utils/functions'



const ParametrosCarteraHistorialHooks = () => {
  const [strFiltro, setStrFiltro] = useState("")
  const [dataParametrosCarteraLogs, setDataParametrosCarteraLogs] = useState([]);
  const [dateTime, setDateTime] = useState("");


  const columns = [
    { field: 'parametro', headerName: 'Parámetro' },
    { field: 'valor_anterior', headerName: 'Valor anterior' },
    { field: 'valor_nuevo', headerName: 'Valor nuevo' },
    { field: 'usuario_nombre', headerName: 'Usuario' },
    { field: 'last_update', headerName: 'Fecha y hora' },
  ];



  // Use Inicial 
  useEffect(() => {
    handleGetAllParametrosCarteraLog();
  }, [])

  /* Todos los parametros */
  const handleGetAllParametrosCarteraLog = async () => {
    await Parametros.GetAllCobranzaParametrosLogs()
      .then(res => {

        let datosFormateados = res.data.responseData?.map((item) => {
          const fecha = FechaYHoraFront(new Date(item.dateupdate))
          return {
            ...item,
            parametro: item.parametro.descripcion,
            usuario_nombre: item.userupdate.names,
            last_update: fecha
          }
        })

        setDataParametrosCarteraLogs(datosFormateados)
      }).catch(e => {
      })
  }



  return { columns, strFiltro, setStrFiltro, dataParametrosCarteraLogs, setDateTime };

}
export default ParametrosCarteraHistorialHooks