import { useEffect, useState } from 'react';
import { useDispatch } from 'react-redux';
import { useNavigate } from 'react-router-dom';
import { setDatosRuta } from '../../Redux/Slice/Cobranza/SeguimientoVisita/DetalleRutaSlice';
import CobranzaService from '../../Services/Cobranza/Cobranza';
import { FormatFecha, FormatFolioNumber } from '../../Utils/functions';

const useRutasCobranza = () => {
   const navigate = useNavigate();
   const dispatch = useDispatch();
   const [data, setData] = useState([])

   const columns = [
      { field: 'ruta', headerName: 'Ruta', width: 350},
      { field: 'contratos', headerName: 'Cantidad Contratos', width: 200},
      { field: 'fechavisita', headerName: 'Fecha de la visita', width: 250}
   ];

   const columnsDetail = [
      { field: 'numero', headerName: '#'},
      { field: 'contrato_id', headerName: 'Contrato' },
      { field: 'tipo_persona', headerName: 'Tipo de Persona'},
      { field: 'nombre', headerName: 'Nombre'},
      { field: 'tipo_visita', headerName: 'Tipo de Visita'},
      { field: 'dias_vencido', headerName: 'Días vencidos' },
      { field: 'monto_vencido', headerName: 'Monto vencido'},
      { field: 'fecha_visita', headerName: 'Fecha de visita'},
   ];

   const [page, setPage] = useState(1);
   const [str, setStr] = useState("");
   const [loading, setLoading] = useState(true);
   const [pageCount, setPageCount] = useState();
   const [pageRows, setpageRows] = useState(10);
   const [openModalRuta, setOpenModalRuta] = useState(false);
   const [currentPage, setCurrentPage] = useState(1);
   const [currentData, setCurrentData] = useState(data)
   const [filterFechaInicio, setFilterFechaInicio] = useState("")
   const [filterFechaFin, setFilterFechaFin] = useState("")
   const [listaCobradores, setListaCobradores] = useState([])

   useEffect(() => {
      let filteredData = data.filter(item => {
         let fechaFiltrada = true
         const nameMatches = str === '' || (item.ruta && item.ruta.toLowerCase().includes(str.toLowerCase()));
         if (filterFechaInicio !== "" && filterFechaFin !== "") {
            const FechaVencimiento = new Date(filterFechaFin)
            const FechaInicio = new Date(filterFechaInicio)
            const fechaItem = new Date(item.fecha_inicio)
            fechaFiltrada = fechaItem >= FechaInicio && fechaItem <= FechaVencimiento
         }
         if(filterFechaInicio !== "" && filterFechaFin !== ""  && str !== ""){
            return (nameMatches || fechaFiltrada)
         }
         else if(filterFechaInicio !== "" && filterFechaFin !== ""){
            return (fechaFiltrada)
         }
         else{
            return (nameMatches)
         }
      })

      const indexOfLastItem = currentPage * pageRows
      const indexOfFirstItem = indexOfLastItem - pageRows
      const newData = filteredData.slice(indexOfFirstItem, indexOfLastItem)
      setCurrentData(newData)
  
      const newTotalPages = Math.ceil(filteredData.length / pageRows)
      setPageCount(newTotalPages)
   }, [currentPage, str, pageRows, data, filterFechaInicio, filterFechaFin])

   const handlePageClick = (event) => {
      setPage(event.selected + 1);
      // handleGetAllCobradores(event.selected + 1);
   };

   const handleCrearRuta = () =>{
      setOpenModalRuta(true);
   }

   useEffect(()=>{
      handleGetRutas();
   }, []);

   const handleGetRutas = async () =>{
      let NewData = [];
      setLoading(true);
      await CobranzaService.GetAllRutasCobranza()
         .then(res =>{
            res.data.responseData?.map((item)=>{
               let DetailData = [];
               let counter = 0;
               item?.items?.map((element)=>{
                  if(element?.contrato !== null && element?.contrato?.cartera_vencida === 1 && element?.contrato?.nivel_cobranza === "2"){
                     counter++;
                     DetailData.push({
                        numero: FormatFolioNumber(item?.folio,9),
                        tipo_visita: element?.tipo_visita,
                        fecha_visita: FormatFecha(element?.fecha), 
                        contrato_id: element.contrato === null ? "" : element?.contrato?.contrato_id,
                        tipo_persona: element.contrato === null ? "" : element?.contrato?.tipo_persona,
                        nombre: element.contrato === null ? "" : `${element?.contrato?.nombre} ${element?.contrato?.apellido_paterno} ${element?.contrato?.apellido_materno}`,
                        direccion: element.contrato === null ? "" : `Calle ${element?.contrato?.calle} ${element?.contrato?.no_ext}, Col.${element?.contrato?.colonia}, C/.P. ${element?.contrato?.cp}`,
                        monto_vencido: element.contrato === null ? "" : element?.contrato?.monto_adeudo,
                        estatus: element.contrato === null ? "" : element?.contrato?.estatus,
                        municipio: element.contrato === null ? "" : element?.contrato?.municipio,
                        dias_vencido: element.contrato === null ? "" : element?.contrato?.dias_vencido,
                        zona: element.contrato === null ? "" : element?.contrato?.zona,
                        zonaid: element.contrato === null ? "" : element?.contrato?.zonaid
                     })
                  }
               })
               NewData.push({
                  ruta: item?.folio === null ? "" : FormatFolioNumber(item?.folio,9),
                  contratos: counter,
                  fechainicio: item?.fecha_inicio === null ? "" : FormatFecha(item?.fecha_inicio),
                  fecha_inicio: item?.fecha_inicio === null ? "" : item?.fecha_inicio,
                  fechafin: item?.fecha_fin === null ? "" : FormatFecha(item?.fecha_fin),
                  fechavisita: item?.fecha_inicio === null ? "" : FormatFecha(item?.fecha_inicio),
                  userId: item?.userid === null ? "" : item?.userid,
                  detail: DetailData
               })
            })
            getAllCobradores();
            // setLoading(false);
         })
         .catch(e => {
            setLoading(false);
            console.log(`ERROR -- ${e}`)
         })
      setData(NewData);
   }

   const getAllCobradores = async () => {
      setLoading(true);
      let NewData = []
      await CobranzaService.getEjecutivosFullDetail()
         .then(res => {
            if(res.data.callSucceded){
               res.data.responseData?.map(item => {
                  NewData.push({
                     userId: item?.userid,
                     nombrecobrador: item?.user_detail?.names
                  })
               })
            }
            setLoading(false);
         })
         .catch(e => {
         setLoading(false);
            console.log(`ERROR -- ${e}`)
         })
         setListaCobradores(NewData)
   }

   const handleDetails = (item) =>{
      let cobrador = ""
      listaCobradores.map(c=>{
         if(item.userId.toLowerCase() === c.userId.toLowerCase()){
            cobrador = c.nombrecobrador
         }
      })

      dispatch(setDatosRuta({
         ruta: item.ruta,
         nombrecobrador: cobrador,
         fechainicio: item.fechainicio,
         fechafin: item.fechafin,
         contratos: item.contratos,
         items: item.detail
         })
      );

      navigate("/cobranza/seguimiento-visita/rutas-cobranza/ruta-detalle", {
         state: {
            page: 'Detalle',
            title: "Detalle de ruta",
            puedeEditar: false,
            tipoUsuario: 0
         },
      });
   }

   return {
      columns,
      columnsDetail,
      currentData,
      pageCount,
      setStr,
      loading,
      handlePageClick,
      handleDetails,
      handleCrearRuta,
      page,
      setpageRows,
      openModalRuta,
      setOpenModalRuta,
      setFilterFechaInicio,
      setFilterFechaFin
   }
}

export default useRutasCobranza