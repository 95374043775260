import { Formik, Form, Field } from 'formik'
import React, { useEffect, useRef, useState } from 'react'
import Modal from '../../Components/Modal/Modal'
import { TextField } from '../../Components/TextField'
import * as Yup from 'yup';
import axios from 'axios';
import { toast, Toaster } from 'react-hot-toast';
import Tabs from '../Tabs/Tabs';
import Tab from '../Tabs/Tab';
import DataTable from '../datatable/DataTable';
import { TextAreaField } from '../TextAreaField';
import { SelectField } from '../SelectField';
import Siniestros from '../../Services/Siniestro/Siniestro';
import { FormatNumberMoney2, message } from '../../Utils/functions';
import AuthLogin from '../../Utils/AuthLogin';
import dataDocsServices from '../../Services/DataDocs/dataDocsServices';
import Spinner from '../Loadig';
import PagoResguardoUnidadModal from './PagoResguardoUnidadModal';
import Catalogo from '../../Services/Catalogos/Catalogo';
import { useLocation, useNavigate, useParams } from 'react-router-dom';
import { increment } from '../../Redux/Slice/reloadSlice';
import { useDispatch, useSelector } from 'react-redux';

const auth = new AuthLogin();
const ResguardoDeUnidadModal = ({ isOpen, setIsOpen, item }) => {
    let url = auth.UrlDataDocs();
    const { state } = useLocation();
    const { id } = useParams();
    const navigate = useNavigate();
    const refbtn = useRef();
    const { value } = useSelector((state) => state.reloadSlice);
    const dispatch = useDispatch();
    let initial = {
        seguroSiniestroSeguimientoProcesoDatamovilId: item?.seguroSiniestroSeguimientoProcesoDatamovilId ?? "",
        siniestroResguardoUnidadId: "",
        origenTipoUbicacion: "",
        origenUbicacion: "",
        fechaCita: "",
        horaCita: "",
        fechaHoraCita: "",
        motivoResguardoId: "",
        motivoResguardo: "",
        formaArriboId: "",
        formaArribo: "",
        edoMexicoId: "",
        estado: "",
        municipioId: "",
        municipio: "",
        coloniaId: "",
        colonia: "",
        calle: "",
        numero: "",
        referenciaUbicacion: "",

        fechaResguardo: "",
        horaResguardo: "",
        quienEntrega: "",
        quienRecibe: "",
        calleNumero: "",
        codigoPostal: "",
        edoMexicoIdE: "",
        estadoE: "",
        municipioIdE: "",
        municipioE: "",
        coloniaIdE: "",
        coloniaE: "",
        calle: "",
        comentarios: "",
        concluido: false,

        fechaInicio: "",
        fechaFin: ""
    }
    const tipoUbicaciones = [{ value: 'interno', label: 'Interno' }, { value: 'externo', label: 'Externo' }];

    const [initialValue, setInitialValue] = useState(initial);
    const [loading, setLoading] = useState(false);
    const [dataCostos, setDataCostos] = useState([]);
    const [totalPages, setTotalPages] = useState(1);
    const [currentPage, setCurrentPage] = useState(1);
    // ver doc
    const [fileName, setFileName] = useState("");
    const [isOpenDocs, setIsOpenDocs] = useState(false)
    const [SelectedDocs, setSelectedDocs] = useState([]);
    const [isOpenModalPago, setIsOpenModalPago] = useState(false);
    const [itemSelected, setItemSelected] = useState();
    const [totalcosto, setTotalcosto] = useState(0);
    const [pageRows, setpageRows] = useState(5)

    const [dataMotivoResguardo, setDataMotivoResguardo] = useState([]);
    const [dataFormaArribo, setDataFormaArribo] = useState([]);
    const [dataEstado, setDataEstado] = useState([]);
    const [dataMunicipio, setDataMunicipio] = useState([]);
    const [dataColonia, setDataColonia] = useState([]);

    const [dataEstado2, setDataEstado2] = useState([]);
    const [dataMunicipio2, setDataMunicipio2] = useState([]);
    const [dataColonia2, setDataColonia2] = useState([]);

    const columns = [
        { field: 'folio', headerName: '#', width: '40' },
        { field: 'fechaHora', headerName: 'Fecha y hora' },
        { field: 'ejecutivo', headerName: 'Ejecutivo', width: '100' },
        { field: 'concepto', headerName: 'Concepto' },
        { field: 'subTotal', headerName: 'Subtotal', width: '100' },
        { field: 'iva', headerName: 'IVA(%)', width: '50' },
        { field: 'total', headerName: 'Total', width: '50' },
        { field: 'acciones', headerName: 'Acciones', width: 200 }
    ]
    const validate = Yup.object({
        //Detalle de resguardo
        motivoResguardoId: Yup.string()
            .required("El campo es requerido."),
        formaArriboId: Yup.string()
            .required("El campo es requerido."),
        // Detalle de la entrega
        fechaResguardo: Yup.string()
            .required("El campo es requerido."),
        horaResguardo: Yup.string()
            .required("El campo es requerido."),
        quienEntrega: Yup.string()
            .required("El campo es requerido."),
        quienRecibe: Yup.string()
            .required("El campo es requerido."),
        calleNumero: Yup.string()
            .required("El campo es requerido."),
        codigoPostal: Yup.string()
            .required("El campo es requerido."),
        edoMexicoIdE: Yup.string()
            .required("El campo es requerido."),
        municipioIdE: Yup.string()
            .required("El campo es requerido."),
        coloniaIdE: Yup.string()
            .required("El campo es requerido."),
        comentarios: Yup.string()
            .required("El campo es requerido."),
    });

    useEffect(() => {
        handleGetAllResguardoUnidad();
        handleGetAllSelects();
    }, [isOpen])

    const handleGetAllResguardoUnidad = async () => {
        setLoading(true);
        await Siniestros.siniestroResguardoUnidadById(item?.seguroSiniestroSeguimientoProcesoDatamovilId)
            .then(res => {
                let itemx = res.data;
                let newData = {
                    seguroSiniestroSeguimientoProcesoDatamovilId: item?.seguroSiniestroSeguimientoProcesoDatamovilId ?? "",
                    siniestroResguardoUnidadId: itemx?.siniestroResguardoUnidadId ?? "",
                    origenTipoUbicacion: itemx?.origenTipoUbicacion ?? "",
                    origenUbicacion: itemx?.origenUbicacion ?? "",
                    fechaCita: itemx?.fechaCita !== null ? itemx?.fechaCita.split("T")[0] : "",
                    horaCita: itemx?.horaCita ?? "",
                    fechaHoraCita: itemx?.fechaHoraCita ?? "",
                    motivoResguardoId: itemx?.motivoResguardoId ?? "",
                    motivoResguardo: itemx?.motivoResguardo ?? "",
                    formaArriboId: itemx?.formaArriboId ?? "",
                    formaArribo: itemx?.formaArribo ?? "",
                    edoMexicoId: itemx?.edoMexicoId ?? "",
                    estado: itemx?.estado ?? "",
                    municipioId: itemx?.municipioId ?? "",
                    municipio: itemx?.municipio ?? "",
                    coloniaId: itemx?.coloniaId ?? "",
                    colonia: itemx?.colonia ?? "",
                    calle: itemx?.calle ?? "",
                    numero: itemx?.numero ?? "",
                    referenciaUbicacion: itemx?.referenciaUbicacion ?? "",

                    fechaResguardo: itemx?.fechaResguardo !== null ? itemx?.fechaResguardo.split("T")[0] : "",
                    horaResguardo: itemx?.horaResguardo ?? "",
                    quienEntrega: itemx?.quienEntrega ?? "",
                    quienRecibe: itemx?.quienRecibe ?? "",
                    calleNumero: itemx?.calleNumero ?? "",
                    codigoPostal: itemx?.codigoPostal ?? "",
                    edoMexicoIdE: itemx?.edoMexicoIdE ?? "",
                    estadoE: itemx?.estadoE ?? "",
                    municipioIdE: itemx?.municipioIdE ?? "",
                    municipioE: itemx?.municipioE ?? "",
                    coloniaIdE: itemx?.coloniaIdE ?? "",
                    coloniaE: itemx?.coloniaE ?? "",
                    calle: itemx?.calle ?? "",
                    comentarios: itemx?.comentarios ?? "",
                    concluido: itemx?.concluido ?? false,

                    fechaInicio: itemx?.fechaInicio ?? "",
                    fechaFin: itemx?.fechaFin ?? ""
                }
                setInitialValue(newData);
                handleGetCostos(res.data.siniestroResguardoUnidadId);
                handleMunicipioById(itemx?.edoMexicoIdE);
                handlecoloniaById(itemx?.municipioIdE);

                handleMunicipioById2(itemx?.edoMexicoId);
                handlecoloniaById2(itemx?.municipioId);
                setLoading(false);
            }).catch(e => {
                setLoading(false);
            })
    }
    const handleGetCostos = async (id, page = 1) => {
        setLoading(true);
        await Siniestros.siniestroResguardoUnidadCostoById(page, pageRows, id)
            .then(res => {
                let data = res.data.data;
                let total = 0
                let newData = data?.map((item, idx) => {
                    total += item.total;
                    return { ...item, folio: idx + 1 }
                });
                setDataCostos(newData);
                setTotalcosto(total);
                setTotalPages(res.data.totalPages);
                setLoading(false);
            }).catch(e => {
                setLoading(false);
            })
    }
    const handledeleteCostosbyId = async (item) => {
        setLoading(true);
        await Siniestros.DeleteSiniestroResguardoUnidadCosto(item.siniestroResguardoUnidad_CostoAdicionalId)
            .then(res => {
                if (res.data.data.ban === 1) {
                    toast.success(message("¡correcto!", res.data.data.mensaje));
                } else {
                    toast.error(message("Error!", res.data.data.mensaje));
                }
                setLoading(false)
                handleGetCostos(initialValue.siniestroResguardoUnidadId);

            }).catch(e => {
                setLoading(false);
            })
    }
    const handleGetAllSelects = async () => {
        setLoading(true)
        const [] = await Promise.all([
            handleMotivoResguardo(),
            handleFormaArribo(),
            handleEdoMexico()
        ]).finally(() => {
            setLoading(false)
        });
    }
    const handlePageClick = (event) => {
        setCurrentPage(event.selected + 1);
        handleGetCostos(initialValue.siniestroResguardoUnidadId, event.selected + 1);
    };

    const handleGetTokenDatadocs = async () => {
        await dataDocsServices.UpdateTokenDataDc()
            .then(res => {
                auth.handleAuthSesionDataDocs(res.data.data);
            }).catch(e => { })
    }
    const handleViewdocs = async (item) => {
        setLoading(true);
        setFileName(item.nombreDocumento);
        await handleGetTokenDatadocs();
        await axios.post(`${url}Documento/GetFileById/${item.idFolder}/${item.documentoId}/false`, {}, {
            headers: {
                'Authorization': 'Bearer ' + auth.tokenDataDocs(),
            },
            responseType: "blob"
        })
            .then(res => {
                var file = new File([res.data], res.data.name, { type: res.data.type });
                setSelectedDocs([file])
                setLoading(false);
                setIsOpenDocs(true)
            }).catch(e => {
                setLoading(false);
                if (e.response !== undefined)
                    toast.error(message("Error.!", e.response.data.errors?.error));
                else
                    toast.error(message("Error.!", e.message));
            })
    }
    const handleUploadFile = async (value, subido) => {
        if (subido) {
            await handleViewdocs(value)
        } else {
            navigate(`/seguro/siniestros/datadocs/fResguardo/eResguardo/${state?.folio}/${id}`, {
                state: { ...value, registroId: value.siniestroResguardoUnidad_CostoAdicionalId, vin: state.vin, nombreMarca: state.marca, nombreModelo: state.modelo, nombreVersion: state.version, nombreYear: state.year }
            });
        }
    }
    const handleUploadFileDetalleResguardo = () => {
        navigate(`/seguro/siniestros/datadocs/fResguardo/eResguardo/${state?.folio}/${id}`, {
            state: { registroId: item?.seguroSiniestroSeguimientoProcesoDatamovilId, vin: state.vin, nombreMarca: state.marca, nombreModelo: state.modelo, nombreVersion: state.version, nombreYear: state.year }
        });
    }
    const handleAddEdit = async (item) => {
        setLoading(true);
        await Siniestros.UpdateSiniestroResguardoUnidad(item)
            .then(res => {
                if (res.data.data.ban === 1) {
                    toast.success(message("¡correcto!", res.data.data.mensaje));
                    setIsOpen(false);
                    dispatch(increment(value));
                    navigate(`/seguro/siniestros`)
                } else {
                    toast.error(message("Error!", res.data.data.mensaje));
                }
                setLoading(false);
            }).catch(e => {
                setLoading(false);
                if (e.response !== undefined)
                    toast.error(message("Error!", e.response.data.message));
                else
                    toast.error(message("Error!", e.message));
            })

    }

    const handleMotivoResguardo = async () => {
        await Catalogo.getDropdownsByTable('Catalogos.Cobranza_MotivoResguardo')
            .then(resp => {
                let NewData = [];
                resp.data.map(item => {
                    NewData.push({ value: item.id, label: item.valor });
                });
                setDataMotivoResguardo(NewData);
            })
            .catch(err => {
                console.error(err);
            });
    }
    const handleFormaArribo = async () => {
        await Catalogo.getDropdownsByTable('Catalogos.ResguardoFormaArribo')
            .then(resp => {
                let NewData = [];
                resp.data.map(item => {
                    NewData.push({ value: item.ResguardoFormaArriboId, label: item.ResguardoFormaArribo });
                });
                setDataFormaArribo(NewData);
            })
            .catch(err => {
                console.error(err);
            });
    }
    const handleEdoMexico = async () => {
        await Catalogo.getDropdownsByTable('Catalogos.EdoMexico')
            .then(resp => {
                console.log("edo mx", resp)
                let NewData = [];
                resp.data.map(item => {
                    NewData.push({ value: item.EdoMexicoId, label: item.Estado });
                });
                setDataEstado(NewData);
                setDataEstado2(NewData);

            })
            .catch(err => {
                console.error(err);
            });
    }
    const handleMunicipioById = async (id) => {
        await Catalogo.getMunicipiobyId(id)
            .then(res => {
                let NewData = [];
                res.data.data?.map(item => {
                    NewData.push({
                        value: item.municipioId,
                        label: item.municipio
                    })
                });
                setDataMunicipio(NewData);
            }).catch(e => {

            })
    }
    const handlecoloniaById = async (id) => {
        await Catalogo.getColoniaById(id)
            .then(res => {
                let NewData = [];
                res.data.data?.map(item => {
                    NewData.push({
                        value: item.coloniaId,
                        label: item.colonia
                    })
                });
                setDataColonia(NewData);
            }).catch(e => {

            })
    }
    const handleMunicipioById2 = async (id) => {
        await Catalogo.getMunicipiobyId(id)
            .then(res => {
                let NewData = [];
                res.data.data?.map(item => {
                    NewData.push({
                        value: item.municipioId,
                        label: item.municipio
                    })
                });
                setDataMunicipio2(NewData);
            }).catch(e => {

            })
    }
    const handlecoloniaById2 = async (id) => {
        await Catalogo.getColoniaById(id)
            .then(res => {
                let NewData = [];
                res.data.data?.map(item => {
                    NewData.push({
                        value: item.coloniaId,
                        label: item.colonia
                    })
                });
                setDataColonia2(NewData);
            }).catch(e => {

            })
    }
    return (
        <>
            <Toaster
                position="top-right"
                toastOptions={{
                    success: {
                        style: {
                            background: '#47a066',
                            color: '#FFFF',
                            borderLeft: '10px solid #2f7246'
                        },
                    },
                    error: {
                        style: {
                            background: '#d53f3f',
                            color: '#FFFF',
                            borderLeft: '10px solid #ac241a'
                        },
                    },
                }}
            />

            <Formik
                enableReinitialize={true}
                initialValues={initialValue}
                validationSchema={validate}
                onSubmit={(values) => {
                    handleAddEdit(values);
                }}
            >
                {({ isValid, values, setFieldValue }) => (
                    <>
                        <Modal isOpen={isOpen} color='#fff'>
                            <section className='my-4 mx-4'>
                                <span className='fs-5'><strong>Resguardo de unidad</strong></span>
                                <Form>
                                    <div className="row mt-2 mb-2">
                                        <div className="col col-12">
                                            <div className="row">
                                                <Tabs>
                                                    <Tab title="Detalle de resguardo">
                                                        <div className="row mt-2 mb-2">
                                                            <div className="col col-12">
                                                                <div className="row mt-2">
                                                                    <div className="col-lg-3 col-md-6 col-sm-12">
                                                                        <SelectField className="col-12 "
                                                                            disabled={initialValue?.concluido}
                                                                            label="Tipo de ubicación" items={tipoUbicaciones}
                                                                            name={`origenTipoUbicacion`}
                                                                            onChange={(event) => {
                                                                                setFieldValue(`origenTipoUbicacion`, event.value);
                                                                            }} />
                                                                    </div>
                                                                    <div className="col-lg-3 col-md-6 col-sm-12">
                                                                        <TextField
                                                                            disabled={initialValue?.concluido}
                                                                            label="Nombre de ubicación"
                                                                            name="origenUbicacion"
                                                                            type="text"
                                                                            holder="Escribe" />
                                                                    </div>
                                                                    <div className="col-lg-3 col-md-6 col-sm-12">
                                                                        <TextField
                                                                            disabled={initialValue?.concluido}
                                                                            label="Fecha cita"
                                                                            name="fechaCita"
                                                                            type="date"
                                                                            holder="Escribe"
                                                                            onChange={(event) => {
                                                                                setFieldValue("fechaCita", event.target.value);
                                                                            }} />
                                                                    </div>
                                                                    <div className="col-lg-3 col-md-6 col-sm-12">
                                                                        <TextField
                                                                            disabled={initialValue?.concluido}
                                                                            label="Hora cita"
                                                                            name="horaCita"
                                                                            type="time"
                                                                            holder="Escribe"
                                                                            onChange={(event) => {
                                                                                setFieldValue("horaCita", event.target.value);
                                                                            }} />
                                                                    </div>
                                                                    <div className="col-lg-3 col-md-6 col-sm-12">
                                                                        <SelectField className="col-12 " disabled={initialValue?.concluido} label="Motivo resguardo" items={dataMotivoResguardo} name={`motivoResguardoId`} onChange={(event) => {
                                                                            setFieldValue(`motivoResguardoId`, event.value);
                                                                        }} />
                                                                    </div>
                                                                </div>
                                                                <div className="row mt-2">
                                                                    <div className="col-lg-3 col-md-6 col-sm-12">
                                                                        <SelectField className="col-12" disabled={initialValue?.concluido} label="Forma de arribo" items={dataFormaArribo} name={`formaArriboId`} onChange={(event) => {
                                                                            setFieldValue(`formaArriboId`, event.value);
                                                                        }} />

                                                                    </div>
                                                                    <div className="col-lg-3 col-md-6 col-sm-12">
                                                                        <SelectField className="col-12" disabled={initialValue?.concluido} label="Estado" items={dataEstado2} name={`edoMexicoId`} onChange={(event) => {
                                                                            setFieldValue(`edoMexicoId`, event.value);
                                                                            if (event.value !== "") {
                                                                                handleMunicipioById2(event.value);
                                                                            } else {
                                                                                setFieldValue(`municipioId`, "");
                                                                                setFieldValue(`coloniaId`, "");
                                                                                setDataMunicipio2([]);
                                                                                setDataColonia2([])
                                                                            }
                                                                        }} />
                                                                    </div>
                                                                    <div className="col-lg-3 col-md-6 col-sm-12">

                                                                        <SelectField className="col-12" disabled={initialValue?.concluido} label="Municipio" items={dataMunicipio2} name={`municipioId`} onChange={(event) => {
                                                                            setFieldValue(`municipioId`, event.value);
                                                                            if (event.value !== "") {
                                                                                handlecoloniaById2(event.value)
                                                                            } else {
                                                                                setFieldValue(`coloniaId`, "");
                                                                                setDataColonia2([])
                                                                            }
                                                                        }} />
                                                                    </div>
                                                                    <div className="col-lg-3 col-md-6 col-sm-12">
                                                                        <SelectField className="col-12" disabled={initialValue?.concluido} label="Colonia" items={dataColonia2} name={`coloniaId`} onChange={(event) => {
                                                                            setFieldValue(`coloniaId`, event.value);
                                                                        }} />
                                                                    </div>
                                                                </div>
                                                                <div className="row mt-2">
                                                                    <div className="col-lg-3 col-md-6 col-sm-6">
                                                                        <TextField
                                                                            disabled={initialValue?.concluido}
                                                                            label="Número"
                                                                            name="numero"
                                                                            type="text"
                                                                            holder="Escribe" />
                                                                    </div>
                                                                    <div className="col-lg-3 col-md-6 col-sm-6 d-flex align-items-center mt-3">
                                                                        <fieldset disabled={initialValue?.concluido} onClick={handleUploadFileDetalleResguardo} className='btn btn-gray'>
                                                                            <i className="icon-light fa fa-file "></i> Subir documento
                                                                        </fieldset>
                                                                    </div>
                                                                    <div className="col-lg-9 col-md-6 col-sm-6">
                                                                        <TextAreaField
                                                                            disabled={initialValue?.concluido}
                                                                            label="Referencia de ubicación"
                                                                            className="form-control col-12"
                                                                            rows="3"
                                                                            name="referenciaUbicacion"
                                                                            id="comentariosEjecutivoId"
                                                                            type="text"
                                                                            holder="Escribe"
                                                                        />
                                                                    </div>
                                                                </div>
                                                            </div>
                                                        </div>
                                                    </Tab>
                                                    <Tab title="Costos adicionales">
                                                        <div className="col-lg-12 col-md-12 col-sm-12">
                                                            <div className="col-6 d-flex align-items-center">
                                                                <small><strong>Costos adicionales</strong></small>
                                                                <fieldset disabled={initialValue?.concluido} className='btn d-flex align-items-center mx-2' onClick={() => { setItemSelected({ siniestroResguardoUnidadId: initialValue.siniestroResguardoUnidadId }); setIsOpenModalPago(true) }}>Registrar adeudo</fieldset>
                                                            </div>
                                                            <div className="col-lg-12 col-md-12 col-sm-12">
                                                                <DataTable
                                                                    data={dataCostos}
                                                                    column={columns}
                                                                    editable={!initialValue?.concluido}
                                                                    deleteable={!initialValue?.concluido}
                                                                    handleEdit={(item) => { setItemSelected(item); setIsOpenModalPago(true); }}
                                                                    handleDelete={handledeleteCostosbyId}
                                                                    handleAction={(value, idx) => { handleUploadFile(value, idx) }}
                                                                    pageCount={totalPages}
                                                                    currentPage={currentPage - 1}
                                                                    handlePageClick={handlePageClick}
                                                                />
                                                                <div className='d-flex align-items-center justify-content-end'>
                                                                    <div>
                                                                        <strong>Total: ${FormatNumberMoney2(totalcosto)}</strong>
                                                                    </div>
                                                                </div>
                                                            </div>
                                                        </div>

                                                    </Tab>
                                                    <Tab title="Detalle de la entrega">
                                                        <div className="row mt-2 mb-2">
                                                            <div className="col col-12">
                                                                <div className="row mt-2">
                                                                    <div className="col-lg-3 col-md-6 col-sm-12">
                                                                        <TextField
                                                                            id="fechaId"
                                                                            disabled={initialValue?.concluido}
                                                                            label="Fecha de resguardo"
                                                                            name="fechaResguardo"
                                                                            type="date"
                                                                            holder="Escribe" />
                                                                    </div>
                                                                    <div className="col-lg-3 col-md-6 col-sm-12">
                                                                        <TextField
                                                                            id="usuarioBoletinaId"
                                                                            disabled={initialValue?.concluido}
                                                                            label="Hora de resguardo"
                                                                            name="horaResguardo"
                                                                            type="time"
                                                                            holder="Escribe" />
                                                                    </div>
                                                                    <div className="col-lg-3 col-md-6 col-sm-12">
                                                                        <TextField
                                                                            disabled={initialValue?.concluido}
                                                                            label="¿Quién entrega?"
                                                                            name="quienEntrega"
                                                                            type="text"
                                                                            holder="Escribe" />
                                                                    </div>
                                                                    <div className="col-lg-3 col-md-6 col-sm-12">
                                                                        <TextField
                                                                            disabled={initialValue?.concluido}
                                                                            label="¿Quién recibe?"
                                                                            name="quienRecibe"
                                                                            type="text"
                                                                            holder="Escribe" />
                                                                    </div>
                                                                </div>
                                                                <div className="row mt-2">
                                                                    <div className="col-lg-3 col-md-6 col-sm-12">
                                                                        <TextField
                                                                            disabled={initialValue?.concluido}
                                                                            label="Calle y número"
                                                                            name="calleNumero"
                                                                            type="text"
                                                                            holder="Escribe" />
                                                                    </div>
                                                                    <div className="col-lg-3 col-md-6 col-sm-12">
                                                                        <TextField
                                                                            disabled={initialValue?.concluido}
                                                                            label="Código postal"
                                                                            name="codigoPostal"
                                                                            type="text"
                                                                            holder="Escribe" />
                                                                    </div>
                                                                    <div className="col-lg-3 col-md-6 col-sm-12">
                                                                        <SelectField className="col-12" disabled={initialValue?.concluido} label="Estado" items={dataEstado} name={`edoMexicoIdE`} onChange={(event) => {
                                                                            setFieldValue(`edoMexicoIdE`, event.value);
                                                                            if (event.value !== "") {
                                                                                handleMunicipioById(event.value);
                                                                            } else {
                                                                                setFieldValue(`municipioIdE`, "");
                                                                                setFieldValue(`coloniaIdE`, "");
                                                                                setDataMunicipio([]);
                                                                                setDataColonia([])
                                                                            }
                                                                        }} />

                                                                    </div>
                                                                    <div className="col-lg-3 col-md-6 col-sm-12">
                                                                        <SelectField className="col-12" disabled={initialValue?.concluido} label="Municipio" items={dataMunicipio} name={`municipioIdE`} onChange={(event) => {
                                                                            setFieldValue(`municipioIdE`, event.value);
                                                                            if (event.value !== "") {
                                                                                handlecoloniaById(event.value)
                                                                            } else {
                                                                                setFieldValue(`coloniaIdE`, "");
                                                                                setDataColonia([])
                                                                            }
                                                                        }} />
                                                                    </div>

                                                                </div>
                                                                <div className="row mt-2">
                                                                    <div className="col-lg-3 col-md-6 col-sm-12">
                                                                        <SelectField className="col-12" disabled={initialValue?.concluido} label="Colonia" items={dataColonia} name={`coloniaIdE`} onChange={(event) => {
                                                                            setFieldValue(`coloniaIdE`, event.value);
                                                                        }} />
                                                                    </div>
                                                                </div>
                                                                <div className="row mt-2">
                                                                    <div className="col-9">
                                                                        <TextAreaField
                                                                            label="Comentarios"
                                                                            disabled={initialValue?.concluido}
                                                                            className="form-control col-12"
                                                                            rows="3"
                                                                            name="comentarios"
                                                                            type="text"
                                                                            holder="Escribe"
                                                                        />
                                                                    </div>
                                                                </div>
                                                            </div>
                                                        </div>
                                                    </Tab>
                                                </Tabs>
                                            </div>
                                            <section className='d-flex justify-content-end'>
                                                <div className="row">
                                                    <div className="col-auto d-flex">
                                                        <button
                                                            type='reset'
                                                            id="AT_BtnCloseModal"
                                                            className='btn btn-outline me-2'
                                                            style={{ paddingLeft: '25px', paddingRight: '25px' }}
                                                            onClick={() => { setIsOpen(false);;dispatch(increment(value)); }}
                                                        >
                                                            Cerrar
                                                        </button>
                                                        <button
                                                            disabled={initialValue?.concluido ? true : !(isValid)}
                                                            type='submit'
                                                            id="AT_BtnCloseModal"
                                                            className='btn'
                                                            style={{ paddingLeft: '25px', paddingRight: '25px' }}
                                                        >
                                                            Guardar
                                                        </button>
                                                    </div>
                                                </div>
                                            </section>
                                            <div className="row mt-4 d-flex justify-content-between">
                                                <div className="col-lg-3 col-md-6 col-sm-12">
                                                    <Field className="form-check-input" disabled={initialValue.concluido} type="checkbox" name="concluido" />
                                                    <label className="form-check-label" style={{ 'marginLeft': '10px' }}>Cierre de siniestro</label>
                                                </div>
                                                <div className="col-auto flex-column align-items-end">
                                                    <div className="col-auto">
                                                        {
                                                            initialValue.fechaInicio !== "" &&
                                                            <small className='text-body-tertiary'>Inicio de proceso: {initialValue.fechaInicio}</small>
                                                        }
                                                    </div>
                                                    <div className="col-auto">
                                                        {
                                                            initialValue.concluido &&
                                                            <small className='text-body-tertiary'>Inicio de proceso: {initialValue.fechaFin}</small>
                                                        }
                                                    </div>
                                                </div>
                                            </div>
                                        </div>
                                    </div>
                                </Form>
                            </section>
                        </Modal>
                        {
                            loading &&
                            <Modal isOpen={loading} color='transparent'><Spinner message={""} /></Modal>
                        }
                        {
                            isOpenModalPago &&
                            <PagoResguardoUnidadModal
                                item={itemSelected}
                                setIsOpenModalPago={setIsOpenModalPago}
                                isOpenModalPago={isOpenModalPago}
                                handleGetAll={() => { handleGetCostos(initialValue.siniestroResguardoUnidadId) }}
                            />
                        }
                    </>
                )
                }
            </Formik>
        </>
    )
}

export default ResguardoDeUnidadModal