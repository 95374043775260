import { useEffect, useState } from "react";
import { useNavigate } from "react-router-dom";
import { toast } from 'react-hot-toast';
import axios from 'axios';
import { FormatFecha, message, FormatNumberMoney, formatearMonto } from "../../Utils/functions";
import AuthLogin from "../../Utils/AuthLogin";
import CobranzaService from "../../Services/Cobranza/Cobranza";
import UnidadesRecuperadasService from "../../Services/Cartera/UnidadesRecuperadas";
import Llamada from "../../Services/Cartera/Llamadas";
import dataDocsServices from "../../Services/DataDocs/dataDocsServices";

const UnidadesRecuperadasDetalleHook = (state) => {
    const userId = sessionStorage.getItem('userId');
    const auth = new AuthLogin();
    let url = auth.UrlDataDocs();

    const columnsDetallePromesas = [
        // { field: 'folio_promesa', headerName: 'Folio Promesa' },
        // { field: 'contrato', headerName: 'Contrato' },
        { field: 'periodo', headerName: 'Periodo' },
        { field: 'dias_vencidos', headerName: 'Días Vencidos' },
        { field: 'fecha_promesa', headerName: 'Fecha Promesa' },
        { field: 'monto_vencido', headerName: 'Monto Vencido' },
        { field: 'monto_promesa', headerName: 'Monto Promesa' },
        { field: 'motivo_promesa_nombre', headerName: 'Motivo Promesa' },
        { field: 'monto_recuperado', headerName: 'Monto Cubierto' },
        { field: 'promesa_status', headerName: 'Estatus' },
        { field: 'ultimaActualizacion', headerName: 'Ultima Actualización' },
    ];

    const columnsGarantiaPromesas = [
        { field: 'folio', headerName: 'Folio Promesa' },
        { field: 'cobradores', headerName: 'Cobrador' },
        { field: 'montoVencidoFormat', headerName: 'Monto Vencido' },
        { field: 'montoPrometidoFormat', headerName: 'Monto Promesa' },
        { field: 'montoRecuperado', headerName: 'Monto Recuperado' },
        { field: 'fechaCompromisoFormat', headerName: 'Fecha Compromiso' },
        { field: 'dias_de_promesa', headerName: 'Días Vigencia' },
    ];

    const columnsHistorialPromesas = [
        { field: 'folioPromesa', headerName: 'Folio Promesa' },
        { field: 'cobrador', headerName: 'Cobrador' },
        { field: 'montoVencido', headerName: 'Monto Vencido' },
        { field: 'montoPromesa', headerName: 'Monto Promesa' },
        { field: 'montoRecuperado', headerName: 'Monto Recuperado' },
        { field: 'fechaCompromiso', headerName: 'Fecha Compromiso' },
        { field: 'fechaCumplimiento', headerName: 'Fecha de Cumplimiento' },
    ];

    const columnsInformacionContacto = [
        { field: 'relacion_solicitante', headerName: 'Relación con solicitante' },
        { field: 'nombre_ref', headerName: 'Nombre' },
        { field: 'apellido_paterno_ref', headerName: 'Apellido' },
        { field: 'domicilio_ref', headerName: 'Domicilio Principal' },
        { field: 'interacciones', headerName: 'Interacciones' },
        // { field: 'operacion_resultado_id_nombre', headerName: 'Última Interacción' }
    ];

    const columnsSubInformacionContacto = [
        { field: 'tipo_contacto', headerName: 'Tipo de Contacto' },
        { field: 'subtipo_contacto', headerName: 'Subtipo' },
        { field: 'dato', headerName: 'Valor' },
        { field: 'fecha', headerName: 'Ultima interaccion' },
        { field: 'interacciones', headerName: 'Interacciones' },
    ]

    const columnsSeguimientoLog = [
        { field: 'fecha', headerName: 'Periodo' },
        { field: 'cobrador', headerName: 'Cobrador' },
        { field: 'contacto', headerName: 'Contacto' },
        { field: 'nombre', headerName: 'Nombre' },
        { field: 'valor', headerName: 'Valor' },
        { field: 'operacion_id', headerName: 'Operacion' },
        { field: 'operacion_resultado_id_nombre', headerName: 'Resultado' },
        { field: 'aplica_visita_nombre', headerName: 'Aplica visita' },
        { field: 'motivo_visita_nombre', headerName: 'Motivo visita' },
        { field: 'acciones', headerName: 'Ver evidencia' },
        // { field: 'comentario_visita', headerName: 'Comentarios' },

    ];

    const [currentDataSeguimiento, setCurrentDataSeguimiento] = useState([{}]);
    const [userDetail, setUserDetail] = useState([]);
    const [loading, setLoading] = useState(true);
    const [modalMessage, setModalMessage] = useState({ isOpen: false, type: 1, title: '', message: '', data: [], authorizationType: 2, Origin: "Listado" });
    const navigate = useNavigate();

    // Promesas de pago
    const [currentDataPromesas, setCurrentDataPromesas] = useState([{}])
    const [dataGarantia, setDataGarantia] = useState([{}])
    const [dataGarantiaHistorial, setDataGarantiaHistorial] = useState([{}])
    const [promesasRotas, setPromesasRotas] = useState(0);
    const [promesaPagoData, setPromesaPagoData] = useState([{}])
    const [isOpenModalPromesaPagoData, setisOpenModalPromesaPagoData] = useState(false)
    const [isOpenModalPromesaPagoDelete, setisOpenModalPromesaPagoDelete] = useState(false)
    const [isOpenModalPromesaPagoIncumplimiento, setisOpenModalPromesaPagoIncumplimiento] = useState(false)
    const [isOpenModalPromesaPagoAmpliacion, setisOpenModalPromesaPagoAmpliacion] = useState(false)
    const [motivoEliminacion, setMotivoEliminacion] = useState([])

    //Directorio de contactos
    const [directorioValue, setDirectorioValue] = useState("Aval")
    const [isOpenModalReferencias, setisOpenModalReferencias] = useState(false)
    const [isOpenModalAgregarContacto, setisOpenModalAgregarContacto] = useState(false)
    const [isOpenModalDireccionContactos, setIsOpenModalDireccionContactos] = useState(false)
    const [isOpenModalRegistroLlamada, setIsOpenModalRegistroLlamada] = useState(false)
    const [currentDataSeguimientoLog, setCurrentDataSeguimientoLog] = useState([{}])
    const [dataEstado, setDataEstado] = useState([]);
    const [tipoContacto, setTipoContacto] = useState([]);
    const [subtipoContacto, setSubtipoContacto] = useState([]);
    const [promesasRotasTotal, setPromesasRotasTotal] = useState(3)
    const [infoContactoData, setInfoContactoData] = useState()
    const [infoContactos, setInfoContactos] = useState([{}])
    const [relacionSolicitante, setRelacionSolicitante] = useState([{}])
    const [infoContactosFiltered, setInfoContactosFiltered] = useState([{}])
    const [motivosVisita, setMotivosVisita] = useState([{}])
    const [tipoResultado, setTipoResultado] = useState([{}])

    //Subir Documentos
    const [isOpenDocs, setIsOpenDocs] = useState(false)
    const [fileName, setFileName] = useState("")
    const [SelectedDocs, setSelectedDocs] = useState([]);

    useEffect(() => {
        getAllData()
    }, [])

    const getAllData = async () => {
        setLoading(true)
        try {
            await handleGetSeguimientoContrato();
            // Promesas de Pago
            await handleFormatState()
            await handleGetPromesasdePago()
            await handleMotivoEliminacion()

            //Directorio de contactos
            await handleGetAllRelacionSolicitante()
            await handleGetEstadoMx()
            await handleGetTipoContacto()
            await handleGetSubtiposContacto()
            await  handleGetInformacionContactos()
            //Directorio de contactos----Parametros 
            await handleParametrosSistema()

            //Seguimiento de contactos 
            await handleContactosSeguimientoLog()
            
                setLoading(false)
        } catch (error) {
            setLoading(false)
        }
    }

    const handleFormatState = async ()=> {
        let newPromesa = state.ruta_item?.promesa
        
        let montoVencidoFormat =`$${newPromesa.monto_vencido}`
        let montoPrometidoFormat = `$${newPromesa.monto_prometido}`
        if (typeof newPromesa.monto_vencido === "number"){
            montoVencidoFormat = formatearMonto(newPromesa.monto_vencido)
        }
        if (typeof newPromesa.monto_prometido === "number"){
        montoPrometidoFormat = formatearMonto(newPromesa.monto_prometido)
        }
        const fechaCompromisoFormat = FormatFecha(newPromesa.fecha_compromiso)

        setDataGarantia([{
            ...newPromesa,
            montoVencidoFormat: montoVencidoFormat,  
            montoPrometidoFormat: montoPrometidoFormat, 
            fechaCompromisoFormat: fechaCompromisoFormat
        }])


        //Otro servicio? 
        const data = {
            FIELD: "promesa_status_id",
            VALUE: "B88E6296-5409-4FF5-A037-03EAEB3AF1B6"
          }
        await UnidadesRecuperadasService.getAllCobranzaPromesaPagoByFields(data)
            .then(res => {
                setDataGarantiaHistorial(res.data.responseData)
            }).catch(e => {
                if (e.response !== undefined)
                    setModalMessage({ ...modalMessage, isOpen: true, type: 1, message: e.response.data.message })
                else
                    setModalMessage({ ...modalMessage, isOpen: true, type: 1, message: e.message })
            })
    }
 

    const handleGetSeguimientoContrato = async () => {
        const data = {
            "verb": null,
            "id": null,
            "field": null,
            "value": null
        }
        await CobranzaService.getAllEjecutivosWithFullDetail(data)
            .then(res => {
                const userContracts = res.data.responseData.find(user => user.userid.toLowerCase() === userId.toLowerCase());
                let datosFormateados = userContracts.contratos.map((item) => {
                    const fechaPago = (item.fecha_ultimo_pago === "1990-01-01T00:00:00" || item.fecha_ultimo_pago === "1900-01-01T00:00:00") ? "-" : FormatFecha(item.fecha_ultimo_pago)
                    const fechaClave = (item.fecha_ultima_clave === "1990-01-01T00:00:00" || item.fecha_ultima_clave === "1900-01-01T00:00:00") ? "-" : FormatFecha(item.fecha_ultima_clave)
                    const saldoCredito = formatearMonto(item.saldo_credito);
                    const montoAdeudo = formatearMonto(item.monto_adeudo);
                    const pagoPeriodo = formatearMonto(item.pago_periodo);

                    return {
                      ...item,
                      fecha_ultimo_pago: fechaPago,
                      fecha_ultima_clave: fechaClave,
                      monto_adeudo: montoAdeudo,
                      saldo_credito: saldoCredito,
                      pago_periodo: pagoPeriodo
                    }
                })

                const contractDetail = datosFormateados.find(contract => contract.contrato_id === state.contrato) || [{}]

                const userDetail = userContracts.user_detail

                setCurrentDataSeguimiento(contractDetail)
                setUserDetail(userDetail)
            }).catch(e => {
                if (e.response !== undefined)
                    setModalMessage({ ...modalMessage, isOpen: true, type: 1, message: e.response.data.message })
                else
                    setModalMessage({ ...modalMessage, isOpen: true, type: 1, message: e.message })
            })
    }

    const handleReturnPage = () => {
        navigate(`/cobranza/unidades-recuperadas`);
    }

    const handleGetPromesasdePago = async () => {
        await CobranzaService.getAllPromesasDePago()
            .then(res => {
                let promesasRotas = 0
                const update = res.data.map(item => {

                    let colorFondo
                    let colorTexto

                    switch (item.promesa_status_nombre) {
                        case "Cumplida":
                            colorFondo = "#D8F2CD"
                            colorTexto = "#47A066"
                            break;
                        case "Rota":
                            colorFondo = "#F5CECE"
                            colorTexto = "#CE2222"
                            break;
                        case "Vigente":
                            colorFondo = "#FAE7BC"
                            colorTexto = "#B46B16"
                            break;
                        default:
                            break;
                    }

                    const fechaPromesa = FormatFecha(item.fecha_promesa)
                    const montoVencido = formatearMonto(item.monto_vencido)
                    const montoPromesa = formatearMonto(item.monto_promesa)
                    const montoRecuperado = formatearMonto(item.monto_recuperado)
                    
                    if (item.promesa_status_nombre === 'Rota') promesasRotas++

                    return {
                        ...item,
                        promesa_status: item.promesa_status_nombre,
                        colorFondo: colorFondo,
                        colorTexto: colorTexto,
                        fecha_promesa: fechaPromesa,
                        monto_vencido: montoVencido,
                        monto_promesa: montoPromesa,
                        monto_recuperado: montoRecuperado,
                    }
                })

                const promesasUpdate = update.filter(promesa => promesa.contrato_id === state.contrato) || [{}]

                setPromesasRotas(promesasRotas)
                setCurrentDataPromesas(promesasUpdate)

            }).catch(e => {
                if (e.response !== undefined)
                    setModalMessage({ ...modalMessage, isOpen: true, type: 1, message: e.response.data.message })
                else
                    setModalMessage({ ...modalMessage, isOpen: true, type: 1, message: e.message })
            })
    }

    const handlePromesasPago = (item) => {
        setPromesaPagoData(item)
        setisOpenModalPromesaPagoData(true)
    }

    const handleMotivoEliminacion = async () => {
        await CobranzaService.getAllMotivoEliminacion()
            .then(res => {
                let motivo = []
                res.data?.responseData?.map(item => {
                    if (item.activo) {
                        motivo.push({
                            value: item.id,
                            label: item.nombre
                        });
                    }
                });
                setMotivoEliminacion(motivo);
            })
    }

    const handlePromesasPagoDelete = (item) => {
        setPromesaPagoData(item)
        setisOpenModalPromesaPagoDelete(true)
    }

    const handleModalIncumplimiento = (value) => {
        // alert("Función ejecutada desde el componente hijo")
        setisOpenModalPromesaPagoIncumplimiento(true)
    }

    const handleModalAmpliacion = (item) => {
        // alert("Función ejecutada desde el componente hijo")
        setInfoContactoData(item)
        setisOpenModalPromesaPagoAmpliacion(true)
    }
    ///Directorio de contactos
    const handleSetStateTab = (value) => {
        setDirectorioValue(value)
    }

    const handleModalRef = (value) => {
        setisOpenModalReferencias(true)
    }

    const GetInformacionContactos = async () => {
        const data = {
            sol_id: state.ruta_item?.contrato.sol_id,
            contrato: state.contrato,
            vin: state.vin
        }

        let result = await CobranzaService.getInformacionContactos(data)
            .then(res => {
                let datosFormateados = res.data.responseData.datos_referencia.map((item) => {
                    if (item) {
                        let domicilio = `${item.calle_ref} ${item.no_ext_ref} ${item.colonia_ref}`
                        return {
                            ...item,
                            domicilio_ref: domicilio,
                            filter: false
                        }
                    }
                })
                return datosFormateados
            }).catch(e => {
                if (e.response !== undefined)
                    toast.error(message("Error.!", e.response.data.errors?.error));
                else
                    toast.error(message("Error.!", e.message));
            })

        return result
    }

    const handleGetInformacionContactos = async () => {
        const data = await GetInformacionContactos()

        const updateResults = await Promise.all(
            data.map(async (item) => {
                const updateItem = await AddInteracciones(item)

                item.interacciones = updateItem

                return item
            })
        )
        setInfoContactos(updateResults)
    }

    const AddInteracciones = async (item) => {
        let data = {
            field: "id_referencia_ascendes",
            value: item.idreferencia
        }
        try {
            const response = await CobranzaService.getRowsIteractions(data)
            return response.data.responseData; // Devuelve los datos de la respuesta
        } catch (e) {
            if (e.response !== undefined)
                toast.error(message("Error.!", e.response.data.errors?.error));
            else
                toast.error(message("Error.!", e.message));
        }
    };

    const handleGetAllRelacionSolicitante = async (id) => {
        await CobranzaService.getAllRelacionSolicitante()
            .then(res => {
                let relaciones = []
                res.data?.responseData?.map(item => {
                    if (item.activo) {
                        relaciones.push({
                            value: item.id,
                            label: item.nombre
                        })
                    }
                });
                setRelacionSolicitante(relaciones)
            })
    }


    const handleGetEstadoMx = async () => {
        await Llamada.GetEstadoMx().then(res => {
            let Estados = []
            res.data.data?.map(item => {
                Estados.push({
                    value: item.edoMexicoId,
                    label: item.estado
                });
            });
            setDataEstado(Estados);
        })
    }

    const handleGetTipoContacto = async () => {
        await CobranzaService.getAllTipoContacto()
            .then(res => {
                let tipos = []
                res.data.responseData?.map(item => {
                    if (item.activo) {
                        tipos.push({
                            value: item.id,
                            label: item.nombre
                        });
                    }
                });
                setTipoContacto(tipos);
            })
    }

    const handleGetSubtiposContacto = async () => {
        await CobranzaService.getAllSubTipoContacto()
            .then(res => {
                let subtipo = []
                res.data?.map(item => {
                    if (item.activo) {
                        subtipo.push({
                            value: item.id,
                            label: item.nombre
                        });
                    }
                });
                setSubtipoContacto(subtipo);
            })
    }

    const handleParametrosSistema = async () => {
        let promesasRotasField =
        {
            FIELD: "ID",
            VALUE: "PROM_ROTAS_NIVEL_1"
        }

        let totalPromesasRotas = await CobranzaService.getAllParametrosByField(promesasRotasField)

        setPromesasRotasTotal(totalPromesasRotas.data.responseData[0].valor)

    }

    const handleModalAdd = (value) => {
        // alert("Función ejecutada desde el componente hijo")
        setInfoContactoData(value)
        setisOpenModalAgregarContacto(true)
    }

    const handleDireccionesModal = (item) => {
        setInfoContactoData(item)
        setIsOpenModalDireccionContactos(true)
    }

    const handleRegistroLlamada = (item) => {
        setInfoContactoData(item)
        setIsOpenModalRegistroLlamada(true)
    }

    //Subir y Ver Documentos


    const handleGetTokenDatadocs = async () => {
        await dataDocsServices.UpdateTokenDataDc()
            .then(res => {
                auth.handleAuthSesionDataDocs(res.data.data);
            }).catch(e => { })
    }

    const handleViewdocs = async (item) => {
        setLoading(true);
        setFileName(item.nombreDocumento);
        await handleGetTokenDatadocs();
        await axios.post(`${url}Documento/GetFileById/${item.idFolder}/${item.documentoId}/false`, {}, {
            headers: {
                'Authorization': 'Bearer ' + auth.tokenDataDocs(),
            },
            responseType: "blob"
        })
            .then(res => {

                var file = new File([res.data], res.data.name, { type: res.data.type });
                setSelectedDocs([file])
                setLoading(false);
                setIsOpenDocs(true)
            }).catch(e => {

                setLoading(false);
                if (e.response !== undefined)
                    toast.error(message("Error.!", e.response.data.errors?.error));
                else
                    toast.error(message("Error.!", e.message));
            })
    }

    const handleUploadFile = async (value, subido) => {
        if (subido) {
            await handleViewdocs(value)
        } else {
            navigate(`/cobranza/seguimiento-telefonico/datadocs/fCobranza/eTelefono/${value.contrato}/${value.id}`, {
                state: { ...value, registroId: value.id }
            });
        }
    }

    useEffect(() => {
        const result = infoContactos?.filter(item =>
            item?.tipo?.toLowerCase().includes(directorioValue.toLowerCase())
        );
        setInfoContactosFiltered(result)
    }, [directorioValue, infoContactos])

    const handleContactosSeguimientoLog = async () => {


        let AllMotivos = await CobranzaService.getAllMotivoVisita()
            .then(res => {
                let newValueMotivos = [{
                    value: "",
                    label: null
                }]
                res.data.responseData?.map(item => {
                    if (item.activo) {
                        newValueMotivos.push({
                            value: item.id,
                            label: item.nombre
                        });
                    }
                });
                return newValueMotivos
            })



        let AllTipoResultados = await CobranzaService.getAllTipoResultado()
            .then(res => {
                let newValueTipo = [{
                    value: "",
                    label: null
                }]
                res.data.responseData?.map(item => {
                    if (item.activo) {
                        newValueTipo.push({
                            value: item.id,
                            label: item.nombre
                        });
                    }
                });
                return newValueTipo
            })


        const data = {
            "value": state.contrato
        }
        await CobranzaService.getAllSeguimientoContactosLogs(data)
            .then(res => {
                const update = res.data.responseData.map(item => {
                    const fechaFormat = FormatFecha(item.fecha)
                    const aplicaVisitaFormat = item.aplica_visita ? "Si" : "No"


                    const motivoVisitaFormat = AllMotivos?.find(motivo => motivo?.value?.toLowerCase() === item?.motivo_visita_id?.toLowerCase())
                    const resultadoFormat = AllTipoResultados?.find(resultado => resultado?.value?.toLowerCase() === item?.operacion_resultado_id?.toLowerCase() || null)

                    return {
                        ...item,
                        fecha: fechaFormat,
                        aplica_visita_nombre: aplicaVisitaFormat,
                        motivo_visita_nombre: motivoVisitaFormat.label,
                        operacion_resultado_id_nombre: resultadoFormat.label,
                        message: item.comentario_visita

                    }
                })

                setMotivosVisita(AllMotivos)
                setTipoResultado(AllTipoResultados)
                setCurrentDataSeguimientoLog(update)
            })


    }
    return {
        currentDataSeguimiento,
        userDetail,
        promesasRotas,
        loading,
        setLoading,
        handleReturnPage,

        //Catalogos
        motivoEliminacion,
        relacionSolicitante,
        dataEstado,
        tipoContacto,
        subtipoContacto,
        infoContactosFiltered,

        ///Promesas de Pago
        columnsDetallePromesas,
        columnsGarantiaPromesas,
        columnsHistorialPromesas,
        currentDataPromesas,
        dataGarantia,
        dataGarantiaHistorial,
        handlePromesasPago,
        handlePromesasPagoDelete,
        handleGetPromesasdePago,
        handleModalIncumplimiento,
        handleModalAmpliacion,
        isOpenModalPromesaPagoData,
        setisOpenModalPromesaPagoData,
        promesaPagoData,
        isOpenModalPromesaPagoDelete,
        setisOpenModalPromesaPagoDelete,
        isOpenModalPromesaPagoIncumplimiento,
        setisOpenModalPromesaPagoIncumplimiento,
        isOpenModalPromesaPagoAmpliacion, 
        setisOpenModalPromesaPagoAmpliacion,
        motivosVisita,
        tipoResultado,

        //Directorio de contactos
        columnsInformacionContacto,
        columnsSubInformacionContacto,
        currentDataSeguimientoLog,
        directorioValue,
        infoContactoData,
        handleSetStateTab,
        handleModalRef,
        handleGetInformacionContactos,
        handleModalAdd,
        handleDireccionesModal,
        handleRegistroLlamada,
        handleContactosSeguimientoLog,
        isOpenModalAgregarContacto,
        setisOpenModalAgregarContacto,
        isOpenModalReferencias,
        setisOpenModalReferencias,
        isOpenModalDireccionContactos,
        setIsOpenModalDireccionContactos,
        isOpenModalRegistroLlamada,
        setIsOpenModalRegistroLlamada,
        promesasRotasTotal,

        //Subido
        handleUploadFile,
        handleViewdocs,
        isOpenDocs,
        setIsOpenDocs,
        fileName,
        SelectedDocs,

        //Logs
        columnsSeguimientoLog,
    }
}

export default UnidadesRecuperadasDetalleHook;