import React, { useEffect, useRef, useState } from 'react';
import { useLocation, useNavigate, useParams } from 'react-router-dom';
import { toast, Toaster } from 'react-hot-toast';
import { message } from '../../../../Utils/functions'
import { Field, Form, Formik } from 'formik'
import * as Yup from 'yup';
import Modal from '../../../../Components/Modal/Modal';
import Spinner from '../../../../Components/Loadig';
import AuthLogin from '../../../../Utils/AuthLogin';
import { SelectField } from '../../../../Components/SelectField';
import { TextAreaField } from '../../../../Components/TextAreaField';
import { TextField } from '../../../../Components/TextField';
import LiberacionUnidadService from '../../../../Services/Inventario/Valuacion/liberacionUnidadService';
import Llamada from '../../../../Services/Cartera/Llamadas';
import Catalogo from '../../../../Services/Catalogos/Catalogo';
import SeguroSiniestrosService from '../../../../Services/Seguros/SeguroSiniestrosService';
import ListadoCostos from '../Detalle/Registro/ListadoCostos';

const auth = new AuthLogin();
const SiniestroResguardoUnidadRoboCard = ({ handleConcluido }) => {
    const { refFormik } = useRef();
    const { id } = useParams();
    const { state } = useLocation();
    const navigate = useNavigate();

    let url = auth.UrlDataDocs();
    const [loading, setLoading] = useState(false);
    const [collpsableGrua, setCollpsableGrua] = useState(false);
    const [dataMotivoResguardo, setDataMotivoResguardo] = useState([]);


    let initial = {
        siniestroId: id,
        siniestroResguardoUnidadId: "",
        origenTipoUbicacionRecoleccion: "",
        origenTipoAseguradora: "",
        destiboUbicacionStr: "",
        fechaCitaRecoleccion: "",
        formaArriboId: "",
        estadoRecoleccion: "",
        municipioRecoleccion: "",
        colonicaRecoleccion: "",
        calleRecoleccion: false,
        numeroRecoleccion: "",
        referenciaUbicacionRecoleccion: false,

        //Detalles de la entrega
        fechaResguardo: false,
        horaResguardo: "",
        quienEntrega: "",
        quienRecibe: "",
        calleNumero: "",
        codigoPostal: "",

        edoMexicoIdE: "",
        municipioIdE: "",
        coloniaIdE: "",
        referenciaUbicacion: "",

        concluido: false,
        fechaInicio: "",
        fechaFin: "",
        //Stepper Update        
        paso: 3,
        porcentaje: 75,
        status: 2,
        procesoId: state?.procesoId
    }

    const [initialValue, setInitialValue] = useState(initial);
    const [estados, setEstados] = useState([]);
    const [municipios, setMunicipios] = useState([]);
    const [colonias, setColonias] = useState([]);
    const [formasArribo, setFormasArribo] = useState([]);

    const [estadosDos, setEstadosDos] = useState([]);
    const [municipiosDos, setMunicipiosDos] = useState([]);
    const [coloniasDos, setColoniasDos] = useState([]);

    const [tipoUbicacion, setTipoUbicacion] = useState([]);
    useEffect(() => {
        getData()
    }, []);

    useEffect(() => {
        getEstados();
        getFormasArribo();
        handleMotivoResguardo();
        // getEstadosDos()
        // handleGetTipoUbicacion()
    }, [])

    const getData = async () => {
        setLoading(true)
        await SeguroSiniestrosService.getResguardoVehiculoSiniestros({ id, step: 3 })
            .then(resp => {
                let item = resp.data;
                let newData = {
                    //Detalle de Resguardo
                    siniestroId: item?.siniestroId ?? id,
                    siniestroResguardoUnidadId: item?.siniestroResguardoUnidadId ?? "",
                    origenTipoUbicacionRecoleccion: item?.origenTipoUbicacionRecoleccion ?? "",
                    origenTipoAseguradora: item?.origenTipoAseguradora ?? "",
                    destiboUbicacionStr: item?.destiboUbicacionStr ?? "",
                    fechaCitaRecoleccion: item?.fechaCitaRecoleccion ?? "",
                    formaArriboId: item?.formaArriboId ?? "",
                    motivoResguardoId: item?.motivoResguardoId ?? "",
                    estadoRecoleccion: item?.estadoRecoleccion ?? "",
                    municipioRecoleccion: item?.municipioRecoleccion ?? "",
                    colonicaRecoleccion: item?.colonicaRecoleccion ?? "",
                    calleRecoleccion: item?.calleRecoleccion ?? "",
                    numeroRecoleccion: item?.numeroRecoleccion ?? "",
                    referenciaUbicacionRecoleccion: item?.referenciaUbicacionRecoleccion ?? "",

                    //Detalles de la entrega
                    fechaResguardo: item?.fechaResguardo !== "" ? item?.fechaResguardo?.split("T")[0] : "",
                    horaResguardo: item?.horaResguardo ?? "",
                    quienEntrega: item?.quienEntrega ?? "",
                    quienRecibe: item?.quienRecibe ?? "",
                    calleNumero: item?.calleNumero ?? "",
                    codigoPostal: item?.codigoPostal ?? "",

                    edoMexicoIdE: item?.edoMexicoIdE ?? "",
                    municipioIdE: item?.municipioIdE ?? "",
                    coloniaIdE: item?.coloniaIdE ?? "",
                    referenciaUbicacion: item?.referenciaUbicacion ?? "",

                    subidoResguardo: item?.subidoResguardo ?? "",


                    concluido: item?.concluido ?? false,
                    fechaInicio: item.fechaInicio ?? "",
                    fechaFin: item.fechaFin ?? "",
                    //Stepper Update        
                    paso: 3,
                    porcentaje: 75,
                    status: 2,
                    procesoId: state?.procesoId
                }
                setInitialValue(newData);
                getMunicipios(item?.edoMexicoIdE);
                getColonias(item?.municipioIdE);

                handleConcluido({ tipo: 4, concluido: false, ubicacionvehiculo: false, requiereGrua: false, recoleccionconcluido: false, resguardoConcluido: item?.concluido ?? false });

            })
            .catch(err => {
                console.error(err);
            })
            .finally(() => setLoading(false));
    };
    const handleMotivoResguardo = async () => {
        await Catalogo.getDropdownsByTable('Catalogos.Cobranza_MotivoResguardo')
            .then(resp => {
                let NewData = [];
                resp.data.map(item => {
                    NewData.push({ value: item.id, label: item.valor });
                });
                setDataMotivoResguardo(NewData);
            })
            .catch(err => {
                console.error(err);
            });
    }
    const validate = Yup.object().shape({
        // formaArriboId: Yup.string()
        //     .required("El campo es requerido."),
        // motivoResguardoId: Yup.string()
        //     .required("El campo es requerido."),

        // fechaResguardo: Yup.string()
        //     .required("El campo es requerido."),
        // horaResguardo: Yup.string()
        //     .required("El campo es requerido."),
        // quienEntrega: Yup.string()
        //     .required("El campo es requerido."),
        // quienRecibe: Yup.string()
        //     .required("El campo es requerido."),
        // calleNumero: Yup.string()
        //     .required("El campo es requerido."),
        // codigoPostal: Yup.string()
        //     .required("El campo es requerido."),
        // edoMexicoIdE: Yup.string()
        //     .required("El campo es requerido."),

        // municipioIdE: Yup.string()
        //     .required("El campo es requerido."),

        // coloniaIdE: Yup.string()
        //     .required("El campo es requerido."),
        // referenciaUbicacion: Yup.string()
        //     .required("El campo es requerido."),
        //////////////////////////////////////////////
        formaArriboId: Yup.string()
            .when("concluido", {
                is: true,
                then: Yup.string()
                    .required("El campo es requerido.")
            }),
        motivoResguardoId: Yup.string()
            .when("concluido", {
                is: true,
                then: Yup.string()
                    .required("El campo es requerido.")
            }),
        fechaResguardo: Yup.string()
            .when("concluido", {
                is: true,
                then: Yup.string()
                    .required("El campo es requerido.")
            }),
        horaResguardo: Yup.string()
            .when("concluido", {
                is: true,
                then: Yup.string()
                    .required("El campo es requerido.")
            }),
        quienEntrega: Yup.string()
            .when("concluido", {
                is: true,
                then: Yup.string()
                    .required("El campo es requerido.")
            }),
        quienRecibe: Yup.string()
            .when("concluido", {
                is: true,
                then: Yup.string()
                    .required("El campo es requerido.")
            }),
        calleNumero: Yup.string()
            .when("concluido", {
                is: true,
                then: Yup.string()
                    .required("El campo es requerido.")
            }),
        codigoPostal: Yup.string()
            .when("concluido", {
                is: true,
                then: Yup.string()
                    .required("El campo es requerido.")
            }),
        edoMexicoIdE: Yup.string()
            .when("concluido", {
                is: true,
                then: Yup.string()
                    .required("El campo es requerido.")
            }),
        municipioIdE: Yup.string()
            .when("concluido", {
                is: true,
                then: Yup.string()
                    .required("El campo es requerido.")
            }),
        coloniaIdE: Yup.string()
            .when("concluido", {
                is: true,
                then: Yup.string()
                    .required("El campo es requerido.")
            }),
        referenciaUbicacion: Yup.string()
            .when("concluido", {
                is: true,
                then: Yup.string()
                    .required("El campo es requerido.")
            }),
    });
    const getFormasArribo = async () => {
        await Catalogo.getDropdownsByTable('Catalogos.ResguardoFormaArribo')
            .then(resp => {
                let list = [];
                resp.data.forEach(item => {
                    list.push({ value: item.ResguardoFormaArriboId, label: item.ResguardoFormaArribo });
                });
                setFormasArribo(list);
            })
            .catch(err => {
                toast.error(message('Error al cargar el catálogo de Formas de Arribo'));
            });
    };
    const submitForm = async (values) => {
        if (values.concluido) {
            if (!values.subidoResguardo) {
                toast.custom(<div className='warnin-toast'>{message("¡Alerta.!", "Falta subir evidencia.")}</div>);
                setTimeout(() => {
                    toast.remove();
                }, 3000);
                return;
            }
        }
        setLoading(true);
        await SeguroSiniestrosService.updateResguardoSiniestro(values)
            .then(res => {
                console.log("res", res)
                if (res.data.ban === 1) {
                    setLoading(false);
                    toast.success(message("Correcto!", res.data.mensaje));
                    getData()
                } else {
                    setLoading(false);
                    toast.error(message("¡Error!", res.data.mensaje));
                }

            })
            .catch(error => {
                setLoading(false);
                console.log(error)
            })
    }
    const handleGetTipoUbicacion = async () => {
        await Catalogo.getDropdownsByTable('Catalogos.Tipo_Ubicacion_Datamovil')
            .then(resp => {
                let newData = [];
                resp.data.forEach(item => {
                    newData.push({
                        value: item.TipoUbicacionId,
                        label: item.TipoUbicacion
                    });
                });

                setTipoUbicacion(newData);
            })
            .catch(err => {
            });
    }
    const getEstados = async () => {
        await Llamada.GetEstadoMx().then(res => {
            let resData = res?.data.data
            let Estados = []

            resData?.map(item => {
                Estados.push({
                    value: item.edoMexicoId,
                    label: item.estado
                });
            });
            setEstados(Estados);
        })
    }

    const getMunicipios = async (value) => {
        if (value === undefined)
            return;
        await Llamada.GetMunicipio(value).then(res => {
            let resData = res?.data?.data
            let Municipios = []
            resData?.map(item => {
                Municipios.push({
                    value: item.municipioId,
                    label: item.municipio
                });
            });
            setMunicipios(Municipios);
        })
    }

    const getColonias = async (value) => {
        if (value === undefined)
            return;
        await Llamada.GetColonia(value).then(res => {
            let resData = res?.data?.data
            let Colonias = []
            resData?.map(item => {
                Colonias.push({
                    value: item.coloniaId,
                    label: item.colonia
                });
            });
            setColonias(Colonias);
        })
    }

    const getEstadosDos = async () => {
        await Llamada.GetEstadoMx().then(res => {
            let resData = res?.data.data
            let Estados = []

            resData.map(item => {
                Estados.push({
                    value: item.edoMexicoId,
                    label: item.estado
                });
            });
            setEstadosDos(Estados);
        })
    }

    const getMunicipiosDos = async (value) => {
        if (value === undefined)
            return;
        await Llamada.GetMunicipio(value).then(res => {
            let resData = res?.data?.data
            let Municipios = []
            resData.map(item => {
                Municipios.push({
                    value: item.municipioId,
                    label: item.municipio
                });
            });
            setMunicipiosDos(Municipios);
        })
    }

    const getColoniasDos = async (value) => {
        if (value === undefined)
            return;
        await Llamada.GetColonia(value).then(res => {
            let resData = res?.data.data
            let Colonias = []
            resData.map(item => {
                Colonias.push({
                    value: item.coloniaId,
                    label: item.colonia
                });
            });
            setColoniasDos(Colonias);
        })
    }

    const handleUploadFile = () => {
        navigate(`/seguro/siniestros/datadocs/fSntroRobo/eCntPRes/${state?.folio}/${initialValue.siniestroId}`, {
            state: { ...initialValue, registroId: initialValue.siniestroResguardoUnidadId, vin: state.vin, nombreMarca: state.marca, nombreModelo: state.modelo, nombreVersion: state?.version, nombreYear: state.año }
        });
    }
    return (
        <>
            <Modal isOpen={loading} color='transparent'><Spinner message={""} /></Modal>
            <Toaster
                position="top-right"
                toastOptions={{
                    success: {
                        style: {
                            background: '#47a066',
                            color: '#FFFF',
                            borderLeft: '10px solid #2f7246'
                        },
                    },
                    error: {
                        style: {
                            background: '#d53f3f',
                            color: '#FFFF',
                            borderLeft: '10px solid #ac241a'
                        },
                    },
                }}
            />

            <div className='d-flex flex-column background-grey' style={{ height: 'auto' }}>
                <div className='row mx-md-4 my-4' >
                    <div className="col-12 px-md-3">
                        <div className="p-4 white-wrapper mb-2">

                            <div className="row mt-2 px-4" data-bs-toggle="collapse" href="#collapseResguardo" role="button" aria-expanded="false" aria-controls="collapseResguardo" onClick={() => setCollpsableGrua(!collpsableGrua)}>
                                <div className="col-11">
                                    <h6><span className={`semaforo semaforo-${initialValue?.concluido ? "green" : "default"}`}></span> <strong>Resguardo de unidad</strong></h6>
                                </div>
                                <div className="col-1 col-md-1 collapsable-icon" style={{ 'textAlign': 'right' }}>
                                    <i className={!collpsableGrua ? "icon-blue bx bx-chevron-down x2" : "icon-blue bx bx-chevron-up x2"}></i>
                                </div>
                                <div className='col-12'>
                                    <small>{initialValue.concluido ? "Concluido" : "En Proceso"}</small>
                                </div>
                            </div>
                            <div className="collapse" id="collapseResguardo">
                                <Formik
                                    innerRef={refFormik}
                                    enableReinitialize={true}
                                    validationSchema={validate}
                                    initialValues={initialValue}
                                    onSubmit={(values) => {
                                        submitForm(values)
                                    }}
                                >
                                    {({ isValid, values, setFieldValue, errors }) => (
                                        <Form>
                                            <div className="separator"></div>
                                            <div className="row mt-2 px-4 mb-2">
                                                <div className='col-3'>
                                                    <h6>
                                                        <strong>Detalle del resguardo</strong>
                                                    </h6>
                                                </div>
                                                <div className="col-9">
                                                    <div className="row justify-content-end">
                                                        <div className="col-auto">
                                                            <fieldset
                                                                disabled={initialValue?.concluido}
                                                                onClick={handleUploadFile}
                                                                className='btn btn-gray'>
                                                                <i className="icon-light fa fa-file "></i> Subir documento
                                                            </fieldset>
                                                        </div>
                                                        <div className="col-auto">
                                                            {
                                                                initialValue.concluido ?
                                                                    <fieldset className='btn btn-light secondary d-flex align-items-center mx-2'><i className="icon-dark bx bx-hide mx-1" ></i>Modo lectura</fieldset>
                                                                    : <button
                                                                        className='btn'
                                                                        disabled={initialValue?.concluido ? true : !(isValid)}
                                                                        type='submit'>
                                                                        Guardar
                                                                    </button>
                                                            }
                                                        </div>
                                                    </div>
                                                </div>
                                            </div>
                                            <div className="row mt-2 px-4">
                                                {
                                                    values.origenTipoUbicacionRecoleccion !== "" &&
                                                    <div className="col-12 col-lg-3">
                                                        <TextField
                                                            id="origenTipoUbicacionRecoleccion"
                                                            label="Tipo de ubicación"
                                                            name="origenTipoUbicacionRecoleccion"
                                                            holder="Escribe"
                                                            type="text"
                                                            disabled={true}
                                                        />
                                                    </div>
                                                }

                                                {
                                                    values.origenTipoUbicacionRecoleccion === "externo" &&
                                                    <>
                                                        <div className="col-12 col-lg-3">
                                                            <TextField
                                                                label="Tipo aseguradora"
                                                                name="origenTipoAseguradora"
                                                                holder="Escribe"
                                                                type="text"
                                                                disabled={true}
                                                            />
                                                        </div>
                                                        <div className="col-12 col-lg-3">
                                                            <TextField
                                                                id="destiboUbicacionStr"
                                                                label="Nombre ubicación"
                                                                name="destiboUbicacionStr"
                                                                holder="Escribe"
                                                                type="text"
                                                                disabled={true}
                                                            />
                                                        </div>
                                                        <div className="col-12 col-lg-3">
                                                            <TextField
                                                                id="fechaHoraCitaRecoleccion"
                                                                label="Fecha y hora de cita"
                                                                name="fechaCitaRecoleccion"
                                                                holder="Escribe"
                                                                type="text"
                                                                disabled={true}
                                                            />
                                                        </div>
                                                    </>
                                                }
                                                {
                                                    values.origenTipoUbicacionRecoleccion === "interno" &&
                                                    <>
                                                        <div className="col-12 col-lg-3">
                                                            <TextField
                                                                id="destiboUbicacionStr"
                                                                label="Ubicación"
                                                                name="destiboUbicacionStr"
                                                                holder="Escribe"
                                                                type="text"
                                                                disabled={true}
                                                            />
                                                        </div>
                                                        <div className="col-12 col-lg-3">
                                                            <TextField
                                                                id="fechaHoraCitaRecoleccion"
                                                                label="Fecha y hora de cita"
                                                                name="fechaCitaRecoleccion"
                                                                holder="Escribe"
                                                                type="text"
                                                                disabled={true}
                                                            />
                                                        </div>
                                                    </>
                                                }

                                            </div>
                                            <div className="row mt-2 px-4">
                                                <div className="col-12 col-lg-3">
                                                    <SelectField
                                                        id="motivoResguardoId"
                                                        label="Motivo resguardo"
                                                        name="motivoResguardoId"
                                                        disabled={initialValue?.concluido}
                                                        items={dataMotivoResguardo}
                                                        onChange={(event) => {
                                                            setFieldValue("motivoResguardoId", event.value);
                                                        }}
                                                    />
                                                </div>
                                                <div className="col-12 col-lg-3">
                                                    <SelectField
                                                        id="formaArriboId"
                                                        label="Forma de arribo"
                                                        name="formaArriboId"
                                                        disabled={initialValue?.concluido}
                                                        items={formasArribo}
                                                        onChange={(event) => {
                                                            setFieldValue("formaArriboId", event.value);
                                                        }}
                                                    />
                                                </div>
                                                {
                                                    values.origenTipoUbicacionRecoleccion === "externo" &&
                                                    <>
                                                        <div className="col-12 col-lg-3">
                                                            <TextField
                                                                id="estadoRecoleccion"
                                                                label="Estado"
                                                                holder="Escribe"
                                                                name="estadoRecoleccion"
                                                                type="text"
                                                                disabled={true}
                                                            />
                                                        </div>
                                                        <div className="col-12 col-lg-3">
                                                            <TextField
                                                                id="municipioRecoleccion"
                                                                label="Municipio"
                                                                holder="Escribe"
                                                                name="municipioRecoleccion"
                                                                type="text"
                                                                disabled={true}
                                                            />
                                                        </div>
                                                        <div className="col-12 col-lg-3">
                                                            <TextField
                                                                id="colonicaRecoleccion"
                                                                label="Colonia"
                                                                holder="Escribe"
                                                                name="colonicaRecoleccion"
                                                                type="text"
                                                                disabled={true}
                                                            />
                                                        </div>
                                                        <div className="col-12 col-lg-3">
                                                            <TextField
                                                                id="calleRecoleccion"
                                                                label="Calle y numero"
                                                                name="calleRecoleccion"
                                                                holder="Escribe"
                                                                type="text"
                                                                disabled={true}
                                                            />
                                                        </div>
                                                        {/* <div className="col-12 col-lg-3">
                                                            <TextField
                                                                id="numeroRecoleccion"
                                                                label="Número"
                                                                holder="Escribe"
                                                                name="numeroRecoleccion"
                                                                type="text"
                                                                disabled={true}
                                                            />
                                                        </div> */}
                                                    </>
                                                }

                                            </div>
                                            {
                                                values.origenTipoUbicacionRecoleccion === "externo" &&
                                                <div className="row mt-2 px-4">
                                                    <div className="col-12 col-lg-6">
                                                        <TextAreaField
                                                            id="referenciaUbicacionRecoleccion"
                                                            label="Referencia de Ubicación"
                                                            className="form-control col-12"
                                                            holder="Escribe"
                                                            rows="3"
                                                            name="referenciaUbicacionRecoleccion"
                                                            disabled={true}
                                                        />
                                                    </div>
                                                </div>
                                            }

                                            <ListadoCostos item={initialValue} siniestroResguardoUnidadId={initialValue.siniestroResguardoUnidadId} />

                                            <div className="row mt-2 px-4 align-items-center">
                                                <div className="col-auto">
                                                    <small><strong>Detalles de la entrega</strong></small>
                                                </div>
                                            </div>
                                            <div className="row mt-2 px-4">
                                                <div className="col-12 col-lg-3">
                                                    <TextField
                                                        id="fechaResguardo"
                                                        label="Fecha de resguardo"
                                                        disabled={initialValue?.concluido}
                                                        name="fechaResguardo"
                                                        type="date"
                                                        onChange={(event) => {
                                                            setFieldValue("fechaResguardo", event.target.value);
                                                        }}
                                                    />
                                                </div>
                                                <div className="col-12 col-lg-3">
                                                    <TextField
                                                        id="horaResguardo"
                                                        label="Hora de resguardo"
                                                        disabled={initialValue?.concluido}
                                                        name="horaResguardo"
                                                        holder="Escribe"
                                                        type="time"
                                                        onChange={(event) => {
                                                            setFieldValue("horaResguardo", event.target.value);
                                                        }}
                                                    />
                                                </div>
                                                <div className="col-12 col-lg-3">
                                                    <TextField
                                                        id="quienEntrega"
                                                        disabled={initialValue?.concluido}
                                                        label="¿Quién entrega?"
                                                        name="quienEntrega"
                                                        holder="Escribe"
                                                        type="text"
                                                        onChange={(event) => {
                                                            setFieldValue("quienEntrega", event.target.value);
                                                        }}
                                                    />
                                                </div>
                                                <div className="col-12 col-lg-3">
                                                    <TextField
                                                        id="quienRecibe"
                                                        disabled={initialValue?.concluido}
                                                        label="¿Quién recibe?"
                                                        holder="Escribe"
                                                        name="quienRecibe"
                                                        type="text"
                                                        onChange={(event) => {
                                                            setFieldValue("quienRecibe", event.target.value);
                                                        }}
                                                    />
                                                </div>
                                                <div className="col-12 col-lg-3">
                                                    <TextField
                                                        id="calleNumero"
                                                        disabled={initialValue?.concluido}
                                                        label="Calle y número"
                                                        name="calleNumero"
                                                        holder="Escribe"
                                                        type="text"
                                                        onChange={(event) => {
                                                            setFieldValue("calleNumero", event.target.value);
                                                        }}
                                                    />
                                                </div>
                                                <div className="col-12 col-lg-3">
                                                    <TextField
                                                        id="codigoPostal"
                                                        disabled={initialValue?.concluido}
                                                        label="Código postal"
                                                        name="codigoPostal"
                                                        holder="Escribe"
                                                        type="text"
                                                        onChange={(event) => {
                                                            setFieldValue("codigoPostal", event.target.value);
                                                        }}
                                                    />
                                                </div>
                                                <div className="col-12 col-lg-3">
                                                    <SelectField
                                                        id="edoMexicoIdE"
                                                        disabled={initialValue?.concluido}
                                                        label="Estado"
                                                        name="edoMexicoIdE"
                                                        items={estados}
                                                        onChange={(event) => {
                                                            setFieldValue("edoMexicoIdE", event.value);
                                                            if (event.value === "") {
                                                                setFieldValue("municipioIdE", "");
                                                                setFieldValue("coloniaIdE", "");
                                                                setMunicipios([]);
                                                                setColonias([])
                                                            } else
                                                                getMunicipios(event.value);
                                                        }}
                                                    />
                                                </div>
                                                <div className="col-12 col-lg-3">
                                                    <SelectField
                                                        id="municipioIdE"
                                                        disabled={initialValue?.concluido}
                                                        label="Municipio"
                                                        name="municipioIdE"
                                                        items={municipios}
                                                        onChange={(event) => {
                                                            setFieldValue("municipioIdE", event.value);
                                                            if (event.value === "") {
                                                                setFieldValue("coloniaIdE", "");
                                                                setColonias([])
                                                            } else
                                                                getColonias(event.value);
                                                        }}
                                                    />
                                                </div>
                                                <div className="col-12 col-lg-3">
                                                    <SelectField
                                                        id="coloniaIdE"
                                                        disabled={initialValue?.concluido}
                                                        label="Colonia"
                                                        name="coloniaIdE"
                                                        items={colonias}
                                                        onChange={(event) => {
                                                            setFieldValue("coloniaIdE", event.value);
                                                        }}
                                                    />
                                                </div>
                                            </div>
                                            <div className="row mt-2 px-4">
                                                <div className="col-12 col-lg-6">
                                                    <TextAreaField
                                                        id="referenciaUbicacion"
                                                        label="Referencia de ubicación"
                                                        className="form-control col-12"
                                                        rows="3"
                                                        disabled={initialValue?.concluido}
                                                        holder="Escribe"
                                                        name="referenciaUbicacion"
                                                        onChange={(event) => {
                                                            setFieldValue("referenciaUbicacion", event.target.value);
                                                        }}
                                                    />
                                                </div>
                                            </div>
                                            <div className='row mt-2 px-4'>
                                                <div className="col-12 col-lg-6">
                                                    <Field className="form-check-input" disabled={initialValue?.concluido} type="checkbox" name="concluido" onClick={(event) => {
                                                        setFieldValue("concluido", event.target.value);
                                                    }} />
                                                    <label className="form-check-label" style={{ 'marginLeft': '10px' }}>Resguardo de unidad concluida</label>
                                                </div>
                                            </div>
                                            <div className="row mt-2 px-4 d-flex justify-content-end">
                                                <div className="col-12 col-lg-6">
                                                    <div className="row flex-column align-items-end">
                                                        <div className="col-auto">
                                                            {
                                                                initialValue?.fechaInicio !== "" &&
                                                                <small className='text-body-tertiary'>Inicio de proceso: {initialValue?.fechaInicio}</small>
                                                            }
                                                        </div>
                                                        <div className="col-auto">
                                                            {
                                                                initialValue?.concluido &&
                                                                <small className='text-body-tertiary'>Fin de proceso: {initialValue?.fechaFin}</small>
                                                            }
                                                        </div>
                                                    </div>
                                                </div>
                                            </div>

                                        </Form>
                                    )}
                                </Formik>

                            </div>
                        </div>
                    </div>
                </div>
            </div>


        </>
    )
}
export default SiniestroResguardoUnidadRoboCard