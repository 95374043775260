import { createSlice } from '@reduxjs/toolkit'

const initialState = {
   contratoActual:{},
   itemGeneral:{},
   datosConsulta: {},
   visitadoliciliariaId: "",
   archivos:[]
}
export const SeguimientoVisitaDetalleSlice = createSlice({
   name: 'SeguimientoVisitaDetalleSlice',
   initialState,
   reducers: {
      setDatosConsulta: (state, action) => {
         return { ...state, datosConsulta: action.payload }
      },
      setLimpiarDatosConsulta: (state, action) => {
         return { 
            ...state,
            datosConsulta: {}
         }
      },
      setContrato:(state, action) => {
         return {
            ...state, 
            contratoActual: action.payload
         }
      },
      setLimpiarContratoActual: (state, action) => {
         return {...state,
            contratoActual:{}
         }
      },
      setItemGeneral:(state, action)=>{
         return {
            ...state,
            itemGeneral: action.payload
         }
      },
      setLimpiarItemGeneral:(state, action) =>{
         return {
            ...state,
            itemGeneral:{}
         }
      },
      setVisitaDomiciliariaId:(state, action)=>{
         return{
            ...state,
            visitadoliciliariaId: action.payload
         }
      },
      setLimpiarVisitaDomiciliariaId:(state, action)=>{
         return{
            ...state,
            visitadoliciliariaId: ""
         }
      }, 
      setArchivos:(state, action)=>{
         return{
            ...state,
            archivos: action.payload
         }
      }
   },
})

export const { setDatosConsulta, setLimpiarDatosConsulta, setContrato, setLimpiarContratoActual, setItemGeneral, setLimpiarItemGeneral,setVisitaDomiciliariaId, setLimpiarVisitaDomiciliariaId, setArchivos } = SeguimientoVisitaDetalleSlice.actions

export default SeguimientoVisitaDetalleSlice.reducer