import React, { useEffect, useRef, useState } from 'react'
import toast from 'react-hot-toast'
import useHistorialVisitasDetalle from "../../../Hooks/SeguimientoVisita/HistorialVisitasDetalle"
import VehiculoInfoCard from "../../../Components/VehiculoInfoCard"
import ContratoInfoCard from "../../../Components/ContratoInfoCard"
import CarteraInfoCard from "../../../Components/CarteraInfoCard"
import Spinner from "../../../Components/Loadig"
import Modal from "../../../Components/Modal/Modal"
import { Formik, Form, Field } from 'formik'
import { useDispatch, useSelector } from 'react-redux';
import CobranzaService from '../../../Services/Cobranza/Cobranza';
import { setVisitaDomiciliariaId } from '../../../Redux/Slice/Cobranza/SeguimientoVisita/SeguimientoVisitaDetalleSlice';
import { message } from '../../../Utils/functions';
import { TextAreaField } from '../../../Components/TextAreaField';
import { TextField } from '../../../Components/TextField';

const HistorialVisitasDetalle = () => {
    const {contratoActual, itemGeneral} = useSelector((state) => state.SeguimientoVisitaDetalleSlice);
    // const [showPromesaPago, setShowPromesaPago] = useState(false);
    const dispatch = useDispatch();
    // const [infoVisitaDom, setInfoVisitaDom] = useState({})
    const [initialValue, setInitialValue] = useState({});
    const [opcionSeleccionada, setOpcionSeleccionada] = useState(0);
    const [fechaInicioProceso, setFechaInicioProceso] = useState("23/10/2023 09:00")
    const [fechaFinProceso, setFechaFinProceso] = useState("23/10/2023 09:00")
    const [collpsableGrua, setCollpsableGrua] = useState(false);
    const [collpsableGruaPromesaPago, setCollpsableGruaPromesaPago] = useState(false);

    const [fechaInicio, setFechaInicio] = useState(new Date((new Date()).setDate((new Date()).getDate() + 1)));
    const [formularioActual, establecerFormularioActual] = useState(0);
   const refFormik = useRef();

    const {
        currentDataSeguimiento,
        userDetail,
        promesasRotas,
        loading,
        handleReturnPage,
        setLoading
    } = useHistorialVisitasDetalle();

    useEffect(()=>{
        GetAllVisitasDomiciliarias()
        if(itemGeneral.promesa !== null){
            handleCargarDatosPromesa();
         }
    },[])

    const getFormatFecha = (fecha) => {
        const year = fecha.getFullYear();
        const month = String(fecha.getMonth() + 1).padStart(2, '0');
        const day = String(fecha.getDate()).padStart(2, '0');
        return `${year}-${month}-${day}`;
     }

    const GetAllVisitasDomiciliarias = async() =>{
        let NewData = []
        setLoading(true)
        await CobranzaService.GetAllVisitasDomiciliarias()
            .then(res =>{
                res.data.responseData?.map(item=>{
                if(item.ruta_item_id === itemGeneral.id){
                    if(item.visitaDomDocs !== null){
                        item.visitaDomDocs?.map(file=>{
                        if(file.binario != null){
                            NewData.push({
                                value: file.binario.documentoId,
                                label: file.binario.nombreDocumento,
                                idFolder: file.binario.idFolder
                            })
                        }
                    })
                }
                dispatch(setVisitaDomiciliariaId(item.id))
                // setInfoVisitaDom(item);
                setOpcionSeleccionada(item.entregaste_cliente === 0 ? 2 : 1)
                setInitialValue({
                    num_visita: contratoActual.visitas,
                    entregaste_cliente: item.entregaste_cliente === 0 ? "No" : "Si",
                    resultado: item.resultado,
                    encontraste_documento: item.encontraste_documento === 0 ? "No" : "Si",
                    tipo_documento: item.tipo_documento,
                    motivo: item.motivo,
                    especificacion: item.especificacion,
                    motivo_ingreso: item.motivo_ingreso,
                    comentarios: item.comentarios,
                    comentario_ingreso: item.comentario_ingreso,
                    reg_concluido: item.reg_concluido === 1 ? true : false
                });
                // setShowPromesaPago(true);
                }
            })
            setLoading(false)
            })
            .catch(e => {
                setLoading(false)
                // setShowPromesaPago(false);
                if (e.response !== undefined)
                    toast.error(message("¡Error!", e.response.data.message));
                else
                    toast.error(message("¡Error!", e.message));
            })
    } 

    const getDiasPromesa = (fecha) => {
        return (new Date(fecha) - new Date()) / (1000 * 60 * 60 * 24);
     }

    const calcularTotal = (fecha) => {
        let frecuencia = 7;
        if (contratoActual.frecuencia_pago?.toLowerCase() == "quincenal") {
           frecuencia = 15;
        } else if (contratoActual.frecuencia_pago?.toLowerCase() == "mensual") {
           frecuencia = 30;
        }
        let dias_promesa = getDiasPromesa(fecha);
        // console.log(dias_promesa);
        const result = Math.floor(dias_promesa / frecuencia);
        // console.log(result);
        
        return result * contratoActual.pago_periodo + contratoActual.monto_adeudo;
     }

     let initialGarantiaPago = {
        tipo_promesa: "Garantía de pago",
        folio: "Por definir",
        proceso: "",
        cobrador_apoyo: "",
        cobradores: "",
        grua: "",
        fecha_vencimiento: getFormatFecha(fechaInicio),
        dias_de_promesa: getDiasPromesa(fechaInicio),
        monto_vencido: contratoActual.monto_adeudo,
        pago_periodo: contratoActual.pago_periodo,
        total: calcularTotal(fechaInicio),
        registro_concluido: false,
        fechaInicioProceso: "23/10/2023 09:00",
        fechaFinProceso: "23/10/2023 09:00"
     };
     let initialPromesaPago = {
        tipo_promesa: "Promesa de pago",
        motivo_promesa: "",
        fecha_compromiso: "",
        horarioCompromiso: "",
        monto_prometido: "",
        monto_vencido: contratoActual.monto_adeudo,
        comentarios: "",
        registro_concluido: false,
        fechaInicioProceso: "23/10/2023 09:00",
        fechaFinProceso: "23/10/2023 09:00"
     };
     let initialPromesaEntregaDefinitiva = {
        tipo_promesa: "Promesa de entrega definitiva",
        folio: "Por definir",
        proceso: "",
        cobrador_apoyo: "",
        cobradores: "",
        grua: "",
        registro_concluido: false,
        fechaInicioProceso: "23/10/2023 09:00",
        fechaFinProceso: "23/10/2023 09:00"
     };

    const [datosGarantiaPago, setDatosGarantiaPago] = useState(initialGarantiaPago);
    const [datosPromesaPago, setDatosPromesaPago] = useState(initialPromesaPago);
    const [datosPromesaEntregaDefinitiva, setDatosPromesaEntregaDefinitiva] = useState(initialPromesaEntregaDefinitiva);

   const handleCargarDatosPromesa = () =>{
        if(itemGeneral.promesa.tipo_promesa === "Garantía de pago"){
            let date = new Date(itemGeneral.promesa.fecha_vencimiento);
            let year = date.getFullYear();
            let month = (date.getMonth() + 1).toString().length === 1 ? `0${date.getMonth() + 1}` :(date.getMonth() +1)
            let day = date.getDate().toString().length === 1 ? `0${date.getDate()}` :date.getDate()
            setDatosGarantiaPago({
                tipo_promesa: "Garantía de pago",
                folio: `${itemGeneral.promesa.folio}`,
                proceso: itemGeneral.promesa.proceso,
                cobrador_apoyo: itemGeneral.promesa.cobrador_apoyo === 1 ?"Si" : "No",
                cobradores: itemGeneral.promesa.cobradores,
                grua: itemGeneral.promesa.grua === 1 ?"Si" : "No",
                fecha_vencimiento: `${year}-${month}-${day}`,
                dias_de_promesa: itemGeneral.promesa.dias_de_promesa,
                monto_vencido: itemGeneral.promesa.monto_vencido,
                pago_periodo: itemGeneral.promesa.pago_periodo,
                total: calcularTotal(`${year}-${month}-${day}`),
                registro_concluido: false,
                fechaInicioProceso: "23/10/2023 09:00",
                fechaFinProceso: "23/10/2023 09:00"
            })
        }
        if(itemGeneral.promesa.tipo_promesa === "Promesa de pago"){
            establecerFormularioActual(1)
            let date = new Date(itemGeneral.promesa.fecha_compromiso);
            let year = date.getFullYear();
            let month = (date.getMonth() + 1).toString().length === 1 ? `0${date.getMonth() + 1}` :(date.getMonth() +1)
            let day = date.getDate().toString().length === 1 ? `0${date.getDate()}` :date.getDate()
            let hours = date.getHours().toString().length === 1 ? `0${date.getHours()}` : date.getHours()
            let minutes = date.getMinutes().toString().length === 1 ? `0${date.getMinutes()}` : date.getMinutes()
            setDatosPromesaPago({
                tipo_promesa: "Promesa de pago",
                motivo_promesa: itemGeneral.promesa.motivo_promesa,
                fecha_compromiso: `${year}-${month}-${day}`,
                horarioCompromiso: `${hours}:${minutes}`,
                monto_prometido: itemGeneral.promesa.monto_prometido,
                monto_vencido: contratoActual.monto_adeudo,
                comentarios: itemGeneral.promesa.comentarios,
                registro_concluido: false,
                fechaInicioProceso: "23/10/2023 09:00",
                fechaFinProceso: "23/10/2023 09:00"
            })
        }
 
        if(itemGeneral.promesa.tipo_promesa === "Promesa de entrega definitiva"){
            establecerFormularioActual(2)
            setDatosPromesaEntregaDefinitiva({
                tipo_promesa: "Promesa de entrega definitiva",
                folio: `${itemGeneral.promesa.folio}`,
                proceso: itemGeneral.promesa.proceso,
                cobrador_apoyo: itemGeneral.promesa.cobrador_apoyo,
                cobradores: itemGeneral.promesa.cobradores === "Si" ? 1 : 2,
                grua: itemGeneral.promesa.grua,
                registro_concluido: false,
                fechaInicioProceso: "23/10/2023 09:00",
                fechaFinProceso: "23/10/2023 09:00"
            })
        }
    }

    return (
        <>
            <Modal isOpen={loading} color='transparent'><Spinner message={""} /></Modal>
            <div style={{ background: "#F5F5F5", paddingBottom: "1rem" }}>
                <header className='heder-subtitle d-flex justify-content-start align-items-center border-bottom px-2' style={{ background: "#FFF" }}>
                    <div className='cursor-pointer' onClick={() => { handleReturnPage() }}>
                        <i className="bx bxs-chevron-left mx-2"></i>
                        Regresar a listado de seguimiento de visita 
                    </div>
                </header>
                <div className="col col-11 d-flex">
                    <div className="col col-6 px-4 py-2">
                        <VehiculoInfoCard 
                            VIN={currentDataSeguimiento.vin}
                            verClaves = {true}
                            datosVehiculo={contratoActual.vehiculo}/>
                    </div>
                    <div className="col col-6 px-4 py-2">
                        <ContratoInfoCard contrato={currentDataSeguimiento}/>
                    </div>
                </div>
                <div className="col col-11 px-4 py-2">
                    <CarteraInfoCard 
                        contrato={currentDataSeguimiento}
                        user={userDetail}
                        promesasRotas={promesasRotas}/>
                </div>
                <div className="col col-11 px-4 py-2">
                <div className="row wrapper-vehiculo">
                    <div className="col col-12">
                    <div className="row mx-md-4 my-4">
                        <div className="col-12 px-md-3">
                        <div className="p-4 white-wrapper mb-2">
                            <div
                            className="row mt-2 px-4"
                            data-bs-toggle="collapse"
                            href="#collapseVisitaDomiciliaria"
                            role="button"
                            aria-expanded="false"
                            aria-controls="collapseRegistroTramite"
                            onClick={() => setCollpsableGrua(!collpsableGrua)}
                            >
                            <div className="col-11">
                                <h6><span className={`semaforo semaforo-${initialValue.color}`} ></span>{" "} <strong>Visita Domiciliaria</strong></h6>
                            </div>
                            <div className="col-1 col-md-1 collapsable-icon" style={{ textAlign: "right" }} >
                                <i className={ !collpsableGrua ? "icon-blue bx bx-chevron-down x2" : "icon-blue bx bx-chevron-up x2" }></i>
                            </div>
                            </div>
                            <div className="collapse" id="collapseVisitaDomiciliaria">
                            <Formik
                                enableReinitialize={true}
                                initialValues={initialValue}
                                >
                                {({ isValid, values, setFieldValue, errors, actions }) => (
                                <>
                                    <Form>
                                    <div className="separator"></div>
                                    <div className="row mt-2 px-4">
                                        <div className="col-2 mt-4">
                                            <p><span style={{ fontSize: "14px", fontWeight: "bold" }}><strong>Detalle de visita</strong></span></p>
                                        </div>
                                    </div>
                                    <div className="mb-3 row px-4" >
                                        <div className="col-12 col-lg-2">
                                            <TextField label="Número de Visita" name="num_visita" type="text" holder={0} disabled={true} />
                                        </div>
                                        <div className="col-12 col-lg-2">
                                            <TextField label="¿Encontarste al Cliente?" name="entregaste_cliente"  type="text" holder={values.entregaste_cliente}  disabled={true} />
                                        </div>
                                        {
                                        opcionSeleccionada === 1 && (
                                        <>
                                            <div className="col-12 col-lg-2">
                                                <TextField label="Resultado" name="resultado"  type="text" holder={values.resultado}  disabled={true} />
                                            </div>
                                            <div className="col-12 col-lg-2">
                                                <TextField label="¿Entregaste documentos?" name="encontraste_documento"  type="text" holder={values.encontraste_documento}  disabled={true} />                                            
                                            </div>
                                            <div className="col-12 col-lg-3">
                                                <TextField label="Tipo de documento" name="tipo_documento"  type="text" holder={values.tipo_documento}  disabled={true} />
                                            </div>
                                        </>
                                        )}
                                        {
                                        opcionSeleccionada === 2 && (
                                        <>
                                            <div className="col-12 col-lg-2">
                                                <TextField label="Motivo" name="motivo"  type="text" holder={values.motivo}  disabled={true} />
                                            </div>
                                            <div className="col-12 col-lg-2">
                                                <TextField label="Especificación" name="especificacion"  type="text" holder={values.especificacion}  disabled={true} />
                                            </div>
                                        </>
                                        )}
                                    </div>
                                    {
                                        opcionSeleccionada === 2 && (
                                        <>
                                            <div className="mb-3 row px-4" >
                                                <div className="col-12 col-lg-2">
                                                    <TextField label="¿Entregaste Documentos?" name="encontraste_documento"  type="text" holder={values.encontraste_documento}  disabled={true} />
                                                </div>
                                                <div className="col-12 col-lg-3">
                                                    <TextField label="Tipo de documento" name="tipo_documento"  type="text" holder={values.tipo_documento}  disabled={true} />
                                                </div>
                                            </div>
                                        </>
                                    )}
                                    <div className="mb-3 row px-4" style={{ marginTop: 30 }}>
                                    <div className="col-10">
                                        <TextAreaField
                                        label="Comentarios:"
                                        className="col-8 mt-2"
                                        rows="4"
                                        name="comentarios"
                                        type="text"
                                        holder={ values.comentarios}
                                        disabled={true}
                                        style={{
                                            border: "none",
                                            backgroundColor: "#f0f0f0",
                                            resize: "none",
                                        }}
                                        />
                                    </div>
                                    </div>
                                    <div className="row mb-3 px-4">
                                        <div className="col-2 mt-3">
                                        <p><span style={{ fontSize: "14px", fontWeight: "bold" }} >Ingreso a cartera</span></p>
                                        </div>
                                    </div>
                                    <div className="row mb-3 px-4">
                                        <div className="col-12 col-lg-3">
                                            <TextField label="Motivo de ingreso" name="motivo_ingreso"  type="text" holder={values.motivo_ingreso}  disabled={true} />
                                        </div>
                                    </div>
                                    <div className="mb-3 row px-4" style={{ marginTop: 30 }}>
                                        <div className="col-6">
                                        <TextAreaField
                                            label="Comentarios de ingreso a cartera:"
                                            className="col-8 mt-2"
                                            rows="4"
                                            name="comentarios"
                                            type="text"
                                            holder={ values.comentario_ingreso}
                                            disabled={true}
                                            style={{
                                            border: "none",
                                            backgroundColor: "#f0f0f0",
                                            resize: "none",
                                            }}
                                        />
                                        </div>
                                    </div>
                                    <div className='my-2 mt-4 px-4'>
                                        <div className="mb-3 row">
                                        <div className="col-sm-12 col-md-6 col-lg-3">
                                            <Field className="form-check-input" type="checkbox" value={values.reg_concluido} checked={values.reg_concluido} disabled={true} name="reg_concluido" onClick={(event) => { 
                                                setFieldValue("reg_concluido", event.target.checked); 
                                            }} />
                                            <label className="form-check-label" style={{ 'marginLeft': '10px' }}>Registro concluido</label>
                                        </div>
                                        <div className="col-sm-12 col-md-6 col-lg-9 text-end">
                                            <label>Inicio de proceso: {fechaInicioProceso}</label> | <label>Fin de proceso: {fechaFinProceso}</label>
                                        </div>
                                        </div>
                                    </div>
                                    </Form>
                                </>
                                )}
                            </Formik>
                            </div>
                        </div>
                        </div>
                    </div>
                    </div>
                </div>
                </div>
                {/* PROMESAS DE PAGO */}
                <div className="col col-11 px-4 py-2">
                    <div className="row wrapper-vehiculo">
                        <div className="col col-12">
                            <div className="row mx-md-4 my-4">
                                <div className="col-12 px-md-3">
                                    <div className="p-4 white-wrapper mb-2">
                                        <div
                                            className="row mt-2 px-4"
                                            data-bs-toggle="collapse"
                                            href="#collapsePromesaPago"
                                            role="button"
                                            aria-expanded="false"
                                            aria-controls="collapseRegistroTramite"
                                            onClick={() => setCollpsableGruaPromesaPago(!collpsableGruaPromesaPago)}
                                        >
                                            <div className="col-11"> <h6> <span className={`semaforo semaforo-`} ></span>{" "} <strong>Promesa de Pago</strong> </h6> </div>
                                            <div className="col-1 col-md-1 collapsable-icon" style={{ textAlign: "right" }} >
                                                <i className={ !collpsableGruaPromesaPago ? "icon-blue bx bx-chevron-down x2" : "icon-blue bx bx-chevron-up x2" }></i>
                                            </div>
                                        </div>
                                        <div className="collapse" id="collapsePromesaPago">
                                            <div className="separator"></div>
                                            <div className="row mt-2 px-4">
                                                {/* registro de promesa */}
                                                {/* tipo promesa: garantia de pago */}
                                                {formularioActual === 0 && (
                                                    <Formik innerRef={refFormik}
                                                        enableReinitialize={true}
                                                        initialValues={datosGarantiaPago}
                                                    >
                                                        {({ isValid, values, setFieldValue }) => (
                                                        <Form>
                                                            <div className="row">
                                                                <div className="col-sm-12 col-md-3">
                                                                    <p className="text-base"><strong>Detalle de promesa de pago</strong></p>
                                                                </div>
                                                            </div>
                                                            <div className='my-2 mt-4'>
                                                                <div className="mb-3 row">
                                                                    <div className="col-sm-12 col-md-6 col-lg-3">
                                                                        <TextField label="Tipo de promesa" name="tipo_promesa" type="text" holder="" disabled={true}/>
                                                                    </div>
                                                                    <div className="col-sm-12 col-md-6 col-lg-3">
                                                                        <TextField label="Folio" name="folio" type="text" holder="" disabled={true}/>
                                                                    </div>
                                                                    <div className="col-sm-12 col-md-6 col-lg-3">
                                                                        <TextField label="Proceso" name="proceso" type="text" holder="" disabled={true}/>
                                                                    </div>
                                                                    <div className="col-sm-12 col-md-6 col-lg-3">
                                                                        <TextField id="cobrador_apoyo" label="Cobrador de apoyo" name="cobrador_apoyo" holder="" disabled={true}/>
                                                                    </div>
                                                                </div>
                                                                <div className="mb-3 row">
                                                                    <div className="col-sm-12 col-md-6 col-lg-3">
                                                                        <TextField id="cobradores" label="Cobradores (en caso de aplicar)" name="cobradores" holder="" disabled={true}/>
                                                                    </div>
                                                                    <div className="col-sm-12 col-md-6 col-lg-3">
                                                                        <TextField id="grua" label="Grua" name="grua" holder="" disabled={true}/>
                                                                    </div>
                                                                </div>
                                                            </div>
                                                            <p className="text-base"><strong>Fecha de promesa</strong></p>
                                                            <div className='my-2 mt-4'>
                                                                <div className="mb-3 row">
                                                                    <div className="col-sm-12 col-md-6 col-lg-3">
                                                                        <TextField id="fecha_vencimiento" label="Fecha de vencimiento" name="fecha_vencimiento" type="date" holder="Escribe" disabled={true} />
                                                                    </div>
                                                                    <div className="col-sm-12 col-md-6 col-lg-3">
                                                                        <TextField label="Días de promesa" name="dias_de_promesa" type="text" holder="" disabled={true} />
                                                                    </div>
                                                                </div>
                                                            </div>
                                                            <p className="text-base"><strong>Monto por cubrir</strong></p>
                                                            <div className='my-2 mt-4'>
                                                                <div className="mb-3 row">
                                                                    <div className="col-sm-12 col-md-6 col-lg-3 d-flex align-items-center">
                                                                        <TextField label="Monto vencido" name="monto_vencido" type="text" holder="" disabled={true} />
                                                                    <div className="mt-3 ms-3">
                                                                        <i className="icon-light fa fa-plus ms-2" style={{ color: '#1351A5' }}></i>
                                                                    </div>
                                                                    </div>
                                                                    <div className="col-sm-12 col-md-6 col-lg-3 d-flex align-items-center">
                                                                        <TextField label="Pago de periodo" name="pago_periodo" type="text" holder="" disabled={true} />
                                                                    <div className="mt-3 ms-3">
                                                                        <i className="icon-light fa fa-equals ms-2" style={{ color: '#1351A5' }}></i>
                                                                    </div>
                                                                    </div>
                                                                    <div className="col-sm-12 col-md-6 col-lg-3">
                                                                    <TextField label="Total" name="total" type="text" holder="" disabled={true}/>
                                                                    </div>
                                                                </div>
                                                                <div className="mb-3 row">
                                                                    <div className="col-sm-12 col-md-6 col-lg-3">
                                                                        <Field className="form-check-input" type="checkbox" name="registro_concluido" disabled={true} />
                                                                        <label className="form-check-label" style={{ 'marginLeft': '10px' }}>Registro concluido</label>
                                                                    </div>
                                                                    <div className="col-sm-12 col-md-6 col-lg-9 text-end">
                                                                        <label>Inicio de proceso: {values.fechaInicioProceso}</label> | <label>Fin de proceso: {values.fechaFinProceso}</label>
                                                                    </div>
                                                                </div>
                                                            </div>
                                                        </Form>
                                                        )}
                                                    </Formik >
                                                )}
                                                {/* tipo promesa: promesa de pago */}
                                                {formularioActual === 1 && (
                                                    <Formik innerRef={refFormik}
                                                        enableReinitialize={true}
                                                        initialValues={datosPromesaPago}
                                                    >
                                                        {({ isValid, values, setFieldValue }) => (
                                                        <Form>
                                                            <div className="row">
                                                                <div className="col-sm-12 col-md-3">
                                                                    <p className="text-base"><strong>Detalle de promesa de pago</strong></p>
                                                                </div>
                                                            </div>
                                                            <div className='my-2 mt-4'>
                                                                <div className="mb-3 row">
                                                                    <div className="col-sm-12 col-md-6 col-lg-3">
                                                                        <TextField label="Tipo de promesa" name="tipo_promesa" type="text" holder="" disabled={true} />
                                                                    </div>
                                                                    <div className="col-sm-12 col-md-6 col-lg-3">
                                                                        <TextField label="Motivo promesa" name="motivo_promesa" type="text" holder="" disabled={true}/>
                                                                    </div>
                                                                    <div className="col-sm-12 col-md-6 col-lg-3">
                                                                        <TextField id="fecha_compromiso" label="Fecha compromiso" name="fecha_compromiso" type="date" holder="" disabled={true}/>
                                                                    </div>
                                                                    <div className="col-sm-12 col-md-6 col-lg-3">
                                                                    <TextField id="horarioCompromiso" label="Horario compromiso" name="horarioCompromiso" type="time" holder="" disabled={true} />
                                                                    </div>
                                                                </div>
                                                                <div className="mb-3 row">
                                                                    <div className="col-sm-12 col-md-6 col-lg-3">
                                                                        <TextField label="Monto prometido" name="monto_prometido" type="text" holder="" disabled={true}/>
                                                                    </div>
                                                                    <div className="col-sm-12 col-md-6 col-lg-3">
                                                                        <TextField label="Monto vencido" name="monto_vencido" type="text" holder="" disabled={true}/>
                                                                    </div>
                                                                </div>
                                                            </div>
                                                            <div className='my-2 mt-4'>
                                                                <div className="mb-3 row">
                                                                    <div className="col-sm-12 col-md-6 col-lg-6">
                                                                        <TextAreaField label="Comentarios promesa de pago" className="form-control col-12" rows="4" name="comentarios" type="text" holder="" disabled={true}/>
                                                                    </div>
                                                                </div>
                                                            </div>
                                                            <div className='my-2 mt-4'>
                                                                <div className="mb-3 row">
                                                                    <div className="col-sm-12 col-md-6 col-lg-3">
                                                                    <Field className="form-check-input" type="checkbox" name="registro_concluido" disabled={true}/>
                                                                    <label className="form-check-label" style={{ 'marginLeft': '10px' }}>Registro concluido</label>
                                                                    </div>
                                                                    <div className="col-sm-12 col-md-6 col-lg-9 text-end">
                                                                    <label>Inicio de proceso: {values.fechaInicioProceso}</label> | <label>Fin de proceso: {values.fechaFinProceso}</label>
                                                                    </div>
                                                                </div>
                                                            </div>
                                                        </Form>
                                                        )}
                                                    </Formik >
                                                )}
                                                {/* tipo promesa: promesa de entrega definitiva */}
                                                {formularioActual === 2 && (
                                                    <Formik innerRef={refFormik}
                                                        enableReinitialize={true}
                                                        initialValues={datosPromesaEntregaDefinitiva}
                                                    >
                                                        {({ isValid, values, setFieldValue }) => (
                                                        <Form>
                                                            <div className="row">
                                                                <div className="col-sm-12 col-md-3">
                                                                    <p className="text-base"><strong>Detalle de promesa de pago</strong></p>
                                                                </div>
                                                            </div>
                                                            <div className='my-2 mt-4'>
                                                                <div className="mb-3 row">
                                                                    <div className="col-sm-12 col-md-6 col-lg-3">
                                                                        <TextField label="Tipo de promesa" name="tipo_promesa" type="text" holder="" disabled={true} />
                                                                    </div>
                                                                    <div className="col-sm-12 col-md-6 col-lg-3">
                                                                        <TextField label="Folio" name="folio" type="text" holder="" disabled={true} />
                                                                    </div>
                                                                    <div className="col-sm-12 col-md-6 col-lg-3">
                                                                        <TextField label="Proceso" name="proceso" type="text" holder="" disabled={true}/>
                                                                    </div>
                                                                    <div className="col-sm-12 col-md-6 col-lg-3">
                                                                        <TextField id="cobrador_apoyo" label="Cobrador de apoyo" name="cobrador_apoyo" holder="" disabled={true}/>
                                                                    </div>
                                                                </div>
                                                                <div className="mb-3 row">
                                                                    <div className="col-sm-12 col-md-6 col-lg-3">
                                                                        <TextField id="cobradores" label="Cobradores (en caso de aplicar)" name="cobradores" holder="" disabled={true}/>
                                                                    </div>
                                                                    <div className="col-sm-12 col-md-6 col-lg-3">
                                                                        <TextField id="grua" label="Grua" name="grua" holder="" disabled={true}/>
                                                                    </div>
                                                                </div>
                                                            </div>
                                                            <div className='my-2 mt-4'>
                                                                <div className="mb-3 row">
                                                                    <div className="col-sm-12 col-md-6 col-lg-3">
                                                                        <Field className="form-check-input" type="checkbox" name="registro_concluido" disabled={true} />
                                                                        <label className="form-check-label" style={{ 'marginLeft': '10px' }}>Registro concluido</label>
                                                                    </div>
                                                                    <div className="col-sm-12 col-md-6 col-lg-9 text-end">
                                                                    <label>Inicio de proceso: {values.fechaInicioProceso}</label> | <label>Fin de proceso: {values.fechaFinProceso}</label>
                                                                    </div>
                                                                </div>
                                                            </div>
                                                        </Form>
                                                        )}
                                                    </Formik >
                                                )}
                                            </div>
                                        </div>
                                    </div>
                                </div>
                            </div>
                        </div>
                    </div>
                </div>
            </div>
        </>
    )
}

export default HistorialVisitasDetalle