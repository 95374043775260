import { Formik, Form} from 'formik'
import React, { useState } from 'react'
import * as Yup from 'yup';
import Modal from '../../../../Components/Modal/Modal';
import Spinner from '../../../../Components/Loadig';
import { SelectField } from '../../../../Components/SelectField';

const ConfirmarRutasModal = ({ isOpen, setIsOpen, setConfirmar, contratos, fechaInicio, fechaFin}) => {
   const [fecha, setFecha] = useState('01/01/1970');
   const [fechaValida, setFechaValida] = useState(false);
   const [tipoVisita, setTipoVisita] = useState('');

   let initial = {
      tipovisita: '',
      fecha: ''
   }
   const [initialValue, setInitialValue] = useState(initial);

   const validate = Yup.object({
      tipovisita: Yup.string()
         .required("El campo es requerido."),
   });

   const dataTipoVisita = [
      { value: 0, label: "Seguimiento"},
      { value: 1, label: "Recuperación de Unidad"},
      { value: 2, label: "Entrega citatorio"}
   ]

   const [loading, setLoading] = useState(false);

   const handleAdd = (setFieldValue) => {
      setConfirmar(tipoVisita, fecha);
      setFieldValue('tipovisita', ''); 
      setFieldValue('fecha', '');
      setTipoVisita('');
      setFechaValida(false);
      setIsOpen(false);
   }

   const handleValidarFechas = (value) => {
      if(value > "01/01/1970"){
         setFecha(value);
         setFechaValida(true);
      }
      else{
         setFecha(value);
         setFechaValida(false);
      }
   }

   const handleCancel = (setFieldValue) => {
      setFieldValue('tipovisita', '');
      setFieldValue('fecha', '');
      setTipoVisita('');
      setFechaValida(false);
      setIsOpen(false);
   }

   return (
      <Formik
         enableReinitialize={true}
         initialValues={initialValue}
         validationSchema={validate}
         onSubmit={(values) => {
            handleAdd(values);
         }}
      >{
         ({ isValid, values, setFieldValue }) => (
      <>
         <Modal isOpen={isOpen} color='#fff'>
            <Form>
            <section className='my-2 mx-4' style={{ width: '40rem' }}>
                  <span className='fs-4' style={{fontWeight:'bold', color:"#000"}}><strong>Completa la información</strong></span>
                  <p className='mt-3'><span style={{color: "#000", fontWeight: "bold"}}>Contratos: {contratos} </span></p>
                  <div className="mb-3 row" style={{marginTop:30}}>
                     <div className="col-sm-6">
                        <SelectField 
                           label={'Tipo de visita'}
                           name='tipovisita' 
                           items={dataTipoVisita} 
                           defaultValue={{value:'', label:'Seleccione'}}
                           onChange={(e) => {
                              if(e.value !== ''){
                                 setFieldValue('tipovisita', e.label)
                                 setTipoVisita(e.label)
                              }
                              else{
                                 setFieldValue('tipovisita', '')
                                 setTipoVisita('')
                              }
                           }}
                        />
                     </div>
                     <div className="col-sm-6">
                        Fecha
                        <input name='fecha' type="date" min={fechaInicio} max={fechaFin} defaultValue={''} className="form-control" placeholder="Fecha" onChange={(e) => { handleValidarFechas(e.target.value); }} />
                     </div>
                  </div>
                  <section className='d-flex justify-content-end mt-5'>
                     <button id="AT_btnCancelar" className='btn btn-outline col-4 mw-100' type='reset' 
                        onClick={() => { 
                           handleCancel(setFieldValue) 
                        }}>Cancelar</button>
                     <button id="AT_btnGuardar" className='btn mx-2 col-4 mw-100' type="submit" disabled={!(fechaValida) || !(isValid) || (tipoVisita==='')} 
                        onClick={() => {
                           // setFieldValue('tipovisita', ''); 
                           // setFieldValue('fecha', ''); 
                           handleAdd(setFieldValue) 
                        }}>Aceptar</button>
                  </section>
            </section>
            </Form>
         </Modal>
         {
            loading &&
            <Modal isOpen={loading} color='transparent'><Spinner message={""} /></Modal>
         }
      </>
      )
   }
   </Formik>
   )
}

export default ConfirmarRutasModal