import React from 'react'
import toast, { Toaster } from 'react-hot-toast'
import Modal from '../../../Components/Modal/Modal';
import { ValidatePermission, ValidatePermissionById } from '../../../Auth/ValidatePermission';
import Spinner from '../../../Components/Loadig';
import DataTable from '../../../Components/datatable/DataTable';
import RowPages from '../../../Components/RowPages';
import Select from 'react-select'
import ModalAprobarRechazarSolicitud from './ModalAprobarRechazarSolicitud';
import { Section, SubModule } from '../../../Auth/Authorization';
import useAutorizacionDesolicitudes from '../../../Hooks/Gestoria/AutorizacionDeSolicitudes/useAutorizarCitas';
const AutorizacionDeSolicitidesListado = () => {
  const { columns,
    data,
    handlePageClick,
    currenPage,
    pageCount,
    setStr,
    loading,
    setpageRows,
    setOpenModal,
    openModal,
    habdleGetAll,
    setItemSelected,
    itemSelected,
    dataUbicacion,
    dataEjecutivo,
    dataTipoCita,
    setEjecutivoId,
    setUbicacionId,
    setTipoCitaId
  } = useAutorizacionDesolicitudes(toast);
  return (
    <>
      <Toaster
        position="top-right"
        toastOptions={{
          success: {
            style: {
              background: '#47a066',
              color: '#FFFF',
              borderLeft: '10px solid #2f7246'
            },
          },
          error: {
            style: {
              background: '#d53f3f',
              color: '#FFFF',
              borderLeft: '10px solid #ac241a'
            },
          },
        }}
      />
      <Modal isOpen={loading} color='transparent'><Spinner message={""} /></Modal>
      <ValidatePermission isActive={ValidatePermissionById(4, Section.AutorizarDictamen, SubModule.AutorizacionDeSolicitudes)?.Ver}>
        <section className='mx-3 py-4 d-flex flex-column'>
          <div className='d-flex justify-content-between'>
            <span className='fs-5'><strong>Citas de trámite</strong></span>
          </div>
          {/* <span className='mt-2'>El siguiente listado corresponde a la gestión de todos los seguros sin pago.</span> */}
        </section>

        <section className='pb-4'>
          <div className='row col-12 px-4'>
            <div className="col-lg-2 col-md-6 col-sm-12 my-1 px-1 inner-addon right-addon">
              <i className="glyphicon fas fa-search"></i>
              <input type="text" className="form-control mb-4" placeholder="Buscar" onKeyUp={(e) => { setStr(e.target.value) }} />
            </div>
            <div className='col-lg-3 col-mg-6 col-sm-12 my-1 px-1'>
              <Select options={dataUbicacion} className='col-12' onChange={(e)=>{setUbicacionId(e.value)}} placeholder="Ubicación" />
            </div>
            <div className='col-lg-3 col-mg-6 col-sm-12 my-1 px-1'>
              <Select options={dataEjecutivo} className='col-12' onChange={(e)=>{setEjecutivoId(e.value)}} placeholder="Ejecutivo" />
            </div>
            <div className='col-lg-3 col-mg-6 col-sm-12 my-1 px-1'>
              <Select options={dataTipoCita} className='col-12' onChange={(e)=>{setTipoCitaId(e.value)}} placeholder="Tipo de cita" />
            </div>
          </div>

        </section>
        <RowPages
          setpageRows={setpageRows}
        />
        <div className='mx-4 table-responsive tbl-scroll'>
          <DataTable
            column={columns}
            data={data}
            pageCount={pageCount}
            handlePageClick={handlePageClick}
            currentPage={currenPage - 1}
            detailable={true}
            handleDetail={(item) => { setItemSelected(item); setOpenModal(true) }}
          />
        </div>
      </ValidatePermission>
      {
        openModal &&
        <ModalAprobarRechazarSolicitud
          isOpen={openModal}
          setIsOpen={setOpenModal}
          item={itemSelected}
          handleGetAll={habdleGetAll}
        />
      }
    </>
  )
}

export default AutorizacionDeSolicitidesListado