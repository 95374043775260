import React, { useState } from "react";

const Tabs = ({ children }) => {
    const [activeTab, setActiveTab] = useState(0);
    const tabs = React.Children.toArray(children);

    return (
        <div className="mb-2">
            <div className="tab-items mb-2 d-flex">
                {
                    tabs.map((tab, index) => (
                        <fieldset
                            key={index}
                            className={`tab-item text-sm py-2 px-4 ${activeTab === index ? 'active-item' : ''}`}
                            onClick={() => setActiveTab(index)}
                        >
                            {tab.props.title}
                        </fieldset>
                    ))
                }
            </div>
            <div>
                {tabs[activeTab]}
            </div>
        </div>
    );
};

export default Tabs;