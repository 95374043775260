import { Formik, Form, Field } from 'formik'
import React, { useEffect, useRef, useState } from 'react'
import * as Yup from 'yup';
import { toast, Toaster } from 'react-hot-toast';
import Modal from '../../../../Components/Modal/Modal';
import { SelectField } from '../../../../Components/SelectField';
import { TextAreaField } from '../../../../Components/TextAreaField';
import Catalogo from '../../../../Services/Catalogos/Catalogo';
import Spinner from '../../../../Components/Loadig';
import AutorizarDictamenService from '../../../../Services/Seguros/AutorizarDictamenService';
import { FormatNumberMoney2, message } from '../../../../Utils/functions';
import { TextField } from '../../../../Components/TextField';

const ModalCostosRecoleccion = ({ isOpen, setIsOpen, item, handleGetAll }) => {
    const refbtn = useRef();
    let initial = {
        concluido: item?.concluido ?? false,
        total: item?.total ?? 0,
        iva: item?.iva ?? 0,
        subTotal: item?.subTotal ?? 0,
        aplicaIva: (item?.iva) ? true : false
    }
    const [initialValue, setInitialValue] = useState(initial);
    const [loading, setLoading] = useState(false);

    const validate = Yup.object({
        iva: Yup.number()
            .when("aplicaIva", {
                is: true,
                then: Yup.number()
                    .required("El campo es requerido.")
                    .positive("ingrese un valor mayor a cero.")
                    .max(99, "El valor no debe ser mayor a 99.")
            }),
        // total: Yup.string().required("El campo es requerido."),
        subTotal: Yup.number().required("El campo es requerido.")
            .positive("ingrese un valor mayor a cero."),
    });

    const handleClickConfirmar = async (values) => {
        setIsOpen(false);
        handleGetAll(values);
    }
    return (
        <Formik
            enableReinitialize={true}
            initialValues={initialValue}
            validationSchema={validate}
            onSubmit={(values) => {
                handleClickConfirmar(values);
            }}
        >
            {({ isValid, setFieldValue, values }) => (
                <>
                    <Modal isOpen={isOpen} color='#fff'>
                        <section className='d-flex justify-content-between mx-4'>
                            <span className=''><strong>Costo Recolección grua</strong></span>
                        </section>
                        <div>
                            <span className='mx-4' style={{ fontSize: '13px' }}>A continuación captura el costo de la recolección de grua.</span> <br />
                        </div>
                        <section className='my-4 mx-4' style={{ width: '25rem' }}>
                            <Form>
                                <div className="mb-3 row">
                                    <section className='row col-12'>
                                        <span className='col-6'>Valor</span>
                                        <span className='col-6'>Total</span>
                                    </section>
                                    <div className="separator"></div>
                                    <section className='row col-12 py-2'>
                                        <span className='col-6'>Costo grua</span>
                                        <span className='col-6'>
                                            <div className="col-12">
                                                <TextField icon={"bi bi-currency-dollar"} disabled={values?.concluido} label="" isMoney={true} name="subTotal" type="text" holder="Escribe" onChange={(event) => {
                                                    setFieldValue("subTotal", event.target.value)
                                                    let total = (parseFloat(event.target.value) * parseInt(values.iva) / 100) + parseFloat(event.target.value);
                                                    setFieldValue("total", total);
                                                }} />
                                            </div>
                                        </span>
                                    </section>
                                    <section className='row col-12 py-2' style={{ backgroundColor: '#E0E0E0', borderRadius: '3px' }}>
                                        <span className='col-6'>Total</span>
                                        <span className='col-6'> $
                                            {
                                                FormatNumberMoney2(values.total)
                                            }
                                        </span>
                                    </section>
                                    {
                                        values.aplicaIva &&
                                        <section className='row col-12 py-2'>
                                            <span className='col-6'>IVA</span>
                                            <span className='col-6'>
                                                <div className="col-12">
                                                    <TextField disabled={values?.concluido} label="" name="iva" type="text" holder="Escribe" onChange={(event) => {
                                                        setFieldValue("iva", event.target.value);
                                                        let total = (parseFloat(values.subTotal) * parseInt(event.target.value) / 100) + parseFloat(values.subTotal);
                                                        setFieldValue("total", total);
                                                    }} />
                                                </div>
                                            </span>
                                        </section>
                                    }
                                </div>
                                <div className='mt-2'>
                                    <div className="col-12 col-lg-6">
                                        <Field className="form-check-input" disabled={initialValue?.concluido} type="checkbox" name="aplicaIva" onClick={(event) => {
                                            setFieldValue("aplicaIva", event.target.value);;
                                        }} />
                                        <label className="form-check-label" style={{ 'marginLeft': '10px' }}>Aplica. IVA</label>
                                    </div>
                                </div>
                                <section className='my-3 d-flex justify-content-end'>
                                    <button id="AT_btnCancelar" className='btn btn-outline' type='reset' onClick={() => { setIsOpen(false) }}>Cancelar</button>
                                    <button id="AT_btnGuardar" className='btn mx-2' ref={refbtn} type="submit" disabled={!(isValid)}>Confirmar</button>
                                </section>
                            </Form>
                        </section>
                    </Modal>
                    <Modal isOpen={loading} color='transparent'><Spinner message={""} /></Modal>
                </>
            )
            }
        </Formik>
    )
}

export default ModalCostosRecoleccion