import { useEffect, useState } from "react";
import { useSelector } from "react-redux";
import { useNavigate } from "react-router-dom";
import CobranzaService from "../../Services/Cobranza/Cobranza";
import { FormatFecha } from "../../Utils/functions";
import { v4 as uuidv4 } from 'uuid';

const useRutaDetalle = (state) =>{
   const userId = sessionStorage.getItem('userId').trim()
   const {datosruta} = useSelector((state)=> state.DetalleRutaSlice);
   const [btnEditConfirm, setBtnEditConfirm] = useState(1);
   const [rutaConfigurada, setRutaConfigurada] = useState(false);
   const [openModal, setOpenModal] = useState(false);
   const navigate = useNavigate();
   const [reset, setReset] = useState(false);
   const [dataContratos, setDataContratos] = useState([]);
   const [contratosNoAsignados, setContratosNoAsignados] = useState([])
   const [dataContratosNoAsignados, setDataContratosNoAsignados] = useState([]);
   const [dataAsignarContratos, setDataAsignarContratos] = useState([]);
   const [dataContratosSinAsignar, setDataContratosSingAsignar] = useState([]);
   const [contratosSeleccionados, setContratosSeleccionados ] = useState('');
   const [contratosAsignadosAruta, setContratosAsignadosARuta] = useState(0);
   const [openModalConfirmacion, setOpenModalConfirmacion] = useState(false);
   const [loading, setLoading] = useState(false);
   const [openModalCambioRuta, setOpenModalCambioRuta] = useState(false);
   const [dataNoAsignados, setDataNoAsignados] = useState([]);
   const [dataAsignados, setDataAsignados] = useState([])
   const [dataZonas, setDataZonas] = useState([]);
   const [strAsignado, setStrAsignado] = useState("");
   const [filtroTipoAsignado ,setFiltroTipoAsignado] = useState("");
   const [filtroMunicipio ,setFiltroMunicipio] = useState("");
   const [strNoAsignado, setStrNoAsignado] = useState("");
   const [filtroTipoNoAsignado ,setFiltroTipoNoAsignado] = useState("");
   const [filtroZonaNoAsignado ,setFiltroZonaNoAsignado] = useState("");
   const [dataMunicipios, setDataMunicipios] = useState([])

   const dataTipoPersonas = [
      {value: 0, label: "Tipo de persona"},
      {value: 1, label: "Cliente"},
      {value: 2, label: "Aval"},
      {value: 3, label: "Representante legal"},
      {value: 4, label: "Coacreditado"},
      {value: 5, label: "Referencia"},
   ]

   const columns = [
      // { field: 'extraInfoTooltip', headerName: '', width: 20},
      { field: 'contrato_id', headerName: 'Contrato', width: 150 },
      { field: 'tipo_persona', headerName: 'Tipo de Persona', width: 150},
      { field: 'zona', headerName: 'Zona', width: 100},
      { field: 'periodo', headerName: 'Periodo', width: 100},
      { field: 'dias_vencido', headerName: 'Días vencidos', width: 150 },
      { field: 'monto_insoluto', headerName: 'Monto Insoluto', width: 150},
      { field: 'monto_adeudo', headerName: 'Monto Adeudado', width: 150},
      { field: 'fecha_ultimo_pago', headerName: 'Fecha últ. pago', width: 150},
      { field: 'fecha_ultima_clave', headerName: 'Fecha última clave', width: 150},
      { field: 'dias_sin_consultar_clave', headerName: 'Días sin consultar', width: 150 },
      { field: 'visitas', headerName: 'Visitas', width: 100},
      { field: 'fecha_ultimo_calculo', headerName: 'Últ. actualización', width: 150 },
   ]

   const columnsContratos = [
      { field: 'tipo_visita', headerName: 'Tipo de Visita', width: 150},
      { field: 'fecha_visita', headerName: 'Fecha', width: 150},
      { field: 'contrato_id', headerName: 'Contrato', width: 150 },
      { field: 'tipo_persona', headerName: 'Tipo de Persona', width: 150},
      { field: 'nombre', headerName: 'Nombre', width: 300},
      { field: 'direccion', headerName: 'Dirección', width: 300},
      { field: 'monto_vencido', headerName: 'Monto', width: 130},
      { field: 'estatus', headerName: 'Estatus', width: 130},
      { field: 'dias_vencido', headerName: 'Días vencidos', width: 150 },
   ]


   useEffect(() => {
      let filteredData = dataNoAsignados.filter(item => {
         const nameMatches = strNoAsignado === '' || (item.contrato_id && item.contrato_id.toLowerCase().includes(strNoAsignado.toLowerCase()));

         return (
            nameMatches
            && (filtroTipoNoAsignado === "" || item.tipo_persona === filtroTipoNoAsignado || filtroTipoNoAsignado === "Tipo de persona") 
            && (filtroZonaNoAsignado === "" || item.zona === filtroZonaNoAsignado || filtroZonaNoAsignado=== "Zonas") 
         )
      })

      setDataContratosNoAsignados(filteredData);
      setContratosNoAsignados(filteredData);
   }, [strNoAsignado, dataNoAsignados, filtroTipoNoAsignado, filtroZonaNoAsignado])

   useEffect(() => {
      let filteredData = dataAsignados.filter(item => {
         const nameMatches = strAsignado === '' || (item.contrato_id && item.contrato_id.toLowerCase().includes(strAsignado.toLowerCase()));

         return (
            nameMatches
            && (filtroTipoAsignado === "" || item.tipo_persona === filtroTipoAsignado || filtroTipoAsignado === "Tipo de persona") 
            && (filtroMunicipio === "" || item.municipio === filtroMunicipio || filtroMunicipio=== "Municipios") 
         )
      })

      setDataContratos(filteredData);
   }, [strAsignado, dataAsignados, filtroTipoAsignado, filtroMunicipio])


   const handleGetAllContratosSinAsignar = async () => {
      let NewData = []
      let NewDataZonas = [{value: "", label: "Zonas"}]
      let NewMunicipios = [{value: 0, label: "Municipios"}]
      let nombreZona = new Set();
      let municipios = new Set();
      let idZona = new Set();
      let counter = 0
      let counterMunicipio = 1
      // http://gateway.dw.consiss.com/api/cartera/GetAllEjecutivosWithContratosSinAsignar
      await CobranzaService.GetAllEjecutivosWithContratosSinAsignar()
         .then(res => {
            if(res.data.callSucceded){
               // if(state.tipoUsuario === 1){
               //    res.data.responseData?.map(item => {
               //       item.contratos?.map(contrato=>{
               //          const fechaUltimoPago = FormatFecha(contrato.fecha_ultimo_pago)
               //          const fechaUltimaClave = FormatFecha(contrato.fecha_ultima_clave)
               //          const fechaUltimoCalculo = FormatFecha(contrato.fecha_ultimo_calculo)
               //          NewData.push({
               //             ...contrato,
               //             fecha_ultimo_pago:fechaUltimoPago,
               //             fecha_ultima_clave: fechaUltimaClave,
               //             fecha_ultimo_calculo: fechaUltimoCalculo
               //          })
               //       })
               //    })
               // }
               // else{
                  res.data.responseData?.map(item => {
                     if(item.userid?.toLowerCase() === datosruta.cobrador_id?.toLowerCase()){
                        item.contratos?.map(contrato=>{
                           const fechaUltimoPago = FormatFecha(contrato.fecha_ultimo_pago)
                           const fechaUltimaClave = FormatFecha(contrato.fecha_ultima_clave)
                           const fechaUltimoCalculo = FormatFecha(contrato.fecha_ultimo_calculo)
                           NewData.push({
                              ...contrato,
                              fecha_ultimo_pago:fechaUltimoPago,
                              fecha_ultima_clave: fechaUltimaClave,
                              fecha_ultimo_calculo: fechaUltimoCalculo
                           })
                           if(contrato.zona !== null && contrato.zona !== ""){
                              nombreZona.add(contrato.zona)
                              idZona.add(contrato.zonaid)
                           }
                           if(contrato.municipio !== null && contrato.municipio !== ""){
                              municipios.add(contrato.municipio)
                           }
                        })
                     }
                  })
                  for (const value of nombreZona) {
                     NewDataZonas.push({
                        value: idZona[counter],
                        label: value
                     })
                     counter++
                  }
                  for(const value of municipios){
                     NewMunicipios.push({
                        value: counterMunicipio,
                        label: value
                     })
                     counterMunicipio++
                  }
               //}
            }
         })
         setDataNoAsignados(NewData)
         setDataZonas(NewDataZonas)
         setDataMunicipios(NewMunicipios)
   }

   const handleCheckAsignar = (checked, value) => {
      let newData = [];
      if(checked){
         newData.push(value);
         setContratosSeleccionados(`${value.contrato}, ${contratosSeleccionados}`)
         dataAsignarContratos?.map((item)=>{
            newData.push(item);
         })
      }
      else{
         let newString = ''
         dataAsignarContratos?.map((item) =>{
            if(item.contrato != value.contrato){
               newData.push(item);
               newString = newString + `${item.contrato}, `
            }
         });
         setContratosSeleccionados(newString);
      }
      setDataAsignarContratos(newData);
      setContratosAsignadosARuta(newData.length);
   }

   const handleCheckSinAsignar = (checked, value) => {
      let newData = [];
      if(checked){
         newData.push(value);
         dataContratosSinAsignar?.map((item)=>{
            newData.push(item)
         })
      }
      else{
         dataContratosSinAsignar?.map((item) =>{
            if(item.contrato_id != value.contrato_id){
               newData.push(item)
            }
         });
      }
      setDataContratosSingAsignar(newData);
   }

   const formatItem = (item, tipovisita, fecha) => {
      return({
         item: item,
         tipo_visita: tipovisita,
         fecha_visita: fecha, 
         contrato_id: item.contrato_id,
         tipo_persona: item.tipo_persona,
         nombre: `${item.nombre} ${item.apellido_paterno} ${item.apellido_materno}`,
         direccion: `Calle ${item.calle} ${item.no_ext}, Col.${item.colonia}, ${item.municipio}`,
         monto_vencido: item.monto_vencido,
         estatus: item.estatus,
         dias_vencido: item.dias_vencido
      })
   }

   const handleAsignarContratos = (tipovisita, fecha) => {
      let newDataAsignar = [];
      let newDataNoAsignados = [];
      if(dataAsignarContratos.length > 0){
         dataContratos?.map((item) => {
            newDataAsignar.push(item);
         });

         dataAsignarContratos?.map((item) => {
            newDataAsignar.push(formatItem(item, tipovisita, fecha));
         });
         setDataAsignados(newDataAsignar);

         dataContratosNoAsignados?.map((itemNoAsignado) => {
            let counter = 0;
            dataAsignarContratos?.map((item) =>{
               if(item.contrato_id === itemNoAsignado.contrato_id){
                  counter++;
               }
            })
            if(counter == 0){
               newDataNoAsignados.push(itemNoAsignado)
            }
         })
         setDataContratosNoAsignados(newDataNoAsignados);
         setDataNoAsignados(newDataNoAsignados)
      }
      setDataAsignarContratos([]);
      setDataContratosSingAsignar([]);
      setContratosAsignadosARuta(0);
      setContratosSeleccionados('')
   }

   const handleDesasignarContratos = () => {
      let newDataAsignar = [];
      let newDataNoAsignados = [];
      if(dataContratosSinAsignar.length > 0){
         dataContratosNoAsignados?.map((item) => {
            newDataNoAsignados.push(item);
         });

         dataContratosSinAsignar?.map((item) => {
            newDataNoAsignados.push(item.item);
         });

         dataContratos?.map((itemAsignado) => {
            let counter = 0;
            dataContratosSinAsignar?.map((item) =>{
               if(item.contrato_id === itemAsignado.contrato_id){
                  counter++;
               }
            })
            if(counter === 0){
               newDataAsignar.push(itemAsignado)
            }
         })
      }
      setDataNoAsignados(newDataNoAsignados);
      setDataAsignados(newDataAsignar);
      setDataAsignarContratos([]);
      setDataContratosSingAsignar([]);
      setBtnEditConfirm(1);
   }
 
   useEffect(()=>{
      if(reset){
         setDataAsignarContratos([]);
         setDataContratosSingAsignar([]);
         setDataContratos([]);
         setDataContratosNoAsignados([]);
         setReset(false)
      }
      else{
         handleCargarDatos()
      }
   },[reset]);

   const handleCargarDatos = () =>{
      let NewMunicipios = [{value: 0, label: "Municipios"}]
      let municipios = new Set();
      let counterMunicipio = 1

      datosruta.items.map(item=>{
         if(item.municipio !== null && item.municipio !== ""){
            municipios.add(item.municipio)
         }
      })

      for(const value of municipios){
         NewMunicipios.push({
            value: counterMunicipio,
            label: value
         })
         counterMunicipio++
      }

      setDataMunicipios(NewMunicipios)
      setDataAsignados(datosruta.items);
      setDataContratosNoAsignados(contratosNoAsignados);
   }

   useEffect(()=>{
      if(datosruta.items.length === 0){
         handleGetAllContratosSinAsignar();
      }
   },[])

   const handleConfirmacion = (tipovisita, fecha) =>{
      handleAsignarContratos(tipovisita, fecha);

      setBtnEditConfirm(1);
      setOpenModal(false);
   }

   const handleAceptar = async () =>{
      setLoading(true);
      const idGuid = uuidv4();
      let items = []
      dataContratos?.map(element=>{
         items.push({
            id: uuidv4(),
            ruta_head_id: idGuid,
            evento_item_id: element.item.id,
            evento_head_id: element.item.evento_id,
            fecha: element.fecha_visita,
            tipo_visita: element.tipo_visita,
            estatus: "Pendiente",
            resultado: "Desconocido",
            userid: userId
         })
      })

      let data = {
         "verb": "string",
         "id": "string",
         "field": "string",
         "value": "string",
         "flag": true,
         "anyObject": {
            "id": idGuid,
            "userid": datosruta.cobrador_id,
            "fecha_inicio": datosruta.fechainicio,
            "fecha_fin": datosruta.fechafin,
            "evento_head_id": '',
            "items":items
         }
      }
      
      await CobranzaService.CreateNewRoute(data)
         .then(res=>{
            setLoading(false);
            navigate("/cobranza/seguimiento-visita/rutas-cobranza");
            setOpenModalConfirmacion(false)
         })
         .catch(e => {
            setOpenModalConfirmacion(false)
            setLoading(false);
            console.log(`ERROR -- ${e}`)
         })
   }

   return{
      columns,
      columnsContratos,
      dataContratos,
      dataContratosNoAsignados,
      datosruta,
      setStrAsignado,
      setStrNoAsignado,
      dataTipoPersonas,
      dataZonas,
      btnEditConfirm,
      setBtnEditConfirm,
      rutaConfigurada,
      setRutaConfigurada,
      handleCheckAsignar,
      handleCheckSinAsignar,
      openModal,
      setOpenModal,
      openModalConfirmacion,
      setOpenModalConfirmacion,
      openModalCambioRuta,
      setOpenModalCambioRuta,
      handleConfirmacion,
      handleDesasignarContratos,
      contratosSeleccionados,
      dataAsignarContratos,
      dataContratosSinAsignar,
      contratosAsignadosAruta,
      handleAceptar,
      loading,
      setFiltroTipoNoAsignado,
      setFiltroZonaNoAsignado,
      setFiltroTipoAsignado,
      setFiltroMunicipio,
      dataMunicipios
   }
}

export default useRutaDetalle;