import axios from '../../Utils/ApiService';

const Gestoria = {
    /* SEGUIMIENTO COBRANZA */
    getSeguimientoGestoria: (data) => {
        return axios.post(`/gestoria/getSeguimientoGestoria`, data);
    },
    FindGestoriaByVIN: (data) => {
        return axios.post(`/gestoria/FindGestoriaByVin`, data);
    },
    /* REPORTE SEGUIMIENTO COBRANZA */
    RegistroTramiteInicialCrear: (data) => {
        return axios.post(`/gestoria/RegistroTramiteInicialCrear`, data);
    },
    RegistroTramiteInicialActualizar: (data) => {
        return axios.put(`/gestoria/RegistroTramiteInicialActualizar`, data);
    },
    RegistroTramiteInicialGet: (data) => {
        return axios.get(`/gestoria/RegistroTramiteInicialGet?gestoriaRegistroInicialId=${data}`);
    },

    PagoTramitesCrear: (data) => {
        return axios.post(`/gestoria/PagoTramitesCrear`, data);
    },
    PagoTramitesActualizar: (data) => {
        return axios.put(`/gestoria/PagoTramitesActualizar`, data);
    },
    PagoTramitesGet: (data) => {
        return axios.get(`/gestoria/PagoTramitesGet?gestoriaRegistroInicialId=${data}`);
    },

    PagoTramitesAdeudosCrear: (data) => {
        return axios.post(`/gestoria/PagoTramitesAdeudosCrear`, data);
    },
    PagoTramitesAdeudosActualizar: (data) => {
        return axios.put(`/gestoria/PagoTramitesAdeudosActualizar`, data);
    },
    PagoTramitesAdeudosGet: (data) => {
        return axios.get(`/gestoria/PagoTramitesAdeudosGet?gestoriaPagoTramitesAdeudosId=${data}`);
    },

    PagoTramitesPagosCrear: (data) => {
        return axios.post(`/gestoria/PagoTramitesPagosCrear`, data);
    },
    PagoTramitesPagosActualizar: (data) => {
        return axios.put(`/gestoria/PagoTramitesPagosActualizar`, data);
    },
    PagoTramitesPagosGet: (data) => {
        return axios.get(`/gestoria/PagoTramitesPagosGet?gestoriaPagoTramitesPagosId=${data}`);
    },

    CambioPlacasCrear: (data) => {
        return axios.post(`/gestoria/CambioPlacasCrear`, data);
    },
    CambioPlacasActualizar: (data) => {
        return axios.put(`/gestoria/CambioPlacasActualizar`, data);
    },
    CambioPlacasGet: (data) => {
        return axios.get(`/gestoria/CambioPlacasGet?gestoriaRegistroInicialId=${data}`);
    },


    AltaPlacasCrear: (data) => {
        return axios.post(`/gestoria/AltaPlacasCrear`, data);
    },
    AltaPlacasActualizar: (data) => {
        return axios.put(`/gestoria/AltaPlacasActualizar`, data);
    },
    AltaPlacasGet: (data) => {
        return axios.get(`/gestoria/AltaPlacasGet?gestoriaRegistroInicialId=${data}`);
    },


    TarjetaCirculacionCrear: (data) => {
        return axios.post(`/gestoria/TarjetaCirculacionCrear`, data);
    },
    TarjetaCirculacionActualizar: (data) => {
        return axios.put(`/gestoria/TarjetaCirculacionActualizar`, data);
    },
    TarjetaCirculacionGet: (data) => {
        return axios.get(`/gestoria/TarjetaCirculacionGet?gestoriaRegistroInicialId=${data}`);
    },


    GestoriaEntregaCrear: (data) => {
        return axios.post(`/gestoria/GestoriaEntregaCrear`, data);
    },
    GestoriaEntregaActualizar: (data) => {
        return axios.put(`/gestoria/GestoriaEntregaActualizar`, data);
    },
    GestoriaEntregaGet: (data) => {
        return axios.get(`/gestoria/GestoriaEntregaGet?gestoriaRegistroInicialId=${data}`);
    },

    GestoriaEncabezadoGet: (data) => {
        return axios.get(`/gestoria/GestoriaEncabezadoGet?gestoriaRegistroInicialId=${data}`);
    },
    ClavesDemoGet: (vin) => {
        return axios.get(`/gestoria/ClavesDemoGet?vin=${vin}`);
    },
    RevisionUnidadHabilitadaGet: (data) => {
        return axios.get(`/gestoria/RevisionUnidadHabilitadaGet?generalId=${data}`);
    },
}

export default Gestoria