import React from 'react'
import './ProgressBar.css'


const ProgressBar = ({ progress, primary, secondProgress, active }) => {

    const colorBar = primary ? `primary` : `secondary`
    const progressBigger = (progress > secondProgress) ? true : false

    return (
        <>
            {progressBigger ?
                <div className={`progress-bar-container`}>
                    <div
                        className={"progress-bar-fill"}
                        // style={{ width: `${progress}%` }}
                        style={{ width: `${progress}%` }}
                    >
                        <div className={`progress-bar-lines ${colorBar}`}></div>
                    </div>
                    <div className="progress-bar-tooltip-top">{`${progress}%`}</div>
                    {active && (
                        <>
                        <div
                            className={`progress-secondbar progress-bar-negative`}
                            style={{ width: `${secondProgress}%` }}
                        >
                        </div>
                    <div className="progress-bar-tooltip-bottom">$10000 <br/> 70 Contratos <br/> {`${secondProgress}%`}</div>
                        </>
                    )}

                </div>
                :
                <div className={`progress-bar-container`}>
                    <div
                        className={"progress-bar-fill"}
                        // style={{ width: `${progress}%` }}
                        style={{ width: `${progress}%` }}
                    >
                        <div className={`progress-bar-lines progress-secondbar ${colorBar}`}></div>
                    </div>
                    <div className="progress-bar-tooltip-top">{`${progress}%`}</div>
                    {active && (
                        <div
                            className={`progress-bar-lines progress-bar-negative`}
                            style={{ width: `${secondProgress}%` }}
                        >
                        </div>
                    )}

                </div>}
        </>
    )
}


export default ProgressBar
