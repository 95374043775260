import axios from '../../Utils/ApiService';

const SeguroSiniestrosService = {
    getRecoleccionVehiculoSiniestros: async ({id, step}) => {
        return await axios.get(`/functions/GetRecoleccionVehiculoSiniestros?SiniestroId=${id}&Paso=${step}`);
    },
    getResguardoVehiculoSiniestros: async ({id, step}) => {
        return await axios.get(`/functions/GetResguardoVehiculoSiniestros?SiniestroId=${id}&Paso=${step}`);
    },
    getResguardoCostosSiniestros: async (id, paso, rows, page) => {
        return await axios.get(`/functions/GetResguardoCostosSiniestros?SiniestroResguardoUnidadId=${id}&Paso=${paso}&Rows=${rows}&page=${page}`);
    },
    updateRecoleccionSiniestro: async (data) => {
        return await axios.put(`/functions/UpdateRecoleccionSiniestro`, data);
    },
    updateResguardoSiniestro: async (data) => {
        return await axios.put(`/functions/UpdateResguardoSiniestro`, data);
    },
    crearResguardoCostosSiniestro: async (data) => {
        return await axios.post(`/functions/AddResguardoCostosSiniestro`, data);
    },
    actualizarResguardoCostosSiniestro: async (data) => {
        return await axios.put(`/functions/UpdateResguardoCostoSiniestro`, data);
    },
    deleteResguardoCostoSiniestro: async (id) => {
        return await axios.delete(`/functions/DeleteResguardoCostoSiniestro?SiniestroResguardoUnidad_CostoAdicionalId=${id}`);
    },
    getUbicacion: async (id) => {
        return await axios.get(`/functions/Ubicaciones?Idtipo=${id}`);
    },
    getDisponibilidadBotones: async (id) => {
        return await axios.get(`/functions/GetDisponibilidadBotones?SiniestroId=${id}`);
    }
}

export default SeguroSiniestrosService;