import { useEffect, useState } from 'react'
import { useNavigate, } from 'react-router';
import CobranzaService from '../../Services/Cobranza/Cobranza'
import { FormatFecha } from '../../Utils/functions'


const SolicitudesHook = () => {
 

  const columns = [
    { field: 'contrato', headerName: 'Contrato' },
    { field: 'cobrador', headerName: 'Cobrador' },
    { field: 'fecha', headerName: 'Fecha visita' },
    { field: 'dias_vencidos', headerName: 'Días Vencidos' },
    { field: 'valor', headerName: 'Monto Vencido' },
    { field: 'comentario_visita', headerName: 'Motivo Visita' },
    //{ field: 'message', headerName: 'Comentarios' },
  ];
  const navigate = useNavigate();

  const [name, setName] = useState("")
  const [pageRows, setpageRows] = useState(10)
  const [data, setData] = useState([{}])
  
  const [pageCount, setPageCount] = useState(1);
  const [currentPage, setCurrentPage] = useState(1);

  const [currentData, setCurrentData] = useState(data)
  //Modal
  const [loading, setLoading] = useState(false);
  const [modalMessage, setModalMessage] = useState({ isOpen: false, type: 1, title: '', message: '', data: [], authorizationType: 2, Origin: "Listado" });

  useEffect(() => {
    setCurrentPage(1);
  }, [])

  useEffect(() => {
    // Filtra los datos según los filtros seleccionados
    const filteredData = data.filter(item => {
      const nameMatches = name === '' || (item.contrato_id && item.contrato_id.toLowerCase().includes(name.toLowerCase()));

      return (
        nameMatches
      )
    })

    const indexOfLastItem = currentPage * pageRows
    const indexOfFirstItem = indexOfLastItem - pageRows
    const newData = filteredData.slice(indexOfFirstItem, indexOfLastItem)
    setCurrentData(newData)

    const newTotalPages = Math.ceil(filteredData.length / pageRows)
    setPageCount(newTotalPages)
    // if (currentPage > newTotalPages) {
    //   setCurrentPage(newTotalPages)
    // } else if (currentPage <= 0) {
    //   setCurrentPage(1)
    // }

  }, [currentPage, name, pageRows, data])

  const handleDetails = (item) => { 
    console.log(item)
  }

  const handlePageClick = (event) => {
    setCurrentPage(event.selected + 1)
  };

  useEffect(() => {
    handleGetSeguimientoContrato()
  }, [])


  const handleGetSeguimientoContrato = async () => {
    setLoading(true);
    const data = {
      "verb": null,
      "id": null,
      "field": null,
      "value": null
    }
    await CobranzaService.GetAllContactoSeguimientoLog(data)
      .then(res => {
        console.log (res)

        const filteredData = res.data.responseData.filter(item => item.aplica_visita === 1);

        const update = filteredData.map(item => {
        
          const fechaFormat = FormatFecha(item.fecha)

            return {
                ...item,
                fecha: fechaFormat,
                message: item.comentario_visita

            }
        })

        setData(update)
        setLoading(false);

      }).catch(e => {
        setLoading(false);
        if (e.response !== undefined)
          setModalMessage({ ...modalMessage, isOpen: true, type: 1, message: e.response.data.message })
        else
          setModalMessage({ ...modalMessage, isOpen: true, type: 1, message: e.message })
      })
  }

  return {
    setName,
    setpageRows,
    columns,
    currentData,
    handleDetails,
    pageCount,
    currentPage,
    handlePageClick
  }
}
export default SolicitudesHook