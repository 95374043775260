import axios from '../../Utils/ApiService';

const CalendarService = {
    getCalendario: (tipo, inicio, fin, sucursal) => {
        return axios.get(`/cartera/getcalendario?tipocalendario=${tipo}&fechainicio=${inicio}&fechafin=${fin}&ubicacionId=${sucursal}`);
    },
    // getGarantias: async(generalId) => {
    //     return await axios.get(`/cartera/garantiasbygeneralid?generalid=${generalId}`);
    // },
    // addReglaNegocio:(data)=>{
    //     return axios.post(`/functions/AddReglasNegocioPorEtapa`,data);
    // },
}

export default CalendarService