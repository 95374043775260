import { Form, Formik } from "formik";
import { useEffect, useState } from "react";
import toast, { Toaster } from "react-hot-toast";
import Modal from "./Modal";
import Spinner from "../Loadig";
import DataTable from "../datatable/DataTable";
import { TextField } from "../TextField";
import { SelectField } from "../SelectField";
import Catalogo from "../../Services/Catalogos/Catalogo";
import { message } from "../../Utils/functions";
import { TextAreaField } from "../TextAreaField";

const SolicitarRecoleccionUnidadModal = ({ isOpen, setIsOpen, item }) => {

    const initial = {

    };

    const columns = [];

    const [initialValue, setInitialValue] = useState(initial);
    const [loading, setLoading] = useState(false);
    const [tipoGrua, setTipoGrua] = useState([]);
    const [empresas, setEmpresas] = useState([]);
    const [ubicaciones, setUbicaciones] = useState([]);

    useEffect(() => {
        getTipoGruas();
        getEmpresas();
        getUbicaciones();
    }, []);

    const getTipoGruas = async () => {
        await Catalogo.getDropdownsByTable('Catalogos.OrigenGrua')
            .then(resp => {
                const items = resp.data.map(item => {
                    return {
                        value: item.OrigenGruaId,
                        label: item.OrigenGrua
                    }
                })
                setTipoGrua(items);
            })
            .catch(err => {
                toast.error(message('No se pudo obtener el listado de grúas'));
            });
    }

    const getEmpresas = async () => {
        await Catalogo.getDropdownsByTable('Catalogos.Empresa')
            .then(resp => {
                const items = resp.data.map(item => {
                    return {
                        value: item.EmpresaId,
                        label: item.Empresa
                    }
                })
                setEmpresas(items);
            })
            .catch(err => {
                toast.error(message('No se pudo obtener el listado de grúas'));
            });
    }

    const getUbicaciones = async () => {
        await Catalogo.getDropdownsByTable('Sistema.Ubicacion')
            .then(resp => {
                const items = resp.data.map(item => {
                    return {
                        value: item.UbicacionId,
                        label: item.Nombre
                    }
                })
                setUbicaciones(items);
            })
            .catch(err => {
                toast.error(message('No se pudo obtener el listado de grúas'));
            });
    }

    return (
        <>
            <Toaster
                position="top-right"
                toastOptions={{
                    success: {
                        style: {
                            background: '#47a066',
                            color: '#FFFF',
                            borderLeft: '10px solid #2f7246'
                        },
                    },
                    error: {
                        style: {
                            background: '#d53f3f',
                            color: '#FFFF',
                            borderLeft: '10px solid #ac241a'
                        },
                    },
                }}
            />

            <Formik
                enableReinitialize={true}
                initialValues={initialValue}
            // validationSchema={validate}
            >
                {({ isValid, values, setFieldValue }) => (
                    <>
                        <Modal isOpen={isOpen} color='#fff' width={800}>
                            <section className='my-4 mx-4'>
                                <span className='fs-6 fw-bold'><strong>Recolección de unidad</strong></span>
                                <Form>
                                    <div className="row mt-2 mb-2">
                                        <div className="col col-12">
                                            <div className="row" style={{ overflowY: 'scroll', maxHeight: '450px' }}>
                                                <div className="col-lg-12 col-md-12 col-sm-12">
                                                    <div className="row">
                                                        <div className="col-12">
                                                            <span className="fw-bold text-xs">Asignación de grúa</span>
                                                        </div>
                                                    </div>
                                                    <div className="row mt-2">
                                                        <div className="col-12 col-lg-4">
                                                            <TextField disabled={true} label="Folio" name="folio" type="text" />
                                                        </div>
                                                        <div className="col-12 col-lg-4">
                                                            <TextField disabled={true} label="Usuario Programó" name="folio" type="text" />
                                                        </div>
                                                        <div className="col-12 col-lg-4">
                                                            <SelectField
                                                                label="Grúa"
                                                                disabled={initialValue?.concluido}
                                                                name="tipoServicioId"
                                                                items={tipoGrua}
                                                                onChange={(event) => {
                                                                    setFieldValue("tipoServicioId", event.value);
                                                                }} />
                                                        </div>
                                                    </div>
                                                    <div className="row mt-2">
                                                        <div className="col-12 col-lg-4">
                                                            <SelectField
                                                                label="Empresa"
                                                                disabled={initialValue?.concluido}
                                                                name="tipoServicioId"
                                                                items={empresas}
                                                                onChange={(event) => {
                                                                    setFieldValue("tipoServicioId", event.value);
                                                                }} />
                                                        </div>
                                                        <div className="col-12 col-lg-4">
                                                            <TextField disabled={false} label="Grúa asignada" name="folio" type="text" />
                                                        </div>
                                                        <div className="col-12 col-lg-4">
                                                            <TextField disabled={true} label="Costo de grúa" name="folio" type="text" />
                                                        </div>
                                                    </div>
                                                    <div className="row mt-2">
                                                        <div className="col-12 col-lg-4">
                                                            <button className="btn" disabled={true}>
                                                                Agregar costo grúa
                                                            </button>
                                                        </div>
                                                    </div>
                                                    <div className="row mt-4">
                                                        <div className="col-12">
                                                            <span className="fw-bold text-xs">Indicar ubicación de origen</span>
                                                        </div>
                                                    </div>
                                                    <div className="row mt-2">
                                                        <div className="col-12 col-lg-4">
                                                            <TextField disabled={false} label="Fecha de resguardo" name="folio" type="date" />
                                                        </div>
                                                        <div className="col-12 col-lg-4">
                                                            <TextField disabled={false} label="Horas de resguardo" name="folio" type="text" />
                                                        </div>
                                                        <div className="col-12 col-lg-4">
                                                            <TextField disabled={true} label="¿Quién entrega?" name="folio" type="text" />
                                                        </div>
                                                    </div>
                                                    <div className="row mt-2">
                                                        <div className="col-12 col-lg-4">
                                                            <TextField disabled={false} label="Usuario Recibe" name="folio" type="text" />
                                                        </div>
                                                        <div className="col-12 col-lg-4">
                                                            <TextField disabled={false} label="Calle y número" name="folio" type="text" />
                                                        </div>
                                                        <div className="col-12 col-lg-4">
                                                            <TextField disabled={true} label="Código Postal" name="folio" type="text" />
                                                        </div>
                                                    </div>
                                                    <div className="row mt-2">
                                                        <div className="col-12 col-lg-4">
                                                            <TextField disabled={false} label="País" name="folio" type="text" />
                                                        </div>
                                                        <div className="col-12 col-lg-4">
                                                            <TextField disabled={false} label="Estado" name="folio" type="text" />
                                                        </div>
                                                        <div className="col-12 col-lg-4">
                                                            <TextField disabled={true} label="Municipio" name="folio" type="text" />
                                                        </div>
                                                    </div>
                                                    <div className="row mt-2">
                                                        <div className="col-12 col-lg-4">
                                                            <TextField disabled={false} label="Colonia" name="folio" type="text" />
                                                        </div>
                                                    </div>
                                                    <div className="row mt-2">
                                                        <div className="col-12 col-lg-12">
                                                            <TextAreaField
                                                                id="comentarios"
                                                                label="Comentarios"
                                                                className="form-control"
                                                                rows="3"
                                                                name="comentarios"
                                                                type="text"
                                                                holder="Escribe"
                                                            />
                                                        </div>
                                                    </div>
                                                    <div className="row mt-4">
                                                        <div className="col-12">
                                                            <span className="fw-bold text-xs">Indicar ubicación de destino</span>
                                                        </div>
                                                    </div>
                                                    <div className="row mt-2 mb-2">
                                                        <div className="col-12 col-lg-4">
                                                            <SelectField
                                                                label="Ubicación"
                                                                disabled={initialValue?.concluido}
                                                                name="tipoServicioId"
                                                                items={ubicaciones}
                                                                onChange={(event) => {
                                                                    setFieldValue("tipoServicioId", event.value);
                                                                }} />
                                                        </div>
                                                        <div className="col-12 col-lg-4">
                                                            <TextField disabled={false} label="Fecha de recolección" name="folio" type="date" />
                                                        </div>
                                                        <div className="col-12 col-lg-4">
                                                            <TextField disabled={false} label="Hora de recolección" name="folio" type="time" />
                                                        </div>
                                                    </div>
                                                </div>
                                            </div>
                                            <div className="row d-flex justify-content-between mt-2">
                                                <div className="col-auto">
                                                    Programada
                                                </div>
                                                <div className="col-auto d-flex">
                                                    <button
                                                        type='reset'
                                                        id="AT_BtnCloseModal"
                                                        className='btn btn-outline me-2'
                                                        style={{ paddingLeft: '25px', paddingRight: '25px' }}
                                                        onClick={() => { setIsOpen(false) }}
                                                    >
                                                        Cancelar
                                                    </button>
                                                    <button
                                                        type='reset'
                                                        id="AT_BtnCloseModal"
                                                        className='btn'
                                                        style={{ paddingLeft: '25px', paddingRight: '25px' }}
                                                        onClick={() => { setIsOpen(false) }}
                                                    >
                                                        Guardar
                                                    </button>
                                                </div>
                                            </div>
                                        </div>
                                    </div>
                                </Form>
                            </section>
                        </Modal>
                        {
                            loading &&
                            <Modal isOpen={loading} color='transparent'><Spinner message={""} /></Modal>
                        }
                        {/* {
                            isOpenModalPago &&
                            <ModalAddSolicitarExpedienteSiniestro
                                item={{
                                    seguroSiniestroSeguimientoProcesoDatamovilId: item?.seguroSiniestroSeguimientoProcesoDatamovilId,
                                    siniestroProcesoDatamovilId: item?.tipoProceso,
                                }}
                                setIsOpenModalPago={setIsOpenModalPago}
                                isOpenModalPago={isOpenModalPago}
                                handleGetAll={() => { GetAllProceso(1) }}
                            />
                        } */}
                    </>
                )
                }
            </Formik>
        </>
    );
}

export default SolicitarRecoleccionUnidadModal;