import React, { useEffect, useRef, useState } from 'react';
import { toast, Toaster } from 'react-hot-toast';
import { message } from '../../../../Utils/functions'
import Modal from '../../../../Components/Modal/Modal';
import Spinner from '../../../../Components/Loadig';
import AuthLogin from '../../../../Utils/AuthLogin';
import DataTable from '../../../../Components/datatable/DataTable';
import Siniestros from '../../../../Services/Siniestro/Siniestro';
import { useDispatch, useSelector } from 'react-redux';

const auth = new AuthLogin();
const SegumientoProcesoDatamovil = ({ Id}) => {
    const { count } = useSelector((state) => state.reloadSlice);

    let url = auth.UrlDataDocs();
    const [loading, setLoading] = useState(false);

    useEffect(() => {
        GetAllProceso();
    }, [count]);

 
    const [collpsableGrua, setCollpsableGrua] = useState(false);
    const [data, setdata] = useState([])

    /* CONSULTAS */
    const GetAllProceso = async (page = 1) => {
        // setLoading(true);
        await Siniestros.GetAllProcesoDatamovil(page, 10, Id)
            .then(res => {
                let data = res.data.data;
                let total = 0
                let newData = data?.map((item, idx) => {
                    total += item.total;
                    return { ...item, folio: idx + 1 }
                });
                setdata(newData);
                // setTotalPages(res.data.totalPages);
                // setLoading(false);
            }).catch(e => {
                // setLoading(false);
            })
    }
    /*  TERMINA CONSULTAS */

    /* CATALOGOS */
    /* TERMINA CATALOGOS */

    const columns = [
        { field: 'folio', headerName: '#', width: '30px' },
        { field: 'usuario', headerName: 'Usuario solicito', width: '150px' },
        { field: 'tipoTramite', headerName: 'Tipo de proceso', width: '200px' },
        { field: 'responsable', headerName: 'Responsable', width: '150px' },
        { field: 'fechaSolicitud', headerName: 'Fecha de solicitud', width: '150px' },
        { field: 'estatus', headerName: 'Estatus', width: '120px' },
        { field: 'fechaUltimaActulizacion', headerName: 'Último movimiento', width: '150px' },
    ];

    return (
        <>
            <Modal isOpen={loading} color='transparent'><Spinner message={""} /></Modal>
            <Toaster
                position="top-right"
                toastOptions={{
                    success: {
                        style: {
                            background: '#47a066',
                            color: '#FFFF',
                            borderLeft: '10px solid #2f7246'
                        },
                    },
                    error: {
                        style: {
                            background: '#d53f3f',
                            color: '#FFFF',
                            borderLeft: '10px solid #ac241a'
                        },
                    },
                }}
            />
            <div className='d-flex flex-column background-grey' style={{ height: 'auto' }}>
                <div className='row mx-md-4 my-4' >
                    <div className="col-12 px-md-3">
                        <div className="p-4 white-wrapper mb-2">

                            <div className="row mt-2 px-4" data-bs-toggle="collapse" href="#collapseProcesoDatamovil" role="button" aria-expanded="false" aria-controls="collapseProcesoDatamovil" onClick={() => setCollpsableGrua(!collpsableGrua)}>
                                <div className="col-11">
                                    <h6><strong>Seguimiento proceso Datamovil</strong></h6>
                                </div>
                                <div className="col-1 col-md-1 collapsable-icon" style={{ 'textAlign': 'right' }}>
                                    <i className={!collpsableGrua ? "icon-blue bx bx-chevron-down x2" : "icon-blue bx bx-chevron-up x2"}></i>
                                </div>
                            </div>
                            <div className="collapse" id="collapseProcesoDatamovil">
                                <div className="separator"></div>
                                <div className="row mt-2 px-4">
                                    <div className="col-12">
                                        <DataTable 
                                            data={data}
                                            column={columns}
                                        />
                                    </div>
                                </div>
                            </div>
                        </div>
                    </div>
                </div>
            </div>
        </>
    )
}
export default SegumientoProcesoDatamovil