import { Formik, Form, Field } from 'formik'
import React, { useRef, useState } from 'react'
import * as Yup from 'yup';
// import Modal from '../../../Components/Modal/Modal';
// import { SelectField } from '../../../Components/SelectField';
import { TextAreaField } from '../TextAreaField';
import Modal from './Modal';
import { SelectField } from '../SelectField';

const SolicitarClaveModal = ({ isOpen, setIsOpen}) => {
   const refbtn = useRef();

   let initial = {
      motivo: "",
      vigencia: "",
      status: false,
      comentarios: ""
   }

   const [initialValue, setInitialValue] = useState(initial);

   const dataMotivo = [
      { value: 1, label: "Motivo 1"},
      { value: 2, label: "Motivo 2"},
      { value: 3, label: "Motivo 3"},
      { value: 4, label: "Motivo 4"},
   ]

   const dataVigencia = [
      { value: 1, label: "Vigencia 1"},
      { value: 2, label: "Vigencia 2"},
      { value: 3, label: "Vigencia 3"},
      { value: 4, label: "Vigencia 4"},
   ]

   const validate = Yup.object({
      motivo: Yup.string()
         .required("El campo es requerido."),
      vigencia: Yup.string()
         .required("El campo es requerido.")
   });

   const handleSend = async (values) => {
      console.log(values);
      setIsOpen(false);
   }

   return (
      <Formik
         enableReinitialize={true}
         initialValues={initialValue}
         validationSchema={validate}
         onSubmit={(values) => {
               handleSend(values);
         }}
      >{
         ({ isValid, values, setFieldValue }) => (
            <>
                  <section className='my-2 mx-4' >
                     <Form>
                        <span className='fs-4' style={{fontWeight:'bold', color:"#000"}}><strong>Solicitar clave</strong></span>
                        <p className='mt-3'><span style={{color: "#000", fontWeight: "bold"}}>1. Datos de ruta</span></p>
                        <div className="mb-3 row" style={{marginTop:30}}>
                        <div className="col-sm-6">
                                 <SelectField  label="Motivo" name="motivo" items={dataMotivo} onChange={(e) => {
                                    setFieldValue("motivo", e.value != 0 ? e.value : "");
                                 }} />
                              </div>
                              <div className="col-sm-6">
                                 <SelectField  label="Vigencia Clave" name="vigencia" items={dataVigencia} onChange={(e) => {
                                    setFieldValue("vigencia", e.value != 0 ? e.value : "");
                                 }} />
                              </div>
                        </div>
                        <div className="mt-4 row">
                           <span><strong>Comentarios</strong></span>
                           <div className="col-sm-12 mb-2">
                              <TextAreaField
                                    id="comentarios"
                                    className="form-control"
                                    rows="3"
                                    name="comentarios"
                                    type="text"
                                    holder="Escribe"
                              />
                           </div>
                        </div>
                        <div className="mb-3 row">
                           <div className="form-check form-switch">
                              <label className="form-check-label">{values?.status ? "Uso interno" : "Inactivo"}</label>
                              <Field className="form-check-input" type="checkbox" name="status" />
                           </div>
                        </div>
                        <section className='d-flex justify-content-end mt-5'>
                           <button id="AT_btnCancelar" className='btn btn-outline col-4 mw-100' type='reset' onClick={() => { setIsOpen(false) }}>Cancelar</button>
                           <button id="AT_btnGuardar" className='btn mx-2 col-4 mw-100' ref={refbtn} type="submit" disabled={!(isValid)}>Enviar</button>
                        </section>
                     </Form>
                  </section>
            </>
         )
      }
   </Formik>
   )
}

export default SolicitarClaveModal
