
export const Module = {
    Inventario: "0030f26f-6af2-400e-a7b5-3aebf4e710ec",
    Seguros: "03b1e9d4-96c5-4512-b956-dccf481bc516",
    Garantia: "fd580eb2-28ed-4cc3-aa99-536181774d51",
    Cobranza: "aad0e377-f889-4d33-9afa-0b66eb0c4731",
    Gestoria: "b3549cdd-cb7c-4555-8a66-96d792b5b781",
    ProcesoDeCierre: "0234873c-c80b-4122-a10c-a998ef75dac4",
    PanelAdministrativo: "053f88fd-ae9a-4e69-87e1-e20f042ae090",
    DatamovilSystems: "4b3bd3c2-9c87-4a9c-a25b-9b5743769d89"
}

export const SubModule = {
    //SubModule - PanelAdministrativo
    Usuarios: "ed63b1b9-7950-4f30-8846-24b7bccfdc53",
    TablasSecundarias: "b0d35045-68e2-4d15-992d-2b386a7de064",
    Logs: "d1a58a00-b1cd-4f01-b8f6-2bed53ac964b",
    Parametros: "f63b392c-eb8f-46fc-9bba-3c1819e015d2",
    Notificaciones: "33df5e4b-1695-432e-8770-73af82960bc0",
    Catalogos: "20257e78-ed51-45c0-a04e-9b315c60e23f",
    ConfiguracionDeEtapas: "01d3f482-0caa-481f-b976-f517160e1dde",
    ConfiguracionDeInventario: "4d781d6e-c3ed-4933-8a53-5c90a96f3dda",
    DataDocs: "6bd59c8c-f72a-4121-82cc-0736bf3fdaff",
    DataDocsConfig: "450f63df-c773-4008-a089-e6e411398468",
    Roles: "0bf67dde-7eae-4035-b611-4c6d612c277b",
    DiasFestivos: "57a7cac0-67f7-4cd7-999a-5df060d2a23f",
    ProgramacionDeJobs: "30db9043-7376-42b0-b6f8-9b697f3f780e",
    ParametrosCartera: "dd78e8b1-8976-4843-a04f-dd68925d0193",
    ParametrosHistorialCartera: "3dc920ac-b92e-417a-b770-db81ae5a4c0f",
    //
    //SubModule - Seguros
    CotizaciónMensual: "e24839ed-8c2a-48f7-912c-83402c10b166",
    EmisiónDePolizas: "79243f97-4017-4e56-9c2c-ed2b0cc434c6",
    RenovaciónDeSeguros: "06bdcd96-462b-4d70-87c0-dda13699c9b0",
    SeguroAutorizado: "c6fef6ef-22f7-42d6-b1fa-c41d659c525b",
    VehículosCotizados: "b21b47af-b2f2-4b1e-b708-ce3728946898",
    VehículosSinCotización: "149e815e-2d9d-4c18-a31f-2f43671fc72c",
    SegurosSinRenovar: "479c25fa-1702-4d58-ad66-5177713d9a5f",
    CancelacionDeSeguro: "c94d0c7b-6043-4ec8-8ec4-22ac62a7da56",
    PolizasCanceladas: "8eb2e59d-2594-4880-81db-affd3e5997bf",
    PagoYDesembolso: "165a42b9-6aa0-4488-b683-d535c5a5284a",
    PolizasVigentes: "b31da4a3-036a-4be8-bfaf-11e0dbfb64f9",
    Comisiones: "75f5b873-3ce9-4956-8dc0-640b996c4a84",
    SeguimientoSiniestro: "715af376-69be-453e-bb71-4847ec5bc5c5",
    AutorizacionDeSolicitudes: "1628c47b-0b5d-44a9-9dd8-5fdae258572c",
    //
    //SubModule - Inventario
    Datamovil: "a493831d-f0a9-4b53-955f-b80fec122102",
    ClavesDemo: "8114931a-45b6-4c1d-80a6-caa12dc9db64",
    Endoso: "d9d98b48-27a2-471e-8d6f-63d3999c4e56",
    Entrega: "4887948b-020c-4aee-b37b-268afaddcdfb",
    Expediente: "b5ce40f9-c2ee-4623-aa3f-6663fb4e42ef",
    FirmaDeContrato: "71b3bc01-a973-4cef-8c0b-350f3fd7c86a",
    GestoríaDeVehículos: "fe8804b5-3372-4966-9ae4-ca8a2fc29515",
    HistorialDeContratos: "df58fd88-b63e-4202-9e4b-72a3966e0ac3",
    InstalaciónDatamovil: "a7aad088-1a5c-499b-9e9f-f85a86df9f76",
    PruebaMeManejo: "23a50f0e-ee02-42be-adcd-eb67e1fb2586",
    SocioComercial: "255747d3-a40f-4011-b291-2f9d98a945a1",
    VehículoUtilitario: "1e9411b8-7daa-4b94-9a9a-344fb1088e6a",
    VehículosConvertidos: "20f3221a-25a3-41bb-ba33-fe3b1859390e",
    VehículosEnCrédito: "f056cfd2-d854-47f3-804d-bb10d41a4d77",
    VehículosEnEnganche: "ff53ae22-9119-43b3-9919-e356f593247c",
    VehículosSeparados: "fe162898-9888-411c-a11e-032016e8689f",
    VentaContado: "f06ab626-e4b5-4cb8-ae7f-29dacf2c5ca4",
    EliminarExterno: "bc660911-6900-4206-8b67-8306aa610e12",
    ComisionesInventario: "22103a51-5ce0-4e5c-b614-ce2e16fbf3b5",
    RevisionUnidadRechazada: "9532b38d-d304-4a84-b714-509d8274425c",
    PagoUnidad: "2ffddf69-d069-4cce-8206-df4f04ac5028",
    ResguardoUnidad: "f6e13bca-1d95-4bb2-b7a8-dee475af1d24",
    SeguimientoValuacion: "0a3e9c6f-c563-4362-9370-1a9443fe9cad",
    //
    //Submodule - Gestoria
    PagoRefrendoPlacas: "936f056d-a3e1-4177-9a45-34ec842f4095",
    PendienteEnviarAcendes: "6b47ad38-7de0-45fa-ae59-1aab99921674",
    //
    //Submodule - Cobranza    
    SeguimientoVisita: "f8dfca1e-60b3-49bb-986d-b41cb084fa1b",
    UnidadesRecuperadas: "7c837c86-1b7f-44b2-82ae-adbe8e1654fa",
    //Submodule - DatamovilSystems

    SeguimientoTelefonico: "9aea7e01-ee70-4235-9065-d62d841a0cb5",
    //Submodule - Unidades en remate
    UnidadesRemate: "2b5bdff3-2915-4dc1-979b-6cd6798326ac"
}

export const AccionesRapidas = {
    CambioDeUbicaciones: "bd3b15d0-1c95-4aec-bd9a-ffd6f213a4bd",
    NumSerieDatamovil: "843ee6a0-8575-4de1-b1d4-22438187ee2a",
    Responsiva: "30251463-ca17-4a16-bc54-ec761668b4f0",
    CambioDeEtapas: "b8df495d-29c9-4758-b942-d43f4041c972",
    CambioAInventario: "1d43facb-f6e1-4f80-8cf0-334fdfc86d96",
    Costos: "1c22ebb4-a967-45f4-a854-15ddb25da1c7",
    ConsultaDeClave: "71931bf5-b904-4e90-b8fc-8ccd6ebd73da",
    CambioProducto: "d8ed7666-8cc7-4415-bd8b-f0ea9cffa229"
}

export const Section = {
    // ####################################
    // ############## ADMIN ##############
    // ####################################
    //Section - Catalogos    
    Intelimotor: "9a7a9f4e-af8d-46e6-8b68-138488b0727d",
    SincronizarCatálogos: "f3ace856-2415-4d4e-a02f-7c995dea8627",
    //
    //Section - Usuarios    
    // EditarUsuario: "1569df3f-8fae-4be5-90c0-54346afd307f",
    // NuevoUsuario: "3af66fa1-b242-48db-a49c-824dd5d2c6d6",
    UsuariosRegistrados: "47b0a828-3980-40d4-88aa-d0c39cc19bd3",
    //
    //Section - Notificaciones
    TiposDeCorreo: "0cef137d-3ae4-4a24-aaf0-214ea6b25f8f",
    //
    //Section - Parametros   
    ActualizarTokenAcendes: "c8ec0c4e-0d90-4350-a090-2f7713bdfcb0",
    HistorialDataware: "c6229c54-8f77-44e9-bdca-5dcefe89ff34",
    ParametrosIntelimotor: "c6e986f7-9f8c-449d-9e76-85ff38b2a881",
    HistorialIntelimotor: "8026a638-cdab-4fc1-b657-90b95707a61d",
    HistorialAcendes: "4fd69d25-f8c7-4ed7-835e-965935964a61",
    ParametrosAcendes: "ea0b4e60-2a1a-4bfe-94bd-acd548e5cd5c",
    ParametrosDataware: "ccf83a08-12f2-433d-a27d-af26ff5f15bf",
    ParametrosDatadocs: "4b9feca2-6f13-4d83-8147-c3c0397bd3a5",
    ActualizarTokenDatadocs: "a3315bbe-c7be-486f-b602-ee28ae6569b1",
    HistorialDatadocs: "2f49dd3e-5866-4ebb-b19a-f4d14cb3513e",
    HistorialParametrosCartera: "aa6e665e-a370-488a-884d-656d1b128131",
    //
    //Section - TablasSecundarias   
    TablasSecundariasOriginacion: "21024ce6-b17c-4222-b578-43003d2ca7d7",
    TablasSecundariasCartera: "d08aba21-77cf-49ea-a34e-3923b6a76d70",
    //
    //Section - Configuracion de etapas  
    ConfiguraciónDeReglas: "09c4f062-c2b7-489f-b2d8-424035ed5502",
    ConfiguraciónDeEtapas: "c25d1c8d-55c8-48a2-a0e2-75230202b7e8",
    HistorialDeEtapas: "5c633832-95f4-4f7d-b299-b2445d5d546a",
    HistorialDeReglas: "07b1bdf4-8c25-45cd-9ee2-cb06e7d97456",
    //
    //Section - Configuracion de Inventario  
    DataWare: "81ab6e2c-8a6a-4203-b8ab-1a19234fb9c8",
    EliminarIntelimotor: "717f3208-68ab-4fd0-ba8c-37f19a665fbc",
    AltaIntelimotor: "982743eb-a7ef-424f-8f44-687cbc8526fe",
    //
    // section- logs    
    Sistema: "1441b0c2-6baa-4679-bbbe-18188e65cd19",
    Interfaces: "14196256-ad9f-480e-8e97-898fd3d85d9f",
    Usuario: "8822240b-3de9-4251-af32-8bcf724e6a07",
    //
    //Section - DataDocs  
    ArchivosEliminados: "423ecc62-a22a-4f35-8df4-ba6fff840b19",
    DocumentosPorEtapa: "3050beae-42fd-416e-bcdb-892d81d2244a",
    DocumentosPorUsuario: "743f5343-b59e-44a4-89cc-45898d4334db",
    Folder: "99987093-1414-4e0a-8b36-db123edec538",
    //
    //Section . Programacion Jobs
    Cartera: "32888ed3-2fa0-4811-9ced-c454bdde044d",
    Originacion: "924c593a-d03d-4af8-8cd0-1626bd9a41b2",
    //
    //Section - DataDocs configuracion
    DataDocsConfig: "94cb654f-6ca8-4155-a289-6552298d8189",
    //Section - Roles
    ConfiguracionDeRoles: "2c01c851-a804-4bb5-bc52-dca2a50908de",
    //
    // ####################################
    // ############# SEGURO ##############
    // ####################################
    // Vehículos sin cotización
    Vehículoscotizados: "f69ebb8c-3a71-4673-a528-722f956ef642",
    // Seguros sin renovar
    VehículosSinCotización: "87df960d-63cc-4739-8ba1-3c9bc78fc436",
    // Cotización mensual
    CotizaciónMensualDeSeguros: "6d1db5bd-5077-49e0-a951-592044f15054",
    // Seguro autorizado
    SegurosProporcionales: "52d173c3-4d41-4fbd-b2c8-123ae1247163",
    SeguroAutorizado: "83f08432-3861-4c8e-93be-7762fda3aedd",
    PendienteAutorizar: "267a63bf-9e4f-47a2-a1c1-aa2e61dbd533",
    // Renovación de seguros
    SegurosRenovados: "776c2bb8-10f5-4676-aeb5-70f2bf1de06c",
    VehículosUtilitarios: "6eea3982-79d3-497e-bef1-7cbbdd395c7e",
    VehículosEnContrato: "424d08a5-8b27-40e1-ae6b-d108854dfda6",
    // Emisión de polizas
    SeguroAutorizadoPoliza: "3b4d07db-1846-48a5-8b18-32ea98993b25",
    SeguroRenovado: "ae38ef09-4401-4137-82bf-4aa1c172e304",
    VehiculoUtilitarioPoliza: "456e7895-52d4-4f7f-a019-94cc71c97a15",
    ReenvioPolizas: "d67a563c-36c8-43f3-90f0-023b1c546794",
    //Vehicuos sin renovar
    VehículosSinRenovacionDeSeguro: "2e4446e8-e7b2-4802-8f51-4a985d684788",
    //CancelacionSeSeguro
    PolizaPagada: "27e1addd-24cf-4081-a4fe-16b80db9a3ee",
    PolizaEmitida: "b64d5376-4731-4178-a912-aa896576a2fa",
    PendientesDeDesembolsoDePago: "4f35f74b-8ba0-443f-b1cd-701530ef90e3",
    //Polizas Canceladas
    PolizasNoPagadas: "b3d1fe0e-a640-4de3-8b83-a40a4f68c14b",
    PolizasPagadas: "b108c18d-253d-418f-b36c-d02d4d4d4d2e",
    //Pago y Desembolso
    PendientesDePago: "3ad93954-4f52-4586-816e-02abbad4e2da",
    ProcesoDePagoPD: "e2aaa9d3-3ca2-4905-a6a3-3b5161b37140",
    //PolizasVigentes
    PolizasVigentes: "6d768a3b-2730-4e74-95b2-d86885b583f2",
    //Comisiones
    PendientesDePagoC: "f57fbaf6-c695-4f45-9281-b5edf001b292",
    ProcesoDePagoComision: "edd492c5-667e-43b7-a243-05e54287923e",
    //->Siniestro
    SeguimientoSiniestroSection: "3098d65c-f059-4060-a3b2-4de1f9f0873d",
    // AutoriarDictamen
    SolicitudesDeCitas: "bc7de716-1dd1-412a-bc09-0cc8d586c0bc",
    AutorizarDictamen: "3043397d-97a8-4dbe-a79c-7b363614f0c4",
    ValuacionEnRobo: "8c4622cd-84be-4728-b29a-fad2e71aa627",
    // ####################################
    // ########### Inventario #############
    // ####################################

    //DataMovil
    VehiculosDataMovil: "ba200afa-d49b-47d7-849f-671a34362cce",
    // SocioComercial
    VehiculosSocioComercial: "f62aa7bf-93b8-4aa9-a7e6-3b807fff6aa9",
    // VehiculoUtilitario
    VehiculoUtilitario: "6b2ac2db-de2f-4616-b5d9-9ea691db79dc",
    //Prueba de manejo
    SeguimientoDePruebaDeManejo: "7478e4d0-28d4-49d0-953a-0ca05d36437c",
    // VehicullosSeparados
    VehiculoSeparado: "e209bb37-7895-4ccd-8976-fdd15d6526ab",
    //Vehiculos en Enganche
    // VehículosEnEnganche: "15de08fc-5b68-4418-a03d-3e77ff9d114c",
    EngancheCompleto: "15de08fc-5b68-4418-a03d-3e77ff9d114c",
    PendienteDeEnganche: "af19c129-eabc-4e73-a64c-5de7b922d135",
    // instalacion datamovil
    VehículosDeSocioComercial: "2b225325-6246-41c0-a612-5bea0f4e64ff",
    ProgramadoEnContrato: "e7bad571-9279-40cb-9820-5e749097135f",
    VehículosDeInventario: "07be0208-1bd4-4226-80fc-a21a33c8698b",
    //Firma  de contrato
    VehículosEnFirmaContrato: "be7d4368-ebf3-481f-a6bc-2d0ce255cd49",
    //Gestoria de vehiculos
    CancelaciónDeContratoPorGestoría: "bd7637e8-c507-4cea-9a02-12b90e6502ea",
    VehículosEnGestoría: "2b666396-d52f-4378-8388-b1761e4019c5",
    VehículosPendientesDePlacas: "3ca05c67-848c-4056-bbaa-d558085e1737",
    GestoríaRealizada: "fed9028b-093a-4814-be78-ed84191283a9",
    //endoso
    PendientesDeEndoso: "13a7643c-6796-4742-91e8-595206055cb0",
    EndosoValidado: "1bbb894b-1586-46d5-8d81-a49f1ed65a78",
    //Entrega
    // EntregaDeVehículos: "a008b121-1e88-4f25-8115-98a8b6996fc9",
    PendientesPorCancelar: "0656bebc-fac3-48cb-aebd-76d6c68add5e",
    VehiculosEnEntrega: "a008b121-1e88-4f25-8115-98a8b6996fc9",
    //Expediente
    VehículosExpediente: "3064db23-d66d-4848-94a2-120b87131f33",
    //Vehiculos en credito
    VehículosEnCrédito: "4f4cffed-f4d2-42c9-988c-44c8e17ac627",
    //Historial de contratos
    HistorialDeContratos: "72e58d2e-aeb8-495e-9f6f-3afd3cd90140",
    //Vehiculos Convertidos
    VehículosConvertidos: "a50ddaa3-95dc-4c32-96f7-36c19d3c2617",
    //Venta de contado
    VentaDeContado: "4f79946f-be96-4d1a-9851-dd96feb511b0",
    SeguimientoDeVentaDeContado: "ff0bb6e0-a798-4580-8a04-a5fe839d1a32",
    VentacontadoConcluido: "1da2ee68-f57b-4650-9c99-db6d1a421c68",
    //Eliminar Exeterno
    PendientesPorEliminar: "916ff066-03c6-4e0c-9797-9c55cb7afa73",
    //Comisiones Inventario
    //-> Comisiones de empresa
    ComisionesDeEmpresa: "4f16eb36-3b3b-431b-b337-08ff5cd8b055",
    ComisionesPendientesDePago: "7b51c318-7ef2-4544-adef-3235c0e69bfc",
    ComisionesProcesoDePago: "6c97a44e-3cde-43f6-a2c3-49b1586e1b4e",
    ComisionesPagadasEmpresa: "160025c8-06d2-4aac-9597-63875f5d5ed1",
    //-> Comisiones Externo
    ComisionExterno: "176cd868-d223-417f-b97d-6777df1b198a",
    ComisionesPendientes: "c611a03a-d523-4e1c-844c-68871dea42f8",
    PagoComisionesEnProceso: "16d75610-3a15-4017-9e67-70916ad15aca",
    ComisionesPagadasExterno: "c5d768fb-b445-485c-a395-82c2355c9377",
    //-> Comisiones Externo
    CobroComisionExterno: "6d0b46b1-e7f6-47e9-847b-9cc75e44faf6",
    CobroComisionesPendientes: "76f6cf5c-b4a8-489c-a308-a485929e581f",
    CobroComisionesProceso: "2d1d5d11-2295-4065-9c0e-d86ab005e00f",
    ComisionesExternasCobradas: "ae2e91ae-24ee-4960-ab1e-e63350c4e79c",
    //ClavesDEmo
    ConsultaClavesDemo: "b591a93d-640a-4b21-bc79-b6ce97c3e7e4",
    //->RevisionUnidadRechazada
    RevisionUnidadRechazadaSection: "1eb69199-f526-4ab7-b904-82508167b4a7",
    //->Unidades Resguardadas
    ResguardoUnidadSection: "74060f9c-c74c-46cc-bc94-532bfff6717b",
    //ValuacionUnidad
    SeguimientoValuacionSection: "b671e8ff-2b95-4019-ae26-710d979d2c1c",
    // ####################################
    // ######## Acciones Rapidas ##########
    // ####################################
    CambioDeUbicacionesSection: "510994a8-eea7-49cd-aba7-c92196c50062",
    NumSerieDatamovilSection: "39fe484c-e070-4d35-b47d-c989d8c80d0b",
    ResponsivaSection: "67310727-2b01-48ad-9f28-137dcd01d9c0",
    CambioDeEtapasSection: "f28b4a4d-9da5-460d-8084-26aa288218db",
    CambioAInventarioSection: "2f40e242-c37f-48df-8a31-536143378b3b",
    CostosSection: "da8cb8c1-1660-4120-a5d7-e5022cc1b7d7",
    ConsultaDeClaveSection: "5f87516a-0a2d-4dc6-a988-1bbb8ea46a92",
    CambioProductoSection: "51f71986-88a6-4f19-b64f-647b69bed09f",
    // ####################################
    // ############ GESTORIA ##############
    // ####################################
    //->PagoRefrendoPlacas
    PagoRefrendoPlacasSeccion: "76054185-76a5-4688-b082-f05944a71d2a",
    PendienteEnviarAcendesSeccion: "7bc1e8c6-fd8f-48f3-8f67-74894f3ae059",
    // ####################################
    // ############ Cobranza ##############
    // ####################################
    // Seguimiento Telefonico
    Seguimientotelefonico: "8e2906f4-d05d-47ea-bb63-3b96f053ebb6",
    Seguimientoboletinado: "d073bc55-31ba-430c-a1a6-18660bf60048",
    Autorizacionsolicitudes: "908bf260-93a7-4c7b-ad0f-157252aa578c",
    //->Seccion SeguimientoVisita
    CarteraGeneral: "aff18a99-333c-4d0e-9957-0923e5581ef6",
    RutasCobranza: "e463fccc-41f5-48d6-9348-759943d5da8c",
    SeguimientoVisita: "25dcd02c-a994-42f4-b10c-0b2f284508bc",
    HistorialVisitas: "13e05da9-1fae-4288-b976-5273f53c3181",
    //->Seccion UnidadesRecuperadas
    UnidadesRecuperadas: "8c38c507-6785-4d2e-afb1-2d9a0a0dbe50",
    //Seccion Unidades en remate
    UnidadesRemate: "9019ddbf-a0b9-4bba-9d5c-bf821d41b8de"
}

// CambioDeUbicaciones: "bd3b15d0-1c95-4aec-bd9a-ffd6f213a4bd",
// NumSerieDatamovil: "843ee6a0-8575-4de1-b1d4-22438187ee2a",
// Responsiva: "30251463-ca17-4a16-bc54-ec761668b4f0",
// CambioDeEtapas: "b8df495d-29c9-4758-b942-d43f4041c972",
// CambioAInventario: "1d43facb-f6e1-4f80-8cf0-334fdfc86d96",
// Costos: "1c22ebb4-a967-45f4-a854-15ddb25da1c7",
// ConsultaDeClave: "71931bf5-b904-4e90-b8fc-8ccd6ebd73da",
// CambioProducto: "d8ed7666-8cc7-4415-bd8b-f0ea9cffa229"